/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DigestEntryAttachmentDto,
} from '../models/index';
import {
    DigestEntryAttachmentDtoFromJSON,
    DigestEntryAttachmentDtoToJSON,
} from '../models/index';

export interface ApiDigestEntryAttachmentsDownloadImageGetRequest {
    fileName?: string;
}

export interface ApiDigestEntryAttachmentsDownloadVideoGetRequest {
    fileName?: string;
}

export interface ApiDigestEntryAttachmentsDummiesDigestEntryIdNPostRequest {
    digestEntryId: number;
    n: number;
}

export interface ApiDigestEntryAttachmentsIdDeleteRequest {
    id: number;
}

export interface ApiDigestEntryAttachmentsIdUploadFilePutRequest {
    id: number;
    file?: Blob;
}

/**
 * 
 */
export class DigestEntryAttachmentsApi extends runtime.BaseAPI {

    /**
     */
    async apiDigestEntryAttachmentsDownloadImageGetRaw(requestParameters: ApiDigestEntryAttachmentsDownloadImageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters['fileName'] != null) {
            queryParameters['fileName'] = requestParameters['fileName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DigestEntryAttachments/Download/Image`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiDigestEntryAttachmentsDownloadImageGet(requestParameters: ApiDigestEntryAttachmentsDownloadImageGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiDigestEntryAttachmentsDownloadImageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDigestEntryAttachmentsDownloadVideoGetRaw(requestParameters: ApiDigestEntryAttachmentsDownloadVideoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters['fileName'] != null) {
            queryParameters['fileName'] = requestParameters['fileName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DigestEntryAttachments/Download/Video`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiDigestEntryAttachmentsDownloadVideoGet(requestParameters: ApiDigestEntryAttachmentsDownloadVideoGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiDigestEntryAttachmentsDownloadVideoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDigestEntryAttachmentsDummiesDigestEntryIdNPostRaw(requestParameters: ApiDigestEntryAttachmentsDummiesDigestEntryIdNPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DigestEntryAttachmentDto>>> {
        if (requestParameters['digestEntryId'] == null) {
            throw new runtime.RequiredError(
                'digestEntryId',
                'Required parameter "digestEntryId" was null or undefined when calling apiDigestEntryAttachmentsDummiesDigestEntryIdNPost().'
            );
        }

        if (requestParameters['n'] == null) {
            throw new runtime.RequiredError(
                'n',
                'Required parameter "n" was null or undefined when calling apiDigestEntryAttachmentsDummiesDigestEntryIdNPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DigestEntryAttachments/Dummies/{digestEntryId}/{n}`.replace(`{${"digestEntryId"}}`, encodeURIComponent(String(requestParameters['digestEntryId']))).replace(`{${"n"}}`, encodeURIComponent(String(requestParameters['n']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DigestEntryAttachmentDtoFromJSON));
    }

    /**
     */
    async apiDigestEntryAttachmentsDummiesDigestEntryIdNPost(requestParameters: ApiDigestEntryAttachmentsDummiesDigestEntryIdNPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DigestEntryAttachmentDto>> {
        const response = await this.apiDigestEntryAttachmentsDummiesDigestEntryIdNPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDigestEntryAttachmentsIdDeleteRaw(requestParameters: ApiDigestEntryAttachmentsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiDigestEntryAttachmentsIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/DigestEntryAttachments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiDigestEntryAttachmentsIdDelete(requestParameters: ApiDigestEntryAttachmentsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiDigestEntryAttachmentsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDigestEntryAttachmentsIdUploadFilePutRaw(requestParameters: ApiDigestEntryAttachmentsIdUploadFilePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DigestEntryAttachmentDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiDigestEntryAttachmentsIdUploadFilePut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/DigestEntryAttachments/{id}/Upload/File`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DigestEntryAttachmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDigestEntryAttachmentsIdUploadFilePut(requestParameters: ApiDigestEntryAttachmentsIdUploadFilePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DigestEntryAttachmentDto> {
        const response = await this.apiDigestEntryAttachmentsIdUploadFilePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
