import Legend from "components/Legend";
import { Loader } from "components/Loader";
import { ActualTimelineTabContents } from "components/Timeline/components/Tabs/ActualTimelineTabContents";
import { PlanTimelineTabContents } from "components/Timeline/components/Tabs/PlanTimelineTabContents";
import { useTimelineEventsList } from "hooks/tvd/useTimelineEventsList";
import type { ITimelineInfo } from "pages/WellDashboard/types";
import React, { Suspense, useEffect } from "react";
import { useAppSelector } from "reducers/store";
import { match, P } from "ts-pattern";

export const Timeline = ({
  timelineOverride,
  setTimelineOverride,
}: {
  timelineOverride?: ITimelineInfo | null;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null>;
}) => {
  const { actualEvents, planEvents } = useAppSelector(
    (state) => state.timeline.events,
  );

  const { planTimelineEventsList, actualTimelineEventsList } =
    useTimelineEventsList({ actualEvents, planEvents });
  const selectedTimeline = useAppSelector(
    (state) => state.state.timeline_state,
  );
  const actionEnabled = useAppSelector((state) => state.timeline.actionEnabled);

  useEffect(() => {
    if (!actionEnabled) setTimelineOverride(null);
  }, [actionEnabled, setTimelineOverride]);

  return (
    <>
      {match({ selectedTimeline })
        .with({ selectedTimeline: "Legend" }, () => <Legend />)
        .with({ selectedTimeline: "Plan" }, () => (
          <PlanTimelineTabContents
            events={planTimelineEventsList}
            timelineOverride={timelineOverride || null}
            setTimelineOverride={setTimelineOverride}
          />
        ))
        .with({ selectedTimeline: P.union("ActualMemos", "ActualWeb") }, () => (
          <Suspense fallback={<Loader centered />}>
            <ActualTimelineTabContents
              events={actualTimelineEventsList}
              timelineOverride={timelineOverride}
              setTimelineOverride={setTimelineOverride}
            />
          </Suspense>
        ))
        .otherwise(() => null)}
    </>
  );
};
