import { SelectorButton } from "components/RigsHeader/SelectorButton";
import { StyledGap } from "components/RigsHeader/style";
import {
  linkedFiltersIconMap,
  popupInitialState,
} from "components/RigsHeader/utils";
import { linkedFilters } from "hooks/filters/useFilterTags";
import {
  AttributeStore,
  useLinkedWellAttributes,
} from "hooks/filters/useLinkedAttributes";
import { useOnWindowScroll } from "hooks/react-utils/useOnWindowScroll";
import React, { useCallback, useImperativeHandle, useState } from "react";
import { Track } from "services/Mixpanel";

export type LinkedSelectorRef = {
  closePopups: () => void;
};

export const LinkedSelectors = React.forwardRef(
  (
    {
      storeName,
    }: {
      storeName: AttributeStore;
    },
    ref: React.ForwardedRef<LinkedSelectorRef>,
  ) => {
    const { options, selectedValues, updateFilters, isSelected } =
      useLinkedWellAttributes({
        storeName,
        closePopups: () => {
          setPopupState(popupInitialState);
        },
      });

    const [popupState, setPopupState] =
      useState<Record<(typeof linkedFilters)[number], boolean>>(
        popupInitialState,
      );

    useOnWindowScroll(() => {
      setPopupState(popupInitialState);
    });

    useImperativeHandle(
      ref,
      () => ({
        closePopups: () => {
          setPopupState(popupInitialState);
        },
      }),
      [],
    );
    const getSelectedValues = useCallback(
      (filterName: (typeof linkedFilters)[number]) =>
        selectedValues[filterName] ??
        (options?.[filterName] ?? []).map((option) => option.id),
      [options, selectedValues],
    );
    return (
      <StyledGap>
        {[...linkedFilters]
          .filter((x) =>
            storeName === AttributeStore.allWells ||
            storeName === AttributeStore.evergreenWells
              ? x !== "wells"
              : true,
          )
          .map((filterName) => {
            const filterNameCapitalized =
              filterName.charAt(0).toUpperCase() + filterName.slice(1);
            return (
              <SelectorButton
                key={filterName}
                onReset={
                  selectedValues[filterName]?.length === 0
                    ? () => {
                        Track.clickEvent(
                          `All Wells - Reset ${filterNameCapitalized}`,
                        );
                        updateFilters(filterName)(null);
                      }
                    : undefined
                }
                options={options?.[filterName] ?? []}
                placeholder={`Search ${filterNameCapitalized}`}
                selectedValues={getSelectedValues(filterName)}
                onChange={(e) => {
                  updateFilters(filterName)(e as number[]);
                  Track.clickEvent(
                    `All Rigs - Select ${filterNameCapitalized}`,
                  );
                  setPopupState(popupInitialState);
                }}
                open={popupState[filterName]}
                onOpenChange={(e) => {
                  setPopupState((prev) => ({
                    ...prev,
                    [filterName]: e,
                  }));
                }}
                isSelected={isSelected[filterName]}
                tooltipName={filterNameCapitalized}
                name={linkedFiltersIconMap[filterName]}
              />
            );
          })}
      </StyledGap>
    );
  },
);
