import { WellStatusType } from "apis/oag";

export const AGGREGATION_MAX_WELLS = 5;
export const OPERATION_COUNT_HEIGHT = 24;
export const TOP_LABEL_HEIGHT = 15;
export const TOP_LABEL_MARGIN = 6;
export const APPROX_CHAR_WIDTH = 6;

export const SIDEBAR_MIN_WIDTH = 250;
export const SIDEBAR_MAX_WIDTH = 376;
export const SIDEBAR_DEFAULT_WIDTH = 400;
export const SIDEBAR_DEFAULT_WIDTH_PERCENT = 20;

export const RIG_OPERATION_COUNT_TOP_MARGIN = -16;
export const RIG_OPERATION_COUNT_LEFT_MARGIN = 36;

export const INSPECTION_VIEW_TICK_HEIGHT = 8;
export const APPROX_TICK_HEIGHT = 17; // Y axis tick height, in PX, default value
export const LARGE_TICK_HEIGHT = 21; // Y axis tick height, in PX, for larger font size
export const LARGER_TICK_HEIGHT = 24; // Y axis tick height, in PX, for detailed larger font size
export const SMALL_TICK_HEIGHT = 14;
export const FALLBACK_NUM_TICKS_Y_AXIS = 5; // For Y axis, default value
export const MIN_TICKS_X_AXIS = 5; // For X axis, default value
export const SHOW_DECIMALS_LIMIT = 30;

export const MOBILE_WIDTH_BREAKPOINT = 768;

export const AVAILABLE_WELL_STATUSES = [
  WellStatusType.Pending,
  WellStatusType.Active,
  WellStatusType.Paused,
  WellStatusType.Completed,
];

export const ALL_WELL_STATUSES = [
  ...AVAILABLE_WELL_STATUSES,
  WellStatusType.Unassigned,
];
