import { DimensionType, ResultDataState } from "apis/oag";
import { LegendAtom } from "components/Legend/LegendAtom";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useTvds } from "hooks/tvd/useTvds";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useWellShortInfo } from "hooks/wells/useWellShortInfo";
import { useCallback, useEffect, useMemo } from "react";
import type { AppDispatch } from "reducers/store";
import { useAppDispatch, useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { PLAN_SERIES_ID, secondsInDay } from "utils/common";
import { useUOM } from "utils/format";
import { useColors } from "utils/useColors";

const Legend = ({ isReport }: { isReport?: boolean }) => {
  const [offsetSelection] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELL,
    [],
  );

  const { isEvergreen } = useDashboardType();

  const showPlan = !isEvergreen;
  const selectedWell = useSelectedWell();
  const selectedWellDetails = useAppSelector(
    (state) => state.timeline.currentWellDetails,
  );
  const { data: wellShortInfo } = useWellShortInfo();

  const selectedSeries = useAppSelector((state) => state.state.selectedSeries);
  const { getColor } = useColors();

  const depthUOM = useUOM(DimensionType.Metres);

  const { data: overview } = useTvds({
    isLegend: true,
    options: { refetchOnMount: false },
  });
  const _dispatch = useAppDispatch();
  const dispatch = useCallback(
    (action: ReturnType<AppDispatch>) =>
      requestAnimationFrame(() => _dispatch(action)),
    [_dispatch],
  );
  useEffect(() => {
    dispatch({
      type: "RESET_WELL_REFRESH_DATE",
    });
  }, [dispatch]);
  const filteredOutWells = useMemo(() => {
    if (!overview) {
      return new Set();
    }
    return new Set(
      (overview?.comparisonTvdSeries ?? [])
        .concat(overview?.tvdSeries ?? [])
        .filter((s) => s.dataState !== ResultDataState.Valid)
        .map((s) => s.wellId),
    );
  }, [overview]);

  useEffect(() => {
    if (
      selectedWell &&
      selectedSeries &&
      selectedSeries !== selectedWell &&
      selectedSeries !== PLAN_SERIES_ID &&
      !offsetSelection.includes(selectedSeries)
    ) {
      dispatch({
        type: "SET_SELECTED_SERIES",
        payload: selectedWell,
      });
    }
  }, [dispatch, offsetSelection, selectedSeries, selectedWell]);

  const isPlanFilteredOut =
    overview?.planTvdSeries?.dataState !== ResultDataState.Valid;
  const isSelectedWellFilteredOut = filteredOutWells.has(selectedWell);

  const selectedWellInfo = `${(
    (selectedWellDetails?.drillingDetails?.cumulativeDuration ?? 0) /
    secondsInDay
  ).toFixed(
    2,
  )} days · ${depthUOM.display(selectedWellDetails?.drillingDetails?.lastHoleDepth ?? 0)}`;

  const focalWellColor = selectedWell
    ? getColor({ key: selectedWell.toString() })
    : "";

  return (
    <>
      {selectedWellDetails?.drillingDetails ? (
        <LegendAtom
          bitDepth={selectedWell === selectedSeries}
          color={isSelectedWellFilteredOut ? colors.default_bg : focalWellColor}
          disabled={isSelectedWellFilteredOut}
          id={selectedWell || -1}
          selected={!isReport && selectedSeries === selectedWell}
          wellInfo={
            isSelectedWellFilteredOut ? "No selected data" : selectedWellInfo
          }
          wellName={selectedWellDetails?.name || ""}
          rigIds={wellShortInfo?.byId[selectedWell || -1]?.rigIds}
        />
      ) : null}
      {selectedWellDetails?.planDetails &&
      selectedWellDetails?.planDetails?.totalDuration !== 0 &&
      showPlan ? (
        <LegendAtom
          id={PLAN_SERIES_ID}
          selected={!isReport && selectedSeries === PLAN_SERIES_ID}
          color={isPlanFilteredOut ? colors.default_bg : colors.off_state}
          wellName="Plan"
          disabled={isPlanFilteredOut}
          wellInfo={
            isPlanFilteredOut
              ? "No selected data"
              : `${(
                  (selectedWellDetails?.planDetails?.totalDuration ?? 0) /
                  secondsInDay
                ).toFixed(
                  2,
                )} days · ${depthUOM.display(selectedWellDetails?.planDetails?.endDepth ?? 0)}`
          }
        />
      ) : null}
      {selectedWell
        ? (offsetSelection ?? []).map((wellId) => {
            if (wellId === selectedWell) {
              return null;
            }
            const isFilteredOut = filteredOutWells.has(wellId);
            const color = getColor({ key: wellId.toString() });
            return (
              <LegendAtom
                key={wellId}
                id={wellId}
                bitDepth={wellId === selectedSeries}
                selected={wellId === selectedSeries}
                color={isFilteredOut ? colors.default_bg : color}
                wellInfo={isFilteredOut ? "No selected data" : undefined}
                wellName={wellShortInfo?.byId[wellId]?.name || ""}
                disabled={isFilteredOut}
                rigIds={wellShortInfo?.byId[wellId]?.rigIds}
              />
            );
          })
        : null}
    </>
  );
};

export default Legend;
