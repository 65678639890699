import { PDComponent } from "components/PDComponents";
import styled, { css } from "styled-components";
import { zIndexLayer } from "utils/zIndex";

export const ImagesContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  position: relative;
  h1 {
    height: 10px;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  &::-webkit-scrollbar {
    height: 5px;
  }

  gap: 6px;
  img {
    width: 80px;
    height: 76px;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const CustomImageContainer = styled.button<{ $isBlurred: boolean }>`
  position: relative;
  border: 0;
  padding: 0;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  overflow: hidden;
  min-width: 80px;
  min-height: 76px;

  img {
    display: block;
    ${({ $isBlurred }) =>
      $isBlurred
        ? css`
            filter: blur(3px) brightness(55%);
            -webkit-filter: blur(3px) brightness(55%);
          `
        : null}
  }
`;

export const PlayIcon = styled(PDComponent.SvgIcon)`
  position: absolute;
  cursor: pointer;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
`;

export const Container = styled.div`
  position: fixed;
  background-color: transparent;
  backdrop-filter: blur(14px);
  z-index: ${zIndexLayer.phobos};
  height: 100lvh;
  width: 100lvw;
  top: 0;
  left: 0;
`;
