import type { IEventProps } from "components/TvDChart/components/TimelineEventIndicator/style";
import styled, { css } from "styled-components";
import colors from "utils/colors";

export const EventIcon = styled.div<
  IEventProps & {
    $isHovered?: boolean;
    $isCombined?: boolean;
    $displayOutline?: boolean;
  }
>(
  (props) => css`
    position: absolute;
    top: ${props.top || 0}px;
    left: ${props.left || 0}px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    transform: translate(${props.translateX || 0}, ${props.translateY || 0});
    color: ${colors.black};

    text-align: center;
    line-height: 28px;

    &:hover {
      outline: 2px solid ${colors.well_color};
    }

    background: ${colors.tern_grey};

    ${props.$isHovered
      ? css`
          background: ${colors.helen_of_troy}4d;
          box-shadow: 0px 0px 1px 6px ${colors.perfect_pear}${props.theme.isDark ? "CC" : undefined};

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: ${colors.white};
            opacity: 0.9;
            z-index: -1;
          }
        `
      : null}
  `,
);
