/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntelGroupingType } from './IntelGroupingType';
import {
    IntelGroupingTypeFromJSON,
    IntelGroupingTypeFromJSONTyped,
    IntelGroupingTypeToJSON,
} from './IntelGroupingType';
import type { QuarterYear } from './QuarterYear';
import {
    QuarterYearFromJSON,
    QuarterYearFromJSONTyped,
    QuarterYearToJSON,
} from './QuarterYear';

/**
 * 
 * @export
 * @interface WellIntelQueryDto
 */
export interface WellIntelQueryDto {
    /**
     * 
     * @type {IntelGroupingType}
     * @memberof WellIntelQueryDto
     */
    grouping: IntelGroupingType;
    /**
     * 
     * @type {Array<QuarterYear>}
     * @memberof WellIntelQueryDto
     */
    selectedQuarters?: Array<QuarterYear> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    wellNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    rigNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    contractorNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    operatorNames?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    formations?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    rigClasses?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    regions?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    fields?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    countries?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    stateProvinces?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    countyParishes?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    basins?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WellIntelQueryDto
     */
    plays?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof WellIntelQueryDto
     */
    minMeasuredDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellIntelQueryDto
     */
    maxMeasuredDepth?: number | null;
}



/**
 * Check if a given object implements the WellIntelQueryDto interface.
 */
export function instanceOfWellIntelQueryDto(value: object): value is WellIntelQueryDto {
    if (!('grouping' in value) || value['grouping'] === undefined) return false;
    return true;
}

export function WellIntelQueryDtoFromJSON(json: any): WellIntelQueryDto {
    return WellIntelQueryDtoFromJSONTyped(json, false);
}

export function WellIntelQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellIntelQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'grouping': IntelGroupingTypeFromJSON(json['Grouping']),
        'selectedQuarters': json['SelectedQuarters'] == null ? undefined : ((json['SelectedQuarters'] as Array<any>).map(QuarterYearFromJSON)),
        'wellNames': json['WellNames'] == null ? undefined : json['WellNames'],
        'rigNames': json['RigNames'] == null ? undefined : json['RigNames'],
        'contractorNames': json['ContractorNames'] == null ? undefined : json['ContractorNames'],
        'operatorNames': json['OperatorNames'] == null ? undefined : json['OperatorNames'],
        'formations': json['Formations'] == null ? undefined : json['Formations'],
        'rigClasses': json['RigClasses'] == null ? undefined : json['RigClasses'],
        'regions': json['Regions'] == null ? undefined : json['Regions'],
        'fields': json['Fields'] == null ? undefined : json['Fields'],
        'countries': json['Countries'] == null ? undefined : json['Countries'],
        'stateProvinces': json['StateProvinces'] == null ? undefined : json['StateProvinces'],
        'countyParishes': json['CountyParishes'] == null ? undefined : json['CountyParishes'],
        'basins': json['Basins'] == null ? undefined : json['Basins'],
        'plays': json['Plays'] == null ? undefined : json['Plays'],
        'minMeasuredDepth': json['MinMeasuredDepth'] == null ? undefined : json['MinMeasuredDepth'],
        'maxMeasuredDepth': json['MaxMeasuredDepth'] == null ? undefined : json['MaxMeasuredDepth'],
    };
}

export function WellIntelQueryDtoToJSON(value?: WellIntelQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Grouping': IntelGroupingTypeToJSON(value['grouping']),
        'SelectedQuarters': value['selectedQuarters'] == null ? undefined : ((value['selectedQuarters'] as Array<any>).map(QuarterYearToJSON)),
        'WellNames': value['wellNames'],
        'RigNames': value['rigNames'],
        'ContractorNames': value['contractorNames'],
        'OperatorNames': value['operatorNames'],
        'Formations': value['formations'],
        'RigClasses': value['rigClasses'],
        'Regions': value['regions'],
        'Fields': value['fields'],
        'Countries': value['countries'],
        'StateProvinces': value['stateProvinces'],
        'CountyParishes': value['countyParishes'],
        'Basins': value['basins'],
        'Plays': value['plays'],
        'MinMeasuredDepth': value['minMeasuredDepth'],
        'MaxMeasuredDepth': value['maxMeasuredDepth'],
    };
}

