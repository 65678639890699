/* eslint-disable react/forbid-dom-props */
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AreaClosed, Bar, LinePath } from "@visx/shape";
import type { ParameterByDepthTrackValuesDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import NoData from "components/Lenses/common/NoData";
import { TrackAxis } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/TrackAxis";
import {
  ChartContainer,
  IconContainer,
  IconsContainer,
  InfoContainer,
  InnerDiv,
  KPIText,
  ParameterAvgKpiVal,
  ParameterComparisonGrid,
  ParameterComparisonGridItem,
  ParameterKpiSlantedName,
  ParameterLeftAxis,
  ParameterName,
  ParameterSlantedName,
  StyledLockIcon,
  TooltipContainer,
  TooltipEntry,
  TooltipExtra,
  TooltipWellColor,
  UOMText,
} from "components/Lenses/ContainerLens/StickSlipByDepth/Chart/style";
import {
  bisectDepth,
  MIN_TRACK_HEIGHT,
  TRACK_SIZE_HEIGHT_INCREMENT_PX,
} from "components/Lenses/ContainerLens/StickSlipByDepth/Chart/utils";
import { useVisibleTracks } from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/hooks/useVisibleTracks";
import type {
  ParameterByDepthTrack,
  ParameterComparisonItemKPIProps,
} from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { curveMonotoneX } from "d3";
import { extent, max } from "d3-array";
import { useTracks } from "hooks/drillingInvariants/useTracks";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import React, {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useInView } from "react-intersection-observer";
import { useResizeDetector } from "react-resize-detector";
import { useAppSelector } from "reducers/store";
import { useDebouncedCallback } from "use-debounce";
import colors from "utils/colors";
import { Typography } from "utils/componentLibrary";
import {
  adjustDecimalPlaces,
  getDecimalsInterval,
  useLockableUOM,
  useUOM,
} from "utils/format";
import { formatTime, truncateMiddleString } from "utils/helper";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

const { Text } = Typography;
const HALF_LETTER_HEIGHT = 6;
const BOTTOM_MARGIN = 12;

export function StickSlipByDepthItem({
  chartWidth,
  forcedUnitSystem,
  depthScale,
  detailed,
  focalWellColor,
  isPointerInsideChart,
  isTooltipEnabled,
  isUnitUnlocked,
  maxChartHeight,
  onPointerPositionChange,
  onTooltipToggle,
  onTrackSettings,
  pointerPosition,
  selectedUnit,
  showGraph,
  trackCount,
  tracks,
  highlightedInterval,
  yMax,
  yMin,
}: ParameterComparisonItemKPIProps & {
  focalWellColor: string;
  isPointerInsideChart: boolean;
  isTooltipEnabled: boolean;
  onPointerPositionChange: (position: { x: number; y: number }) => void;
  pointerPosition: { x: number; y: number };
  onTooltipToggle: () => void;
}) {
  const svgRef = useRef<SVGSVGElement>(null);
  const { height: chartHeightHook, ref: chartRef } = useResizeDetector();
  const { chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
  };
  const { data: wellData } = useWellShortInfoSuspended();
  const depthUOM = useUOM(DimensionType.Metres);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const hasNoData = useMemo(
    () => tracks.length && tracks.every((tr) => !tr.trackValues?.length),
    [tracks],
  );

  const selectedWell = useAppSelector((state) => state.state.selectedWell);
  const { getColor } = useColors();
  const { data: trackData } = useTracks();
  const focalTrack = tracks?.find((track) => track.isFocalWell);
  const trackInfo = trackData
    ? trackData.byId[
        focalTrack?.trackId || (tracks?.length > 0 && tracks[0]?.trackId) || 0
      ]
    : undefined;

  const trackUOM = useLockableUOM({
    dimension: trackInfo?.dimension || DimensionType.Undefined,
    lockUnitSystemTo: forcedUnitSystem,
  });

  const { ref, inView } = useInView({ threshold: 0.5 });

  const averageSIByWell = useMemo(
    () =>
      tracks.reduce<Record<number, number>>((acc, track) => {
        acc[track.wellId] = track.average ?? 0;
        return acc;
      }, {}),
    [tracks],
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `${focalTrack?.trackId || -1}`,
    disabled: !tracks,
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition ?? "",
  };

  const currentChartHeight = useMemo(() => chartHeight ?? 0, [chartHeight]);

  const valueScale = useMemo(() => {
    const values = tracks.flatMap(
      (track) => track.trackValues?.map((item) => item.value ?? 0) ?? [],
    );
    const [minValue, maxValue] = [yMin ?? 0, yMax ?? max(values) ?? 0];

    return scaleLinear<number>({
      range: [currentChartHeight - BOTTOM_MARGIN, BOTTOM_MARGIN],
      domain: [minValue, maxValue],
    });
  }, [currentChartHeight, tracks, yMax, yMin]);

  const comparisonBarScale = useMemo(() => {
    const [min = 0, max = 0] = extent(Object.values(averageSIByWell).concat(0));
    return scaleLinear<number>({
      range: [0, detailed ? 80 : 70],
      domain: [min, max],
    });
  }, [averageSIByWell, detailed]);

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      const { left, top } = event.currentTarget.getBoundingClientRect();
      const x = ("clientX" in event ? event.clientX : 0) - left;
      const y = ("clientY" in event ? event.clientY : 0) - top;
      onPointerPositionChange({ x, y });
    },
    [onPointerPositionChange],
  );

  const sortedTracks = useVisibleTracks(tracks);

  const visibleTrackValues = useMemo(() => {
    const [maxRange, minRange] = valueScale.range();

    return sortedTracks.reduce(
      (acc, track) => {
        acc[track.wellId] = track.trackValues.filter((point) => {
          const value = valueScale(point.value ?? 0);
          return value > minRange && value < maxRange;
        });
        return acc;
      },
      {} as Record<number, ParameterByDepthTrackValuesDto[]>,
    );
  }, [sortedTracks, valueScale]);

  const tooltipInfo = useMemo(() => {
    const x0 = depthScale.invert(pointerPosition.x);
    let eyeTrack: ParameterByDepthTrack | null = null;
    const tooltipData: Record<
      string,
      ParameterByDepthTrackValuesDto & { top: number; left: number }
    > = {};

    for (const track of sortedTracks) {
      if (!track.trackId) {
        continue;
      }

      const trackValues = visibleTrackValues[track.wellId];

      if (!trackValues) {
        continue;
      }

      const index = bisectDepth(trackValues, x0);
      if (index > 0 && Number.isFinite(trackValues[index]?.value)) {
        // First track is the one the eye icon will be displayed
        if (eyeTrack === null) {
          eyeTrack = track;
        }

        const d0 = trackValues[index - 1];
        const d1 = trackValues[index];

        const data =
          x0 - (d0.holeDepth ?? 0) > (d1.holeDepth ?? 0) - x0 ? d1 : d0;
        const top = valueScale(data.value ?? 0);
        const left = depthScale(data.holeDepth ?? 0);

        tooltipData[track.wellId] = { ...data, top, left };
      }
    }

    if (!eyeTrack || !svgRef.current) {
      return null;
    }

    const { top, left, holeDepth, timestamp } = tooltipData[eyeTrack.wellId];

    const containerRect = svgRef.current.getBoundingClientRect();
    if (
      !detailed &&
      containerRect.top + containerRect.height > window.innerHeight + 20
    )
      return null;
    const pageX = left + window.scrollX;

    const selectedBoundary =
      tracks.length == 0
        ? null
        : tracks[0]?.boundaries?.find(
            (b) => (b.measuredDepth ?? 0) >= (holeDepth ?? 0),
          );

    return {
      top,
      left,
      pageX,
      holeDepth,
      timestamp,
      data: tooltipData,
      selectedBoundary,
    };
  }, [
    depthScale,
    detailed,
    pointerPosition.x,
    sortedTracks,
    tracks,
    valueScale,
    visibleTrackValues,
  ]);

  // Visible always in detailed view and only when pointer inside chart in mini view
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);

  useEffect(() => {
    const newValue =
      isTooltipEnabled && inView && (detailed || isPointerInsideChart);
    if (newValue !== isTooltipVisible) {
      setIsTooltipVisible(newValue);
    }
  }, [
    detailed,
    inView,
    isPointerInsideChart,
    isTooltipEnabled,
    isTooltipVisible,
  ]);

  const hideTooltipIfVisible = useDebouncedCallback(() => {
    if (isTooltipVisible && !isPointerInsideChart) {
      startTransition(() => {
        setIsTooltipVisible(false);
      });
    }
  }, 0);

  useEffect(() => {
    window.addEventListener("scroll", hideTooltipIfVisible, true);
    return () => {
      window.removeEventListener("scroll", hideTooltipIfVisible, true);
    };
  }, [hideTooltipIfVisible]);

  const chartHeightAccountingForTooltipExpand = useMemo(() => {
    let height = maxChartHeight || chartHeight;
    if (sortedTracks.length > 1) {
      height += sortedTracks.length * TRACK_SIZE_HEIGHT_INCREMENT_PX;
    }
    return Math.max(height, MIN_TRACK_HEIGHT);
  }, [chartHeight, maxChartHeight, sortedTracks.length]);

  if (!selectedUnit) {
    return null;
  }

  const displayScaleDomain = [
    parseFloat(selectedUnit?.toString(valueScale.domain()[1])),
    parseFloat(selectedUnit?.toString(valueScale.domain()[0])),
  ];

  return (
    <div ref={setNodeRef} style={{ ...style, height: "100%" }}>
      <InnerDiv
        ref={ref}
        detailed={detailed}
        style={{
          minHeight: detailed
            ? 150
            : `${chartHeightAccountingForTooltipExpand}px`,
        }}
        showGraph={showGraph}
      >
        <ParameterName detailed={detailed ? trackCount > 1 : undefined}>
          <IconsContainer>
            <IconContainer
              {...attributes}
              {...listeners}
              cursor={isDragging ? "grabbing" : "grab"}
            >
              <PDComponent.SvgIcon name="move" />
            </IconContainer>
            <IconContainer>
              <PDComponent.SvgIcon name="settings" onClick={onTrackSettings} />
            </IconContainer>
          </IconsContainer>

          <InfoContainer>
            <KPIText $detailed={detailed}>{trackInfo?.name}</KPIText>
            <UOMText $detailed={detailed}>
              ({selectedUnit.measureType ? selectedUnit.measureType : "Avg"})
            </UOMText>
          </InfoContainer>
        </ParameterName>

        {hasNoData ? (
          <NoData isStatic detailed={detailed} />
        ) : (
          <ParameterAvgKpiVal
            detailed={detailed}
            $isSingle={sortedTracks.length === 1}
          >
            {sortedTracks.filter((t) => !t.isFocalWell).length === 0 ? (
              selectedWell && averageSIByWell[selectedWell] ? (
                <Text
                  style={{
                    color: themeColors.primary_typography,
                    fontSize: 16,
                  }}
                >
                  {adjustDecimalPlaces(
                    selectedUnit?.toString(averageSIByWell[selectedWell]),
                    getDecimalsInterval(displayScaleDomain),
                  ) || ""}{" "}
                </Text>
              ) : null
            ) : (
              <ParameterComparisonGrid rowCount={sortedTracks.length}>
                {sortedTracks.map((track, idx) => {
                  const barWidth = comparisonBarScale(
                    averageSIByWell[track.wellId],
                  );
                  return (
                    <ParameterComparisonGridItem
                      key={`${track.trackId}-${track.wellId}-${idx}`}
                      isFocalWell={track.isFocalWell}
                    >
                      <div
                        style={{
                          width: barWidth + "px",
                          // TODO the assumption if just well id 0 is going to be aggregated is a bit inelegant
                          backgroundColor:
                            track.wellId === 0
                              ? colors.off_state
                              : getColor({ key: track.wellId.toString() }),
                        }}
                      />
                      <span>
                        {adjustDecimalPlaces(
                          selectedUnit?.toString(averageSIByWell[track.wellId]),
                          getDecimalsInterval(displayScaleDomain),
                        ) || "No data available"}{" "}
                      </span>
                    </ParameterComparisonGridItem>
                  );
                })}
              </ParameterComparisonGrid>
            )}
          </ParameterAvgKpiVal>
        )}

        {showGraph && !hasNoData ? (
          <ParameterLeftAxis>
            <ParameterSlantedName>
              <span
                style={{
                  writingMode: "vertical-rl",
                  position: "relative",
                }}
              >
                <ParameterKpiSlantedName>
                  {selectedUnit?.abv}
                </ParameterKpiSlantedName>
                {!isUnitUnlocked ? <StyledLockIcon width={17} /> : null}
              </span>
            </ParameterSlantedName>
            <svg>
              <TrackAxis
                chartHeight={currentChartHeight}
                scale={valueScale}
                top={HALF_LETTER_HEIGHT}
                trackUomHelper={trackUOM}
                isManual={yMax !== undefined || yMin !== undefined}
              />
            </svg>
          </ParameterLeftAxis>
        ) : null}

        {showGraph ? (
          <ChartContainer ref={chartRef}>
            <svg
              width={"100%"}
              height={"100%"}
              onPointerMove={hasNoData ? undefined : handlePointerMove}
              ref={svgRef}
            >
              <Group>
                {sortedTracks.map((track) => {
                  return (
                    <React.Fragment
                      key={`${track.wellId}-${track.trackId}-${track.type}`}
                    >
                      {sortedTracks.length === 1 ? (
                        <AreaClosed
                          curve={curveMonotoneX}
                          data={track.trackValues ?? []}
                          defined={(d) =>
                            d.holeDepth >= depthScale.domain()[0] - 5 &&
                            d.holeDepth <= depthScale.domain()[1] + 5
                          }
                          yScale={valueScale}
                          x={(d) => depthScale(d.holeDepth) || 0}
                          y0={valueScale.range()[0] + BOTTOM_MARGIN}
                          y1={(d) =>
                            Number.isNaN(valueScale(d.value ?? 0))
                              ? 0
                              : valueScale(d.value ?? 0)
                          }
                          strokeWidth={0}
                          fill={`${colors.well_color}26`}
                        />
                      ) : null}
                      <LinePath
                        curve={curveMonotoneX}
                        data={track.trackValues ?? []}
                        defined={(d) =>
                          d.holeDepth >= depthScale.domain()[0] - 5 &&
                          d.holeDepth <= depthScale.domain()[1] + 5
                        }
                        x={(d) => depthScale(d.holeDepth) || 0}
                        y={(d) =>
                          Number.isNaN(valueScale(d.value ?? 0))
                            ? 0
                            : valueScale(d.value ?? 0)
                        }
                        stroke={
                          track.wellId === 0
                            ? colors.off_state
                            : getColor({ key: track.wellId.toString() })
                        }
                        strokeOpacity={
                          track.isFocalWell || !focalTrack ? 1 : 0.6
                        }
                        strokeWidth={track.isFocalWell || !focalTrack ? 2 : 1.2}
                      />
                    </React.Fragment>
                  );
                })}
              </Group>

              {(isPointerInsideChart || detailed) && !hasNoData ? (
                <Bar
                  height={currentChartHeight}
                  width={2}
                  fill="rgba(0, 0, 0, 0.3)"
                  x={pointerPosition.x - 1}
                  y={0}
                  onClick={onTooltipToggle}
                  cursor="pointer"
                />
              ) : null}

              {tooltipInfo &&
              Number.isFinite(tooltipInfo.top) &&
              (isPointerInsideChart || detailed) ? (
                <Group cursor="pointer" onClick={onTooltipToggle}>
                  <circle
                    fill={focalWellColor}
                    fillOpacity={0.4}
                    cx={tooltipInfo.left}
                    cy={tooltipInfo.top}
                    r={12}
                  />
                  <circle
                    fill="black"
                    cx={tooltipInfo.left}
                    cy={tooltipInfo.top}
                    r={9}
                  />

                  {isTooltipVisible ? (
                    <circle
                      cx={tooltipInfo.left}
                      cy={tooltipInfo.top}
                      r={2.5}
                      fill="white"
                    />
                  ) : (
                    <svg
                      style={{ fontSize: "10px", color: "white" }}
                      focusable="false"
                      preserveAspectRatio="xMidYMid meet"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      width="1em"
                      height="1em"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                      x={tooltipInfo.left - 5}
                      y={tooltipInfo.top - 5}
                    >
                      <circle cx="16" cy="16" r="4" />
                      <path d="M30.94,15.66A16.69,16.69,0,0,0,16,5,16.69,16.69,0,0,0,1.06,15.66a1,1,0,0,0,0,.68A16.69,16.69,0,0,0,16,27,16.69,16.69,0,0,0,30.94,16.34,1,1,0,0,0,30.94,15.66ZM16,22.5A6.5,6.5,0,1,1,22.5,16,6.51,6.51,0,0,1,16,22.5Z" />
                    </svg>
                  )}
                </Group>
              ) : null}
            </svg>

            {isTooltipVisible && showGraph && tooltipInfo ? (
              <TooltipContainer
                left={detailed ? tooltipInfo.left : tooltipInfo.pageX}
                unstyled
                top={-BOTTOM_MARGIN}
              >
                {sortedTracks.map((track, idx) => {
                  const tooltipData = tooltipInfo.data[track.wellId];
                  const wellName = wellData?.byId[track.wellId]?.name;

                  const formattedName =
                    sortedTracks.length === 1
                      ? "Actual"
                      : wellName
                        ? truncateMiddleString(wellName, 20)
                        : "Comparison";

                  return (
                    <TooltipEntry
                      key={`${track.wellId}-${track.trackId}-${idx}`}
                    >
                      {sortedTracks.length > 1 && (
                        <TooltipWellColor
                          color={
                            track.wellId === 0
                              ? colors.off_state
                              : getColor({ key: track.wellId.toString() })
                          }
                        />
                      )}
                      <div>{formattedName}</div>
                      <div>
                        {tooltipData?.value ? (
                          <strong>
                            {adjustDecimalPlaces(
                              selectedUnit?.toString(tooltipData.value),
                              getDecimalsInterval(displayScaleDomain),
                            ) || ""}{" "}
                            {selectedUnit?.abv}
                          </strong>
                        ) : (
                          <strong>No Data</strong>
                        )}
                      </div>
                    </TooltipEntry>
                  );
                })}
                <TooltipExtra>
                  <div>{depthUOM.display(tooltipInfo?.holeDepth ?? 0)}</div>
                  <div>
                    {formatTime(tooltipInfo.timestamp, {
                      formatStr: "M/D HH:mm",
                    })}
                  </div>
                </TooltipExtra>
              </TooltipContainer>
            ) : null}

            {chartWidth > 0 ? (
              <svg
                height="100%"
                width={chartWidth}
                style={{
                  position: "absolute",
                  zIndex: zIndexLayer.moon,
                  top: 0,
                  left: 0,
                }}
                pointerEvents={"none"}
              >
                {(highlightedInterval?.intervals ?? []).map((int, index) => {
                  return (
                    <Bar
                      key={int.startDepth + int.endDepth + index}
                      x={depthScale(int.startDepth)}
                      y={0}
                      width={Math.abs(
                        depthScale(int.endDepth) - depthScale(int.startDepth),
                      )}
                      height="100%"
                      fill={getColor({
                        key: highlightedInterval?.wellId.toString() ?? "0",
                      })}
                      opacity={0.25}
                    />
                  );
                })}
              </svg>
            ) : null}
          </ChartContainer>
        ) : null}
      </InnerDiv>
    </div>
  );
}
