import { BitmovinVideoPlayer } from "components/BitmovinVideoPlayer/BitmovinVideoPlayer";
import { VideoItem } from "components/Carousel/styles";
import type { VideoPlayerSource } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/interfaces";
import { useMemo } from "react";
import { PDConstants } from "utils/PDConstants/PDConstants";

import * as Styled from "./style";

export const EmpiricallyWorkingLocalVideoMimeTypes =
  PDConstants.Collections.Carousel.mimeTypes.video
    .empiricallyLocalWorkingFormats;

const shouldDisplayUnsupportedPreviewerFormat = (
  videoPlayerSource: VideoPlayerSource,
) => {
  const localFileMimeType = videoPlayerSource?.progressiveConfig?.type;
  return (
    localFileMimeType &&
    !EmpiricallyWorkingLocalVideoMimeTypes.includes(localFileMimeType)
  );
};

const forceLocalVideoSourceToMp4 = (videoPlayerSource: VideoPlayerSource) => {
  //  Forcing the renderer to use mp4 for local videos. This has the upside of allowing .mov videos to be locally played
  return videoPlayerSource?.progressiveConfig
    ? {
        ...videoPlayerSource,
        progressiveConfig: {
          ...videoPlayerSource?.progressiveConfig,
          type: "video/mp4",
        },
      }
    : videoPlayerSource;
};

export const VideoCarouselItem = ({
  source,
  handleVideoInteractionStart,
  handleVideoInteractionEnd,
}: {
  source: VideoPlayerSource;
  handleVideoInteractionStart: () => void;
  handleVideoInteractionEnd: () => void;
}) => {
  const forcedSource = useMemo(() => {
    return forceLocalVideoSourceToMp4(source);
  }, [source]);

  return shouldDisplayUnsupportedPreviewerFormat(source) ? (
    <Styled.PreviewerNotAvailableContainer>
      <Styled.PreviewerNotAvailableText>
        <p>Preview Unavailable for This Video Format.</p>
        <br />
        <span>
          But don’t worry! Once uploaded, it will be processed and converted to
          a viewable format. The video will appear correctly in your posts after
          the conversion is complete.
        </span>
      </Styled.PreviewerNotAvailableText>
    </Styled.PreviewerNotAvailableContainer>
  ) : (
    <VideoItem>
      <BitmovinVideoPlayer
        source={forcedSource}
        handleVideoInteractionStart={handleVideoInteractionStart}
        handleVideoInteractionEnd={handleVideoInteractionEnd}
      />
    </VideoItem>
  );
};
