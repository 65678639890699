import type { IntelGroupingType } from "apis/oag";
import { ProfilesApi } from "apis/oag";
import type { SelectorItem } from "components/GroupSelector/GroupSelector";
import { GroupSelector } from "components/GroupSelector/GroupSelector";
import { useLayoutEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { PDConstants } from "utils/PDConstants/PDConstants";

import * as Styled from "./style";

const api = new ProfilesApi(apiConfig);

export enum IntelLegendVisibleTabs {
  "Rig" = "Rig",
  "Contractor" = "Contractor",
  "Operator" = "Operator",
}

export const LegendGroupSelector = () => {
  const dispatch = useAppDispatch();
  const selectedGroupingType = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );

  const [distanceFromTop, setDistanceFromTop] = useState(
    PDConstants.SizesPx.Layout.TopBarHeight.asNumber() * 2,
  );

  const selectorItems: SelectorItem<IntelGroupingType>[] = useMemo(
    () =>
      Object.keys(IntelLegendVisibleTabs).map((key) => {
        return {
          key: key as IntelGroupingType,
          label: key,
        };
      }),
    [],
  );

  const handleOnClickLegend = (intelGroupingName: IntelGroupingType) => {
    Track.interact(`Intel Dashboard - Legend grouping selector`, {
      name: intelGroupingName,
    });

    dispatch({
      type: "INTEL_DASHBOARD_SET_GROUPING_TYPE",
      payload: intelGroupingName,
    });

    api.apiProfilesIntelDashboardGroupingTypePut({
      body: intelGroupingName,
    });
  };

  useLayoutEffect(() => {
    const node = document.getElementById(PDConstants.uniqueDomIds.Intel.header);

    if (!node) {
      return;
    }

    const observer = new ResizeObserver(([entry]) => {
      const heightOfTheFilterBar = entry.contentRect.height;
      const heightOfTopbar = PDConstants.SizesPx.Layout.TopBarHeight.asNumber();

      setDistanceFromTop(heightOfTheFilterBar + heightOfTopbar);
    });

    observer.observe(node);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Styled.LegendGroupSelectorWrapper style={{ top: distanceFromTop }}>
      <GroupSelector
        width={253}
        items={selectorItems}
        selectedItem={selectedGroupingType}
        onSelect={(key) => handleOnClickLegend(key)}
      />
    </Styled.LegendGroupSelectorWrapper>
  );
};
