import type { DigestChapterDto } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { AttachmentsCarousel } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/AttachmentsCarousel";
import { KnotDescription } from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotDescription/KnotDescription";
import { useMouseHover } from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotTypes/useMouseHover";
import { useImperativeTimelineEventScroll } from "components/TvDChart/components/TimelineEventIndicator/useImperativeTimelineEventScroll";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";

import * as Styled from "./style";

export const LogbookKnotPanel = ({
  chapter,
}: {
  chapter: DigestChapterDto;
}) => {
  const entry = useMemo(() => chapter.notes[0], [chapter.notes]);
  const { getDomEventId } = useImperativeTimelineEventScroll();
  const dispatch = useDispatch();

  const isSelected = useAppSelector(
    (state) => entry.id === state.timeline.selectedTimelineEvent,
  );

  const isHovered = useAppSelector((state) => {
    return entry.id === state.state.hoveredEvent?.id;
  });

  const handleOnSelect = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      if (isSelected) {
        dispatch({
          type: "SET_SELECTED_TIMELINE_EVENT",
          payload: null,
        });
      } else {
        dispatch({
          type: "SET_SELECTED_TIMELINE_EVENT",
          payload: entry.id,
        });
      }
    },
    [dispatch, entry.id, isSelected],
  );

  const { trackMouseHoverState } = useMouseHover({
    onHover: () => {
      dispatch({
        type: "SET_EVENT_HOVERED",
        payload: {
          id: entry.id,
          type: chapter.type,
        },
      });
    },
    onLeave: () => {
      dispatch({
        type: "SET_EVENT_HOVERED",
        payload: null,
      });
    },
  });

  if (!entry) {
    return null;
  }

  return (
    <Styled.MemoContainer id={getDomEventId(entry.id)}>
      <Styled.Knot
        $bg={colors.tern_grey}
        $isSelected={isSelected || isHovered}
        onClick={handleOnSelect}
        ref={trackMouseHoverState}
      >
        <PDComponent.SvgIcon name="logbook" />
      </Styled.Knot>

      <Styled.KnotPanel>
        <Styled.Title>Logbook Entry</Styled.Title>
        <Styled.EntrySubtitle>
          Event Date:{" "}
          <b>
            {" "}
            {dayjs(chapter.referenceDate.utc).format("MMM DD, YYYY · HH:mm")}
          </b>
        </Styled.EntrySubtitle>
        <Styled.UserName>{entry.authorDisplayName}</Styled.UserName>
        <KnotDescription description={entry.description} />

        <AttachmentsCarousel
          digestEntry={entry}
          downloadDate={chapter.referenceDate}
        />
      </Styled.KnotPanel>
    </Styled.MemoContainer>
  );
};
