import type { DigestEntryType, TimelineEventType, WellDto } from "apis/oag";
import type { TypeEventMarkers } from "utils/eventUtils";

export interface IAction {
  type: string;
  payload?: any;
}

export interface IWellInfo extends WellDto {
  color: string;
}

export interface IFiltersType {
  operationTime: Array<string> | null;
  sections: Array<number> | null;
  directionalIntervals: Array<number> | null;
  holeSizes: Array<number> | null;
  phases: Array<number> | null;
}

export const initialFilters: IFiltersType = {
  operationTime: null,
  sections: null,
  directionalIntervals: null,
  holeSizes: null,
  phases: null,
};

export enum CurvesEnum {
  HOLE_DEPTH = "HOLE_DEPTH",
  BIT_DEPTH = "BIT_DEPTH",
  PLAN = "PLAN",
  OFFSET_WELLS = "OFFSET_WELLS",
}

export enum EvergreenEventsEnum {
  NOTES = "NOTES",
  HAZARDS = "HAZARDS",
  INSTRUCTIONS = "INSTRUCTIONS",
  WARNINGS = "WARNINGS",
}

export enum EventsEnum {
  NOTES = "NOTES",
  HAZARDS = "HAZARDS",
  INSTRUCTIONS = "INSTRUCTIONS",
  WARNINGS = "WARNINGS",
  LOGBOOK_ENTRIES = "Logbook Entries",
  UNPLANNED_EVENTS = "Unplanned Events",
}

export enum PhasesEnum {
  INTERMEDIATE_DRILL = "INTERMEDIATE_DRILL",
  INTERMEDIATE_FLAT = "INTERMEDIATE_FLAT",
  PRE_DRILL = "PRE_DRILL",
  PRODUCTION_DRILL = "PRODUCTION_DRILL",
  PRODUCTION_FLAT = "PRODUCTION_FLAT",
  SURFACE_DRILL = "SURFACE_DRILL",
  SURFACE_FLAT = "SURFACE_FLAT",
}
export interface IDisplayOptionsType {
  curves: Array<CurvesEnum> | null;
  events: Array<EventsEnum> | null;
  phases?: Array<PhasesEnum> | null;
}

export const initialDisplayOptions: IDisplayOptionsType = {
  curves: null,
  events: null,
  phases: null,
};

export const initialDisplayOptionsEvergreen: IDisplayOptionsType = {
  curves: [CurvesEnum.BIT_DEPTH, CurvesEnum.HOLE_DEPTH],
  events: null,
};

// TODO Why define the type in here as well ?

export type ExtendedEventType =
  | TypeEventMarkers
  | TimelineEventType
  | "TimelineEndEvent"
  | "CombinedEvents"
  | "EndOfTimeline"
  | DigestEntryType;

// Use interface instead of triple because of typescript lack of support
export interface IEventType {
  type: ExtendedEventType;
  id: number;
  isPlanEventProjection: boolean;
}

export enum IUnitSystem {
  METRIC = "MetricView",
  IMPERIAL = "ImperialView",
}

export enum IZoomType {
  TIME = "ts",
  DEPTH = "depth",
  DATE = "date",
  DYNAMIC_WINDOW = "dynamic_window",
}

export interface IZoomData {
  date_start: Date | null;
  date_start_well_offset: number | null;
  date_end: Date | null;
  date_end_well_offset: number | null;
  ts_start: number;
  ts_end: number | null;
  depth_start: number;
  depth_end: number | null;
  type: IZoomType;
  internal_zoom: boolean;
}
