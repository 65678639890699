import type { ActualTvdPointDto, DigestTimelineDto } from "apis/oag";
import { MAX_GAP } from "components/TvDChart/constants";
import type { ICombinedEvents } from "components/TvDChart/types";
import {
  getHoleDepthFromCumulativeDuration,
  isBetween,
} from "components/WellDashboard/ControlHeader/atoms/Zoom/utils";
import { secondsInDay } from "utils/common";

export type ExtendedCombinedEvents = ICombinedEvents & {
  startDynamicDuration: number;
  endDynamicDuration: number | null | undefined;
};

const getEventsCombinations = (
  unsortedEvents: ExtendedCombinedEvents[],
  xMaxInDays: number = 0,
) => {
  if (!unsortedEvents?.length) return [];

  const events = unsortedEvents.toSorted(
    (a, b) => a.startDynamicDuration - b.startDynamicDuration,
  );

  const threshold =
    xMaxInDays > 0
      ? xMaxInDays * secondsInDay * MAX_GAP
      : Number.POSITIVE_INFINITY;

  const result: ExtendedCombinedEvents[] = [];

  let currentGroup: ExtendedCombinedEvents | null = null;
  let leaderStart: number | null = null;

  for (const event of events) {
    const startTime = event.startDynamicDuration ?? 0;

    if (!currentGroup) {
      currentGroup = { ...event, combinedEvents: [] };
      leaderStart = startTime;
      continue;
    }

    const distanceFromLeader = Math.abs(startTime - (leaderStart ?? 0));
    if (distanceFromLeader <= threshold) {
      // Merge into the current group
      currentGroup.combinedEvents = [
        ...(currentGroup.combinedEvents ?? []),
        event,
      ];
    } else {
      // Push the completed group and start a new one
      result.push(currentGroup);
      currentGroup = { ...event, combinedEvents: [] };
      leaderStart = startTime;
    }
  }

  if (currentGroup) {
    result.push(currentGroup);
  }

  return result;
};

const getEventDynamicValues = ({
  cumulativeDuration,
  series,
  depthNormalized,
}: {
  cumulativeDuration: number | null | undefined;
  series: ActualTvdPointDto[];
  depthNormalized: boolean;
}) => {
  if (
    !series ||
    cumulativeDuration === undefined ||
    cumulativeDuration === null
  )
    return { dynamicDuration: cumulativeDuration, dynamicHoleDepth: 0 };

  const dynamicPosition = series.findIndex(
    (ev: ActualTvdPointDto) =>
      ev.cumulativeDuration >= (cumulativeDuration || 0),
  );
  if (dynamicPosition > 0) {
    if (
      series[dynamicPosition - 1]?.cumulativeDuration &&
      series[dynamicPosition]?.cumulativeDuration
    ) {
      const percent =
        (cumulativeDuration - series[dynamicPosition - 1].cumulativeDuration) /
        (series[dynamicPosition].cumulativeDuration -
          series[dynamicPosition - 1].cumulativeDuration);
      const calculatedDepth =
        (series[dynamicPosition - 1][
          depthNormalized ? "dynamicHoleDepth" : "holeDepth"
        ] ?? 0) +
        percent *
          ((series[dynamicPosition][
            depthNormalized ? "dynamicHoleDepth" : "holeDepth"
          ] ?? 0) -
            (series[dynamicPosition - 1][
              depthNormalized ? "dynamicHoleDepth" : "holeDepth"
            ] ?? 0));
      const dynamicHoleDepth =
        calculatedDepth ||
        series.find(
          (x) =>
            x.cumulativeDuration >= (cumulativeDuration || 0) &&
            x.holeDepth !== undefined,
        )?.dynamicHoleDepth;

      return {
        dynamicDuration:
          series[dynamicPosition - 1].dynamicDuration +
          percent *
            (series[dynamicPosition].dynamicDuration -
              series[dynamicPosition - 1].dynamicDuration),
        dynamicHoleDepth,
      };
    }
  } else {
    if (cumulativeDuration >= series.slice(-1)[0]?.cumulativeDuration)
      return {
        ...series.slice(-1)[0],
      };
    else
      return {
        ...series[0],
      };
  }
};

export const convertDateToDuration =
  (series: ActualTvdPointDto[], clampItemsOutsideOfTheInterval = true) =>
  (date: Date) => {
    if (!date || !series?.length) return 0;

    const index = series.findIndex((e) => e.at.utc.getTime() >= date.getTime());

    if (index === 0)
      return clampItemsOutsideOfTheInterval
        ? series[0].cumulativeDuration
        : -Infinity;

    if (index === -1 || index > series.length - 1)
      return clampItemsOutsideOfTheInterval
        ? series[series.length - 1].cumulativeDuration
        : Infinity;

    const [startDate, endDate] = [
      series[index - 1].at.utc.getTime(),
      series[index].at.utc.getTime(),
    ];
    const [durationStart, durationEnd] = [
      series[index - 1].cumulativeDuration,
      series[index].cumulativeDuration,
    ];

    const factor = (date.getTime() - startDate) / (endDate - startDate);
    const smoothDuration =
      +durationStart + Math.round(factor * (durationEnd - durationStart));

    return smoothDuration;
  };

interface ConvertToDigestTimelineArgs {
  digestTimeline: DigestTimelineDto;
  series: ActualTvdPointDto[];
  depthNormalized: boolean;
  xMinCumulative: number;
  xMaxCumulative: number;
  xMaxInDays?: number;
  isZoomed?: boolean;
}

export const convertDigestTimelineToCombinedEvents = ({
  digestTimeline,
  series,
  depthNormalized,
  xMinCumulative = -Infinity, // to make sure zoomed out values are left out.
  xMaxCumulative = Infinity,
  xMaxInDays,
  isZoomed = false,
}: ConvertToDigestTimelineArgs): ExtendedCombinedEvents[] => {
  const toDuration = convertDateToDuration(series, !isZoomed);
  const toDepth = getHoleDepthFromCumulativeDuration(series);

  const unplanned = digestTimeline.unplannedEvents.flatMap((chapter) => {
    const startCumulativeDuration = toDuration(chapter.referenceDate.utc);
    return {
      type: chapter.type,
      eventType: chapter.type,
      compressible: true,
      id: chapter.unplannedEvents[0].id,
      startDate: chapter.referenceDate.utc,
      endDate: chapter.referenceEndDate?.utc,
      dynamicDepth: toDepth(startCumulativeDuration),
      description: chapter.unplannedEvents[0].description,
      startCumulativeDuration,
      endCumulativeDuration: chapter.referenceEndDate?.utc
        ? toDuration(chapter.referenceEndDate?.utc)
        : null,
    };
  });

  const notes = digestTimeline.notes.flatMap((chapter) => {
    return chapter.notes.flatMap((note) => {
      const startCumulativeDuration = toDuration(note.startAt.utc);
      return {
        type: chapter.type,
        eventType: chapter.type,
        compressible: true,
        id: note.id,
        dynamicDepth: toDepth(startCumulativeDuration),
        startDate: note.startAt.utc,
        startCumulativeDuration,
        endCumulativeDuration: null,
        description: note.description,
      };
    });
  });

  // Before compressing them into combined events

  const beforeCompression = [...notes, ...unplanned]
    .map((e) => ({
      ...e,
      startDynamicDuration:
        getEventDynamicValues({
          cumulativeDuration: e.startCumulativeDuration,
          series,
          depthNormalized,
        })?.dynamicDuration ?? 0,
      endDynamicDuration: e.endCumulativeDuration
        ? getEventDynamicValues({
            cumulativeDuration: e.endCumulativeDuration,
            series,
            depthNormalized,
          })?.dynamicDuration
        : null,
    }))
    .filter((event) => {
      return isBetween({
        value: event.startCumulativeDuration,
        min: xMinCumulative,
        max: xMaxCumulative,
      });
    });

  const afterCompression = getEventsCombinations(beforeCompression, xMaxInDays);

  return afterCompression;
};
