import styled from "styled-components";

export const VideoPlayer = styled.div<{ $isLandscape: boolean }>`
  min-width: 100%;
  min-height: 1px;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    height: ${(props) => (props.$isLandscape ? "auto" : "100%")};
    width: ${(props) => (props.$isLandscape ? "100%" : "auto")};
  }
  .bmpui-ui-fullscreentogglebutton {
    background-image: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" xml:space="preserve"><path fill="%23ffffff" d="M4.7,7.7h4.7V5.4H4.7c-1.3,0-2.4,1.1-2.4,2.4v4.7h2.4V7.7z" /><path fill="%23ffffff" d="M4.7,19.5H2.4v4.7c0,1.3,1.1,2.4,2.4,2.4h4.7v-2.4H4.7V19.5z" /><path fill="%23ffffff" d="M27.3,5.4h-4.7v2.4h4.7v4.7h2.4V7.7C29.6,6.4,28.6,5.4,27.3,5.4z" /><path fill="%23ffffff" d="M27.3,24.3h-4.7v2.4h4.7c1.3,0,2.4-1.1,2.4-2.4v-4.7h-2.4V24.3z" /></svg>');
  }

  .bmpui-ui-settingstogglebutton,
  .bmpui-ui-volumeslider {
    display: none;
  }

  .bmpui-ui-watermark {
    display: none;
  }
`;
