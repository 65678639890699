import type {
  UseQueryOptions,
  UseSuspenseQueryOptions,
} from "@tanstack/react-query";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type { TrackDto } from "apis/oag";
import { TrackApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const tracks = new TrackApi(apiConfig);

export const TracksQueryKey: PDUniqueQueryKey = { uid: RequestUID.tracks };

export type TracksResponse = {
  byId: Record<number, TrackDto>;
  list: TrackDto[];
};

export async function fetchTracks(): Promise<TracksResponse> {
  const data = await tracks.apiTrackGet();

  return {
    list: data,
    byId: data.reduce<Record<number, TrackDto>>((acc, cur) => {
      acc[cur.id ?? 0] = cur;
      return acc;
    }, {}),
  };
}

export function useTracks(options?: UseQueryOptions<TracksResponse>) {
  return useQuery<TracksResponse>({
    queryKey: [TracksQueryKey],
    queryFn: () => fetchTracks(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useTracksSuspense(
  options?: UseSuspenseQueryOptions<TracksResponse>,
) {
  return useSuspenseQuery<TracksResponse>({
    queryKey: [TracksQueryKey],
    queryFn: () => fetchTracks(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
