import { AttachmentReadiness } from "apis/oag";
import { match } from "ts-pattern";

import * as Styled from "./style";

export const CarouselThumbnailByReadiness = ({
  attachmentReadiness,
  isBlurred,
  isVideo,
}: {
  attachmentReadiness: AttachmentReadiness;
  isBlurred: boolean;
  isVideo?: boolean;
}) => {
  if (isBlurred) {
    return null;
  }

  return (
    match({ attachmentReadiness, isVideo })
      //   LEFT out the other states from the PWA where you had processing / error.
      .with(
        { attachmentReadiness: AttachmentReadiness.Ready, isVideo: true },
        () => <Styled.PlayIcon name="play" height={40} width={40} />,
      )
      .otherwise(() => null)
  );
};
