/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface InspectionTvdPointDto
 */
export interface InspectionTvdPointDto {
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    dynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    holeDepth: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    dynamicHoleDepth: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    phaseId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof InspectionTvdPointDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    operationClassId: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    bitDepth: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    blockHeight: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    torque: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    hookLoad: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    standpipePressure: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    pumpOutput: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    rateOfPenetration: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    revolutionsPerSecond: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    differentialPressure: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    weightOnBit: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    sssi: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    zTorqueStatus: number | null;
}

/**
 * Check if a given object implements the InspectionTvdPointDto interface.
 */
export function instanceOfInspectionTvdPointDto(value: object): value is InspectionTvdPointDto {
    if (!('cumulativeDuration' in value) || value['cumulativeDuration'] === undefined) return false;
    if (!('dynamicDuration' in value) || value['dynamicDuration'] === undefined) return false;
    if (!('holeDepth' in value) || value['holeDepth'] === undefined) return false;
    if (!('dynamicHoleDepth' in value) || value['dynamicHoleDepth'] === undefined) return false;
    if (!('phaseId' in value) || value['phaseId'] === undefined) return false;
    if (!('at' in value) || value['at'] === undefined) return false;
    if (!('operationClassId' in value) || value['operationClassId'] === undefined) return false;
    if (!('bitDepth' in value) || value['bitDepth'] === undefined) return false;
    if (!('blockHeight' in value) || value['blockHeight'] === undefined) return false;
    if (!('torque' in value) || value['torque'] === undefined) return false;
    if (!('hookLoad' in value) || value['hookLoad'] === undefined) return false;
    if (!('standpipePressure' in value) || value['standpipePressure'] === undefined) return false;
    if (!('pumpOutput' in value) || value['pumpOutput'] === undefined) return false;
    if (!('rateOfPenetration' in value) || value['rateOfPenetration'] === undefined) return false;
    if (!('revolutionsPerSecond' in value) || value['revolutionsPerSecond'] === undefined) return false;
    if (!('differentialPressure' in value) || value['differentialPressure'] === undefined) return false;
    if (!('weightOnBit' in value) || value['weightOnBit'] === undefined) return false;
    if (!('sssi' in value) || value['sssi'] === undefined) return false;
    if (!('zTorqueStatus' in value) || value['zTorqueStatus'] === undefined) return false;
    return true;
}

export function InspectionTvdPointDtoFromJSON(json: any): InspectionTvdPointDto {
    return InspectionTvdPointDtoFromJSONTyped(json, false);
}

export function InspectionTvdPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionTvdPointDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cumulativeDuration': json['CumulativeDuration'],
        'dynamicDuration': json['DynamicDuration'],
        'holeDepth': json['HoleDepth'],
        'dynamicHoleDepth': json['DynamicHoleDepth'],
        'phaseId': json['PhaseId'],
        'at': DateDtoFromJSON(json['At']),
        'operationClassId': json['OperationClassId'],
        'bitDepth': json['BitDepth'],
        'blockHeight': json['BlockHeight'],
        'torque': json['Torque'],
        'hookLoad': json['HookLoad'],
        'standpipePressure': json['StandpipePressure'],
        'pumpOutput': json['PumpOutput'],
        'rateOfPenetration': json['RateOfPenetration'],
        'revolutionsPerSecond': json['RevolutionsPerSecond'],
        'differentialPressure': json['DifferentialPressure'],
        'weightOnBit': json['WeightOnBit'],
        'sssi': json['Sssi'],
        'zTorqueStatus': json['ZTorqueStatus'],
    };
}

export function InspectionTvdPointDtoToJSON(value?: InspectionTvdPointDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'CumulativeDuration': value['cumulativeDuration'],
        'DynamicDuration': value['dynamicDuration'],
        'HoleDepth': value['holeDepth'],
        'DynamicHoleDepth': value['dynamicHoleDepth'],
        'PhaseId': value['phaseId'],
        'At': DateDtoToJSON(value['at']),
        'OperationClassId': value['operationClassId'],
        'BitDepth': value['bitDepth'],
        'BlockHeight': value['blockHeight'],
        'Torque': value['torque'],
        'HookLoad': value['hookLoad'],
        'StandpipePressure': value['standpipePressure'],
        'PumpOutput': value['pumpOutput'],
        'RateOfPenetration': value['rateOfPenetration'],
        'RevolutionsPerSecond': value['revolutionsPerSecond'],
        'DifferentialPressure': value['differentialPressure'],
        'WeightOnBit': value['weightOnBit'],
        'Sssi': value['sssi'],
        'ZTorqueStatus': value['zTorqueStatus'],
    };
}

