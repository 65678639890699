import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { BarGroup, BarRounded } from "@visx/shape";
import { DisplayOption, ResultDataState, VisualAidType } from "apis/oag";
import {
  AverageLabel,
  AverageLine,
} from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import OperationCount, {
  StyledOperationCountContainer,
} from "components/Lenses/common/OperationCount";
import TargetLine from "components/Lenses/common/TargetLine";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import {
  TooltipVisualAidInfo,
  useChartTooltip,
} from "components/Lenses/common/useChartTooltip";
import { useOutlierThreshold } from "components/Lenses/common/useOutlierThreshold";
import type { SingleKpiChartComparisonProps } from "components/Lenses/ContainerLens/SingleKpi/interfaces";
import { getMinMax, getSVGNormalizedValue } from "components/Lenses/utils";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import { max } from "d3-array";
import { useTargetSegments } from "hooks/charting/useTargetSegments";
import { useDirectionalIntervals } from "hooks/drillingInvariants/useDirectionalIntervals";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { keyBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { OPERATION_COUNT_HEIGHT } from "utils/constants";
import { truncateMiddleString } from "utils/helper";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";

export type TransformedDataItem = {
  wellId: number;
  id: number;
  categoryId?: number;
  categoryName: string;
  targetValue: number;
  operationCount?: number;
  totalValue: number;
  value: number;
  isOutlier?: boolean;
};

// TODO paddingOuter needs to be a useMemo
export function SingleComparisonCategoryChart({
  detailed,
  data,
  displayOption,
  lens,
  valueUOM,
}: SingleKpiChartComparisonProps) {
  const { getColor } = useColors();
  const { data: holeSectionsValues } = useHoleSections();
  const { data: directionalIntervalsValues } = useDirectionalIntervals();
  const { data: wellShortInfo } = useWellShortInfoSuspended();

  const availableDisplayOptionIds = useMemo(
    () =>
      data.comparisons?.reduce<number[]>((acc, curr) => {
        return [
          ...new Set([
            ...acc,
            ...(curr.detailsByDisplayOption ?? []).map((e) => e.id),
          ]),
        ];
      }, []),
    [data.comparisons],
  );

  const categoryValues = useMemo(() => {
    return (
      (displayOption === DisplayOption.HoleSection
        ? holeSectionsValues
        : directionalIntervalsValues) || []
    )
      .filter((e) => availableDisplayOptionIds.includes(e.id))
      .sort((a, b) => a.position - b.position);
  }, [
    availableDisplayOptionIds,
    displayOption,
    holeSectionsValues,
    directionalIntervalsValues,
  ]);

  const categoryMap = useMemo(
    () => keyBy(categoryValues, (i) => i.id),
    [categoryValues],
  );

  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth, chartHeight: containerHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const chartHeight = getSVGNormalizedValue(
    containerHeight - (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0),
  );

  const plotHeight = getSVGNormalizedValue(chartHeight - (detailed ? 136 : 36));
  const plotWidth = getSVGNormalizedValue(
    chartWidth - (detailed ? 76 : 44) - 10,
  );

  const average = useMemo(
    () => data.summaryByKpi?.allAverage,
    [data.summaryByKpi?.allAverage],
  );
  const median = useMemo(
    () => data.summaryByKpi?.median,
    [data.summaryByKpi?.median],
  );

  const transformedData = useMemo(() => {
    return categoryValues.map((category) => {
      const infoByWell =
        (data.comparisons ?? [])
          .filter(
            (comparison) =>
              comparison.dataState === ResultDataState.Valid &&
              comparison.detailsByDisplayOption?.find?.(
                (detail) => detail.id === category.id,
              ),
          )
          .map((comparison) => {
            const { wellId } = comparison;
            const categoryInfo = comparison.detailsByDisplayOption?.find?.(
              (detail) => detail.id === category.id,
            );
            return {
              wellId,
              id: wellId,
              categoryId: categoryInfo?.id,
              targetValue: categoryInfo?.targetAverage ?? 0,
              operationCount: categoryInfo?.operationCount,
              totalValue: categoryInfo?.average ?? 0,
              isOutlier: categoryInfo?.isOutlier,
            };
          }) ?? [];

      const maxValue = max(infoByWell, (item) =>
        Math.max(item.totalValue, item.targetValue),
      );
      const categories: {
        isOutlier?: boolean;
        average?: number;
        targetAverage?: number;
        id?: number;
        operationCount?: number;
        position?: number;
        [key: number]: number | boolean;
      } = {};
      (data.comparisons ?? [])
        .filter((d) => d.dataState === ResultDataState.Valid)
        .forEach?.((comparison) => {
          const { wellId } = comparison;
          const categoryInfo = comparison.detailsByDisplayOption?.find?.(
            (detail) => detail.id === category.id,
          );
          categories.targetAverage = categoryInfo?.targetAverage ?? 0;
          categories[wellId] = categoryInfo?.average ?? 0;
          categories.id = categoryInfo?.id ?? categories.id ?? 0;
          categories.position =
            categoryInfo?.position ?? categories.position ?? 0;
          categories.average = categoryInfo?.average ?? 0;
          categories.operationCount = categoryInfo?.operationCount;
          categories.isOutlier = categoryInfo?.isOutlier;
        });

      return {
        ...categories,
        position: categories.position,
        targetAverage: categories?.targetAverage,
        isOutlier: categories?.isOutlier,
        operationCount: categories?.operationCount,
        id: categories.id || -1,
        name: category.name,
        selectedWells: infoByWell.map((e) => e.wellId),
        list: infoByWell,
        maxValue,
      };
    });
    // No need to sort data as well, we are already sorting the categories.
  }, [categoryValues, data.comparisons]);

  const totalValues = useMemo(
    () =>
      transformedData
        ?.filter((stand) => !stand.isOutlier)
        .map((stand) => stand.maxValue || 0),
    [transformedData],
  );

  const categoryScale = useMemo(
    () =>
      scaleBand({
        domain: categoryValues.map((e) => e.id),
        // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
        range: [0, plotWidth],
        paddingInner: 0.2,
        paddingOuter: 1,
      }),
    [categoryValues, plotWidth],
  );

  const categoryColumnWidth = categoryScale.bandwidth();

  const groupScale = useMemo(() => {
    return scaleBand({
      domain: categoryValues.map((e) => e.id),
      // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
      range: [0, plotWidth],
      paddingInner: 0.2,
      paddingOuter: 1,
    });
  }, [categoryValues, plotWidth]);
  const pairScaleValue = useMemo(() => {
    return scaleBand({
      domain: data.comparisons.map((comp) => comp.wellId),
      paddingInner: 0.15,
      range: [0, groupScale.bandwidth()],
    });
  }, [data.comparisons, groupScale]);

  const computedTargetSegments = useTargetSegments(
    transformedData,
    categoryScale,
    pairScaleValue,
  );

  const { outlierThreshold, gradientDefinition, gradientFill } =
    useOutlierThreshold({
      values: totalValues,
      enabled: lens?.showsOutliers,
      selectedVisualAids: lens?.selectedVisualAids,
      targetSegments: computedTargetSegments,
    });

  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      domain: getMinMax(
        totalValues,
        computedTargetSegments,
        outlierThreshold,
        median,
        lens?.selectedVisualAids,
        lens?.outlierFlaggingType,
        lens?.isManualYaxis,
        lens?.yaxisEnd,
        lens?.yaxisStart,
      ),
      range: [plotHeight, 0],
      clamp: true,
    });
  }, [
    totalValues,
    computedTargetSegments,
    outlierThreshold,
    median,
    lens?.selectedVisualAids,
    lens?.outlierFlaggingType,
    lens?.isManualYaxis,
    lens?.yaxisEnd,
    lens?.yaxisStart,
    plotHeight,
  ]);

  const valuesDomain = useMemo(() => valueScale.domain(), [valueScale]);

  const {
    themeStyle: { outliers: outliersTheme },
  } = useCustomTheme();

  const { showTooltip, hideTooltip, tooltipElement } =
    useChartTooltip<TransformedDataItem>({
      containerRef,
      renderContent: ({ tooltipData }) => (
        <>
          <TooltipHighlightValue>
            {valueUOM.displayWithAutoDecimals(valuesDomain, tooltipData?.value)}
          </TooltipHighlightValue>

          <TooltipVisualAidInfo
            selectedVisualAids={lens?.selectedVisualAids}
            display={(value) =>
              valueUOM.displayWithAutoDecimals(valuesDomain, value)
            }
            targetValue={tooltipData?.targetValue}
            averageValue={average}
            median={median}
          />
          {lens.showOperationCount && tooltipData?.operationCount ? (
            <TooltipHighlightValue>
              Operation count: {tooltipData?.operationCount}
            </TooltipHighlightValue>
          ) : null}
          <span>{tooltipData?.categoryName}</span>
          <span>
            {wellShortInfo?.byId[tooltipData?.wellId || -1]?.name ??
              "Unnamed Well"}
          </span>
          {tooltipData?.isOutlier ? (
            <TooltipHighlightValue fontWeight="normal">
              OUTLIER
            </TooltipHighlightValue>
          ) : null}
        </>
      ),
    });

  const handleMouseOver = useCallback(
    (barData: {
      wellId: number;
      categoryId: number;
      categoryName: string;
      top: number;
      left: number;
      value: number;
    }) => {
      const dataTooltip = transformedData
        .find((e) => e.name === barData.categoryName)
        ?.list?.find((e: { wellId: number }) => e.wellId === barData.wellId);
      showTooltip({
        tooltipLeft: barData.left + pairScaleValue.bandwidth() / 2,
        tooltipTop: barData.top,
        tooltipData: dataTooltip
          ? {
              ...dataTooltip,
              categoryName:
                (categoryValues ?? []).find(
                  (category) => category.id === barData.categoryId,
                )?.name || "",
              value: barData.value,
            }
          : undefined,
      });
    },
    [transformedData, categoryValues, showTooltip, pairScaleValue],
  );

  const keys = useMemo(() => {
    return [...new Set(transformedData.flatMap((e) => e.selectedWells))];
  }, [transformedData]);

  // Starting initialization only with div to calculate plot width & height
  if (Number.isNaN(plotWidth) || Number.isNaN(plotHeight)) {
    return (
      <div
        ref={containerRef}
        style={{
          padding: 0,
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "visible",
        }}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "visible",
      }}
    >
      <StyledOperationCountContainer visible={lens.showOperationCount} />
      <svg
        width={chartWidth}
        height={chartHeight}
        style={{ overflow: "visible", userSelect: "none" }}
      >
        {gradientDefinition}
        <Chart
          detailed={detailed}
          isManual={lens?.isManualYaxis}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          plotWidth={plotWidth}
          plotHeight={plotHeight}
          valueScale={valueScale}
          showOperationCount={lens.showOperationCount}
          categoryScale={categoryScale}
          valueUOM={valueUOM}
          bottomTickComponent={undefined}
          tickFormat={(id) =>
            truncateMiddleString(
              categoryMap[id as keyof typeof categoryMap]?.name ?? "Undefined",
              categoryColumnWidth / 10,
            )
          }
        >
          <BarGroup
            keys={keys}
            data={transformedData}
            x0={(d) => d.id || 0}
            x0Scale={groupScale}
            x1Scale={pairScaleValue}
            yScale={valueScale}
            color={(e) => getColor({ key: e.toString() })}
            height={plotHeight}
          >
            {(barGroups) => {
              return barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                >
                  {barGroup.bars.map((bar, index) => {
                    if (!bar.value) return null;
                    const barHeight = Math.min(
                      plotHeight - valueScale(bar.value ?? 0),
                      plotHeight,
                    );
                    const barY = plotHeight - barHeight;
                    const category = categoryValues[barGroup.index];
                    const data = transformedData[barGroup.index];
                    const value = valueUOM.displayWithAutoDecimals(
                      valuesDomain,
                      bar.value,
                      { unit: "" },
                    );
                    return (
                      <React.Fragment
                        key={`bar-group-${barGroup.index}-${bar.index}-${barGroup.x0}-bar-grp-${value}`}
                      >
                        <rect
                          onMouseOver={() => {
                            handleMouseOver({
                              wellId: bar.key,
                              value: bar.value,
                              categoryId: category.id,
                              categoryName: category.name || "",
                              top: barY,
                              left: barGroup.x0 + bar.x,
                            });
                          }}
                          onMouseOut={hideTooltip}
                          key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          x={bar.x}
                          y={barY}
                          width={bar.width}
                          height={barHeight}
                          fill={
                            data.isOutlier
                              ? outliersTheme.bars_transparent
                              : bar.color
                          }
                        />

                        {data?.isOutlier ? (
                          <BarRounded
                            radius={4}
                            top
                            x={bar.x - 1}
                            y={barY - 1}
                            width={categoryScale.bandwidth() + 2}
                            onMouseOver={() => {
                              handleMouseOver({
                                wellId: bar.key,
                                value: bar.value,
                                categoryId: category.id,
                                categoryName: category.name || "",
                                top: barY,
                                left: barGroup.x0 + bar.x,
                              });
                            }}
                            onMouseOut={hideTooltip}
                            height={barHeight}
                            fill={gradientFill}
                          />
                        ) : null}
                        <Label
                          barHeight={barHeight}
                          barX={bar.x}
                          barY={barY + (detailed ? 0 : 10)}
                          columnWidth={bar.width}
                          detailed={detailed}
                          index={index}
                          topLabel={barY !== plotHeight}
                          value={value}
                          label={lens?.label}
                        />
                        {lens.showOperationCount ? (
                          <OperationCount
                            x={bar.x}
                            width={bar.width}
                            index={index}
                            detailed={detailed}
                            value={
                              (data?.list ?? []).find(
                                (e) => e.wellId === bar.key,
                              )?.operationCount
                            }
                          />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </Group>
              ));
            }}
          </BarGroup>

          {average ? (
            <AverageLine
              isVisible={(lens?.selectedVisualAids ?? []).includes(
                VisualAidType.Average,
              )}
              y={valueScale(average)}
              x={-RIGHT_AXIS_PADDING}
              width={plotWidth + RIGHT_AXIS_PADDING}
            />
          ) : null}

          <MedianLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(
              VisualAidType.Median,
            )}
            y={valueScale(median ?? 0)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            computedTargetSegments.map((targetSegment, idx) => {
              if (!targetSegment.target) return null;
              return (
                <TargetLine
                  key={`${targetSegment.categoryId.toString()}-${idx}`}
                  start={targetSegment.lineStart}
                  end={targetSegment.lineEnd}
                  y={valueScale(targetSegment.target)}
                  label={valueUOM.displayWithAutoDecimals(
                    valuesDomain,
                    targetSegment.target,
                    { unit: "" },
                  )}
                  showTag={!!targetSegment.showTag}
                  detailed={detailed}
                />
              );
            })}
        </Chart>
      </svg>

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Average) &&
      average ? (
        <AverageLabel
          style={{
            top: `${valueScale(average) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)}px`,
          }}
        >
          Average: {valueUOM.displayWithAutoDecimals(valuesDomain, average)}
        </AverageLabel>
      ) : null}

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Median) && (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${valueScale(median ?? 0) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)}px`,
          }}
        >
          Median: {valueUOM.displayWithAutoDecimals(valuesDomain, median)}
        </MedianLabel>
      )}
      {tooltipElement}
    </div>
  );
}
