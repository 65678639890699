/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoManifestDto
 */
export interface VideoManifestDto {
    /**
     * 
     * @type {string}
     * @memberof VideoManifestDto
     */
    dash?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoManifestDto
     */
    hls?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VideoManifestDto
     */
    width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VideoManifestDto
     */
    height?: number | null;
}

/**
 * Check if a given object implements the VideoManifestDto interface.
 */
export function instanceOfVideoManifestDto(value: object): value is VideoManifestDto {
    return true;
}

export function VideoManifestDtoFromJSON(json: any): VideoManifestDto {
    return VideoManifestDtoFromJSONTyped(json, false);
}

export function VideoManifestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoManifestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dash': json['Dash'] == null ? undefined : json['Dash'],
        'hls': json['Hls'] == null ? undefined : json['Hls'],
        'width': json['Width'] == null ? undefined : json['Width'],
        'height': json['Height'] == null ? undefined : json['Height'],
    };
}

export function VideoManifestDtoToJSON(value?: VideoManifestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Dash': value['dash'],
        'Hls': value['hls'],
        'Width': value['width'],
        'Height': value['height'],
    };
}

