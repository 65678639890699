import { EmptyTimeline } from "components/Timeline/components/EmptyTimeline";
import { TimelineDrawing } from "components/Timeline/components/TimelineDrawing/TimelineDrawing";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import type { ITimelineInfo } from "pages/WellDashboard/types";
import React, { useEffect } from "react";
import { useAppSelector } from "reducers/store";
import { match, P } from "ts-pattern";
import type { ITimelineEventList } from "utils/eventUtils";

export const PlanTimelineTabContents = ({
  events,
  timelineOverride,
  setTimelineOverride,
}: {
  events: ITimelineEventList[];
  timelineOverride?: ITimelineInfo | null;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null>;
}) => {
  const wellId = useSelectedWell();
  const actionEnabled = useAppSelector((state) => state.timeline.actionEnabled);

  useEffect(() => {
    if (!actionEnabled) setTimelineOverride(null);
  }, [actionEnabled, setTimelineOverride]);

  return match({ events })
    .with({ events: P.union(P.nullish, []) }, () => (
      <EmptyTimeline selectedTimeline={"Plan"} link={`/well/${wellId}/plan`} />
    ))
    .otherwise(() => (
      <TimelineDrawing
        events={events}
        timelineOverride={timelineOverride || null}
        setTimelineOverride={setTimelineOverride}
        selectedTimeline={"Plan"}
      />
    ));
};
