import type { DigestTimelineDto } from "apis/oag";
import { ChronoLine } from "components/Timeline/components/DigestTimeline/ChronoLine/ChronoLine";
import { TimelineKnotsContainer } from "components/Timeline/components/DigestTimeline/TimelineKnots/TimelineKnotsContainer";
import { type FC, useState } from "react";
import React from "react";

import * as Styled from "./style";

export const DigestTimeline: FC<{ digestTimeline: DigestTimelineDto }> =
  React.memo(({ digestTimeline }) => {
    const [chronoLineHeight, setChronoLineHeight] = useState(0);

    return (
      <Styled.FullHeight>
        <Styled.SvgWithVisibleOverflow height="100%" width="100%">
          <ChronoLine height={chronoLineHeight} />
        </Styled.SvgWithVisibleOverflow>

        <TimelineKnotsContainer
          noteChapters={digestTimeline.notes}
          unplannedEventChapters={digestTimeline.unplannedEvents}
          totalUnplannedDuration={digestTimeline.totalUnplannedTime}
          onResizeHeight={setChronoLineHeight}
        />
      </Styled.FullHeight>
    );
  });
