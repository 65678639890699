import { _ButtonSwitchGroup } from "components/PDComponents/ButtonGroup";
import { _PDCheckbox } from "components/PDComponents/Checkbox/Checkbox";
import _VerticalDivider from "components/PDComponents/Divider";
import { _Dropdown } from "components/PDComponents/Dropdown";
import { _Modal } from "components/PDComponents/Modal";
import { _Pickoff } from "components/PDComponents/Pickoff";
import { _ScrollHintContainer } from "components/PDComponents/ScrollHintContainer/ScrollHintContainer";
import { _ComboBoxMultiSelect } from "components/PDComponents/Search/ComboBoxMultiSelect/ComboBoxMultiSelect";
import { _ListWithSearch } from "components/PDComponents/Search/ListWithSearch/ListWithSearch";
import { _SvgIcon } from "components/PDComponents/SvgIcon";
import { _VirtualizedList } from "components/PDComponents/VirtualizedList/VirtualizedList";

export const PDComponent = {
  Checkbox: _PDCheckbox,
  ButtonSwitchGroup: _ButtonSwitchGroup,
  VirtualizedList: _VirtualizedList,
  ScrollHintContainer: _ScrollHintContainer,
  VerticalDivider: _VerticalDivider,
  Pickoff: _Pickoff,
  Dropdown: _Dropdown,
  ListWithSearch: _ListWithSearch,
  ComboBoxMultiSelect: _ComboBoxMultiSelect,
  SvgIcon: _SvgIcon,
  Modal: _Modal,
};
