import { Input } from "atoms/Form";
import styled from "styled-components";
import { PdSpace } from "utils/componentLibrary/Space";

export const DepthContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  border-radius: 4px;
  padding-top: 20px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
  ${PdSpace} {
    padding: 0 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 16px;
  padding: 14px;
  gap: 16px;
  background: ${({ theme }) => theme.themeStyle.intel.buttons_bg};
`;

export const DepthInput = styled(Input)`
  display: inline-flex;
  max-width: 200px;
  margin-left: 64px;
`;
