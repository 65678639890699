import { AttachmentType, type DigestEntryAttachmentDto } from "apis/oag";
import type {
  EssentialMediaDescriptor,
  RemoteImageSource,
} from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/interfaces";

export const mimeToMediaMapper: Record<
  AttachmentType,
  Extract<AttachmentType, "Video" | "Image" | "Unknown">
> = {
  [AttachmentType.Image]: AttachmentType.Image,
  [AttachmentType.Video]: AttachmentType.Video,
  [AttachmentType.Unknown]: AttachmentType.Unknown,
  [AttachmentType.Audio]: AttachmentType.Unknown,
};

export function attachmentToEssentialMediaSource(
  attachment: DigestEntryAttachmentDto,
): EssentialMediaDescriptor {
  return {
    id: attachment.id,
    fileName: attachment.sourceFilename ?? attachment.id.toString(),
    attachmentType: mimeToMediaMapper[attachment.attachmentType],
    thumbnail: attachment.thumbnail ?? "",
    readiness: attachment.readiness,
    mimeType: attachment.mimeType,
    imageSrc: {
      remoteUid: attachment.sourceFilename,
    } as RemoteImageSource,
    videoSrc: {
      manifests: attachment.videoManifests,
    },
  };
}
