import type { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { PivotKpiGroupUserLensDto, PivotOrderType } from "apis/oag";
import { DashboardType, DimensionType, PivotType } from "apis/oag";
import { arrayMoveImmutable } from "array-move";
import { Button as ButtonAtom, SelectAlt } from "atoms/Form";
import { Title } from "atoms/Typography";
import PivotKpi from "components/Lenses/ContainerLens/PivotKpi/Chart";
import { LoadingChart } from "components/Lenses/LoadingChart";
import { PDComponent } from "components/PDComponents";
import { usePivotFactsOrder } from "hooks/drillingInvariants/usePivotFactsOrder";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import { getCorrectOrder } from "hooks/pivot/useStackedKpi";
import { useKpiGroupPivotFactsMock } from "hooks/pivot/useStackedKpiMock";
import { useEffectExceptOnMount } from "hooks/react-utils/useEffectExceptOnMount";
import { useCurrentUser } from "hooks/user/useCurrentUser";
import { capitalize } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Col, Menu, Popover, Row, Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import {
  defaultPointsOrder,
  getDefaultPivotOrderTypeByPivot,
  getDisplayOptions,
  getPivotOrderIcon,
  getPivotOrderTypeTitle,
  isPerformanceSorting,
} from "./utils";

export const MenuPD = styled(Menu)`
  padding-top: 0;
  min-width: 320px;
  border-radius: 4px;
  &.ant-menu {
    box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  }
`;

export const Item = styled.div<{
  isActive?: boolean;
  isNotSelectable?: boolean;
  isSelected?: boolean;
}>`
  margin: 0px;
  margin-top: 1px;
  padding: 0px 15px;
  border-bottom: 1px solid ${colors.actions_bg};
  line-height: 38px;
  background: ${({ theme, isSelected }) =>
    isSelected
      ? `${theme.themeStyle.colors.alt_secondary_bg}b0`
      : theme.themeStyle.colors.tertiary_bg};
  color: ${({ theme, isNotSelectable }) =>
    isNotSelectable
      ? theme.themeStyle.colors.disabled_typography
      : theme.themeStyle.colors.primary_typography};
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  border-left: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  cursor: pointer;
  .pd-icon {
    font-size: 18px;
  }

  ${({ isNotSelectable, theme }) =>
    isNotSelectable
      ? null
      : css`
          :hover {
            background: ${theme.themeStyle.colors.alt_secondary_bg};
            color: ${colors.well_color};
          }
        `}

  ${(props) =>
    props.isActive &&
    css`
      background: ${props.theme.themeStyle.colors.primary_bg};
      color: ${props.theme.themeStyle.colors.disabled_typography};
      cursor: auto;
      :hover {
        color: ${props.theme.themeStyle.colors.disabled_typography};
      }
    `}
`;

const swapArrayLocs = (arr: Array<unknown>, index1: number, index2: number) => {
  const temp = arr[index1];

  arr[index1] = arr[index2];
  arr[index2] = temp;
};

const DragOverlayDnd = styled.div`
  background-color: ${({ theme }) =>
    `${theme.themeStyle.colors.tertiary_chart_bg}BF`};
  height: 86px;
  width: 400px;
  position: absolute;
  border-radius: 6px;
  z-index: ${zIndexLayer.fence};
  border: 1px dashed black;
  display: grid;
  place-items: center;
  pointer-events: none;
  .ant-typography {
    color: ${({ theme }) =>
      theme.themeStyle.colors.primary_typography}!important;
  }
  .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

const Button = styled(ButtonAtom)`
  .pd-icon {
    font-size: 18px;
  }
`;

const OptionRow = styled(Row)<{ isdragged?: string }>`
  flex-direction: column;
  width: 400px;
  height: 86px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  border-radius: 6px;
  border: solid 1px rgba(130, 140, 145, 0.14);
  box-shadow: ${(props) =>
    props.isdragged === "true" && `0 16px 16px 0 ${colors.buttons_clicked}`};
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
`;

const DragRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 6px;
  .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

const DisplayOptionsRow = ({
  option,
  axisIndex,
  data,
  id,
  isComparison,
  hasOverlay,
  isDragged,
  onSelect,
  selectedPivotOrderType,
  onPivotOrderSelect,
  isPerformanceSelectable,
}: {
  axisIndex: number;
  option: PivotType;
  id: string;
  data: Array<PivotType>;
  isComparison?: boolean;
  hasOverlay?: boolean;
  isDragged?: boolean;
  onSelect: (option: PivotType) => void;
  onPivotOrderSelect: (selection: PivotOrderType) => void;
  selectedPivotOrderType: PivotOrderType;
  isPerformanceSelectable?: boolean;
}) => {
  const { data: sortGroups } = usePivotFactsOrder();
  const [sortingGroupOpen, setSortingGroupOpen] = useState(false);
  const sortingGroups = useMemo(() => {
    return sortGroups
      ? sortGroups.find((i) => i.key === option)?.options ?? []
      : [];
  }, [option, sortGroups]);
  const getAxisNameByIndex = useCallback((axisIndex: number) => {
    switch (axisIndex) {
      case 0:
        return "Primary X-Axis";
      case 1:
      case 2:
        return "Secondary X-Axis";
      default:
        return "";
    }
  }, []);
  // Coloring coloring is going to appear only if you have 3
  const getLabel = useCallback(() => {
    const label = getAxisNameByIndex(axisIndex);
    return (isComparison ? "Coloring" : label).trim();
  }, [axisIndex, getAxisNameByIndex, isComparison]);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
      disabled: !id,
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <OptionRow
      ref={setNodeRef}
      {...attributes}
      style={style}
      isdragged={(isDragged ?? "false").toString()}
    >
      {hasOverlay ? (
        <DragOverlayDnd>
          <Title variant="title" level={3}>
            <PDComponent.SvgIcon name="sort" /> Swap positions
          </Title>
        </DragOverlayDnd>
      ) : null}
      <div
        style={{
          padding: "14px 13px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 6,
            flexDirection: "row",
          }}
        >
          <Col>
            <Title variant="faded" level={3}>
              {getLabel()}
            </Title>
          </Col>
          <Col>
            <Title variant="faded" level={3}>
              {getPivotOrderTypeTitle({
                pivot: option,
                pivotSort: selectedPivotOrderType,
              })}
            </Title>
          </Col>
        </div>
        <Col>
          <Row gutter={[6, 0]}>
            <DragRow {...listeners}>
              <PDComponent.SvgIcon name="drag" width="8px" height="12px" />
            </DragRow>
            <Col flex="1">
              <SelectAlt
                disabled={isComparison}
                showArrow={!isComparison}
                style={{ width: "100%" }}
                size="large"
                error={""}
                value={
                  isComparison && option === PivotType.Rig
                    ? "Offset Rigs"
                    : option
                }
                onSelect={(value) => onSelect(value as PivotType)}
              >
                {(data ?? [])
                  .filter((pivotType) => pivotType !== PivotType.Operator)
                  .map((value) => {
                    return (
                      <SelectAlt.Option
                        key={value}
                        value={value}
                        isicondisabled={"true"}
                      >
                        {value.split(/(?=[A-Z])/).join(" ")}
                      </SelectAlt.Option>
                    );
                  })}
              </SelectAlt>
            </Col>
            {sortingGroups?.length > 0 ? (
              <Col>
                <Popover
                  content={
                    <MenuPD>
                      {sortingGroups.map((sortOption) => (
                        <Item
                          key={sortOption}
                          isActive={false}
                          isSelected={selectedPivotOrderType === sortOption}
                          // Using negative logic as we default to it being selectable
                          isNotSelectable={
                            !isPerformanceSelectable &&
                            isPerformanceSorting(sortOption)
                          }
                          onClick={() => {
                            if (
                              isPerformanceSelectable
                                ? true
                                : !isPerformanceSorting(sortOption)
                            ) {
                              onPivotOrderSelect(sortOption);
                            }
                            setSortingGroupOpen(false);
                          }}
                        >
                          <Tooltip
                            title={
                              !isPerformanceSelectable &&
                              isPerformanceSorting(sortOption)
                                ? "Not available for this X-Axis"
                                : null
                            }
                            placement="right"
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              {getPivotOrderIcon({
                                pivot: option,
                                pivotSort: sortOption,
                              })}{" "}
                              {getPivotOrderTypeTitle({
                                pivot: option,
                                pivotSort: sortOption,
                              })}
                            </div>
                          </Tooltip>
                        </Item>
                      ))}
                    </MenuPD>
                  }
                  open={sortingGroupOpen}
                  onOpenChange={(value) => {
                    setSortingGroupOpen(value);
                  }}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <Button>
                    {getPivotOrderIcon({
                      pivot: option,
                      pivotSort: selectedPivotOrderType,
                    })}
                  </Button>
                </Popover>
              </Col>
            ) : null}
          </Row>
        </Col>
      </div>
    </OptionRow>
  );
};

interface IDisplayOptionsModal {
  isComparing?: boolean;
  lensTemplateId?: number;
  isOpen: boolean;
  onClose: () => void;
  saveDisplayOptions: (
    options: Array<PivotType>,
    order: PivotOrderType[],
  ) => void;
  prevDisplayOptions: Array<PivotType>;
  prevDisplayOrderOptions: Array<PivotOrderType>;
}
const customStyles = {
  overlay: {
    zIndex: zIndexLayer.moon,
    backgroundColor: colors.faded_bg,
  },
  content: {
    top: "50%",
    left: "50%",
    overflow: "hidden",
    right: "auto",
    height: "512px",
    width: "870px",
    borderRadius: 6,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
export const DisplayOptionsModal = ({
  isComparing,
  isOpen,
  onClose,
  lensTemplateId,
  saveDisplayOptions,
  prevDisplayOrderOptions,
  prevDisplayOptions,
}: IDisplayOptionsModal) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const { data: userData } = useCurrentUser();
  const onCancel = () => setIsShowModal(false);
  const [isDragging, setIsDragging] = useState<string | null>(null);
  const activeId = useRef<string | number | null>(null);
  const { data: templates } = useLensTemplates();
  const template = lensTemplateId ? templates.byId[lensTemplateId] : null;
  const [displayOptions, setDisplayOptions] = useState(
    prevDisplayOptions.map((e, idx) => ({
      orderValue: prevDisplayOrderOptions[idx],
      value: e,
      id: `${e}-${idx}`,
    })),
  );

  useEffect(() => {
    setDisplayOptions(
      prevDisplayOptions.map((e, idx) => ({
        orderValue: prevDisplayOrderOptions[idx],
        value: e,
        id: `${e}-${idx}`,
      })),
    );
  }, [prevDisplayOptions, prevDisplayOrderOptions]);
  const saveDisplayOptionsHandler = useCallback(() => {
    saveDisplayOptions(
      displayOptions.map((e) => e.value),
      displayOptions.map((e) => e.orderValue),
    );
    onClose();
  }, [displayOptions, onClose, saveDisplayOptions]);

  const params = useParams<{
    dashboardType: string;
    rigId?: string;
  }>();

  const { data, isLoading: isLoadingPivotsFacts } = useKpiGroupPivotFactsMock(
    {
      lensTemplateId,
      pivotLevelOrder: displayOptions.map((e) => e.orderValue),
      pivots: displayOptions.map((e) => e.value),
    },
    {
      enabled:
        template?.targetDashboardType === DashboardType.Rig ||
        capitalize(params.dashboardType) === DashboardType.Rig ||
        !!params.rigId,
    },
  );
  const sensors = useSensors(useSensor(PointerSensor));
  useEffectExceptOnMount(() => {
    setDisplayOptions((prevOptions) => {
      const pivot = getDisplayOptions(isComparing)(
        prevOptions.map((option) => option.value),
      );
      const order = getCorrectOrder(
        Object.values(pivot),
        {
          pivot: prevOptions.map((option) => option.value),
          order: prevOptions.map((option) => option.orderValue),
        },
        !!isComparing,
      );
      const crtDisplayOptions = pivot.map((option, idx) => ({
        value: option,
        orderValue: order[idx] as unknown as PivotOrderType,
        id: `${option}-${idx}`,
      }));

      return crtDisplayOptions;
    });
  }, [isComparing]);

  const currentDisplayOptions = useMemo(
    () => displayOptions.slice(0, isComparing ? 3 : 2),
    [displayOptions, isComparing],
  );
  const onSortEnd = ({ active, over }: DragEndEvent) => {
    setIsDragging(null);
    setDisplayOptions((prev) => {
      const points = prev;
      const oldIndex = points.findIndex((item) => active?.id === item.id);
      const newIndex = points.findIndex((item) => over?.id === item.id);

      const newPoints = arrayMoveImmutable(points, oldIndex, newIndex);
      if (
        currentDisplayOptions.filter((e) => e.value !== PivotType.None)
          .length === 3 &&
        newPoints[0].id?.startsWith(PivotType.Rig)
      ) {
        setIsShowModal(true);
        return defaultPointsOrder(points);
      }
      return defaultPointsOrder(newPoints);
    });
  };

  const ids = useMemo(
    () => displayOptions.map((option) => option.id),
    [displayOptions],
  );
  const { atomThemeVariant, themeStyle } = useCustomTheme();

  const onDragStart = ({ active }: DragStartEvent) => {
    activeId.current = active.id;
    setIsDragging(active.id.toString());
  };

  return [
    <Modal
      key="display_options_modal"
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        content: {
          ...customStyles.content,
          backgroundColor: themeStyle.colors.primary_bg,
        },
        overlay: { ...customStyles.overlay },
      }}
      ariaHideApp={false}
    >
      <div
        style={{
          display: "grid",
          overflow: "hidden",
          gridTemplateRows: "444px 1fr",
        }}
      >
        <div>
          <Row
            style={{
              height: "100%",
            }}
            wrap={false}
            justify="space-between"
          >
            <Col
              style={{
                width: "100%",
              }}
            >
              <Col
                style={{
                  padding: "28px 24px",
                }}
              >
                <Title
                  variant={atomThemeVariant}
                  level={2}
                  weight={500}
                  style={{
                    paddingBottom: 21,
                  }}
                >
                  Data Groups
                </Title>
                <Title variant={atomThemeVariant} level={3} weight={500}>
                  Prioritize data groups by:
                </Title>
              </Col>
              <Col>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={onSortEnd}
                  onDragStart={onDragStart}
                >
                  <SortableContext
                    items={ids}
                    strategy={verticalListSortingStrategy}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {currentDisplayOptions.map((option, index) => {
                        let data: Array<PivotType> | null = null;
                        /**
                         * Pivot selection rules
                         * 1. Rigs can not be selected
                         * 2. No duplicated
                         * 3. No stand when offsets selected
                         * 4. One of them should not be None
                         */
                        if (option.value === PivotType.Rig) data = null;
                        else
                          data = Object.values(PivotType).filter((e) => {
                            if (
                              !userData?.canAccessCrew &&
                              e === PivotType.Driller
                            )
                              return false;
                            if (e === PivotType.None) {
                              return currentDisplayOptions.some(
                                (crtOption, idx) =>
                                  crtOption.value !== PivotType.None &&
                                  (isComparing ? true : index !== idx),
                              );
                            }
                            if (e === PivotType.Rig) return false;
                            if (
                              currentDisplayOptions.some(
                                (crtOption, crtIdx) =>
                                  crtOption.value === e && index !== crtIdx,
                              )
                            )
                              return false;
                            if (isComparing && e === PivotType.StandNumber)
                              return false;

                            return true;
                          });
                        const selectedDisplayOptions = displayOptions.filter(
                          (pivot) => pivot.value !== PivotType.None,
                        );
                        const axisIndex = selectedDisplayOptions.findIndex(
                          (displayOption) => displayOption === option,
                        );
                        return [
                          <DisplayOptionsRow
                            key={option.id}
                            id={option.id}
                            isComparison={
                              isComparing
                                ? option.value === PivotType.Rig
                                : undefined
                            }
                            axisIndex={axisIndex}
                            isPerformanceSelectable={
                              axisIndex === selectedDisplayOptions.length - 1
                            }
                            selectedPivotOrderType={
                              currentDisplayOptions[index]?.orderValue
                            }
                            onPivotOrderSelect={(selection: PivotOrderType) => {
                              setDisplayOptions((prev) => {
                                const crt = [
                                  ...prev.slice(0, index),
                                  { ...prev[index], orderValue: selection },
                                  ...prev.slice(index + 1),
                                ];
                                return crt;
                              });
                            }}
                            hasOverlay={
                              !!(isDragging && isDragging !== option.id)
                            }
                            isDragged={
                              !!(isDragging && isDragging === option.id)
                            }
                            onSelect={(selection: PivotType) => {
                              setDisplayOptions((prev) => {
                                const getNewArray = () => {
                                  const crt = [
                                    ...prev.slice(0, index),
                                    {
                                      value: selection,
                                      orderValue:
                                        getDefaultPivotOrderTypeByPivot(
                                          selection,
                                        ),
                                      id: prev[index].id,
                                    },
                                    ...prev.slice(index + 1),
                                  ];

                                  if (
                                    crt.filter(
                                      (e) => e.value !== PivotType.None,
                                    ).length === 3 &&
                                    crt[0].value === PivotType.Rig
                                  ) {
                                    swapArrayLocs(crt, 0, 1);
                                    return crt;
                                  }
                                  return crt;
                                };
                                const newDisplayOptionsOrder = getNewArray();
                                const getIndexWrongDisplayOption = (
                                  argDisplayArr: typeof newDisplayOptionsOrder,
                                ) => {
                                  const pivotsNullFiltered =
                                    argDisplayArr.filter(
                                      (e) => e.value !== PivotType.None,
                                    );
                                  const pivotKpiValueIndexInsideFiltered =
                                    pivotsNullFiltered.findIndex((e) =>
                                      isPerformanceSorting(e.orderValue),
                                    );
                                  const pivotKpiValueIndex =
                                    argDisplayArr.findIndex((e) =>
                                      isPerformanceSorting(e.orderValue),
                                    );
                                  if (
                                    pivotKpiValueIndexInsideFiltered !==
                                    pivotsNullFiltered.length - 1
                                  )
                                    return pivotKpiValueIndex;
                                  return -1;
                                };
                                const indexDisplayOptionDefault =
                                  getIndexWrongDisplayOption(
                                    newDisplayOptionsOrder,
                                  );
                                if (
                                  indexDisplayOptionDefault !== -1 &&
                                  newDisplayOptionsOrder[
                                    indexDisplayOptionDefault
                                  ]
                                ) {
                                  const ret = [
                                    ...newDisplayOptionsOrder.slice(
                                      0,
                                      indexDisplayOptionDefault,
                                    ),
                                    {
                                      ...newDisplayOptionsOrder[
                                        indexDisplayOptionDefault
                                      ],
                                      orderValue:
                                        getDefaultPivotOrderTypeByPivot(
                                          newDisplayOptionsOrder[
                                            indexDisplayOptionDefault
                                          ].value,
                                        ),
                                    },
                                    ...newDisplayOptionsOrder.slice(
                                      indexDisplayOptionDefault + 1,
                                    ),
                                  ];
                                  return ret;
                                }
                                return newDisplayOptionsOrder;
                              });
                            }}
                            data={(data ?? []).filter((e) =>
                              isComparing && selectedDisplayOptions.length === 3
                                ? e !== PivotType.Rig
                                : true,
                            )}
                            option={option.value}
                          />,
                        ];
                      })}
                    </div>
                  </SortableContext>
                </DndContext>
              </Col>
            </Col>
            <Col>
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100%",
                  padding: "0px 24px",
                  width: "420px",
                  backgroundColor: themeStyle.colors.tertiary_bg,
                }}
              >
                <Col
                  style={{
                    width: "372px",
                    height: "298px",
                    margin: "62px 0 0",
                    overflow: "hidden",
                    border: `solid 1px ${themeStyle.colors.primary_accent}`,
                    background: themeStyle.colors.tertiary_bg,
                  }}
                >
                  {isLoadingPivotsFacts || !data ? (
                    <LoadingChart
                      isLoading
                      title={"Loading..."}
                      description={""}
                    />
                  ) : (
                    <PivotKpi
                      data={data}
                      disableValueAxis
                      isMock
                      dimension={DimensionType.Metres}
                      lens={
                        { showsOutliers: false } as PivotKpiGroupUserLensDto
                      }
                      detailed={false}
                    />
                  )}
                </Col>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row
            justify="space-between"
            gutter={[16, 16]}
            style={{
              padding: 16,
              borderTop: `1px solid ${themeStyle.colors.primary_accent}`,
              height: "100%",
              backgroundColor: themeStyle.colors.alt_secondary_bg,
            }}
          >
            <Col>
              <Button trackingName="Cancel Data Groups" onClick={onClose}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                trackingName="Save Changes"
                onClick={saveDisplayOptionsHandler}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>,
    <Modal
      onRequestClose={onCancel}
      key="offset_rig_modal_error"
      style={{
        content: {
          ...customStyles.content,
          backgroundColor: themeStyle.colors.primary_bg,
          height: "auto",
          width: "auto",
          padding: 16,
          paddingTop: 32,
        },
        overlay: { ...customStyles.overlay, zIndex: zIndexLayer.phobos },
      }}
      ariaHideApp={false}
      isOpen={isShowModal}
    >
      <Title
        variant={atomThemeVariant}
        level={3}
        weight={500}
        style={{
          paddingBottom: 12,
        }}
      >
        Offset Rigs cannot be the first priority group
        <br />
        when there are three items.
      </Title>
      <Title variant="faded" level={3} style={{ marginBottom: 16 }}>
        If desired, please set one of the values to <b>None</b>.
      </Title>
      <div>
        <Button
          type="primary"
          onClick={() => {
            onCancel();
          }}
        >
          Got it
        </Button>
      </div>
    </Modal>,
  ];
};
