/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigestChapterDto } from './DigestChapterDto';
import {
    DigestChapterDtoFromJSON,
    DigestChapterDtoFromJSONTyped,
    DigestChapterDtoToJSON,
} from './DigestChapterDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface DigestTimelineDto
 */
export interface DigestTimelineDto {
    /**
     * 
     * @type {Array<DigestChapterDto>}
     * @memberof DigestTimelineDto
     */
    notes: Array<DigestChapterDto>;
    /**
     * 
     * @type {Array<DigestChapterDto>}
     * @memberof DigestTimelineDto
     */
    unplannedEvents: Array<DigestChapterDto>;
    /**
     * 
     * @type {ResultDataState}
     * @memberof DigestTimelineDto
     */
    readonly dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof DigestTimelineDto
     */
    readonly totalUnplannedTime: number;
}



/**
 * Check if a given object implements the DigestTimelineDto interface.
 */
export function instanceOfDigestTimelineDto(value: object): value is DigestTimelineDto {
    if (!('notes' in value) || value['notes'] === undefined) return false;
    if (!('unplannedEvents' in value) || value['unplannedEvents'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('totalUnplannedTime' in value) || value['totalUnplannedTime'] === undefined) return false;
    return true;
}

export function DigestTimelineDtoFromJSON(json: any): DigestTimelineDto {
    return DigestTimelineDtoFromJSONTyped(json, false);
}

export function DigestTimelineDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigestTimelineDto {
    if (json == null) {
        return json;
    }
    return {
        
        'notes': ((json['Notes'] as Array<any>).map(DigestChapterDtoFromJSON)),
        'unplannedEvents': ((json['UnplannedEvents'] as Array<any>).map(DigestChapterDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'totalUnplannedTime': json['TotalUnplannedTime'],
    };
}

export function DigestTimelineDtoToJSON(value?: Omit<DigestTimelineDto, 'DataState'|'TotalUnplannedTime'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Notes': ((value['notes'] as Array<any>).map(DigestChapterDtoToJSON)),
        'UnplannedEvents': ((value['unplannedEvents'] as Array<any>).map(DigestChapterDtoToJSON)),
    };
}

