import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { UserWithAccessCountDto } from "apis/oag";
import { OperatorsApi, UserStatusType } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { Button, Input } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import { TableWhite } from "components/CustomTable";
import { ActionsHeader, PlanSectionLayout } from "components/Layout/Tabbed";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import dayjs from "dayjs";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { useAdminSingleAccountUserList } from "hooks/user/admin/useAdminSingleAccountUserList";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { rangeAll } from "utils/helper";
import { PDConstants } from "utils/PDConstants/PDConstants";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

const tableColumns = (atomThemeVariant: "dark" | "white") => [
  {
    title: "User",
    dataIndex: "userName",
    key: "user",
    render(text: string, record: unknown, index: number) {
      return (
        <Space>
          <Title level={3} variant={atomThemeVariant} weight={500}>
            {
              (
                record as {
                  firstName: string;
                  lastName: string;
                }
              )?.firstName
            }{" "}
            {
              (
                record as {
                  firstName: string;
                  lastName: string;
                }
              )?.lastName
            }
          </Title>
          <Title level={3} variant="faded">
            {text}
          </Title>
        </Space>
      );
    },
  },
  {
    title: "Job Title",
    dataIndex: "jobTitle",
    key: "job",
    render(text: string) {
      return (
        <Space>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {text}
          </Title>
        </Space>
      );
    },
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    render(text: string) {
      return (
        <Space>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {text}
          </Title>
        </Space>
      );
    },
  },
  {
    title: "Wells",
    dataIndex: "wells",
    key: "wells",
    render(text: string, record: unknown) {
      return (
        <Space>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {
              (
                record as {
                  claimedWellCount: number;
                  operatorWellCount: number;
                }
              ).claimedWellCount
            }{" "}
            /{" "}
            {
              (
                record as {
                  claimedWellCount: number;
                  operatorWellCount: number;
                }
              ).operatorWellCount
            }
          </Title>
        </Space>
      );
    },
  },
  {
    title: "Expiration Date",
    dataIndex: "expirationDate",
    key: "expirationDate",
    render(value: string) {
      return value ? dayjs(value).format("MM/DD/YYYY") : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render(text: string) {
      return (
        <Space>
          <Title level={3} variant={atomThemeVariant} weight={400}>
            {text}
          </Title>
        </Space>
      );
    },
  },
];

const operators = new OperatorsApi(apiConfig);

const Users = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { accountId } = useParams<{ accountId: string }>();
  const { atomThemeVariant } = useCustomTheme();
  const rigs = useRigs();
  const users = useAdminSingleAccountUserList({ id: Number(accountId) });

  const period = useAppSelector(
    (state) => state.admin.singleAccountUsers.period,
  );
  const rigIdsState = useAppSelector(
    (state) => state.admin.singleAccountUsers.rigs,
  );
  const statusState = useAppSelector(
    (state) => state.admin.singleAccountUsers.userStatus,
  );

  const [showRigPopup, setShowRigPopup] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const [quickSearchValue, setQuickSearchValue] = useState<string>("");

  const currentAllAction = useMemo(() => {
    const allStates = [
      ...new Set(users.data?.list?.map((e) => e.status) || []),
    ];
    if (allStates.length === 1) {
      // This code is verbose to help understand better in the future
      if (allStates[0] === UserStatusType.Active) {
        return UserStatusType.Inactive;
      } else {
        return UserStatusType.Active;
      }
    } else {
      return UserStatusType.Inactive;
    }
  }, [users.data?.list]);

  const updateTimeRange = (period: RangeType) => {
    dispatch({
      type: "ADMIN_ACCOUNTS_USERS_SET_TIME_RANGE",
      payload: {
        period,
      },
    });
  };

  const updateRigs = useCallback(
    (rigIds: number[]) => {
      dispatch({
        type: "ADMIN_ACCOUNTS_USERS_SET_RIGS",
        payload: {
          rigs: rigIds,
        },
      });
      return true;
    },
    [dispatch],
  );

  const updateStatuses = (f: UserStatusType[]) => {
    setShowStatusPopup(false);
    dispatch({
      type: "ADMIN_ACCOUNTS_USERS_SET_USER_STATUSES",
      payload: {
        statuses: f,
      },
    });
  };

  const updateSearch = () => {
    dispatch({
      type: "ADMIN_ACCOUNTS_USERS_SET_SEARCH",
      payload: {
        search: quickSearchValue,
      },
    });
  };

  const toggleUserStateMutation = useMutation({
    mutationFn: ({ accountId }: { accountId: number }) => {
      if (currentAllAction === UserStatusType.Inactive) {
        return operators.apiOperatorsIdInactiveAllPut({ id: accountId });
      } else {
        return operators.apiOperatorsIdActiveAllPut({ id: accountId });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [{ type: PDQueryType.OPERATORS_ACCOUNT_USERS }],
        exact: false,
      });
    },
    onSuccess: () => {
      toast.success({ message: "All user states were updated!" });
    },
    onError: () => {
      toast.error({ message: "Something went wrong!" });
    },
  });

  useEffect(() => {
    if (rigs.data) {
      updateRigs(rigs.data.list.map((e) => e.id));
    }
  }, [rigs.data, updateRigs]);

  // Reset search on clear
  useEffect(() => {
    if (!quickSearchValue) {
      dispatch({
        type: "ADMIN_ACCOUNTS_USERS_SET_SEARCH",
        payload: {
          search: null,
        },
      });
    }
  }, [dispatch, quickSearchValue]);

  const isLoading = useMemo(
    () => users.isLoading || rigs.isLoading,
    [rigs.isLoading, users.isLoading],
  );

  return (
    <PlanSectionLayout
      header={
        <ActionsHeader>
          <Row
            gutter={12}
            justify="space-between"
            align="middle"
            style={{
              height: PDConstants.SizesPx.Layout.TopBarHeight.asPixels(),
            }}
          >
            <Col flex="0 auto">
              <Space>
                <Space direction="horizontal" size={4}>
                  <Input
                    allowClear
                    placeholder="Search by Name or Email"
                    style={{ width: "300px" }}
                    value={quickSearchValue}
                    onChange={(e) => setQuickSearchValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        updateSearch();
                      }
                    }}
                  />
                  <Button
                    size="large"
                    type="primary"
                    icon={<PDComponent.SvgIcon name="search" />}
                    onClick={updateSearch}
                  />
                </Space>

                <PDComponent.VerticalDivider />

                <Tooltip title="Time Range">
                  <DatePicker
                    allowAllDates
                    defaultDate={rangeAll}
                    selection={period}
                    onApply={updateTimeRange}
                  />
                </Tooltip>

                <Tooltip title="Rigs">
                  <Popover
                    content={
                      <PDComponent.ComboBoxMultiSelect
                        placeholder="Search Rigs"
                        options={(rigs.data?.list ?? []).map((e) => ({
                          id: e.id,
                          name: e.shortName || "",
                        }))}
                        values={rigIdsState ?? []}
                        onChange={(rigIds: number[]) => {
                          updateRigs(rigIds);
                          setShowRigPopup(false);
                          return void 0;
                        }}
                      />
                    }
                    trigger="click"
                    placement="bottom"
                    open={showRigPopup}
                    onOpenChange={(e) => setShowRigPopup(e)}
                    destroyTooltipOnHide
                  >
                    <Button
                      size="large"
                      icon={<PDComponent.SvgIcon name="rig" />}
                      type={
                        rigIdsState === null ||
                        rigIdsState?.length !== rigs.data?.list?.length
                          ? "primary"
                          : "default"
                      }
                      ghost={
                        !!(
                          rigIdsState &&
                          rigIdsState?.length !== rigs.data?.list?.length
                        )
                      }
                      $engaged={showRigPopup}
                    />
                  </Popover>
                </Tooltip>

                <Tooltip title="User Status">
                  <Popover
                    content={
                      <PDComponent.Pickoff
                        options={Object.values(UserStatusType).map((e) => ({
                          id: e,
                          name: e,
                        }))}
                        values={statusState || []}
                        onChange={(e) => updateStatuses(e)}
                      />
                    }
                    trigger="click"
                    placement="bottom"
                    open={showStatusPopup}
                    onOpenChange={(e) => setShowStatusPopup(e)}
                    destroyTooltipOnHide
                  >
                    <Button
                      icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
                      type={
                        (statusState || []).length !==
                        Object.keys(UserStatusType).length
                          ? "primary"
                          : "default"
                      }
                      ghost={
                        (statusState || []).length !==
                        Object.keys(UserStatusType).length
                      }
                      $engaged={showStatusPopup}
                    />
                  </Popover>
                </Tooltip>
              </Space>
            </Col>
            <Col flex="0 auto">
              <Space>
                <Button
                  icon={
                    currentAllAction === UserStatusType.Active ? (
                      <PDComponent.SvgIcon name="playFilled" />
                    ) : (
                      <PDComponent.SvgIcon name="stopFilled" />
                    )
                  }
                  onClick={() =>
                    toggleUserStateMutation.mutate({
                      accountId: +(accountId || 0),
                    })
                  }
                  loading={toggleUserStateMutation.isPending}
                >
                  Set All Users {currentAllAction}
                </Button>
                <Link to={`/admin/accounts/${accountId}/users/new/overview`}>
                  <Button type="primary">Add User</Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </ActionsHeader>
      }
      content={
        isLoading ? (
          <Loader centered />
        ) : (
          <TableWhite
            columns={tableColumns(atomThemeVariant)}
            dataSource={users?.data?.list}
            // TODO Not sure this one works
            rowKey={(user) => (user as UserWithAccessCountDto).externalId || ""}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                navigate(
                  `/admin/accounts/${accountId}/users/${(record as UserWithAccessCountDto).id}/overview`,
                );
              },
            })}
          />
        )
      }
    />
  );
};

export default Users;
