import { isNil } from "lodash";
import { numberToInput } from "pages/IntelDashboard/components/IntelHeader/DepthFilter/utils";
import type { UOMHelper } from "utils/format";

export const getDepthText = (
  minMeasuredDepth: number | null | undefined,
  maxMeasuredDepth: number | null | undefined,
  depthUOM: UOMHelper,
) => {
  if (isNil(minMeasuredDepth) && isNil(maxMeasuredDepth)) {
    return "";
  }
  if (isNil(minMeasuredDepth) && !isNil(maxMeasuredDepth)) {
    return `< ${numberToInput(maxMeasuredDepth, depthUOM)} ${depthUOM.abbr}`;
  }
  if (!isNil(minMeasuredDepth) && isNil(maxMeasuredDepth)) {
    return `> ${numberToInput(minMeasuredDepth, depthUOM)} ${depthUOM.abbr}`;
  }

  return `${numberToInput(minMeasuredDepth, depthUOM) ?? ""} - ${numberToInput(maxMeasuredDepth, depthUOM) ?? ""} ${depthUOM.abbr}`;
};
