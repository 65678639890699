import Line from "@visx/shape/lib/shapes/Line";
import { useMemo } from "react";
import colors from "utils/colors";
import { PDConstants } from "utils/PDConstants/PDConstants";

/**
 * The vertical line inside a timeline.
 */
export const ChronoLine = ({ height }: { height: number }) => {
  const [x, y] = useMemo(
    () => [
      PDConstants.SizesPx.Timeline.ChronoLine.LeftMargin.asNumber(),
      PDConstants.SizesPx.Timeline.ChronoLine.TopMargin.asNumber(),
    ],
    [],
  );

  return (
    <Line
      from={{ x, y }}
      to={{ x, y: y + height }}
      stroke={colors.light_green}
      opacity={0.6}
      strokeWidth={2}
    />
  );
};
