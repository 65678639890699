import { ResultDataState } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { DigestTimeline } from "components/Timeline/components/DigestTimeline/DigestTimeline";
import { TimelineDrawing } from "components/Timeline/components/TimelineDrawing/TimelineDrawing";
import * as Styled from "components/Timeline/style";
import { MemoTabs } from "components/Timeline/utils";
import { useWellDigestTimeline } from "hooks/comments/useWellDigestTimeline";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { useEffectExceptOnMount } from "hooks/react-utils/useEffectExceptOnMount";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import type { ITimelineInfo } from "pages/WellDashboard/types";
import React, { startTransition, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { match } from "ts-pattern";
import type { ITimelineEventList } from "utils/eventUtils";

export const ActualTimelineTabContents = ({
  events,
  timelineOverride,
  setTimelineOverride,
}: {
  events: ITimelineEventList[];
  timelineOverride?: ITimelineInfo | null;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null>;
}) => {
  const timelineState = useAppSelector((state) => state.state.timeline_state);
  const { isWell: isWellDashboard } = useDashboardType();
  const dispatch = useAppDispatch();

  const selectedTab = useMemo(() => {
    return timelineState === "ActualWeb" ? MemoTabs.Web : MemoTabs.Mobile;
  }, [timelineState]);

  const wellId = useSelectedWell();
  const actionEnabled = useAppSelector((state) => state.timeline.actionEnabled);

  useEffectExceptOnMount(() => {
    dispatch({
      type: "SET_TIMELINE_STATE",
      payload: "ActualWeb",
    });
  }, [wellId]);

  const { data: digestTimelineMemos } = useWellDigestTimeline({
    wellId,
    // Keeping the mess outside of the hook. No other sensible approach other than creating needless component layers.
    options: isWellDashboard
      ? undefined
      : {
          queryKey: [],
          queryFn: () =>
            Promise.resolve({
              notes: [],
              unplannedEvents: [],
              totalUnplannedTime: 0,
              dataState: ResultDataState.Valid,
            }),
        },
  });

  useEffect(() => {
    if (!actionEnabled) setTimelineOverride(null);
  }, [actionEnabled, setTimelineOverride]);

  const hasMemoSelector = useMemo(
    () =>
      isWellDashboard &&
      digestTimelineMemos.dataState === ResultDataState.Valid,
    [digestTimelineMemos.dataState, isWellDashboard],
  );

  return (
    <>
      {hasMemoSelector ? (
        <Styled.MemoButtonContainer>
          <PDComponent.ButtonSwitchGroup
            selectedButtonIndex={selectedTab === MemoTabs.Web ? 0 : 1}
            buttons={[
              {
                label: MemoTabs.Web,
                onClick: () =>
                  startTransition(() => {
                    dispatch({
                      type: "SET_TIMELINE_STATE",
                      payload: "ActualWeb",
                    });
                  }),
              },
              {
                label: MemoTabs.Mobile,
                onClick: () =>
                  startTransition(() => {
                    dispatch({
                      type: "SET_TIMELINE_STATE",
                      payload: "ActualMemos",
                    });
                  }),
              },
            ]}
          />
        </Styled.MemoButtonContainer>
      ) : null}

      {match({ selectedTab, events })
        .with({ selectedTab: MemoTabs.Web }, () => (
          <TimelineDrawing
            events={events}
            timelineOverride={timelineOverride || null}
            setTimelineOverride={setTimelineOverride}
            selectedTimeline={"ActualWeb"}
          />
        ))
        .with(
          {
            selectedTab: MemoTabs.Mobile,
          },
          () => <DigestTimeline digestTimeline={digestTimelineMemos} />,
        )
        .otherwise(() => null)}
    </>
  );
};
