import styled from "styled-components";
import { PDConstants } from "utils/PDConstants/PDConstants";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: ${PDConstants.SizesPx.Timeline.ChronoLine.LeftMargin.asPixels()};
  top: ${PDConstants.SizesPx.Timeline.ChronoLine.TopMargin.asPixels()};
  width: calc(
    100% - ${PDConstants.SizesPx.Timeline.ChronoLine.LeftMargin.asPixels()}
  );
  padding-bottom: ${PDConstants.SizesPx.Timeline.ChronoLine.BottomPadding.asPixels()};
`;
