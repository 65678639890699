import type { RefObject } from "react";
import { useLayoutEffect, useState } from "react";

export const useTruncatedText = ({ ref }: { ref: RefObject<HTMLElement> }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isShowingMore, setIsShowingMore] = useState(false);

  useLayoutEffect(() => {
    const { offsetHeight, scrollHeight } = ref.current || {};
    if (!offsetHeight || !scrollHeight) return;

    setIsTruncated(scrollHeight > offsetHeight + 1);
  }, [ref]);

  const toggleIsShowingMore = () => setIsShowingMore((prev) => !prev);

  return {
    isTruncated,
    isShowingMore,
    toggleIsShowingMore,
  };
};
