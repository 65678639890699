/* eslint-disable local-rules/styles-location */
import type { FC, ReactNode } from "react";
import { useAppSelector } from "reducers/store";
import { IThemeState } from "reducers/themeReducer";
import { ThemeProvider } from "styled-components";
import colors from "utils/colors";

export interface IThemeStyle {
  colors: {
    black_white: string;
    white_black: string;
    alt_primary_accent: string;
    alt_primary_bg: string;
    alt_quaterniary_bg: string;
    alt_secondary_bg: string;
    alt_tertiary_bg: string;
    alt_typography: string;
    disabled_typography: string;
    faint_typography: string;
    light_typography: string;
    light_typography_inverted: string;
    inverted_primary_typography: string;
    off_bg: string;
    off_secondary_bg: string;
    overlay_bg: string;
    primary_shadow: string;
    primary_accent: string;
    primary_bg: string;
    alternate_bg: string;
    primary_bg_faded: string;
    primary_button_bg: string;
    primary_button_accent: string;
    primary_chart_accent: string;
    secondary_chart_accent: string;
    primary_chart_bg: string;
    primary_wedge_bg: string;
    primary_wedge_arc: string;
    primary_hover_typography: string;
    primary_scroll_bg: string;
    primary_typography: string;
    quaterniary_bg: string;
    quaterniary_chart_bg: string;
    alt_accent: string;
    secondary_accent: string;
    secondary_bg: string;
    secondary_chart_bg: string;
    secondary_typography: string;
    separation_line: string;
    soft_accent: string;
    tertiary_accent: string;
    tertiary_bg: string;
    tertiary_chart_bg: string;
    score_card_bg: string;
    tertiary_typography: string;
    inner_label: string;
    label_pill: string;
    row_positive_hover: string;
    positive_tag: string;
    negative_tag: string;
    frozen_tundra: string;
    torque_and_drag_legend: string;
    primary_box_shadow: string;
    delimiter: string;
    primary_tabs_bg: string;
    secondary_tabs_bg: string;
    personal_tab_bar_bg: string;
    operator_tab_bar_bg: string;
    active_tab_bg: string;
    white_gray: string;
    inspection_view_border: string;
    phase_indicator: string;
    future_plan_line: string;
    all_rigs_title: string;
  };
  tabs: {
    typography: string;
    edit_button: string;
  };
  intel: {
    legend_selected_bg: string;
    legend_hover_bg: string;
    typography: string;
    selector_bg: string;
    selected_button_bg: string;
    grid_lines: string;
    chart_bg: string;
    buttons_bg: string;
  };
  legend: {
    selected_bg: string;
    bg: string;
  };
  outliers: {
    bars: string;
    bars_transparent: string;
    bars_stacked_transparent: string;
  };
  notifications: {
    row_bg: string;
    read_bg: string;
    unread_bg: string;
    hover_bg: string;
  };
  clara: {
    bg: string;
    CTA_bg: string;
    icon_border: string;
    icon_bg: string;
    generating_bg: string;
    sidebar_bg: string;
    text_color: string;
  };
  wells: {
    phases: {
      "Pre-Drill": string;
      "Surface Drill": string;
      "Surface Flat": string;
      "Intermediate Flat": string;
      "Intermediate Drill": string;
      "Production Flat": string;
      "Production Drill": string;
      Unknown: string;
    };
    directionalIntervals: {
      Vertical: string;
      Build: string;
      Lateral: string;
      Unknown: string;
    };
  };
  digestTimeline: {
    knotSmall: {
      bg: string;
      drop: string;
    };
    dateUpdates: {
      bg: string;
    };
  };
}

export const WHITE_THEME_KEY = "white";
export const DARK_THEME_KEY = "dark";

export interface ITheme {
  isDark: boolean;
  themeStyle: IThemeStyle;
  atomThemeVariant: "dark" | "white"; // Tightly coupled with named strings from atom typography, for easier switch
}

const themes: Record<IThemeState, IThemeStyle> = {
  [IThemeState.DARK]: {
    colors: {
      black_white: colors.white,
      white_black: colors.black,
      alt_primary_accent: colors.actions_bg,
      alt_primary_bg: colors.revolver,
      alt_quaterniary_bg: colors.shutter_black,
      alt_secondary_bg: colors.jaguar_gray,
      alt_tertiary_bg: colors.shutter_black,
      alt_typography: colors.pale_grey,
      disabled_typography: colors.gray,
      faint_typography: colors.light_gray,
      light_typography: colors.misty_gray,
      light_typography_inverted: colors.revolver,
      inverted_primary_typography: colors.transparent_black,
      off_bg: colors.bastille,
      off_secondary_bg: colors.bastille,
      overlay_bg: colors.necron_compound,
      primary_accent: colors.limo_scene,
      primary_shadow: colors.light_soft_shadow,
      primary_bg: colors.gluon_grey,
      alternate_bg: colors.gluon_grey,
      primary_bg_faded: colors.pale_grey,
      primary_button_bg: colors.well_color,
      primary_button_accent: colors.gray,
      primary_chart_accent: colors.black_cat,
      secondary_chart_accent: colors.black_cat,
      primary_chart_bg: colors.shutter_black,
      primary_wedge_bg: colors.black_bay,
      primary_wedge_arc: colors.revolver,
      primary_hover_typography: colors.well_color,
      primary_scroll_bg: colors.castlerock,
      primary_typography: colors.pale_grey,
      quaterniary_bg: colors.revolver,
      quaterniary_chart_bg: colors.revolver,
      alt_accent: colors.battleship_grey,
      secondary_accent: colors.bottom_line_widget,
      secondary_bg: colors.shutter_black,
      secondary_chart_bg: colors.bastille,
      secondary_typography: colors.gray,
      separation_line: colors.soft_white,
      soft_accent: colors.soft_white,
      tertiary_accent: colors.black_bay,
      tertiary_bg: colors.shutter_black,
      tertiary_chart_bg: colors.bastille,
      score_card_bg: colors.bastille,
      inner_label: colors.neutral_white,
      label_pill: colors.labels_dark_transparent,
      row_positive_hover: colors.positive_dark_hover,
      positive_tag: colors.positive_green_dark,
      negative_tag: colors.negative_red_dark,
      tertiary_typography: colors.pale_grey,
      frozen_tundra: colors.frozen_tundra,
      torque_and_drag_legend: colors.frozen_tundra,
      primary_box_shadow: colors.weathervane,
      delimiter: colors.limo_scene,
      // Change those
      primary_tabs_bg: colors.bastille,
      secondary_tabs_bg: colors.bamboo_charcoal,
      personal_tab_bar_bg: colors.coated,
      operator_tab_bar_bg: colors.aswad_black,
      active_tab_bg: colors.darth_vader,
      white_gray: colors.white,
      inspection_view_border: colors.black_cat,
      phase_indicator: colors.limo_scene,
      future_plan_line: colors.golden_samovar,
      all_rigs_title: colors.lunar_rays,
    },
    legend: {
      selected_bg: colors.shutter_black,
      bg: colors.gluon_grey,
    },
    clara: {
      bg: colors.darth_vader,
      CTA_bg: colors.gluon_grey,
      icon_border: colors.white,
      icon_bg: colors.darth_vader + "4d",
      generating_bg: colors.black_water,
      sidebar_bg: colors.revolver,
      text_color: colors.lunar_rays,
    },
    notifications: {
      row_bg: colors.midnight_express,
      read_bg: colors.shutter_black,
      unread_bg: colors.revolver,
      hover_bg: colors.pale_grey + "3f", // added opacity;
    },
    tabs: {
      typography: colors.white,
      edit_button: colors.darth_vader,
    },
    intel: {
      typography: colors.gray,
      selector_bg: colors.gluon_grey,
      selected_button_bg: colors.limo_scene,
      grid_lines: colors.revolver,
      chart_bg: colors.dynamic_black,
      legend_selected_bg: colors.revolver,
      legend_hover_bg: colors.battleship_grey,
      buttons_bg: colors.revolver,
    },
    outliers: {
      bars: colors.default_bg,
      bars_transparent: colors.default_bg_transparent,
      bars_stacked_transparent: colors.outliers_dark_transparent,
    },
    wells: {
      phases: {
        "Pre-Drill": colors.bauhaus,
        "Surface Drill": colors.ravenwood,
        "Surface Flat": colors.volcanic_rock,
        "Intermediate Flat": colors.hulett_ore,
        "Intermediate Drill": colors.private_black,
        "Production Flat": colors.dry_mud,
        "Production Drill": colors.charadon_granite,
        Unknown: colors.tuatara,
      },
      directionalIntervals: {
        Vertical: colors.revolver,
        Build: colors.castlerock,
        Lateral: colors.bastille,
        Unknown: colors.revolver,
      },
    },
    digestTimeline: {
      knotSmall: {
        bg: colors.revolver,
        drop: colors.perfect_pear,
      },
      dateUpdates: {
        bg: colors.revolver,
      },
    },
  },
  [IThemeState.LIGHT]: {
    colors: {
      black_white: colors.black,
      white_black: colors.white,
      alt_primary_accent: colors.light_gray,
      alt_primary_bg: colors.bottom_line_widget,
      alt_quaterniary_bg: colors.neutral_white,
      alt_secondary_bg: colors.actions_bg,
      alt_tertiary_bg: colors.default_bg,
      alt_typography: colors.black,
      disabled_typography: colors.gray,
      faint_typography: colors.gray,
      light_typography: colors.gray,
      light_typography_inverted: colors.misty_gray,
      inverted_primary_typography: colors.white,
      off_bg: colors.off_white,
      off_secondary_bg: colors.white,
      overlay_bg: colors.necron_compound_light,
      separation_line: colors.separation_line,
      primary_shadow: colors.dark_soft_shadow,
      primary_accent: colors.widget_line,
      primary_bg: colors.default_bg,
      alternate_bg: colors.white,
      primary_bg_faded: colors.faded_bg,
      primary_button_bg: colors.well_color,
      primary_button_accent: colors.widget_line,
      primary_chart_accent: colors.separation_line,
      secondary_chart_accent: colors.actions_bg,
      primary_chart_bg: colors.actions_bg,
      primary_wedge_bg: colors.light_gray,
      primary_wedge_arc: colors.misty_gray,
      primary_hover_typography: colors.well_color,
      primary_scroll_bg: colors.off_state,
      primary_typography: colors.transparent_black,
      quaterniary_bg: colors.white,
      quaterniary_chart_bg: colors.neutral,
      alt_accent: colors.off_grey,
      secondary_accent: colors.actions_bg,
      secondary_bg: colors.actions_bg,
      secondary_chart_bg: colors.actions_bg,
      secondary_typography: colors.transparent_black,
      soft_accent: colors.separation_line,
      tertiary_accent: colors.hover_secondary,
      tertiary_bg: colors.white,
      tertiary_chart_bg: colors.neutral_white,
      score_card_bg: colors.white,
      tertiary_typography: colors.neutral,
      inner_label: colors.infinity_black,
      label_pill: colors.labels_light_transparent,
      row_positive_hover: colors.positive_light_hover,
      positive_tag: colors.positive_green_light,
      negative_tag: colors.negative_red_light,
      frozen_tundra: colors.frozen_tundra,
      torque_and_drag_legend: colors.necron_compound,
      primary_box_shadow: colors.tahoe_snow,
      delimiter: colors.pale_grey,
      primary_tabs_bg: colors.callisto,
      secondary_tabs_bg: colors.light_soft_celadon,
      personal_tab_bar_bg: colors.celestial_glow,
      operator_tab_bar_bg: colors.lilac_champagne,
      active_tab_bg: colors.actions_bg,
      white_gray: colors.gray,
      inspection_view_border: colors.transparent,
      phase_indicator: colors.white,
      future_plan_line: colors.yellow_sumac,
      all_rigs_title: colors.limo_scene,
    },
    legend: {
      selected_bg: colors.widget_line,
      bg: colors.actions_bg,
    },
    clara: {
      bg: colors.actions_bg,
      CTA_bg: colors.white,
      icon_border: colors.noble_black,
      icon_bg: colors.burnished_metal + "80",
      generating_bg: colors.dark_shamrock + "40",
      sidebar_bg: colors.white,
      text_color: colors.necron_compound,
    },
    notifications: {
      row_bg: colors.neutral_white,
      read_bg: colors.actions_bg,
      unread_bg: colors.white,
      hover_bg: colors.pale_grey,
    },
    tabs: {
      typography: colors.sheet_metal,
      edit_button: colors.bleached_silk,
    },
    intel: {
      typography: colors.lunar_rays,
      selector_bg: colors.widget_line,
      selected_button_bg: colors.white,
      grid_lines: colors.separation_line,
      chart_bg: colors.white,
      legend_selected_bg: colors.widget_line,
      legend_hover_bg: colors.widget_line,
      buttons_bg: colors.actions_bg,
    },
    outliers: {
      bars: colors.gluon_grey,
      bars_transparent: colors.gluon_grey_transparent,
      bars_stacked_transparent: colors.outlier_light_transparent,
    },
    wells: {
      phases: {
        "Pre-Drill": colors.lady_nicole,
        "Surface Drill": colors.laid_back_grey,
        "Surface Flat": colors.burnished_metal,
        "Intermediate Flat": colors.gazebo_grey,
        "Intermediate Drill": colors.rampart,
        "Production Flat": colors.subtle_touch,
        "Production Drill": colors.overdue_grey,
        Unknown: colors.crystal_brooke,
      },
      directionalIntervals: {
        Vertical: colors.spring_thaw,
        Build: colors.off_state,
        Lateral: colors.off_grey,
        Unknown: colors.bottom_line_widget,
      },
    },
    digestTimeline: {
      knotSmall: {
        bg: colors.white,
        drop: colors.getaway,
      },
      dateUpdates: {
        bg: colors.widget_line,
      },
    },
  },
};

export const CustomThemeProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const selectedTheme = useAppSelector((state) => state.theme);
  const theme: ITheme = {
    themeStyle: themes[selectedTheme],
    atomThemeVariant: selectedTheme === IThemeState.DARK ? "white" : "dark",
    isDark: selectedTheme === IThemeState.DARK,
  };

  return (
    <ThemeProvider theme={theme}>
      <div data-theme={selectedTheme}>{children}</div>
    </ThemeProvider>
  );
};
