import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Bar, LinePath } from "@visx/shape";
import { DimensionType } from "apis/oag";
import { Title } from "atoms/Typography";
import {
  IndicatorBar,
  maxIndicatorWidth,
} from "components/Lenses/common/IndicatorBar";
import { RangesComponent } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/Ranges";
import {
  returnZeroIfInvalid,
  RoadmapColoredSegment,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/RoadmapColoredSegment";
import {
  ChartContainer,
  IconContainer,
  InfoContainer,
  InnerDiv,
  KPIText,
  ParameterAvgKpiVal,
  ParameterComparisonGrid,
  ParameterComparisonGridItem,
  ParameterLeftAxis,
  ParameterName,
  UOMText,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/style";
import {
  axisMargins,
  COMPLIANCE_BAR_HEIGHT,
  TRACK_SIZE_HEIGHT_INCREMENT_PX,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/utils";
import { useTrackRanges } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackRanges";
import { TrackAxis } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/TrackAxis";
import type { ParameterComparisonItemKPIProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { curveMonotoneX } from "d3";
import { extent, max } from "d3-array";
import { useTracks } from "hooks/drillingInvariants/useTracks";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import React, { useCallback, useEffect, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { Space, Typography } from "utils/componentLibrary";
import {
  adjustDecimalPlaces,
  getDecimalsInterval,
  useLockableUOM,
} from "utils/format";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";

const { Text } = Typography;

function _ParameterByDepthItem({
  chartWidth,
  depthScale,
  detailed,
  indicators,
  isTopMost,
  isUnitUnlocked,
  maxChartHeight,
  onPointerPositionChange,
  onTrackSettings,
  selectedUnit,
  showGraph,
  trackCount,
  tracks,
  yMax,
  forcedUnitSystem,
  yMin,
  isRoadmap,
  svgRef,
  chartHeightPassThrough,
}: ParameterComparisonItemKPIProps & {
  svgRef: React.RefObject<SVGSVGElement>;
  chartHeightPassThrough: (height: number) => void;
}) {
  const { height: chartHeightHook, ref: chartRef } = useResizeDetector();
  const { chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
  };

  useEffect(() => {
    chartHeightPassThrough(chartHeight);
  }, [chartHeight, chartHeightPassThrough]);

  const { atomThemeVariant } = useCustomTheme();

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const selectedWell = useAppSelector((state) => state.state.selectedWell);
  const { getColor } = useColors();
  const { data: trackData } = useTracks();
  const focalTrack = tracks?.find((track) => track.isFocalWell);
  const trackInfo = trackData
    ? trackData.byId[
        focalTrack?.trackId || (tracks?.length > 0 && tracks[0]?.trackId) || 0
      ]
    : undefined;

  const trackUOM = useLockableUOM({
    dimension: trackInfo?.dimension || DimensionType.Undefined,
    lockUnitSystemTo: forcedUnitSystem,
  });

  const margin = useMemo(
    () =>
      detailed && chartHeight > 165
        ? { top: 24, bottom: 24, left: 0, right: 0 }
        : { top: 12, bottom: 12, left: 0, right: 0 },
    [chartHeight, detailed],
  );

  const averageSIByWell = useMemo(
    () =>
      tracks.reduce<Record<number, number>>((acc, track) => {
        acc[track.wellId] = track.average ?? 0;
        return acc;
      }, {}),
    [tracks],
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `${focalTrack?.trackId || -1}`,
    disabled: !tracks,
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition ?? "",
  };

  const valueScale = useMemo(() => {
    const maxRange = chartHeight - margin.top - margin.bottom;

    let values = tracks.flatMap(
      (track) => track.trackValues?.map((item) => item.value ?? 0) ?? [],
    );

    if (isRoadmap) {
      values = values.concat(
        tracks.flatMap(
          (track) =>
            track.boundaries?.flatMap((boundary) => [
              boundary.max ?? 0,
              boundary.min ?? 0,
            ]) ?? 0,
        ),
      );
    }

    const minValue = yMin ?? 0;
    const maxValue = yMax ?? max(values) ?? 0;

    return scaleLinear<number>({
      range: [maxRange, 0],
      domain: [minValue, maxValue],
    });
  }, [chartHeight, isRoadmap, margin.bottom, margin.top, tracks, yMax, yMin]);

  const displayScaleDomain = [
    parseFloat(selectedUnit?.toString(valueScale.domain()[1])),
    parseFloat(selectedUnit?.toString(valueScale.domain()[0])),
  ];

  const comparisonBarScale = useMemo(() => {
    const [min = 0, max = 0] = extent(Object.values(averageSIByWell).concat(0));
    return scaleLinear<number>({
      range: [0, detailed ? 170 : 72],
      domain: [min, max],
    });
  }, [averageSIByWell, detailed]);

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      const { left, top } = event.currentTarget.getBoundingClientRect();
      const x = ("clientX" in event ? event.clientX : 0) - left;
      const y = ("clientY" in event ? event.clientY : 0) - top;
      onPointerPositionChange?.({ x, y });
    },
    [onPointerPositionChange],
  );

  const tracksWithRanges = useTrackRanges(tracks);
  const [offsetSelection] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );

  const sortedTracks = useMemo(
    () =>
      tracksWithRanges.slice().sort((a, b) => {
        return (
          offsetSelection.indexOf(a.wellId) - offsetSelection.indexOf(b.wellId)
        );
      }),
    [offsetSelection, tracksWithRanges],
  );
  const reversedTracks = useMemo(
    () => sortedTracks.slice().reverse(),
    [sortedTracks],
  );

  const {
    lines: roadmapLines,
    maxBoundary,
    minBoundary,
  } = useMemo(() => {
    const boundaries = tracks[0]?.boundaries || [];
    const lines = [];

    const lastBoundary = [...boundaries]
      .reverse()
      .find((b) => Number.isFinite(b.min) || Number.isFinite(b.max));

    for (let i = 0; i < boundaries.length; i++) {
      const boundary = boundaries[i];

      const startValue = i === 0 ? 0 : boundaries[i - 1].measuredDepth ?? 0;
      const x = depthScale(startValue);
      const width = depthScale(boundary.measuredDepth ?? 0) - x;

      if (width <= 0) {
        continue;
      }

      if (Number.isFinite(boundary.max)) {
        // Line
        lines.push(
          <Bar
            key={i + "_max"}
            x={returnZeroIfInvalid(x)}
            width={returnZeroIfInvalid(width)}
            y={returnZeroIfInvalid(valueScale(boundary.max ?? 0))}
            height={2}
            fill={colors.off_state}
          />,
        );
      }

      if (Number.isFinite(boundary.min)) {
        lines.push(
          <Bar
            key={i + "_min"}
            x={returnZeroIfInvalid(x)}
            width={returnZeroIfInvalid(width)}
            y={returnZeroIfInvalid(valueScale(boundary.min ?? 0))}
            height={2}
            fill={colors.off_state}
          />,
        );
      }
    }

    return {
      lines,
      maxBoundary: lastBoundary?.max,
      minBoundary: lastBoundary?.min,
    };
  }, [depthScale, tracks, valueScale]);

  const chartHeightAccountingForTooltipExpand = useMemo(() => {
    const initialSize = maxChartHeight || chartHeight;
    if (sortedTracks.length <= 1) {
      return initialSize;
    } else {
      return initialSize + sortedTracks.length * TRACK_SIZE_HEIGHT_INCREMENT_PX;
    }
  }, [chartHeight, maxChartHeight, sortedTracks.length]);

  if (!selectedUnit) {
    return null;
  }

  return (
    <div ref={setNodeRef} style={{ ...style, height: "100%" }}>
      <InnerDiv
        detailed={detailed}
        showGraph={showGraph}
        ref={chartRef}
        style={{
          height: detailed ? "100%" : chartHeightAccountingForTooltipExpand,
        }}
      >
        <ParameterName
          parameterNameWidth={!showGraph ? "100%" : undefined}
          detailed={detailed ? trackCount > 1 : undefined}
        >
          <IconContainer
            {...attributes}
            {...listeners}
            cursor={isDragging ? "grabbing" : "grab"}
          >
            <PDComponent.SvgIcon name="move" />
          </IconContainer>
          <InfoContainer>
            <KPIText>{trackInfo?.name}</KPIText>
            {detailed ? <UOMText>{selectedUnit.abv}</UOMText> : null}
            {detailed && !isUnitUnlocked ? (
              <PDComponent.SvgIcon
                name="locked"
                style={{
                  fontSize: "16px",
                  color: themeColors.primary_typography,
                }}
              />
            ) : null}
          </InfoContainer>
          <IconContainer>
            <PDComponent.SvgIcon name="settings" onClick={onTrackSettings} />
          </IconContainer>
        </ParameterName>

        <ParameterAvgKpiVal
          parameterNameWidth={!showGraph ? "100%" : undefined}
          detailed={detailed}
        >
          {sortedTracks.filter((t) => !t.isFocalWell).length === 0 ? (
            <Space
              direction="vertical"
              style={{ marginLeft: detailed ? "40px" : "24px" }}
            >
              {selectedWell && averageSIByWell[selectedWell] ? (
                <Text style={{ color: themeColors.primary_typography }}>
                  {adjustDecimalPlaces(
                    selectedUnit?.toString(averageSIByWell[selectedWell]),
                    getDecimalsInterval(displayScaleDomain),
                  ) || ""}{" "}
                  {detailed ? (
                    "avg"
                  ) : (
                    <span style={{ fontSize: "16px" }}>
                      {selectedUnit?.abv || ""} avg{" "}
                      {!isUnitUnlocked && (
                        <PDComponent.SvgIcon
                          name="locked"
                          style={{
                            fontSize: "16px",
                            color: themeColors.primary_typography,
                          }}
                        />
                      )}
                    </span>
                  )}
                </Text>
              ) : null}
              {isRoadmap ? (
                <Title level={3} variant={atomThemeVariant}>
                  {minBoundary && Number.isFinite(minBoundary) && selectedUnit
                    ? `${selectedUnit.toString(minBoundary)} ${selectedUnit.abv}`
                    : "N/A"}{" "}
                  -{" "}
                  {maxBoundary && Number.isFinite(maxBoundary) && selectedUnit
                    ? `${selectedUnit.toString(maxBoundary)} ${selectedUnit.abv}`
                    : "N/A"}
                </Title>
              ) : null}
            </Space>
          ) : (
            <ParameterComparisonGrid rowCount={sortedTracks.length}>
              {sortedTracks.map((track) => {
                const barWidth = comparisonBarScale(
                  averageSIByWell[track.wellId],
                );
                return (
                  <ParameterComparisonGridItem
                    key={`${track.trackId}-${track.wellId}`}
                    isFocalWell={track.isFocalWell}
                  >
                    <div
                      // eslint-disable-next-line react/forbid-dom-props
                      style={{
                        width: barWidth + "px",
                        // TODO the assumption if just well id 0 is going to be aggregated is a bit inelegant
                        backgroundColor:
                          track.wellId === 0
                            ? colors.off_state
                            : getColor({ key: track.wellId.toString() }),
                      }}
                    />
                    <div>
                      {adjustDecimalPlaces(
                        selectedUnit?.toString(averageSIByWell[track.wellId]),
                        getDecimalsInterval(displayScaleDomain),
                      ) || "No data available"}{" "}
                      {detailed ? "" : `${selectedUnit?.abv} avg`}
                    </div>

                    {!isUnitUnlocked && track.isFocalWell ? (
                      <PDComponent.SvgIcon name="locked" />
                    ) : null}
                  </ParameterComparisonGridItem>
                );
              })}
            </ParameterComparisonGrid>
          )}
        </ParameterAvgKpiVal>
        {showGraph ? (
          <ParameterLeftAxis>
            <svg>
              <rect
                width={"100%"}
                height={"100%"}
                x={0}
                y={0}
                fill={themeColors.quaterniary_bg}
              />
              {trackInfo ? (
                <TrackAxis
                  chartHeight={chartHeight}
                  scale={valueScale}
                  top={margin.top}
                  left={axisMargins.horizontal + 2}
                  trackUomHelper={trackUOM}
                  isManual={yMax !== undefined || yMin !== undefined}
                />
              ) : null}
            </svg>
          </ParameterLeftAxis>
        ) : null}
        {showGraph ? (
          <ChartContainer>
            <svg
              width={"100%"}
              height={"100%"}
              onPointerMove={handlePointerMove}
              ref={svgRef}
            >
              <Group top={margin.top}>
                {reversedTracks.map((track) => {
                  return (
                    <React.Fragment
                      key={`${track.trackId}-${track.wellId}-parameter-depth-item`}
                    >
                      <LinePath
                        curve={curveMonotoneX}
                        key={`${track.wellId}-${track.trackId}`}
                        data={track.trackValues ?? []}
                        defined={(d) =>
                          d &&
                          d.holeDepth >= depthScale.domain()[0] - 5 &&
                          d.holeDepth <= depthScale.domain()[1] + 5
                        }
                        x={(d) => {
                          if (Number.isNaN(depthScale(d.holeDepth))) {
                            return 0;
                          }
                          return depthScale(d.holeDepth);
                        }}
                        y={(d) =>
                          Number.isNaN(valueScale(d.value ?? 0))
                            ? 0
                            : valueScale(d.value ?? 0)
                        }
                        stroke={
                          track.wellId === 0
                            ? colors.off_state
                            : getColor({ key: track.wellId.toString() })
                        }
                        strokeOpacity={
                          track.isFocalWell || !focalTrack ? 1 : 0.6
                        }
                        strokeWidth={track.isFocalWell || !focalTrack ? 2 : 1.2}
                      />
                      {isRoadmap
                        ? track.ranges.map((trackRange, rangeIdx) => (
                            <RoadmapColoredSegment
                              key={`${track.trackId}-${rangeIdx}`}
                              track={track}
                              rangeIdx={rangeIdx}
                              trackRange={trackRange}
                              depthScale={depthScale}
                              valueScale={valueScale}
                              chartWidth={chartWidth}
                              chartHeight={chartHeight}
                            />
                          ))
                        : null}
                    </React.Fragment>
                  );
                })}
              </Group>

              <Group>
                {detailed && indicators
                  ? Object.keys(indicators).map((value) => {
                      const x = depthScale(+value);
                      const [minX, maxX] = depthScale.range();

                      let alignment: "left" | "right" | "center" = "center";

                      if (x - minX < maxIndicatorWidth / 2) {
                        alignment = "left";
                      } else if (maxX - x < maxIndicatorWidth / 2) {
                        alignment = "right";
                      }

                      return (
                        <IndicatorBar
                          key={value}
                          x={x}
                          height={(maxChartHeight || chartHeight) ?? 0}
                          labels={indicators[value].map((item) => item.label)}
                          showLabels={isTopMost}
                          alignment={alignment}
                        />
                      );
                    })
                  : null}
              </Group>

              {isRoadmap ? (
                <Group top={margin.top}>{roadmapLines}</Group>
              ) : null}

              {isRoadmap ? (
                <Group>
                  {returnZeroIfInvalid(chartWidth ?? 0) > 0 ? (
                    <Bar
                      y={returnZeroIfInvalid(
                        chartHeight - COMPLIANCE_BAR_HEIGHT,
                      )}
                      height={COMPLIANCE_BAR_HEIGHT}
                      x={0}
                      width={returnZeroIfInvalid(chartWidth ?? 0)}
                      fill={colors.well_color}
                      fillOpacity={0.4}
                    />
                  ) : null}
                  {
                    <RangesComponent
                      tracksWithRanges={tracksWithRanges}
                      chartHeight={chartHeight}
                      depthScale={depthScale}
                    />
                  }
                </Group>
              ) : null}
            </svg>
          </ChartContainer>
        ) : null}
      </InnerDiv>
    </div>
  );
}

const ParameterByDepthItem = React.memo(_ParameterByDepthItem);
export { ParameterByDepthItem };
