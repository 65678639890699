import type { PlanDrillingParameterDto, PlanDto } from "apis/oag";
import { DimensionType, WellsApi } from "apis/oag";
import { Button } from "atoms/Form";
import { ActionsHeader, PlanSectionLayout } from "components/Layout/Tabbed";
import { PDComponent } from "components/PDComponents";
import { DrillingParameterRow } from "components/WellPlan/DrillingParameters/DrillingParameterRow";
import * as Styled from "components/WellPlan/DrillingParameters/style";
import { useCopyFromModal } from "components/WellPlan/WellPlanCommun/CopyFromModal";
import React, { useEffect } from "react";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { Row } from "utils/componentLibrary";
import { useUOM } from "utils/format";
import { PDConstants } from "utils/PDConstants/PDConstants";
import type { IErrorPlanEditor } from "utils/wellplan/errors";
import {
  flowData,
  pressureData,
  rotationData,
  speedData,
  torqueData,
  wobData,
} from "utils/wellplan/utils";

interface OverviewProps {
  wellId: number;
  plan?: PlanDto;
  setPlan: React.Dispatch<React.SetStateAction<PlanDto | undefined>>;
  setPlanModified: React.Dispatch<React.SetStateAction<boolean>>;
  selection?: SelectedUnits;
  setSelection?: React.Dispatch<SelectedUnits>;
  errors: IErrorPlanEditor["drillingParameters"];
}

export type SelectedUnits = {
  wob: keyof typeof wobData;
  rpm: keyof typeof rotationData;
  rop: keyof typeof speedData;
  spp: keyof typeof pressureData;
  diffPressure: keyof typeof pressureData;
  torque: keyof typeof torqueData;
  flowIn: keyof typeof flowData;
};

const columnList: Array<{
  key: keyof SelectedUnits;
  data: {
    [key: string]: {
      value?: string;
      abv?: string;
      unitSystem?: IUnitSystem;
      toSI?: (key: string) => number;
      toString?: (key: number) => string;
    };
  };
  title: string;
}> = [
  { key: "wob", data: wobData, title: "WOB" },
  { key: "rpm", data: rotationData, title: "RPM" },
  { key: "rop", data: speedData, title: "ROP" },
  { key: "diffPressure", data: pressureData, title: "Diff-Pressure" },
  { key: "torque", data: torqueData, title: "Torque" },
  { key: "flowIn", data: flowData, title: "Flow In" },
];

export const drillingParameterFields: Array<
  keyof Partial<PlanDrillingParameterDto>
> = [
  "measuredDepth",
  "wobMin",
  "wobMax",
  "revolutionPerSecondMin",
  "revolutionPerSecondMax",
  "ropMin",
  "ropMax",
  "diffPressureMin",
  "diffPressureMax",
  "torqueMin",
  "torqueMax",
  "flowInMin",
  "flowInMax",
];

export function DrillingParameter({
  selection,
  setSelection,
  wellId,
  plan,
  setPlan,
  setPlanModified,
  errors,
}: Readonly<OverviewProps>) {
  const wellsApi = new WellsApi(apiConfig);

  const depthData = useUOM(DimensionType.Metres);
  useEffect(() => {
    if (depthData.currentUOM === IUnitSystem.METRIC)
      setSelection?.({
        diffPressure: "kpa",
        flowIn: "m3min",
        rop: "mhr",
        rpm: "rpm",
        spp: "kpa",
        torque: "nm",
        wob: "kdan",
      });
    else
      setSelection?.({
        diffPressure: "psi",
        flowIn: "gpm",
        rop: "fthr",
        rpm: "rpm",
        spp: "kpa",
        torque: "ftlb",
        wob: "klbs",
      });
  }, [depthData, setSelection]);
  const { node: copyFromModalElement, open: openCopyFrom } = useCopyFromModal({
    wellId,
    onCopy: (selectedWellId) => {
      wellsApi
        .apiWellsIdPlanDrillingParametersGet({ id: selectedWellId })
        .then((set) => {
          set.drillingParameters.forEach((p, i) => (p.id = -(i + 1))); // BE will interpret it as a proper copy
          setPlan((prevPlan) => {
            return prevPlan
              ? {
                  ...prevPlan,
                  startParameterDepth: set.startDepth,
                  drillingParameters: set.drillingParameters,
                }
              : undefined;
          });
          setPlanModified(true);
        });
    },
    type: "drillingParameters",
  });

  if (!plan) {
    return null;
  }

  return (
    <>
      <PlanSectionLayout
        header={
          <ActionsHeader>
            <Row
              gutter={12}
              justify="end"
              align="middle"
              style={{
                height: PDConstants.SizesPx.Layout.TopBarHeight.asPixels(),
              }}
            >
              <Button
                trackingName="Copy Modal Open"
                size="large"
                onClick={openCopyFrom}
                icon={<PDComponent.SvgIcon name="migrate" />}
              >
                Copy From
              </Button>
            </Row>
          </ActionsHeader>
        }
        content={
          <Styled.FormationCard>
            <Styled.HeaderGrid>
              <div>Measured Depth</div>
              {columnList.map((column) => {
                const keys = Object.keys(column.data);
                return (
                  <Styled.KpiWithDropdown key={column.key}>
                    <span> {column.title} </span>
                    <PDComponent.Dropdown
                      variant="text"
                      placement="bottom"
                      selectedItemStyle={{
                        marginLeft: 15,
                        fontSize: 16,
                      }}
                      selectedOption={selection?.[column.key]}
                      isLocked={keys.length < 2}
                      handleOptionUpdate={(idStr) =>
                        setSelection?.({
                          ...selection,
                          [column.key]: idStr as keyof SelectedUnits,
                        } as SelectedUnits)
                      }
                      options={keys.map((k) => ({
                        id: k,
                        label: "" + column.data[k].abv,
                        value: k,
                      }))}
                      trackingText="Leaderboard Cards - Update Pivot"
                      alwaysShowChevron
                    />
                  </Styled.KpiWithDropdown>
                );
              })}
            </Styled.HeaderGrid>
            <DrillingParameterRow
              errors={errors}
              drillingParameter={{ id: -1, measuredDepth: 0 }}
              plan={plan}
              setPlan={setPlan}
              isFirst
              setPlanModified={setPlanModified}
            />
            {plan?.drillingParameters.map((drillingParameter) => (
              <DrillingParameterRow
                errors={errors}
                key={drillingParameter.id}
                drillingParameter={drillingParameter}
                plan={plan}
                setPlan={setPlan}
                setPlanModified={setPlanModified}
                selectedUnits={selection}
              />
            ))}
            <Button
              size="large"
              trackingName="Add Drilling Parameter"
              style={{
                width: "calc(100% - 40px)",
                margin: "0px 20px",
                color: colors.well_color,
              }}
              onClick={() => {
                Track.interact("Drilling Parameter - Add Record");
                setPlan((prevPlan) => {
                  const minNegativeId = prevPlan?.drillingParameters
                    .map((formation) => formation.id)
                    .filter((id) => id < 0)
                    .reduce((a: number, b: number) => Math.min(a, b), 0);

                  const newDrillingParameter: PlanDrillingParameterDto = {
                    measuredDepth: 0,
                    id: (minNegativeId || 0) - 1,
                  };

                  return prevPlan
                    ? {
                        ...prevPlan,
                        drillingParameters: [
                          ...prevPlan.drillingParameters,
                          newDrillingParameter,
                        ],
                      }
                    : undefined;
                });
              }}
            >
              <PDComponent.SvgIcon name="plusOutline" />
              Add record
            </Button>
          </Styled.FormationCard>
        }
      />
      {copyFromModalElement}
    </>
  );
}
