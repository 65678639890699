import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Radio, Switch } from "utils/componentLibrary";
import {
  SIDEBAR_DEFAULT_WIDTH_PERCENT,
  SIDEBAR_MAX_WIDTH,
  SIDEBAR_MIN_WIDTH,
} from "utils/constants";
import { PDConstants } from "utils/PDConstants/PDConstants";

export const GridLayout = styled.div`
  height: calc(100% - ${PDConstants.SizesPx.Layout.TopBarHeight.asPixels()});
  width: 100%;
  display: grid;
  grid-template-columns:
    clamp(
      ${SIDEBAR_MIN_WIDTH}px,
      ${SIDEBAR_DEFAULT_WIDTH_PERCENT}%,
      ${SIDEBAR_MAX_WIDTH}px
    )
    1fr;
  gap: 16px;
  padding: 16px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const StyledCard = styled.div`
  /* Default Grey */
  background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  /* Box Shadow */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: relative;
`;

export const RadioButtonGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    .ant-radio-button.ant-radio-button-checked {
      background-color: transparent;
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${({ theme }) =>
        theme.themeStyle.colors.primary_button_bg};
    }
  }
  .ant-radio-button-wrapper {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    :hover {
      color: ${({ theme }) => theme.themeStyle.colors.primary_button_bg};
    }
  }

  .ant-radio-button-wrapper-checked {
    color: ${colors.white};
    :not(.ant-radio-button-wrapper-disabled):hover {
      color: ${colors.white};
    }
  }
`;

export const StyledDropdownButton = styled.div`
  width: 36px;
  height: 33.5px;
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  border-left: solid 1px
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  border-radius: 0 4px 4px 0;

  i {
    ${({ theme }) =>
      theme.isDark
        ? css`
            border-color: ${colors.white};
          `
        : null};
  }
`;

export const Arrow = styled.i<{ up?: boolean }>`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(${(props) => (props.up ? -135 : 45)}deg);
  -webkit-transform: rotate(${(props) => (props.up ? -135 : 45)}deg);
`;

export const CustomSwitch = styled(Switch)<{ $isChecked?: boolean }>`
  width: 44px !important;
  height: 24px !important;
  border-radius: 4px !important;
  padding: 2px 2px 2px 22px !important;
  border-radius: 4px !important;
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};

  .ant-switch-handle:before {
    left: ${(props) =>
      props.$isChecked ? "calc(100% - 18px)" : "0px"}!important;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  }
`;

export enum Cursor {
  Default = "default",
  Pointer = "pointer",
  NotAllowed = "not-allowed",
}

export type TagVariants = "filled" | "faded" | "default" | "borderless";

export const StyledTag = styled.div<{
  $variant: TagVariants;
  $color: string;
  $isLarge?: boolean;
  $cursor?: Cursor;
}>`
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 99px;
  font-size: 14px;
  gap: 5px;
  color: ${({ theme }) => theme.themeStyle.colors.white_black};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background: transparent;
  white-space: nowrap;
  cursor: ${({ $cursor }) => $cursor || "default"};
  svg {
    width: 14px;
    height: 14px;
  }
  ${({ $color, $variant, theme }) => {
    switch ($variant) {
      case "filled":
        return `
          background: ${$color};
          border-color: ${$color};
        `;
      case "faded":
        return `
          color: ${theme.themeStyle.colors.primary_scroll_bg};
        `;
      case "borderless":
        return `
          color: ${$color};
          background: transparent;
          border-color: transparent;
        `;
      case "default":
        return `
          background:  ${theme.themeStyle.colors.tertiary_bg};
          color: ${$color};
        `;
    }
  }}

  ${({ $isLarge }) =>
    $isLarge
      ? css`
          width: 120px;
          height: 31px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 9px;
          font-size: 20px;
          line-height: 23px;

          svg {
            width: 16px;
            height: 16px;
          }
        `
      : null}
`;
