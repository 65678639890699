import styled from "styled-components";

export const PreviewerNotAvailableContainer = styled.div`
  position: absolute;
  width: 100%;
`;

export const PreviewerNotAvailableText = styled.div`
  text-align: center;
  padding: 0 5em;
  left: auto;
  right: auto;
  font-size: 1em;
  position: absolute;
  font-weight: 700;

  span {
    font-style: italic;
    font-size: 0.8em;
    font-weight: 500;
  }
`;
