/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigestEntryVoteType } from './DigestEntryVoteType';
import {
    DigestEntryVoteTypeFromJSON,
    DigestEntryVoteTypeFromJSONTyped,
    DigestEntryVoteTypeToJSON,
} from './DigestEntryVoteType';
import type { DigestEntryType } from './DigestEntryType';
import {
    DigestEntryTypeFromJSON,
    DigestEntryTypeFromJSONTyped,
    DigestEntryTypeToJSON,
} from './DigestEntryType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import type { DigestEntryAttachmentDto } from './DigestEntryAttachmentDto';
import {
    DigestEntryAttachmentDtoFromJSON,
    DigestEntryAttachmentDtoFromJSONTyped,
    DigestEntryAttachmentDtoToJSON,
} from './DigestEntryAttachmentDto';

/**
 * 
 * @export
 * @interface DigestEntryUnplannedEventDto
 */
export interface DigestEntryUnplannedEventDto {
    /**
     * 
     * @type {number}
     * @memberof DigestEntryUnplannedEventDto
     */
    id: number;
    /**
     * 
     * @type {DateDto}
     * @memberof DigestEntryUnplannedEventDto
     */
    createdAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryUnplannedEventDto
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof DigestEntryUnplannedEventDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof DigestEntryUnplannedEventDto
     */
    isApproved: boolean;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryUnplannedEventDto
     */
    chapterId: number;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryUnplannedEventDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof DigestEntryUnplannedEventDto
     */
    authorDisplayName: string;
    /**
     * 
     * @type {Array<DigestEntryAttachmentDto>}
     * @memberof DigestEntryUnplannedEventDto
     */
    attachments: Array<DigestEntryAttachmentDto>;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryUnplannedEventDto
     */
    attachmentCount: number;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryUnplannedEventDto
     */
    likeCount: number;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryUnplannedEventDto
     */
    dislikeCount: number;
    /**
     * 
     * @type {DigestEntryVoteType}
     * @memberof DigestEntryUnplannedEventDto
     */
    currentUserVoteType: DigestEntryVoteType;
    /**
     * 
     * @type {DateDto}
     * @memberof DigestEntryUnplannedEventDto
     */
    startAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DigestEntryUnplannedEventDto
     */
    endAt?: DateDto | null;
    /**
     * 
     * @type {DigestEntryType}
     * @memberof DigestEntryUnplannedEventDto
     */
    readonly digestEntryType: DigestEntryType;
    /**
     * 
     * @type {DateDto}
     * @memberof DigestEntryUnplannedEventDto
     */
    previousStartAt?: DateDto | null;
    /**
     * 
     * @type {DateDto}
     * @memberof DigestEntryUnplannedEventDto
     */
    previousEndAt?: DateDto | null;
}



/**
 * Check if a given object implements the DigestEntryUnplannedEventDto interface.
 */
export function instanceOfDigestEntryUnplannedEventDto(value: object): value is DigestEntryUnplannedEventDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('priority' in value) || value['priority'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('isApproved' in value) || value['isApproved'] === undefined) return false;
    if (!('chapterId' in value) || value['chapterId'] === undefined) return false;
    if (!('authorId' in value) || value['authorId'] === undefined) return false;
    if (!('authorDisplayName' in value) || value['authorDisplayName'] === undefined) return false;
    if (!('attachments' in value) || value['attachments'] === undefined) return false;
    if (!('attachmentCount' in value) || value['attachmentCount'] === undefined) return false;
    if (!('likeCount' in value) || value['likeCount'] === undefined) return false;
    if (!('dislikeCount' in value) || value['dislikeCount'] === undefined) return false;
    if (!('currentUserVoteType' in value) || value['currentUserVoteType'] === undefined) return false;
    if (!('startAt' in value) || value['startAt'] === undefined) return false;
    if (!('digestEntryType' in value) || value['digestEntryType'] === undefined) return false;
    return true;
}

export function DigestEntryUnplannedEventDtoFromJSON(json: any): DigestEntryUnplannedEventDto {
    return DigestEntryUnplannedEventDtoFromJSONTyped(json, false);
}

export function DigestEntryUnplannedEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigestEntryUnplannedEventDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'createdAt': DateDtoFromJSON(json['CreatedAt']),
        'priority': json['Priority'],
        'description': json['Description'],
        'isApproved': json['IsApproved'],
        'chapterId': json['ChapterId'],
        'authorId': json['AuthorId'],
        'authorDisplayName': json['AuthorDisplayName'],
        'attachments': ((json['Attachments'] as Array<any>).map(DigestEntryAttachmentDtoFromJSON)),
        'attachmentCount': json['AttachmentCount'],
        'likeCount': json['LikeCount'],
        'dislikeCount': json['DislikeCount'],
        'currentUserVoteType': DigestEntryVoteTypeFromJSON(json['CurrentUserVoteType']),
        'startAt': DateDtoFromJSON(json['StartAt']),
        'endAt': json['EndAt'] == null ? undefined : DateDtoFromJSON(json['EndAt']),
        'digestEntryType': DigestEntryTypeFromJSON(json['DigestEntryType']),
        'previousStartAt': json['PreviousStartAt'] == null ? undefined : DateDtoFromJSON(json['PreviousStartAt']),
        'previousEndAt': json['PreviousEndAt'] == null ? undefined : DateDtoFromJSON(json['PreviousEndAt']),
    };
}

export function DigestEntryUnplannedEventDtoToJSON(value?: Omit<DigestEntryUnplannedEventDto, 'DigestEntryType'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'CreatedAt': DateDtoToJSON(value['createdAt']),
        'Priority': value['priority'],
        'Description': value['description'],
        'IsApproved': value['isApproved'],
        'ChapterId': value['chapterId'],
        'AuthorId': value['authorId'],
        'AuthorDisplayName': value['authorDisplayName'],
        'Attachments': ((value['attachments'] as Array<any>).map(DigestEntryAttachmentDtoToJSON)),
        'AttachmentCount': value['attachmentCount'],
        'LikeCount': value['likeCount'],
        'DislikeCount': value['dislikeCount'],
        'CurrentUserVoteType': DigestEntryVoteTypeToJSON(value['currentUserVoteType']),
        'StartAt': DateDtoToJSON(value['startAt']),
        'EndAt': DateDtoToJSON(value['endAt']),
        'PreviousStartAt': DateDtoToJSON(value['previousStartAt']),
        'PreviousEndAt': DateDtoToJSON(value['previousEndAt']),
    };
}

