import { useTruncatedText } from "hooks/timeline/useTruncatedText";
import { useRef } from "react";

import * as Styled from "./style";

export const KnotDescription = ({ description }: { description: string }) => {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const { isTruncated, isShowingMore, toggleIsShowingMore } = useTruncatedText({
    ref: descriptionRef,
  });

  return (
    <>
      <Styled.DescriptionCard
        ref={descriptionRef}
        $isShowingMore={isShowingMore}
      >
        {description}
      </Styled.DescriptionCard>

      {isTruncated ? (
        <Styled.ShowMoreOrLess onClick={toggleIsShowingMore}>
          {isShowingMore ? "View Less" : "View More"}
        </Styled.ShowMoreOrLess>
      ) : null}
    </>
  );
};
