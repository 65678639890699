import { PDComponent } from "components/PDComponents";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useMemo } from "react";
import { durationToText } from "utils/helper";

import * as Styled from "./style";

dayjs.extend(duration);

export const StartKnot = ({ durationSeconds }: { durationSeconds: number }) => {
  const displayedDuration = useMemo(
    () => durationToText(durationSeconds),
    [durationSeconds],
  );

  return (
    <Styled.MemoContainer>
      <Styled.Knot>
        <PDComponent.SvgIcon name="section" />
      </Styled.Knot>
      <Styled.KnotPanel>
        <Styled.Title>Start Of Well</Styled.Title>
        <Styled.UnplannedTimeSubtitle>
          Total Unplanned Duration: <b>{displayedDuration}</b>
        </Styled.UnplannedTimeSubtitle>
      </Styled.KnotPanel>
    </Styled.MemoContainer>
  );
};
