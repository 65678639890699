import type { DigestEntryUnplannedEventDto } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import dayjs from "dayjs";
import { useMemo } from "react";

import * as Styled from "./style";

export const DateUpdates = ({
  entry,
}: {
  entry: DigestEntryUnplannedEventDto;
}) => {
  const dateUpdates = useMemo(() => {
    const updates = [];
    if (
      entry.previousStartAt?.utc &&
      !dayjs(entry.previousStartAt?.utc).isSame(dayjs(entry.startAt?.utc))
    ) {
      updates.push({
        label: "Start Updated",
        from: entry.previousStartAt
          ? dayjs(entry.previousStartAt.utc).format("MMM DD, HH:mm")
          : null,
        to: dayjs(entry.startAt?.utc).format("MMM DD, HH:mm"),
      });
    }
    if (!dayjs(entry.previousEndAt?.utc).isSame(dayjs(entry.endAt?.utc))) {
      updates.push({
        label: "End Updated",
        from: entry.previousEndAt
          ? dayjs(entry.previousEndAt.utc).format("MMM DD, HH:mm")
          : null,
        to: dayjs(entry.endAt?.utc).format("MMM DD, HH:mm"),
      });
    }
    return updates;
  }, [entry]);

  return (
    <>
      {dateUpdates.map((dateChange) => (
        <Styled.DateChangeLogRow key={dateChange.label}>
          <div>{dateChange.label}:</div>
          <Styled.DateTransitionContainer>
            <Styled.FromDate>{dateChange.from ?? "Empty"}</Styled.FromDate>
            <PDComponent.SvgIcon name="spearRight" height={14} width={14} />
            <div>{dateChange.to ?? "Empty"}</div>
          </Styled.DateTransitionContainer>
        </Styled.DateChangeLogRow>
      ))}
    </>
  );
};
