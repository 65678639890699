import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import type { ReactNode } from "react";
import React, { Suspense, useCallback } from "react";

import {
  AttachmentsFooter,
  CarouselContainer,
  CarouselSlot,
  Dot,
  DotsContainer,
  DotsGridSlot,
  DownloadGridSlot,
  DownloadIconContainer,
  NextButton,
  PrevButton,
  Wrapper,
} from "./styles";

export enum CarouselDirection {
  PREV = "PREV",
  NEXT = "NEXT",
}

export interface State {
  position: number;
  sliding: boolean;
  direction: CarouselDirection;
  isDownloading: boolean;
}

export interface Action {
  type: ActionType;
  numItems?: number;
  position?: number;
  isDownloading?: boolean;
}

export enum ActionType {
  Reset = "reset",
  Prev = "PREV",
  Next = "NEXT",
  StopSliding = "stopSliding",
  SetPosition = "setPosition",
  SetIsDownloading = "setIsDownloading",
}

interface CarouselProps {
  children: ReactNode;
  dispatch: React.Dispatch<Action>;
  state: State;
  disableSwipes: boolean;
  onClickDownload: (position: number) => void;
}

export const initialState: State = {
  position: 0,
  sliding: false,
  isDownloading: false,
  direction: CarouselDirection.NEXT,
};

export const reducer: (state: State, action: Action) => State = (
  state,
  action,
) => {
  switch (action.type) {
    case ActionType.Reset:
      return initialState;
    case ActionType.Prev:
      return {
        ...state,
        direction: CarouselDirection.PREV,
        sliding: true,
        position:
          state.position === 0
            ? (action?.numItems ?? 0) - 1
            : state.position - 1,
      };
    case ActionType.Next:
      return {
        ...state,
        direction: CarouselDirection.NEXT,
        sliding: true,
        position:
          state.position === (action.numItems ?? 0) - 1
            ? 0
            : state.position + 1,
      };
    case ActionType.StopSliding:
      return { ...state, sliding: false };
    case ActionType.SetPosition:
      return { ...state, pos: action.position ?? 0 };
    case ActionType.SetIsDownloading:
      return { ...state, isDownloading: !!action.isDownloading };
    default:
      return state;
  }
};

export const Carousel: React.FC<CarouselProps> = ({
  children,
  dispatch,
  state,
  disableSwipes,
  onClickDownload,
}) => {
  const numItems = React.Children.count(children);
  const slide = useCallback(
    (direction: CarouselDirection) => {
      if (!disableSwipes) {
        const actionType =
          direction === CarouselDirection.NEXT
            ? ActionType.Next
            : ActionType.Prev;
        dispatch({ type: actionType, numItems });
        setTimeout(() => {
          dispatch({ type: ActionType.StopSliding, numItems });
        }, 50);
      }
    },
    [disableSwipes, dispatch, numItems],
  );

  return (
    <Wrapper>
      <CarouselContainer
        $dir={state.direction}
        $sliding={state.sliding}
        $position={state.position}
      >
        {React.Children.map(children, (child, index) => (
          <CarouselSlot
            $numItems={React.Children.toArray(children).length}
            key={index}
          >
            <Suspense fallback={<Loader centered />}>{child}</Suspense>
          </CarouselSlot>
        ))}
      </CarouselContainer>
      <AttachmentsFooter>
        <DotsGridSlot>
          <DotsContainer>
            {React.Children.map(children, (child, index) => (
              <Dot
                key={index}
                $selected={index === state.position}
                onClick={() => {
                  dispatch({
                    type: ActionType.SetPosition,
                    numItems: React.Children.toArray(children).length,
                    position: index,
                  });
                }}
              />
            ))}
          </DotsContainer>
        </DotsGridSlot>
        <DownloadGridSlot>
          {state.isDownloading ? (
            <DownloadIconContainer>
              <Loader size={28} />
            </DownloadIconContainer>
          ) : (
            <DownloadIconContainer
              onClick={() => onClickDownload(state.position)}
            >
              <PDComponent.SvgIcon name="download" />
            </DownloadIconContainer>
          )}
        </DownloadGridSlot>
      </AttachmentsFooter>
      <PrevButton onClick={() => slide(CarouselDirection.PREV)}>
        <PDComponent.SvgIcon name="chevronLeft" height={20} width={20} />
      </PrevButton>
      <NextButton onClick={() => slide(CarouselDirection.NEXT)}>
        <PDComponent.SvgIcon name="chevronRight" height={20} width={20} />
      </NextButton>
    </Wrapper>
  );
};
