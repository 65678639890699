import styled from "styled-components";
import { PDConstants } from "utils/PDConstants/PDConstants";

export const Content = styled.div`
  width: 100%;
  height: calc(100% - ${PDConstants.SizesPx.Carousel.Topbar.height.asPixels()});
  overflow-y: hidden;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  background: rgba(0, 0, 0, 0.6);
`;
