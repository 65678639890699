import "dayjs/locale/en";

import type {
  ActualTvdPointDto,
  ApiActualTimelineEventsIdPutRequest,
  ApiActualTimelineEventsPostRequest,
  ApiPlanTimelineEventsIdPutRequest,
  ApiPlanTimelineEventsPostRequest,
  DateDto,
  PlanTvdPointDto,
  TimelineEventType,
} from "apis/oag";
import {
  ActualTimelineEventsApi,
  DimensionType,
  PlanTimelineEventsApi,
} from "apis/oag";
import { Button, DatePicker, FormItem } from "atoms/Form";
import { toast } from "atoms/toast";
import { Text } from "atoms/Typography";
import { Spinner } from "components/Loader/Spinner";
import { PDComponent } from "components/PDComponents";
import { RadioButtonGroup, StyledForm } from "components/Timeline/style";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { useTvds } from "hooks/tvd/useTvds";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import type { ExtendedEventType } from "reducers/types";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { defaultDateDto, ftInM, secondsInDay } from "utils/common";
import { Col, Divider, Input, Radio, Row, Space } from "utils/componentLibrary";
import type { ITimelineEventList } from "utils/eventUtils";
import { useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

dayjs.extend(utc);
dayjs.locale("en");

const apiActual = new ActualTimelineEventsApi(apiConfig);
const apiPlan = new PlanTimelineEventsApi(apiConfig);

interface IInput {
  action: string;
  key: string;
  value: {
    description: string;
    time: string;
    depth: string;
  };
}

const AddAction = ({
  addItemType,
  cumulativeDuration,
  editedEvent,
  onSave,
  depth: _depth,
}: {
  addItemType: ITimelineEventList["type"];
  editedEvent: ITimelineEventList | null;
  cumulativeDuration: number;
  onSave: () => void;
  depth: number | null;
}) => {
  const { dashboardType } = useDashboardType();

  const timelineActions = useMemo(
    () => ({
      cancelAction: () => {
        onSave();
      },
    }),
    [onSave],
  );

  // TODO remove all selectors
  const dispatch = useAppDispatch();
  const { planId } = useAppSelector((state) => state.timeline);
  const timeline_ts = useAppSelector((state) => state.state.timeline_ts);
  const selectedWell = useSelectedWell();
  const selectedTimeline = useAppSelector(
    (state) => state.state.timeline_state,
  );
  const selectedWellDetails = useAppSelector(
    (state) => state.timeline.currentWellDetails,
  );
  const uom = useUOM(DimensionType.Metres);
  const { data: overview } = useTvds({
    isTimeline: true,
    options: { refetchOnMount: false },
  });

  const [depth, setDepth] = useState("");
  const [time, setTime] = useState<DateDto | string>(editedEvent?.at ?? "");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const parseString = (value: string) => {
    const retVal = parseFloat(value);
    if (isNaN(retVal)) return null;
    return value.replace(/[^0-9.]*/g, "").replace(/(?<=(.*\..*))\./gm, "");
  };

  useEffect(() => {
    setError(null);
  }, [time, depth]);

  useEffect(() => {
    setDepth((prevDepth) => {
      if (prevDepth !== "")
        return (
          parseFloat(prevDepth) *
          (uom.currentUOM === IUnitSystem.IMPERIAL ? ftInM : 1 / ftInM)
        ).toFixed(2);
      return "";
    });
  }, [uom]);

  const closestPoint = useMemo<
    ActualTvdPointDto | PlanTvdPointDto | null
  >(() => {
    const seriesData =
      selectedTimeline === "ActualWeb"
        ? overview?.tvdSeries?.series
        : overview?.planTvdSeries?.series;
    if (!seriesData) return null;
    const cumulativePlanData = seriesData.find(
      (point) => point.cumulativeDuration >= cumulativeDuration,
    );
    const holeDepth =
      cumulativePlanData?.holeDepth === null ||
      cumulativePlanData?.holeDepth === undefined
        ? seriesData.find(
            (point) =>
              point.cumulativeDuration >= cumulativeDuration &&
              point.holeDepth !== null &&
              point.holeDepth !== undefined,
          )?.holeDepth
        : cumulativePlanData.holeDepth;

    if (cumulativePlanData)
      return {
        ...cumulativePlanData,
        holeDepth: holeDepth ?? null,
      };

    return null;
  }, [
    selectedTimeline,
    overview?.tvdSeries?.series,
    overview?.planTvdSeries?.series,
    cumulativeDuration,
  ]);

  useEffect(() => {
    const endOfTimelineAdd = editedEvent?.eventType === "EndOfTimeline";
    let depth = _depth ?? editedEvent?.holeDepth ?? closestPoint?.holeDepth;
    if (depth)
      depth = depth * (uom.currentUOM === IUnitSystem.IMPERIAL ? ftInM : 1);
    setDepth(depth ? depth.toFixed(2) : "");
    if (selectedTimeline === "ActualWeb") {
      if (
        endOfTimelineAdd &&
        selectedWellDetails?.drillingDetails?.lastWellFactUpdateAt
      )
        setTime(selectedWellDetails?.drillingDetails?.lastWellFactUpdateAt);
      // TODO check this
      else if (editedEvent !== null) setTime(editedEvent.at ?? "");
      else if (cumulativeDuration) {
        const tvdData = overview?.tvdSeries?.series ?? [];
        const closestPoint = tvdData.find(
          (point) => point.cumulativeDuration >= cumulativeDuration,
        );
        if (!closestPoint) return;
        setTime(closestPoint.at);
      }
    } else {
      setTime(
        (editedEvent !== null
          ? editedEvent.cumulativeDuration / secondsInDay
          : cumulativeDuration / secondsInDay
        ).toFixed(2),
      );
    }
  }, [
    editedEvent,
    selectedTimeline,
    uom,
    selectedWellDetails?.drillingDetails?.lastWellFactUpdateAt,
    overview?.tvdSeries?.series,
    timeline_ts,
    cumulativeDuration,
    overview?.planTvdSeries?.series,
    closestPoint,
    _depth,
  ]);

  const saveEvent = useCallback(
    (inputs: IInput) => {
      if (loading) return;
      setLoading(true);
      if (
        !(
          inputs?.value?.description?.trim() ?? editedEvent?.description?.trim()
        )
      ) {
        setError(`Description should not be empty`);
        setLoading(false);
        return;
      }
      const timeNorm: number = (
        Number.isFinite(time) ? time : parseFloat(time as string)
      ) as number;
      if (selectedTimeline === "ActualWeb") {
        const reqData: ApiActualTimelineEventsPostRequest = {
          actualTimelineEventDto: {
            dashboardType,
            wellId: selectedWell,
            title: addItemType.charAt(0).toUpperCase() + addItemType.slice(1),
            type: (addItemType.charAt(0).toUpperCase() +
              addItemType.slice(1)) as TimelineEventType,
            description: inputs?.value?.description ?? editedEvent?.description,
            isSetByDepth: inputs.action === "depth",
            id: 0,
            deletedAtUtc: null,
            authorId: 0,
            authorDisplayName: "",
            cumulativeDuration: 0,
            holeDepth: null,
            planEventId: null,
            at: defaultDateDto.from,
          },
        };
        if (inputs.action === "time") {
          if (reqData.actualTimelineEventDto)
            reqData.actualTimelineEventDto.at = time as DateDto;

          const currentMax =
            selectedWellDetails?.drillingDetails?.lastWellFactUpdateAt?.utc ??
            new Date(Date.now());
          const currentMin =
            selectedWellDetails?.drillingDetails?.spudDateTime?.utc ??
            new Date(Date.now());
          if (timeNorm < currentMin.getTime()) {
            setError(`Must be greater than ${currentMin.toDateString()}`);
            setLoading(false);
            return;
          }
          if (timeNorm > currentMax.getTime()) {
            setError(`Must be less than ${currentMax.toDateString()}`);
            setLoading(false);
            return;
          }
        } else if (inputs.action === "depth") {
          if (reqData.actualTimelineEventDto)
            reqData.actualTimelineEventDto.holeDepth =
              parseFloat(depth) /
              (uom.currentUOM === IUnitSystem.IMPERIAL ? ftInM : 1);
          const currentMax =
            selectedWellDetails?.drillingDetails?.maxHoleDepth ?? 0;
          if (parseFloat(depth) < 0) {
            setError("Must be greater than 0");
            setLoading(false);
            return;
          }
          if (
            parseFloat(depth) /
              (uom.currentUOM === IUnitSystem.IMPERIAL ? ftInM : 1) >
            currentMax
          ) {
            setError(
              `Must be less than ${Math.floor(
                (currentMax *
                  (uom.currentUOM === IUnitSystem.IMPERIAL ? ftInM : 1) *
                  100) /
                  100,
              ).toFixed(2)}`,
            );
            setLoading(false);
            return;
          }
        }

        setError(null);
        if (!Number.isFinite(editedEvent?.id)) {
          // CREATE actual event
          apiActual
            .apiActualTimelineEventsPost(reqData)
            .then((event) => {
              Track.interact("Timeline - Add Actual Event", { ...event });
              dispatch({
                type: "ADD_ACTUAL_EVENT",
                payload: {
                  startCumulativeDuration: 0,
                  startHoleDepth: 0,
                  planId: null,
                  isFutureEvent: false,
                  holeDepth: null,
                  ...event,
                  key: `${event.type}_${event.id}`,
                  eventType: event.type as ExtendedEventType,
                  compressible: true,
                  combinedEvents: [],
                },
              });
              timelineActions.cancelAction();
            })
            .catch(() => {
              toast.error({
                message:
                  "Could not create actual event at that position, please try again",
              });
              timelineActions.cancelAction();
            });
        } else {
          const requestData: ApiActualTimelineEventsIdPutRequest = {
            id: editedEvent?.id || -1,
            actualTimelineEventDto: editedEvent
              ? {
                  ...editedEvent,
                  ...reqData.actualTimelineEventDto,
                  id: editedEvent?.id || -1,
                  dashboardType,
                }
              : undefined,
          };

          // EDIT actual event
          apiActual
            .apiActualTimelineEventsIdPut(requestData)
            .then((event) => {
              Track.interact("Timeline - Edit Actual Event", { ...event });

              dispatch({
                type: "EDIT_ACTUAL_EVENT",
                payload: event,
              });
              dispatch({
                type: "SET_EDIT_EVENT",
                payload: null,
              });
              timelineActions.cancelAction();
            })
            .catch(() => {
              toast.error({
                message: `Could not update actual event id: ${editedEvent?.id}, please try again`,
              });
              timelineActions.cancelAction();
            });
        }
      } else if (selectedTimeline === "Plan" && planId) {
        const reqData: ApiPlanTimelineEventsPostRequest = {
          planTimelineEventDto: {
            planId,
            title:
              addItemType.toString().charAt(0).toUpperCase() +
              addItemType.toString().slice(1),
            type: (addItemType.toString().charAt(0).toUpperCase() +
              addItemType.toString().slice(1)) as TimelineEventType,
            description: inputs?.value?.description ?? editedEvent?.description,
            isSetByDepth: inputs.action === "depth",
            id: 0,
            deletedAtUtc: null,
            authorId: 0,
            authorDisplayName: "",
            cumulativeDuration: Math.floor(cumulativeDuration),
            holeDepth: null,
            isFutureEvent: false,
          },
        };

        if (inputs.action === "time") {
          if (reqData.planTimelineEventDto)
            reqData.planTimelineEventDto.cumulativeDuration = Math.floor(
              timeNorm * secondsInDay,
            );
          const currentMax =
            selectedWellDetails?.planDetails?.totalDuration ?? 0;
          if (Math.floor(timeNorm * secondsInDay) < 0) {
            setError("Must be higher than 0");
            setLoading(false);
            return;
          }
          if (Math.floor(timeNorm * secondsInDay) > currentMax) {
            setError(
              `Must be less than ${(Math.floor((currentMax / secondsInDay) * 100) / 100).toFixed(2)}`,
            );
            setLoading(false);
            return;
          }
        } else if (inputs.action === "depth") {
          if (reqData.planTimelineEventDto)
            reqData.planTimelineEventDto.holeDepth =
              parseFloat(depth) /
              (uom.currentUOM === IUnitSystem.IMPERIAL ? ftInM : 1);
          const currentMax = selectedWellDetails?.planDetails?.endDepth ?? 0;
          if (parseFloat(depth) < 0) {
            setError("Must be higher than 0");
            setLoading(false);
            return;
          }
          if (
            parseFloat(depth) /
              (uom.currentUOM === IUnitSystem.IMPERIAL ? ftInM : 1) >
            currentMax
          ) {
            setError(
              `Must be less than ${Math.floor(
                (currentMax *
                  (uom.currentUOM === IUnitSystem.IMPERIAL ? ftInM : 1) *
                  100) /
                  100,
              ).toFixed(2)}`,
            );
            setLoading(false);
            return;
          }
        }

        setError(null);

        if (!Number.isFinite(editedEvent?.id)) {
          // CREATE plan event
          apiPlan
            .apiPlanTimelineEventsPost(reqData)
            .then((event) => {
              Track.interact("Timeline - Add Plan Event", { ...event });

              dispatch({
                type: "ADD_PLAN_EVENT",
                payload: {
                  startCumulativeDuration: 0,
                  startHoleDepth: 0,
                  at: null,
                  wellId: null,
                  isPlanEventProjection: null,
                  dashboardType: null,
                  holeDepth: null,
                  ...event,
                  planId: 0,
                  isFutureEvent: false,
                  key: `${event.type}_${event.id}_${event.isFutureEvent}`,
                  eventType: event.type as ExtendedEventType,
                  compressible: true,
                  combinedEvents: [],
                },
              });
              timelineActions.cancelAction();
            })
            .catch(() => {
              toast.error({
                message:
                  "Could not create plan event at that position, please try again",
              });
              timelineActions.cancelAction();
            });
        } else {
          const requestData: ApiPlanTimelineEventsIdPutRequest = {
            id: editedEvent?.id || -1,
            planTimelineEventDto: editedEvent
              ? {
                  ...editedEvent,
                  ...reqData.planTimelineEventDto,
                  id: editedEvent?.id,
                }
              : undefined,
          };

          // EDIT plan event
          apiPlan
            .apiPlanTimelineEventsIdPut(requestData)
            .then((event) => {
              Track.interact("Timeline - Edit Plan Event", { ...event });
              dispatch({
                type: "EDIT_PLAN_EVENT",
                payload: event,
              });
              dispatch({
                type: "SET_EDIT_EVENT",
                payload: null,
              });
              timelineActions.cancelAction();
            })
            .catch(() => {
              toast.error({
                message: `Could not update plan event id: ${editedEvent?.id}, please try again`,
              });
              timelineActions.cancelAction();
            });
        }
      }
    },
    [
      addItemType,
      cumulativeDuration,
      dashboardType,
      depth,
      dispatch,
      editedEvent,
      loading,
      planId,
      selectedTimeline,
      selectedWell,
      selectedWellDetails?.drillingDetails?.lastWellFactUpdateAt?.utc,
      selectedWellDetails?.drillingDetails?.maxHoleDepth,
      selectedWellDetails?.drillingDetails?.spudDateTime?.utc,
      selectedWellDetails?.planDetails?.endDepth,
      selectedWellDetails?.planDetails?.totalDuration,
      time,
      timelineActions,
      uom.currentUOM,
    ],
  );

  const onDateChange = useCallback(
    (e: dayjs.Dayjs | null) => {
      const getClosestPointOffset = () => {
        const closestOffset =
          (closestPoint as ActualTvdPointDto)?.at?.minutesOffset ?? 0;
        if (closestOffset) return closestOffset;
        if (selectedTimeline === "ActualWeb") {
          if (!overview?.tvdSeries?.series) return null;
          const tvdData = overview.tvdSeries.series;
          // take the offset of the last point of the TVD series.. this will break if we change DST during well drilling
          return tvdData.slice(-1)[0]?.at?.minutesOffset ?? 0;
        }
        if (selectedTimeline === "Plan") {
          return 0;
        }
      };
      const adjustedDate = e
        ?.subtract(
          (getClosestPointOffset?.() || 0) +
            (e ? new Date(e.toDate()).getTimezoneOffset() : 0),
          "minutes",
        )
        .toDate();
      if (adjustedDate) {
        setTime({
          utc: adjustedDate,
          minutesOffset: getClosestPointOffset() || 0,
          isDateOnly: false,
        });
      }
    },
    [selectedTimeline, closestPoint, overview?.tvdSeries?.series],
  );

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const [values, setValues] = useState<{
    action: "time" | "depth";
    value: {
      description: string | null;
    };
  }>({ action: "time", value: { description: null } });

  useEffect(() => {
    if (editedEvent?.description && values.value.description === null) {
      setValues({
        action: "time",
        value: {
          description: editedEvent.description,
        },
      });
    }
  }, [editedEvent, values]);

  const defaultDate = useMemo(
    () => (time ? dayjs(formatTime(time, {})) : undefined),
    [time],
  );

  return (
    <StyledForm
      error={error || ""}
      onSubmit={(e) => {
        e.preventDefault();
        saveEvent(values as IInput);
      }}
    >
      <Row gutter={0}>
        <Col span={24}>
          <Input.TextArea
            value={values.value.description ?? ""}
            maxLength={300}
            onChange={(e) => {
              setError(null);

              setValues({
                ...values,
                value: {
                  ...values.value,
                  description: e.target.value,
                },
              });
            }}
          />
        </Col>
        <Col
          span={24}
          style={{
            background: themeColors.secondary_bg,
            padding: "2px 2px",
            height: 40,
            borderBottom: `1px solid ${themeColors.primary_chart_accent}`,
            borderTop: `1px solid ${themeColors.primary_chart_accent}`,
          }}
        >
          <Row justify="space-between">
            <Col>
              <RadioButtonGroup
                size="large"
                value={values.action}
                onChange={(e) => {
                  setValues({
                    ...values,
                    action: e.target.value,
                  });
                }}
              >
                <Radio.Button value="time">
                  <PDComponent.SvgIcon name="time" style={{ fontSize: 18 }} />
                </Radio.Button>
                <Radio.Button value="depth">
                  <PDComponent.SvgIcon name="depth" style={{ fontSize: 18 }} />
                </Radio.Button>
              </RadioButtonGroup>
            </Col>
            <Col>
              <Space size={2}>
                <Button
                  trackingName="Cancel Add Event"
                  type="link"
                  size="large"
                  onClick={() => timelineActions.cancelAction()}
                  icon={
                    <PDComponent.SvgIcon
                      name="closeOutline"
                      style={{ fontSize: 18 }}
                    />
                  }
                />
                <Divider type="vertical" style={{ margin: 0 }} />
                <Button
                  trackingName="Add Event"
                  type="link"
                  size="large"
                  disabled={loading ? !!error : undefined}
                  htmlType="submit"
                  icon={
                    loading ? (
                      <Spinner color={colors.widget_line} />
                    ) : (
                      <PDComponent.SvgIcon
                        name="sendFilled"
                        style={{
                          fontSize: 18,
                          color: error ? colors.widget_line : colors.well_color,
                        }}
                      />
                    )
                  }
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            backgroundColor: themeColors.quaterniary_bg,
            borderRadius: "0 0 8px 8px",
            height: 40,
          }}
        >
          {error ? (
            <Col
              span={24}
              style={{
                backgroundColor: colors.error_color,
                borderRadius: "0 0 8px 8px",
                height: 40,
                padding: "12px 16px 14px 12px",
              }}
            >
              <Text primary="description" variant="white">
                {error}
              </Text>
            </Col>
          ) : (
            <>
              {values.action === "time" && (
                <FormItem>
                  {selectedTimeline === "ActualWeb" && defaultDate ? (
                    <DatePicker
                      size="large"
                      onChange={onDateChange}
                      defaultValue={defaultDate}
                      allowClear={false}
                    />
                  ) : (
                    <Input
                      size="large"
                      suffix="Day"
                      value={(time as string) ?? ""}
                      onChange={(element) =>
                        setTime(parseString(element.target.value) || "")
                      }
                    />
                  )}
                </FormItem>
              )}
              {values.action === "depth" && (
                <FormItem>
                  <Input
                    size="large"
                    suffix={
                      uom.currentUOM === IUnitSystem.METRIC ? "Meters" : "Feet"
                    }
                    value={depth}
                    onChange={(element) =>
                      setDepth(parseString(element.target.value) || "")
                    }
                  />
                </FormItem>
              )}
            </>
          )}
        </Col>
      </Row>
    </StyledForm>
  );
};

export default AddAction;
