/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ActualTvdPointDto
 */
export interface ActualTvdPointDto {
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    dynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    holeDepth: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    dynamicHoleDepth: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    phaseId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof ActualTvdPointDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    operationClassId: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPointDto
     */
    bitDepth: number | null;
}

/**
 * Check if a given object implements the ActualTvdPointDto interface.
 */
export function instanceOfActualTvdPointDto(value: object): value is ActualTvdPointDto {
    if (!('cumulativeDuration' in value) || value['cumulativeDuration'] === undefined) return false;
    if (!('dynamicDuration' in value) || value['dynamicDuration'] === undefined) return false;
    if (!('holeDepth' in value) || value['holeDepth'] === undefined) return false;
    if (!('dynamicHoleDepth' in value) || value['dynamicHoleDepth'] === undefined) return false;
    if (!('phaseId' in value) || value['phaseId'] === undefined) return false;
    if (!('at' in value) || value['at'] === undefined) return false;
    if (!('operationClassId' in value) || value['operationClassId'] === undefined) return false;
    if (!('bitDepth' in value) || value['bitDepth'] === undefined) return false;
    return true;
}

export function ActualTvdPointDtoFromJSON(json: any): ActualTvdPointDto {
    return ActualTvdPointDtoFromJSONTyped(json, false);
}

export function ActualTvdPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActualTvdPointDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cumulativeDuration': json['CumulativeDuration'],
        'dynamicDuration': json['DynamicDuration'],
        'holeDepth': json['HoleDepth'],
        'dynamicHoleDepth': json['DynamicHoleDepth'],
        'phaseId': json['PhaseId'],
        'at': DateDtoFromJSON(json['At']),
        'operationClassId': json['OperationClassId'],
        'bitDepth': json['BitDepth'],
    };
}

export function ActualTvdPointDtoToJSON(value?: ActualTvdPointDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'CumulativeDuration': value['cumulativeDuration'],
        'DynamicDuration': value['dynamicDuration'],
        'HoleDepth': value['holeDepth'],
        'DynamicHoleDepth': value['dynamicHoleDepth'],
        'PhaseId': value['phaseId'],
        'At': DateDtoToJSON(value['at']),
        'OperationClassId': value['operationClassId'],
        'BitDepth': value['bitDepth'],
    };
}

