import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import type { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Button } from "atoms/Form";
import type { IProps } from "components/WellDashboard/ChartControls/atoms/Indicators";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import type { FC } from "react";
import { useMemo, useState } from "react";
import type { IDisplayOptionsType, PhasesEnum } from "reducers/types";
import {
  CurvesEnum,
  EventsEnum,
  EvergreenEventsEnum,
  initialDisplayOptions,
  initialDisplayOptionsEvergreen,
} from "reducers/types";
import { Track } from "services/Mixpanel";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Checkbox, Col, Popover, Row, Tooltip } from "utils/componentLibrary";

const StyledContainer = styled.div`
  border: solid 1px ${colors.widget_line};
  padding: 12px 0;
  border-radius: 4px;
  .ant-checkbox-wrapper {
    display: flex;
  }
`;

// TODO fix styling in this component
const StyledCheckbox = styled(Checkbox)<{ $isCommonCheckbox?: string }>`
  ${(props) =>
    props.$isCommonCheckbox === "true"
      ? css`
          text-transform: capitalize;
          padding: 0px 12px;
          font-weight: 500;
        `
      : ""}
  text-transform: capitalize;
`;

const DisplayOptions: FC<IProps> = ({ disabled, icon }: IProps) => {
  const { isEvergreen } = useDashboardType();
  const [displayOptions, setDisplayOptions] =
    useStateQuery<IDisplayOptionsType>(
      URL_STATE_PARAM.DISPLAY_OPTIONS_WELL,
      isEvergreen ? initialDisplayOptionsEvergreen : initialDisplayOptions,
    );

  const [visible, setVisible] = useState(false);
  const checkboxOptions: IDisplayOptionsType = useMemo(
    () => ({
      curves: isEvergreen
        ? [CurvesEnum.BIT_DEPTH, CurvesEnum.HOLE_DEPTH]
        : (Object.keys(CurvesEnum) as CurvesEnum[]),
      events: isEvergreen
        ? (Object.keys(EvergreenEventsEnum) as EventsEnum[])
        : (Object.keys(EventsEnum) as EventsEnum[]),
    }),
    [isEvergreen],
  );

  const activeDisplayOptions = useMemo(() => {
    const displayOptionsCopy = JSON.parse(JSON.stringify(displayOptions));
    const checkboxOptionsCopy = JSON.parse(JSON.stringify(checkboxOptions));

    if (
      Object.keys(displayOptions).every((key) => {
        return (
          displayOptions[key as keyof typeof displayOptions] === null ||
          JSON.stringify(
            displayOptionsCopy[key as keyof typeof displayOptionsCopy]?.sort(),
          ) ===
            JSON.stringify(
              checkboxOptionsCopy[
                key as keyof typeof checkboxOptionsCopy
              ]?.sort(),
            )
        );
      })
    )
      return false;
    return true;
  }, [checkboxOptions, displayOptions]);

  const handleVisibleChange = (visibility: boolean) => setVisible(visibility);
  const onCommonCheckboxChange = (
    key: keyof typeof displayOptions,
    e: CheckboxChangeEvent,
  ) => {
    if (e.target.checked) {
      const allValues = checkboxOptions[key];
      Track.interact("Display Options - Well Dashboard", {
        [key === "curves" ? "Chart Lines" : key]: allValues,
      });
      setDisplayOptions({
        ...displayOptions,
        [key]: allValues,
      });
    } else {
      Track.interact("Display Options - Well Dashboard", {
        [key === "curves" ? "Chart Lines" : key]: [],
      });
      const newDisplayOptions: IDisplayOptionsType = {
        curves: displayOptions.curves ?? checkboxOptions.curves,
        events: displayOptions.events ?? checkboxOptions.events,
        phases: displayOptions.phases ?? checkboxOptions.phases,
        [key]: [],
      };
      setDisplayOptions(newDisplayOptions);
    }
  };

  const updateDisplayOptions = (e: IDisplayOptionsType) => {
    Track.interact("Display Options - Well Dashboard", {
      "Chart Lines": e.curves,
      "Selected Events": e.events,
      "Selected Phases": e.phases,
    });
    setDisplayOptions(e);
  };

  return (
    <Tooltip title="Display Options">
      <Popover
        placement="bottomRight"
        trigger={disabled ? [] : "click"}
        open={visible}
        onOpenChange={handleVisibleChange}
        destroyTooltipOnHide
        content={
          <Col
            style={{
              padding: "12px 16px",
            }}
          >
            <form style={{ width: "550px" }}>
              <Row
                style={{
                  margin: "8px -6px",
                }}
                gutter={[20, 20]}
              >
                {(
                  Object.keys(
                    checkboxOptions,
                  ) as (keyof typeof checkboxOptions)[]
                ).map((option) => {
                  return (
                    <Col key={option} span={12}>
                      <StyledContainer>
                        <StyledCheckbox
                          indeterminate={
                            (displayOptions?.[option]?.length ?? 0) > 0 &&
                            (displayOptions?.[option]?.length ?? 0) !==
                              checkboxOptions[option]?.length
                          }
                          checked={
                            displayOptions[option] === null ||
                            displayOptions?.[option]?.length ===
                              checkboxOptions[option]?.length
                          }
                          $isCommonCheckbox="true"
                          onChange={(e) => onCommonCheckboxChange(option, e)}
                        >
                          {option === "curves" ? "Chart Lines" : "Memos"}
                        </StyledCheckbox>
                        <Checkbox.Group
                          value={
                            displayOptions[option] ??
                            (checkboxOptions[option] as CheckboxValueType[])
                          }
                        >
                          <Row
                            style={{
                              padding: "12px 12px 0 12px",
                              opacity: "0.75",
                              flexDirection: "column",
                            }}
                            gutter={[0, 8]}
                          >
                            {(checkboxOptions[option] ?? []).map((e) => {
                              const displayOption =
                                displayOptions[option] === null
                                  ? checkboxOptions[option]
                                  : (displayOptions[option] as Array<
                                      CurvesEnum | EventsEnum | PhasesEnum
                                    >);
                              return (
                                <Col key={e} span={24}>
                                  <StyledCheckbox
                                    value={e}
                                    onClick={() => {
                                      updateDisplayOptions({
                                        ...displayOptions,
                                        [option]: displayOption?.includes(e)
                                          ? displayOption?.filter(
                                              (x) => x !== e,
                                            )
                                          : [...(displayOption ?? []), e],
                                      });
                                    }}
                                  >
                                    {e.replaceAll("_", " ").toLowerCase()}
                                  </StyledCheckbox>
                                </Col>
                              );
                            })}
                          </Row>
                        </Checkbox.Group>
                      </StyledContainer>
                    </Col>
                  );
                })}
              </Row>
            </form>
          </Col>
        }
      >
        <Button
          disabled={disabled}
          icon={icon}
          $engaged={visible}
          type={activeDisplayOptions ? "primary" : "default"}
          ghost={activeDisplayOptions}
        />
      </Popover>
    </Tooltip>
  );
};

export default DisplayOptions;
