import styled, { css } from "styled-components";
import colors from "utils/colors";

export const DescriptionCard = styled.div<{ $isShowingMore: boolean }>`
  white-space: pre-wrap;
  display: inline-block;
  text-wrap: wrap;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-clamp: 6;
  -webkit-line-clamp: 6;
  font-size: 14px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};

  /* unfortunately css is not playing nice, so once you specify a line-clamp that needs to be specified always */
  ${({ $isShowingMore }) =>
    !$isShowingMore
      ? css`
          line-clamp: 4;
          -webkit-line-clamp: 4;
        `
      : css`
          line-clamp: 500;
          -webkit-line-clamp: 500;
        `}
`;

export const ShowMoreOrLess = styled.div`
  cursor: pointer;
  color: ${colors.necron_compound};
  font-size: 14px;
  font-weight: bold;
  margin-top: 4px;
`;
