import type { PivotOrderType } from "apis/oag";
import { PivotType, WellStatusType } from "apis/oag";
import { LegendAtom } from "components/Legend/LegendAtom";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { getDefaultPivotOrderTypeByPivot } from "components/RigComparisonSelectors/DisplayOptionsModal/utils";
import { useCrewMembers } from "hooks/drillingInvariants/useCrewMembers";
import { useDirectionalIntervals } from "hooks/drillingInvariants/useDirectionalIntervals";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { useHoleSizes } from "hooks/drillingInvariants/useHoleSizes";
import { useRigs } from "hooks/drillingInvariants/useRigs";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useRigToWells } from "hooks/rigs/useRigToWells";
import { useSelectedRig } from "hooks/rigs/useSelectedRig";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { useCallback, useEffect, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { RIG_LEGEND_IDS } from "utils/common";
import { useCustomHoleSizeUom } from "utils/format";
import { getCurrentPivot, useColors } from "utils/useColors";

export const RigLegend = () => {
  const { getColor } = useColors();

  const selectedRig = useSelectedRig();

  const [offsetRigs] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET,
    [],
  );
  const [{ pivot: displayOptions }] = useStateQuery<{
    pivot: Array<PivotType>;
    order: Array<PivotOrderType>;
  }>(URL_STATE_PARAM.DATA_GROUPS_WIDGET, {
    pivot: [PivotType.Day, PivotType.None],
    order: [PivotType.Day, PivotType.None].map((e) =>
      getDefaultPivotOrderTypeByPivot(e),
    ),
  });
  const [realtimeDataDashboard, setRealTime] = useStateQuery(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.UNAVAILABLE,
    [URL_STATE_PARAM.REALTIME_DATA_WIDGET],
  );

  const { data: availableWellsRig } = useRigToWells();
  const { data: wellInfo } = useWellShortInfoSuspended();

  useEffect(() => {
    if (!wellInfo?.byId) return;
    if (realtimeDataDashboard !== RealTimeDataEnum.UNAVAILABLE) return;
    if (
      availableWellsRig?.some(
        (wellId) => wellInfo?.byId[wellId]?.status === WellStatusType.Active,
      )
    )
      setRealTime(RealTimeDataEnum.ACTIVE);
  }, [availableWellsRig, realtimeDataDashboard, setRealTime, wellInfo]);

  // TODO this assignment of positions 0 and 1 can be detrimental in the future; check again
  const pivotType = useMemo(() => {
    return getCurrentPivot({
      group: displayOptions[0],
      subGroup: displayOptions[1],
      isComparing: offsetRigs.length > 0,
    });
  }, [displayOptions, offsetRigs.length]);

  const legendColorList = useAppSelector(
    (state) => state.legendColor.colorById,
  )[pivotType];

  const { data: crewMembers } = useCrewMembers();
  const { data: holeSizes } = useHoleSizes();
  const { data: holeSections } = useHoleSections();
  const { data: directionalIntervals } = useDirectionalIntervals();
  const { data: rigs } = useRigs({});
  const holeSizeUom = useCustomHoleSizeUom();
  const legendName = useCallback(
    (key: string) => {
      if (offsetRigs.length > 0) {
        const rig = (rigs?.list ?? []).find((e) => e.id === +key);
        return rig?.shortName;
      }
      switch (pivotType) {
        case PivotType.Rig:
          return (rigs?.list ?? []).find((e) => e.id === +key)?.shortName;
        case PivotType.Driller:
          return (crewMembers ?? []).find((e) => e.id === +key)?.name;
        case PivotType.HoleSize: {
          const holeSize = (holeSizes ?? []).find((e) => e.id === +key)?.value;
          return holeSize && holeSize >= 0
            ? holeSizeUom.display(+holeSize, { metricOption: true })
            : "Unknown";
        }
        case PivotType.HoleSection:
          return (holeSections ?? []).find((e) => e.id === +key)?.name;
        case PivotType.DirectionalInterval:
          return (directionalIntervals ?? []).find((e) => e.id === +key)?.name;
        default:
          return null;
      }
    },
    [
      offsetRigs.length,
      rigs?.list,
      crewMembers,
      holeSizes,
      holeSizeUom,
      holeSections,
      pivotType,
      directionalIntervals,
    ],
  );
  const rigsArr = useMemo(
    () => [selectedRig, ...offsetRigs],
    [offsetRigs, selectedRig],
  );

  const legendKeys = useMemo(
    () =>
      pivotType === PivotType.Rig
        ? rigsArr.map((e) => e.toString())
        : legendColorList,
    [pivotType, legendColorList, rigsArr],
  );

  const getColorCb = useCallback(
    (key: string) => {
      return getColor({
        key,
        currentPivot: pivotType,
      });
    },
    [getColor, pivotType],
  );

  if (!legendColorList) return null;
  return (
    <>
      {legendKeys.map((key) => {
        return (
          legendName(key) && (
            <LegendAtom
              key={key}
              wellName={legendName(key) || ""}
              wellInfo={""}
              selected={false}
              id={RIG_LEGEND_IDS}
              color={getColorCb(key)}
            />
          )
        );
      })}
    </>
  );
};
