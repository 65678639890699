/* eslint-disable react/forbid-dom-props */
import type {
  ApiActualTimelineEventsIdDeleteRequest,
  ApiActualTimelineEventsIdRestorePutRequest,
  ApiPlanTimelineEventsIdDeleteRequest,
  ApiPlanTimelineEventsIdRestorePutRequest,
} from "apis/oag";
import {
  ActualTimelineEventsApi,
  DimensionType,
  PlanTimelineEventsApi,
  TimelineEventType,
  UserRoleType,
} from "apis/oag";
import AddAction from "components/Timeline/components/AddAction";
import { AddActionWrapper } from "components/Timeline/components/Event/style";
import { EventHover } from "components/Timeline/components/EventHover/EventHover";
import {
  EditedEventContainer,
  EventBox,
  EventBoxRelative,
  EventButton,
  EventIconSelector,
  Separator,
  StyledDescription,
  StyledSubtitle,
  StyledTitle,
} from "components/Timeline/style";
import { eventIcon, isSameEvent, LEFT_MARGIN } from "components/Timeline/utils";
import { TimelineEventIndicator } from "components/TvDChart/components/TimelineEventIndicator/TimelineEventIndicator";
import { useHoleSections } from "hooks/drillingInvariants/useHoleSections";
import { usePhases } from "hooks/drillingInvariants/usePhases";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import type { FC } from "react";
import React, { useMemo } from "react";
import type { TimelineStates } from "reducers/reportReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { PLAN_SERIES_ID, secondsInDay } from "utils/common";
import { Col, Row, Tooltip } from "utils/componentLibrary";
import type { ITimelineEventList } from "utils/eventUtils";
import { useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export const Event: FC<{
  width: number;
  event: ITimelineEventList;
  selectedTimeline: TimelineStates;
  top: number;
  actionEnabled: boolean;
  resetRef: () => void;
  setCurrentEvent: React.Dispatch<
    React.SetStateAction<{
      type: TimelineEventType;
      icon: JSX.Element;
    } | null>
  >;
  addEndOfTimeline: boolean;
  setAddEndOfTimeline: React.Dispatch<React.SetStateAction<boolean>>;
  setEditedEvent: React.Dispatch<
    React.SetStateAction<ITimelineEventList | null>
  >;
  eventTypes: Array<{ type: TimelineEventType; icon: JSX.Element }>;
  editedEvent: ITimelineEventList | null;
  isStartOfPhase?: boolean;
}> = ({
  width,
  eventTypes,
  actionEnabled,
  event,
  setEditedEvent,
  editedEvent,
  selectedTimeline,
  top,
  resetRef,
  addEndOfTimeline,
  setAddEndOfTimeline,
  isStartOfPhase,
}) => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  const depthUOM = useUOM(DimensionType.Metres);
  const subtitle = (() => {
    if (event.eventType.toString().includes("Phase")) {
      const phaseStart = event.startCumulativeDuration / secondsInDay;
      const phaseEnd = (event.endCumulativeDuration || 0) / secondsInDay;
      return `Day ${phaseStart.toFixed(2)} to Day ${phaseEnd.toFixed(2)}`;
    }
    // Start of well shall not have a description
    if (event.eventType === "Section")
      return event.id === 1
        ? ""
        : `Day ${(event.cumulativeDuration / secondsInDay).toFixed(2)} · ${depthUOM.display(
            event.startHoleDepth ?? event.holeDepth,
          )}`;
    if (selectedTimeline === "ActualWeb") {
      return `${event.authorDisplayName} ${
        event.at
          ? ` · ${formatTime(event.at, { formatStr: "MM/DD/YYYY, HH:mm" })}`
          : ""
      } ${event.holeDepth?.toFixed(2) ? ` · ${depthUOM.display(event.holeDepth)}` : ""}`;
    }
    return `${event.authorDisplayName} · Day ${(
      event.cumulativeDuration / secondsInDay
    ).toFixed(2)} · ${depthUOM.display(event.holeDepth)}`;
  })();

  // Move all of those out of timeline
  const dispatch = useAppDispatch();
  const hoveredEvent = useAppSelector((state) => state.state.hoveredEvent);
  const selectedSeries = useAppSelector((state) => state.state.selectedSeries);
  const timeline_state = useAppSelector((state) => state.state.timeline_state);
  const { data: phases } = usePhases();
  const { data: sectionHole } = useHoleSections();

  const wellId = useSelectedWell();
  const isHovered =
    event.id === hoveredEvent?.id &&
    hoveredEvent?.type === event.type &&
    ((selectedSeries === wellId && timeline_state === "ActualWeb") ||
      (selectedSeries === PLAN_SERIES_ID && timeline_state === "Plan"));

  const editEvent = () => {
    Track.interact("Edit Event", {
      Timeline: selectedTimeline,
      "Event Type": event.type,
      Description: event.description,
      Date: event?.at,
      "Hole Depth": event.holeDepth,
    });
    setEditedEvent(event);
  };

  const restoreEventCb = (id: number) => {
    if (selectedTimeline === "Plan")
      return new PlanTimelineEventsApi(
        apiConfig,
      ).apiPlanTimelineEventsIdRestorePut({
        id,
      } as ApiPlanTimelineEventsIdRestorePutRequest);
    return new ActualTimelineEventsApi(
      apiConfig,
    ).apiActualTimelineEventsIdRestorePut({
      id,
    } as ApiActualTimelineEventsIdRestorePutRequest);
  };
  const deleteEventCb = (id: number) => {
    if (selectedTimeline === "Plan")
      return new PlanTimelineEventsApi(apiConfig).apiPlanTimelineEventsIdDelete(
        {
          id,
        } as ApiPlanTimelineEventsIdDeleteRequest,
      );
    return new ActualTimelineEventsApi(
      apiConfig,
    ).apiActualTimelineEventsIdDelete({
      id,
    } as ApiActualTimelineEventsIdDeleteRequest);
  };

  const restoreEvent = () => {
    Track.interact("Restore Event", {
      Timeline: selectedTimeline,
      "Event Type": event.type,
      Description: event.description,
      Date: event.at,
      "Hole Depth": event.holeDepth,
    });
    restoreEventCb(event.id).then((e) => {
      if (e) {
        dispatch({
          type:
            selectedTimeline === "Plan"
              ? "DELETE_OR_RESTORE_PLAN_EVENT"
              : "DELETE_OR_RESTORE_ACTUAL_EVENT",
          payload: { event, restore: true },
        });
      }
    });
  };

  const deleteEvent = () => {
    Track.interact("Delete Event", {
      Timeline: selectedTimeline,
      "Event Type": event.type,
      Description: event.description,
      Date: event.at,
      "Hole Depth": event.holeDepth,
    });
    deleteEventCb(event.id)
      .then((e) => {
        if (e) {
          if (user?.role === UserRoleType.Administrator) {
            dispatch({
              type:
                selectedTimeline === "Plan"
                  ? "DELETE_OR_RESTORE_PLAN_EVENT"
                  : "DELETE_OR_RESTORE_ACTUAL_EVENT",
              payload: { event, restore: false },
            });
          } else {
            dispatch({
              type:
                selectedTimeline === "Plan"
                  ? "SIMPLE_DELETE_PLAN_EVENT"
                  : "SIMPLE_DELETE_ACTUAL_EVENT",
              payload: event,
            });
          }
        }
      })
      .catch((err) => Track.event(err));
  };

  const user = useAppSelector((state) => state.global.userInfo);
  const selectedEvent = useAppSelector(
    (state) => state.timeline.selectedTimelineEvent,
  );

  const disableHover = !Object.keys(TimelineEventType).includes(event.type);
  const isColored = disableHover.toString();
  const isAddingAtEndOfTimeline =
    addEndOfTimeline && event?.eventType === "EndOfTimeline";
  const currentEventIcon = useMemo(() => {
    if (isStartOfPhase) return eventIcon("Section");
    if (isAddingAtEndOfTimeline && editedEvent)
      return eventIcon(editedEvent.type);
    return eventIcon(event.eventType, !!event.deletedAtUtc);
  }, [
    editedEvent,
    event.deletedAtUtc,
    event.eventType,
    isAddingAtEndOfTimeline,
    isStartOfPhase,
  ]);

  const title = useMemo(() => {
    if (event.eventType === "Section")
      return event.id === 1
        ? "Start of Well"
        : sectionHole.find((sections) => sections.id === event.id)?.name ?? "";
    if (event.eventType.toString().includes("Phase")) {
      return (
        (phases || []).find((phase) => phase.id === event.phaseId)?.name ?? ""
      );
    }
    return event.type ?? "";
  }, [
    event.eventType,
    event.id,
    event.phaseId,
    event.type,
    phases,
    sectionHole,
  ]);

  const eventColor = useMemo(() => {
    if (isStartOfPhase) return "white";
    return (event.eventType === "PhaseEnd" ||
      event.eventType === "PhaseStart") &&
      !isStartOfPhase
      ? colors.well_color
      : themeColors.primary_bg;
  }, [event.eventType, isStartOfPhase, themeColors.primary_bg]);
  return (
    <div
      style={{
        position: "absolute",
        top,
        left: LEFT_MARGIN,
      }}
    >
      {editedEvent && isSameEvent({ event, eventCmp: editedEvent }) ? (
        <AddActionWrapper>
          <AddAction
            addItemType={event.type || editedEvent?.type}
            cumulativeDuration={event.cumulativeDuration}
            editedEvent={editedEvent}
            depth={null}
            onSave={() => {
              setEditedEvent(null);
              setAddEndOfTimeline(false);
              resetRef();
            }}
          />
        </AddActionWrapper>
      ) : null}
      <TimelineEventIndicator
        // todo: grouping in here based on the event icon
        isPhase={
          isStartOfPhase
            ? "false"
            : (
                event.eventType === "PhaseEnd" ||
                event.eventType === "PhaseStart"
              ).toString()
        }
        isPlacedInTimeline
        isHovered={
          event.eventType === "EndOfTimeline" ? "false" : isHovered.toString()
        }
        isColored={
          event.eventType === "EndOfTimeline" && isAddingAtEndOfTimeline
            ? "false"
            : isColored
        }
        isGlowing={(isAddingAtEndOfTimeline && !editedEvent).toString()}
        eventId={event.id}
        onClick={() => {
          if (event.eventType === "EndOfTimeline") {
            setAddEndOfTimeline((event) => !event);
          }

          dispatch({
            type: "SET_EVENT_HOVERED",
            payload: event,
          });
        }}
        onMouseEnter={() => {
          if (editedEvent || actionEnabled) return;
          if (!selectedEvent) {
            dispatch({
              type: "SET_EVENT_HOVERED",
              payload: event,
            });
          }
        }}
        onMouseLeave={() => {
          if (editedEvent || actionEnabled) return;
          if (!selectedEvent) {
            dispatch({
              type: "SET_EVENT_HOVERED",
              payload: null,
            });
          }
        }}
        color={eventColor}
        translateX="-50%"
        translateY="-50%"
      >
        {currentEventIcon}
      </TimelineEventIndicator>
      <EventBoxRelative width={width}>
        {isAddingAtEndOfTimeline && !editedEvent && !actionEnabled ? (
          <EventBox
            width={42 * eventTypes.length}
            style={{
              marginTop: -15,
              marginLeft: 30,
            }}
          >
            {eventTypes
              ? eventTypes.map((crtEvent, index) => (
                  <EventIconSelector key={crtEvent?.type}>
                    <Tooltip placement="bottom" title={crtEvent?.type}>
                      <EventButton
                        onClick={() => {
                          Track.clickEvent("Select Event", {
                            "Event Type": crtEvent?.type,
                          });
                          setEditedEvent({
                            ...event,
                            type: crtEvent.type,
                          });
                        }}
                      >
                        {crtEvent.icon}
                      </EventButton>
                    </Tooltip>
                    {index < eventTypes.length - 1 ? <Separator /> : null}
                  </EventIconSelector>
                ))
              : null}
          </EventBox>
        ) : editedEvent &&
          isSameEvent({ event, eventCmp: editedEvent }) ? null : (
          <EditedEventContainer>
            {(() => {
              if (event.eventType === "PhaseEnd") return null;
              return (
                <Row justify="space-between">
                  <Col>
                    <StyledTitle
                      style={{
                        fontWeight: ![
                          TimelineEventType.Hazard,
                          TimelineEventType.Instruction,
                          TimelineEventType.Note,
                          TimelineEventType.Warning,
                        ].includes(event.type)
                          ? 500
                          : 400,
                        paddingBottom:
                          title === "Start of Well" ? "20px" : "0px",
                      }}
                    >
                      {event?.deletedAtUtc ? "Deleted " : ""}
                      {title}
                    </StyledTitle>
                  </Col>
                  <Col>
                    {user ? (
                      <EventHover
                        user={user}
                        event={event}
                        deleteEvent={deleteEvent}
                        disableHover={disableHover}
                        selectedTimeline={selectedTimeline}
                        restoreEvent={restoreEvent}
                        editEvent={editEvent}
                      />
                    ) : null}
                  </Col>
                </Row>
              );
            })()}
            {(() => {
              if (
                event.eventType === "EndOfTimeline" ||
                event.eventType === "PhaseEnd"
              )
                return null;
              return (
                <>
                  <Row gutter={2}>
                    <Col>
                      <StyledSubtitle>{subtitle ?? ""}</StyledSubtitle>
                    </Col>
                  </Row>
                  <StyledDescription $isDeactivated={!!event?.deletedAtUtc}>
                    {event.description}
                  </StyledDescription>
                </>
              );
            })()}
          </EditedEventContainer>
        )}
      </EventBoxRelative>
    </div>
  );
};
