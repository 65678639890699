import type { PivotType } from "apis/oag";
import type { IAction } from "reducers/types";

export interface LegendColors {
  colorById: Record<string, string[]>;
}

export const initialState: LegendColors = {
  colorById: {},
};

interface IAddLegendColors extends IAction {
  type: "ADD_COLORS_FOR_ID";
  payload: { keys: string[]; pivot: PivotType | "WedgeAlternative" };
}

interface IResetLegendColors extends IAction {
  type: "RESET_LEGEND_COLORS";
}

type AvailableActions = IAddLegendColors | IResetLegendColors;

export const AddColorsForIdsAction = (
  payload: IAddLegendColors["payload"],
): IAddLegendColors => ({
  type: "ADD_COLORS_FOR_ID",
  payload,
});

export const ResetLegendColorsAction = (): IResetLegendColors => ({
  type: "RESET_LEGEND_COLORS",
});

function legendColorReducer(
  state: LegendColors = initialState,
  action: AvailableActions,
): LegendColors {
  switch (action.type) {
    case "ADD_COLORS_FOR_ID": {
      const newState = state.colorById?.[action.payload.pivot] ?? [];
      action.payload.keys.forEach((key) => {
        if (!newState.includes(key)) {
          newState.push(key);
        }
      });
      return {
        ...state,
        colorById: {
          ...state.colorById,
          [action.payload.pivot]: newState,
        },
      };
    }
    case "RESET_LEGEND_COLORS": {
      return {
        colorById: {},
      };
    }
    default:
      return state;
  }
}

export default legendColorReducer;
