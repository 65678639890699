import styled from "styled-components";

export const FullHeight = styled.div`
  height: 100%;
  position: relative;
`;

export const SvgWithVisibleOverflow = styled.svg.attrs({
  height: "100%",
  width: "100%",
})`
  overflow: visible;
`;
