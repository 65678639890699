import { Loader } from "components/Loader";
import { useIntelLens } from "hooks/intel/useIntelLens";
import { useCurrentUserCanAccessIntel } from "hooks/user/useCurrentUser";
import { IntelHeader } from "pages/IntelDashboard/components/IntelHeader/IntelHeader";
import { IntelLegend } from "pages/IntelDashboard/components/IntelLegend/IntelLegend";
import { IntelRankingRibbon } from "pages/IntelDashboard/components/IntelRankingRibbon/IntelRankingRibbon";
import { IntelScatterPlot } from "pages/IntelDashboard/components/IntelScatterPlot/IntelScatterPlot";
import { LegendGroupSelector } from "pages/IntelDashboard/components/LegendGroupSelector/LegendGroupSelector";
import * as Styled from "pages/IntelDashboard/style";
import { Suspense, useMemo, useState } from "react";
import { useAppSelector } from "reducers/store";
import { usePageView } from "services/Mixpanel";
import { zIndexLayer } from "utils/zIndex";

import { Card } from "./components/IntelRankingRibbon/style";
import { IntelScorecard } from "./components/IntelScorecard/IntelScorecard";
import { IntelLegendColorsProvider } from "./useIntelLegendColors";

export const IntelDashboard = () => {
  usePageView("Intel");

  const { data } = useIntelLens();
  const [itemCount, setItemCount] = useState<number>();
  const selectedTab = useAppSelector(
    (state) => state.intelDashboard.groupingType,
  );

  const [scatterPlotLens, rankingRibbonLens, scorecardLens] = data;

  const legendStatusText = useMemo(() => {
    if (!selectedTab || itemCount === undefined) return "Loading...";
    return `Legend (${itemCount} ${selectedTab}s)`;
  }, [itemCount, selectedTab]);

  const canAccessIntel = useCurrentUserCanAccessIntel();
  return canAccessIntel ? (
    <IntelLegendColorsProvider>
      <IntelHeader legendStatusText={legendStatusText} />
      <LegendGroupSelector />
      <Styled.PageGrid>
        <Styled.LeftPane>
          <IntelLegend onItemCountChange={setItemCount} />
        </Styled.LeftPane>

        <Styled.RightPane>
          <Styled.InnerGrid>
            <Styled.ScatterPlotContainer>
              <Suspense
                fallback={<Loader centered zIndex={zIndexLayer.above} />}
              >
                {scatterPlotLens ? (
                  <IntelScatterPlot lens={scatterPlotLens} />
                ) : null}
              </Suspense>
            </Styled.ScatterPlotContainer>

            <Styled.RibbonContainer>
              <Card>
                <Suspense
                  fallback={<Loader centered zIndex={zIndexLayer.above} />}
                >
                  {rankingRibbonLens ? (
                    <IntelRankingRibbon lens={rankingRibbonLens} />
                  ) : null}
                </Suspense>
              </Card>
            </Styled.RibbonContainer>

            <Styled.ScorecardContainer>
              <Card>
                <Suspense
                  fallback={<Loader centered zIndex={zIndexLayer.above} />}
                >
                  {scorecardLens ? (
                    <IntelScorecard lens={scorecardLens} />
                  ) : null}
                </Suspense>
              </Card>
            </Styled.ScorecardContainer>
          </Styled.InnerGrid>
        </Styled.RightPane>
      </Styled.PageGrid>
    </IntelLegendColorsProvider>
  ) : (
    <span>Access forbidden</span>
  );
};
