import styled from "styled-components";
import colors from "utils/colors";
import { PDConstants } from "utils/PDConstants/PDConstants";

export const NEXT = "NEXT";
export const PREV = "PREV";

export const Item = styled.div<{ $img: string }>`
  text-align: center;
  background-image: ${(props) => `url(${props.$img})`};
  background-size: cover;
`;

export const ImageItem = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const VideoItem = styled.div`
  pointer-events: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
`;

export const CarouselContainer = styled.div<{
  $sliding: boolean;
  $dir: string;
  $position: number;
}>`
  display: flex;
  height: calc(100% - ${PDConstants.SizesPx.Carousel.Topbar.height.asPixels()});
  transition: transform 1s ease;
  transform: ${(props) => {
    return `translateX(${props.$position * -100}%)`;
  }};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const PrevButton = styled.button`
  width: 45px;
  height: 45px;
  position: absolute;
  left: 20px;
  top: calc(50% - 29px);
  transform: translateY(-50%);
  background-color: ${colors.revolver};
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  color: ${colors.white};
`;

export const NextButton = styled(PrevButton)`
  left: auto;
  right: 20px;
  color: ${colors.white};
`;

export const CarouselSlot = styled.div<{ $numItems: number }>`
  position: relative;
  flex: 1 0 100%;
  min-width: ${(props) => 100 / props.$numItems}%;
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  display: flex;
  align-items: center;
`;

export const AttachmentsFooter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: ". dots download";
  justify-content: center;
  align-items: center;
  height: ${PDConstants.SizesPx.Carousel.Topbar.height.asPixels()};
  background: linear-gradient(180deg, rgba(27, 27, 31, 0) 0%, #1b1b1f 100%);
`;

export const DotsGridSlot = styled.div`
  grid-area: dots;
  display: flex;
  justify-content: center;
`;

export const DownloadGridSlot = styled.div`
  grid-area: download;
  display: flex;
  justify-content: flex-end;
`;

export const DotsContainer = styled.div`
  display: inline-flex;
  background-color: ${colors.revolver};
  padding: 8px;
  gap: 8px;
  border-radius: 24px;
  height: 24px;
`;

export const Dot = styled.div<{ $selected: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $selected }) =>
    $selected ? colors.white : colors.gray};
`;

export const DownloadIconContainer = styled.div`
  margin-left: auto;
  &:hover {
    filter: brightness(200%);
  }
  cursor: pointer;
  padding: 4px;
  margin-right: 35px;
  color: ${colors.white};
  font-size: 22px;
`;
