/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AttachmentType = {
    Unknown: 'Unknown',
    Image: 'Image',
    Video: 'Video',
    Audio: 'Audio'
} as const;
export type AttachmentType = typeof AttachmentType[keyof typeof AttachmentType];


export function instanceOfAttachmentType(value: any): boolean {
    for (const key in AttachmentType) {
        if (Object.prototype.hasOwnProperty.call(AttachmentType, key)) {
            if (AttachmentType[key as keyof typeof AttachmentType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AttachmentTypeFromJSON(json: any): AttachmentType {
    return AttachmentTypeFromJSONTyped(json, false);
}

export function AttachmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentType {
    return json as AttachmentType;
}

export function AttachmentTypeToJSON(value?: AttachmentType | null): any {
    return value as any;
}

