import { SelectorButton } from "components/RigsHeader/SelectorButton";
import { useIntelFilterActions } from "hooks/filters/useIntelFilterActions";
import { useOnWindowScroll } from "hooks/react-utils/useOnWindowScroll";
import type { IconName } from "icons/autogeneratedTypes";
import { startTransition, useCallback, useMemo, useState } from "react";
import type { SimpleFilterVisibleKeys } from "reducers/intelFiltersReducerUncommitted";
import { useAppSelector } from "reducers/store";

export const SimpleFilterSelector = ({
  storeKey,
  displayName,
  listOptions,
  iconName,
  disabled,
}: {
  storeKey: SimpleFilterVisibleKeys;
  displayName: string;
  listOptions: string[] | null | undefined;
  iconName: IconName;
  disabled: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { commitFilterKey, setFilterKey, resetFilterKey } =
    useIntelFilterActions();

  useOnWindowScroll(() => setIsOpen(false));

  const uncommittedFilters = useAppSelector(
    (state) => state.intelFiltersUncommitted,
  );

  const selectedOptions = useMemo(
    () => uncommittedFilters[storeKey],
    [storeKey, uncommittedFilters],
  );

  const committedSelectedOptions = useAppSelector(
    (state) => state.intelFiltersCommitted[storeKey],
  );

  const { removeFilterKey } = useIntelFilterActions();

  const selectedValues = useMemo(() => {
    return selectedOptions ?? listOptions ?? [];
  }, [listOptions, selectedOptions]);

  const options = useMemo(
    () =>
      (listOptions ?? []).map((optionString) => {
        return {
          id: optionString,
          name: optionString,
        };
      }),
    [listOptions],
  );

  const handleOnApply = useCallback(
    (receivedOptions: (number | string)[]) => {
      setIsOpen(false);

      startTransition(() => {
        const newFilterValues =
          receivedOptions.length === listOptions?.length
            ? null
            : receivedOptions;

        commitFilterKey(storeKey, newFilterValues as string[]);
      });
    },
    [commitFilterKey, listOptions?.length, storeKey],
  );

  const handleOnOptionsChange = useCallback(
    (receivedOptions: (number | string)[]) => {
      startTransition(() => {
        const areAllSelected = listOptions?.length === receivedOptions.length;

        if (areAllSelected) {
          resetFilterKey(storeKey);
        } else {
          setFilterKey(storeKey, receivedOptions as string[]);
        }
      });
    },
    [listOptions?.length, resetFilterKey, setFilterKey, storeKey],
  );

  const handleOnReset = useCallback(() => {
    startTransition(() => {
      setIsOpen(false);
      removeFilterKey(storeKey);
    });
  }, [removeFilterKey, storeKey]);

  return (
    <SelectorButton
      disabled={disabled}
      disableIfNoOptions={true}
      showResetOnlyIfNoOptions={true}
      key={storeKey}
      onReset={handleOnReset}
      options={options}
      placeholder={`Search ${displayName}`}
      selectedValues={selectedValues}
      onChange={handleOnApply}
      onOptionsChange={handleOnOptionsChange}
      open={isOpen}
      onOpenChange={setIsOpen}
      isSelected={committedSelectedOptions !== null}
      tooltipName={displayName}
      name={iconName}
    />
  );
};
