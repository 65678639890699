/* eslint-disable react/no-multi-comp */
import { PDComponent } from "components/PDComponents";
import styled, { css } from "styled-components";
import { Col } from "utils/componentLibrary";
import { PDConstants } from "utils/PDConstants/PDConstants";

const noScrollCommonStyle = css`
  overflow: hidden scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NoScrollCol = styled(Col)<{
  hz: number;
  $isReport: boolean;
  $isReportTvD: boolean;
}>`
  height: calc(
    100vh - ${(props) => props.hz}px -
      ${(props) =>
        props.$isReport
          ? "0px"
          : `${PDConstants.SizesPx.Layout.TopBarHeight.asPixels()} - 1px`}
  );
  ${noScrollCommonStyle}
`;

export const NoScrollColWithHints = styled(PDComponent.ScrollHintContainer)<{
  hz: number;
}>`
  height: calc(
    100vh - ${(props) => props.hz}px -
      ${PDConstants.SizesPx.Layout.TopBarHeight.asPixels()} - 1px
  );
  width: 100%;
  ${noScrollCommonStyle}
`;
