import { _Amounts } from "utils/PDConstants/Amounts";
import { _Collections } from "utils/PDConstants/Collections";
import { _Format } from "utils/PDConstants/Format";
import { _SizesPx } from "utils/PDConstants/SizesPx";
import { _uniqueDomIds } from "utils/PDConstants/uniqueDomIds";

import { _RegExp } from "./RegExp";

export const PDConstants = {
  RegExp: _RegExp,
  SizesPx: _SizesPx,
  Amounts: _Amounts,
  Collections: _Collections,
  Format: _Format,
  uniqueDomIds: _uniqueDomIds,
};
