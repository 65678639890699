import { ErrorBoundary } from "@sentry/react";
import type {
  ParameterByDepthUserLensDto,
  ParameterByDepthUserLensTrackItemDto,
  UserLensDto,
  VisualAidType,
} from "apis/oag";
import { DimensionType, WellStatusType } from "apis/oag";
import DetailsTopBar from "components/DetailsTopBar";
import { getContainerLens } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { Loader } from "components/Loader";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { useKpiOptions } from "hooks/drillingInvariants/useKpiOptions";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import { useUserLenses } from "hooks/lens/useUserLenses";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useWellShortInfo } from "hooks/wells/useWellShortInfo";
import { LensFallback } from "pages/Lens/LensFallback";
import LensLegendView from "pages/Lens/LensLegendView";
import { ChartContainer, StyledColTransition } from "pages/Lens/style";
import { useResetOptions } from "pages/Lens/useResetOptions";
import { RigLegend } from "pages/RigDashboard/RigLegend";
import { Suspense, useEffect, useMemo, useState } from "react";
import { usePageVisibility } from "react-page-visibility";
import { Navigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { Row } from "utils/componentLibrary";
import {
  SIDEBAR_DEFAULT_WIDTH_PERCENT,
  SIDEBAR_MAX_WIDTH,
  SIDEBAR_MIN_WIDTH,
} from "utils/constants";
import { getErrorHashCode } from "utils/getErrorHashCode";
import { useColors, useResetRigColors } from "utils/useColors";

const LegendWidth = `clamp(${SIDEBAR_MIN_WIDTH}px, ${SIDEBAR_DEFAULT_WIDTH_PERCENT}%, ${SIDEBAR_MAX_WIDTH}px)`;

export type MaybeKPiTypeUserLens = UserLensDto & {
  showsOutliers?: boolean;
  squeezesDisplay?: boolean;
  selectedVisualAids?: VisualAidType[];
};

const Lens = () => {
  const dispatch = useAppDispatch();
  const { isRig: isRigDashboard, isWell: isWellDashboard } = useDashboardType();
  const { data: wells } = useWellShortInfo();
  const [crtRealTime, setRealTimeData] = useStateQuery<RealTimeDataEnum>(
    URL_STATE_PARAM.REALTIME_DATA_WIDGET,
    RealTimeDataEnum.UNAVAILABLE,
  );

  const [comparisonWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );

  useEffect(() => {
    if (crtRealTime !== RealTimeDataEnum.UNAVAILABLE) return;
    if (!isRigDashboard || !wells?.list) return;
    if (wells?.list.some((w) => w.status === WellStatusType.Active))
      setRealTimeData(RealTimeDataEnum.ACTIVE);
    else setRealTimeData(RealTimeDataEnum.UNAVAILABLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRigDashboard, wells?.list]);

  const {
    dashboardType: dt,
    dashboardId: dId,
    lensId,
  } = useParams<{
    dashboardType: string;
    dashboardId: string;
    lensId: string;
  }>();
  const lensIdNumber = parseInt(lensId || "-1", 10);
  const dashboardId = Number(dId);

  useEffect(() => {
    if (isWellDashboard) {
      dispatch({
        type: "SET_SELECTED_WELL",
        payload: {
          well: dashboardId,
        },
      });
    } else {
      dispatch({
        type: "SET_SELECTED_RIG",
        payload: dashboardId,
      });
    }
  }, [dispatch, isWellDashboard, dashboardId]);
  const { registerKeys } = useColors();

  useEffect(() => {
    if (isWellDashboard) {
      const offsetKeys = comparisonWells.map((key) => key.toString());
      registerKeys({
        keys: [dashboardId.toString(), ...offsetKeys],
      });
    }
  }, [registerKeys, comparisonWells, isWellDashboard, dashboardId]);

  useResetRigColors(null, isRigDashboard);

  const color = useAppSelector((state) => state.state.focalColor);

  const displayOption = useAppSelector(
    (state) => state.widgetOptions.display_options,
  );
  const legendVisibilityState = useAppSelector(
    (state) => state.widgetOptions.legendVisible,
  );

  const { data: lenses } = useUserLenses({ refetchOnMount: false });
  const { data: templates } = useLensTemplates({ refetchOnMount: false });
  const { data: kpiTypes } = useKpiTypes({ refetchOnMount: false });

  useResetOptions();

  useEffect(() => {
    dispatch({
      type: "SET_OFFSET_WELLS_WIDGET",
      payload: {
        wells: comparisonWells,
      },
    });
  }, [comparisonWells, dispatch]);
  const [localLens, setLocalLens] = useState(lenses.byId[lensIdNumber]);

  useEffect(() => {
    setLocalLens(lenses.byId[lensIdNumber]);
    dispatch({
      type: "SET_DISPLAYED_LENS",
      payload: [lenses.byId[lensIdNumber]],
    });
  }, [dispatch, lensIdNumber, lenses.byId]);
  const templateId = localLens?.lensTemplateId;
  const template = templateId ? templates.byId[templateId] : null;
  const { kpiType, kpiGroup } = useKpiOptions(localLens);
  const isPageVisible = usePageVisibility();
  const [showLens, setShowLens] = useState(false);

  const [selectedTrackItems, setSelectedTrackItems] = useState<
    ParameterByDepthUserLensTrackItemDto[]
  >([]);

  useEffect(() => {
    if (
      selectedTrackItems.length === 0 &&
      (localLens as ParameterByDepthUserLensDto)?.userLensTrackItems
    ) {
      setSelectedTrackItems(
        (
          (localLens as ParameterByDepthUserLensDto).userLensTrackItems ?? []
        ).sort((a, b) => a.position - b.position),
      );
    }
  }, [selectedTrackItems.length, localLens]);

  useEffect(() => {
    setShowLens((e) => isPageVisible || e);
  }, [isPageVisible]);
  const dimension: DimensionType = useMemo(() => {
    if (kpiType) {
      return kpiType.dimension;
    }

    if (kpiGroup && kpiTypes?.byId) {
      return kpiTypes.byId[kpiGroup.kpiTypeIds[0]].dimension;
    }

    return DimensionType.Seconds;
  }, [kpiType, kpiGroup, kpiTypes?.byId]);

  if (!templateId) {
    window.location.href = "/auth/404";
    return null;
  }

  if (!Number.isFinite(lensIdNumber)) {
    return <Navigate to={`/${dt}/${dashboardId}`} />;
  }

  const ContainerComponentInfo = template
    ? getContainerLens(template.type)
    : null;
  const Component = ContainerComponentInfo ? (
    <ContainerComponentInfo.Component
      lens={localLens}
      detailed
      dimension={dimension}
      displayOption={displayOption}
      focalWellColor={color}
      selectedTrackItems={selectedTrackItems}
      setSelectedTrackItems={setSelectedTrackItems}
      onLensUpdated={(newLens: UserLensDto) => {
        setLocalLens(newLens);
      }}
    />
  ) : null;

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100%",
        backgroundColor: colors.actions_bg,
      }}
    >
      <Row
        justify="space-between"
        wrap={false}
        style={{
          height: "100%",
        }}
      >
        <StyledColTransition
          flex={
            legendVisibilityState
              ? `1 0 calc(100% - ${LegendWidth})`
              : "1 0 100%"
          }
          width={legendVisibilityState ? `calc(100% - ${LegendWidth})` : "100%"}
        >
          <div>
            <Suspense fallback={null}>
              {template ? (
                <DetailsTopBar
                  isSystem={localLens?.isSystem}
                  lensUpdater={ContainerComponentInfo?.lensUpdater}
                  trackingProps={{
                    "Lens Name": template?.name,
                  }}
                  template={template}
                  lens={localLens}
                  onLensUpdated={setLocalLens}
                />
              ) : null}
            </Suspense>
          </div>
          <ChartContainer>
            {showLens ? (
              <div
                style={{
                  flexGrow: 1,
                  height: 0,
                }}
              >
                <Suspense
                  fallback={
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <Loader />
                    </div>
                  }
                >
                  <ErrorBoundary
                    beforeCapture={(scope, error) => {
                      scope.setTag("lensId", localLens.id);
                      scope.setTag(
                        "pd-error-tracking-id",
                        getErrorHashCode(error?.stack ?? ""),
                      );
                    }}
                    fallback={({ resetError }) => (
                      <LensFallback
                        resetError={resetError}
                        lensId={localLens.id}
                      />
                    )}
                  >
                    {Component}
                  </ErrorBoundary>
                </Suspense>
              </div>
            ) : null}
          </ChartContainer>
        </StyledColTransition>
        <StyledColTransition flex={`0 0 ${LegendWidth}`}>
          <Suspense
            fallback={
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Loader />
              </div>
            }
          >
            {isRigDashboard ? (
              <RigLegend />
            ) : (
              <>{template ? <LensLegendView template={template} /> : null}</>
            )}
          </Suspense>
        </StyledColTransition>
      </Row>
    </div>
  );
};

export default Lens;
