import { DimensionType } from "apis/oag";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { useIntelFilterActions } from "hooks/filters/useIntelFilterActions";
import { useOnWindowScroll } from "hooks/react-utils/useOnWindowScroll";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reducers/store";
import { Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { useUOM } from "utils/format";

import { DepthInput } from "./DepthInput";
import * as Styled from "./style";
import { inputToNumber, numberToInput } from "./utils";

export const DepthFilter = () => {
  const [isFilterPopupVisible, setIsFilterPopupVisible] = useState(false);
  useOnWindowScroll(() => setIsFilterPopupVisible(false));

  const { commitFilterKey, removeFilterKey } = useIntelFilterActions();

  const [maxMeasuredDepth, minMeasuredDepth] = useAppSelector(
    ({ intelFiltersCommitted }) => [
      intelFiltersCommitted.maxMeasuredDepth,
      intelFiltersCommitted.minMeasuredDepth,
    ],
  );

  const isFilterApplied = useMemo(() => {
    return maxMeasuredDepth !== null || minMeasuredDepth !== null;
  }, [maxMeasuredDepth, minMeasuredDepth]);

  const depthUOM = useUOM(DimensionType.Metres);

  const [minDepth, setMinDepth] = useState<string>(
    numberToInput(minMeasuredDepth, depthUOM),
  );
  const [maxDepth, setMaxDepth] = useState<string>(
    numberToInput(maxMeasuredDepth, depthUOM),
  );

  useEffect(() => {
    setMinDepth(numberToInput(minMeasuredDepth, depthUOM));
    setMaxDepth(numberToInput(maxMeasuredDepth, depthUOM));
  }, [depthUOM, minMeasuredDepth, maxMeasuredDepth]);

  const onApply = useCallback(() => {
    setIsFilterPopupVisible(false);
    if (inputToNumber(maxDepth, depthUOM) === null) {
      removeFilterKey("maxMeasuredDepth");
    } else {
      commitFilterKey("maxMeasuredDepth", inputToNumber(maxDepth, depthUOM));
    }
    if (inputToNumber(minDepth, depthUOM) === null) {
      removeFilterKey("minMeasuredDepth");
    } else {
      commitFilterKey("minMeasuredDepth", inputToNumber(minDepth, depthUOM));
    }
  }, [commitFilterKey, removeFilterKey, depthUOM, minDepth, maxDepth]);

  const reset = useCallback(() => {
    setIsFilterPopupVisible(false);
    setMinDepth("");
    setMaxDepth("");
    removeFilterKey("maxMeasuredDepth");
    removeFilterKey("minMeasuredDepth");
  }, [removeFilterKey]);

  const minDepthError = useMemo(() => {
    if (minDepth === "" || maxDepth === "") {
      return;
    }
    if (Number(minDepth) > Number(maxDepth)) {
      return "Minimum depth cannot be greater than maximum depth";
    }
  }, [minDepth, maxDepth]);

  const maxDepthError = useMemo(() => {
    if (maxDepth === "") {
      return;
    }
    if (Number(minDepth) > Number(maxDepth)) {
      return "Maximum depth cannot be smaller than minimum depth";
    }
  }, [minDepth, maxDepth]);

  const popoverContent = useMemo(() => {
    return (
      <Styled.DepthContainer>
        <Space direction="vertical" size="small">
          <Title level={3} weight={700}>
            Measured Depth Range
          </Title>
          <Row align="middle" justify="space-between">
            <Title level={3}>Exclude values above </Title>
            <DepthInput
              placeholder="Max"
              error={maxDepthError}
              onChange={setMaxDepth}
              value={maxDepth}
            />
          </Row>
          <Row align="middle" justify="space-between">
            <Title level={3}>Exclude values below </Title>
            <DepthInput
              placeholder="Min"
              error={minDepthError}
              onChange={setMinDepth}
              value={minDepth}
            />
          </Row>
        </Space>
        <Styled.ButtonContainer>
          {minMeasuredDepth || maxMeasuredDepth ? (
            <Button size="large" type="default" onClick={reset}>
              Remove
            </Button>
          ) : null}
          <Button
            disabled={
              !!(minDepthError || maxDepthError) || (!minDepth && !maxDepth)
            }
            size="large"
            type="primary"
            onClick={onApply}
          >
            Apply
          </Button>
        </Styled.ButtonContainer>
      </Styled.DepthContainer>
    );
  }, [
    maxDepth,
    minDepth,
    maxDepthError,
    minDepthError,
    minMeasuredDepth,
    maxMeasuredDepth,
    onApply,
    reset,
  ]);

  return (
    <Tooltip title="Depth Filter">
      <Popover
        content={popoverContent}
        trigger={["click", "scroll"]}
        placement="bottom"
        open={isFilterPopupVisible}
        onOpenChange={(e) => setIsFilterPopupVisible(e)}
        destroyTooltipOnHide
      >
        <Button
          size="large"
          icon={<PDComponent.SvgIcon name="depthFilter" />}
          onClick={() => setIsFilterPopupVisible(!isFilterPopupVisible)}
          $engaged={isFilterPopupVisible}
          type={isFilterApplied ? "primary" : "default"}
          ghost={isFilterApplied}
        />
      </Popover>
    </Tooltip>
  );
};
