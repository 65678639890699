import { useEffect } from "react";

export const useOnWindowScroll = (fn: () => void) => {
  useEffect(() => {
    const onScroll = () => {
      fn();
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [fn]);
};
