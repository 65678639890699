/* eslint-disable react/forbid-dom-props */
import { useIsFetching } from "@tanstack/react-query";
import { AxisBottom, AxisRight } from "@visx/axis";
import { Brush } from "@visx/brush";
import type { Bounds } from "@visx/brush/lib/types";
import { localPoint } from "@visx/event";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Bar, LinePath } from "@visx/shape";
import type {
  ActualTvdPointDto,
  ActualTvdSeriesDto,
  PlanTvdPointDto,
  PlanTvdSeriesDto,
  RawTvdSeriesDto,
} from "apis/oag";
import { ResultDataState, TimelineEventType, WellStatusType } from "apis/oag";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import {
  getSVGNormalizedValue,
  selectedBrushStyle,
} from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { eventIcon } from "components/Timeline/utils";
import { DigestTimelineEvents } from "components/TvDChart/components/DigestTimelineEvents/DigestTimelineEvents";
import { convertDigestTimelineToCombinedEvents } from "components/TvDChart/components/DigestTimelineEvents/utils";
import {
  AddIconWrapper,
  AxisIndicator,
  BitDepthIndicator,
  EndOfWellIndicator,
} from "components/TvDChart/components/Icons";
import { PlannedFutureEvents } from "components/TvDChart/components/PlannedFutureEvents/Index";
import { Sections } from "components/TvDChart/components/Sections";
import { TimelineEventIndicator } from "components/TvDChart/components/TimelineEventIndicator/TimelineEventIndicator";
import { usePhasesAndIntervalsToSegments } from "components/TvDChart/components/WellSegmentBand/segmentDefinitions";
import { WellSegmentBand } from "components/TvDChart/components/WellSegmentBand/WellSegmentBand";
import {
  LEFT_PADDING,
  LEFT_PADDING_SECTIONS,
  MAX_DEPTH_LABELS_COUNT,
  MAX_GAP,
  RIGHT_AXIS_PADDING,
} from "components/TvDChart/constants";
import type {
  BitIndicatorProps,
  ICombinedEvents,
  IndicatorPosition,
  ITvDCurveData,
  TCallbacks,
  TState,
} from "components/TvDChart/types";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import {
  convertDateToDuration,
  convertDurationToDate,
  DatepickDateFormat,
  isBetween,
  useZoomModeLabel,
} from "components/WellDashboard/ControlHeader/atoms/Zoom/utils";
import { TEMP_BRUSH_FIX_CONST } from "components/WellDashboard/ControlHeader/atoms/Zoom/ZoomSvg";
import { bisector, max, min } from "d3-array";
import useDiscontinuousTimeAxis from "hooks/charting/useDiscontinuousTimeAxis";
import { useDashboardType } from "hooks/dashboard/useDashboardType";
import { usePreviousNonNullValue } from "hooks/react-utils/usePreviousNonNullValue";
import { isArray, isEqual } from "lodash";
import { SetterEnum } from "pages/WellDashboard/types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useResizeDetector } from "react-resize-detector";
import { IndicatorsState } from "reducers/stateReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { CurvesEnum, EventsEnum, IZoomType } from "reducers/types";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { PLAN_SERIES_ID, secondsInDay } from "utils/common";
import { Tooltip, Typography } from "utils/componentLibrary";
import type { ITimelineEventList } from "utils/eventUtils";
import { formatTime } from "utils/helper";
import { PDQueryType } from "utils/queryNamespaces";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

const OVERRIDE_TIME_AXIS_LABEL_SIZE = 120;
const CHART_BOTTOM_MARGIN = 10;

type TvDPointWithGap = ActualTvdPointDto & { isInGap?: boolean };
export const TvDChart = ({
  callbacks,
  data,
  isReport,
  state,
}: {
  callbacks: TCallbacks;
  data?: RawTvdSeriesDto | null;
  isReport?: boolean;
  state: TState;
}) => {
  const {
    actionEnabledCB,
    setHoveredEventTimeline,
    setHoveredSeries,
    setRealtimeData,
    setRealtimeDataWidget,
    setSelectedSeries,
    setTimelineOverride,
    setTimelineState,
    setZoomState,
  } = callbacks;
  const {
    actionEnabled,
    actualTimelineEventsList,
    depthNormalized,
    depthTransformer,
    depthUOM,
    displayOptions,
    editedEvent,
    hoveredEvent,
    hoveredSeriesTvd,
    isLoading,
    futurePlanEvents,
    offsetSelection,
    planTimelineEventsList,
    realTimeDataState,
    realTimeDataStateWidget,
    selectedIndicators,
    selectedSeries,
    selectedWellDetails,
    timelineOverride,
    timeline_state,
    wellDetails,
    wellId,
    zoomState,
    planActive,
    offsetWellsActive,
    actualActive,
    digestTimelineMemos,
  } = state;
  const brushRef = useRef(null);
  const brushActiveRef = useRef<boolean | null>(null);

  const { getColor } = useColors();
  const onBrushChange = (bounds: Bounds | null) => {
    if (bounds) {
      // A hack until we figure out what causes negative bounds yielded by brush
      bounds.x0 += xScale.invert(TEMP_BRUSH_FIX_CONST);
      bounds.x1 -= xScale.invert(TEMP_BRUSH_FIX_CONST);

      const newZoomValues: IZoomData = {
        ...zoomState,
        ts_start:
          zoomState.type === IZoomType.DEPTH
            ? zoomState.ts_start
            : bounds.x0 * secondsInDay,
        ts_end:
          zoomState.type === IZoomType.DEPTH
            ? zoomState.ts_end
            : bounds.x1 * secondsInDay,
        depth_start:
          zoomState.type === IZoomType.DEPTH
            ? bounds.y0
            : zoomState.depth_start,
        depth_end:
          zoomState.type === IZoomType.DEPTH ? bounds.y1 : zoomState.depth_end,
        internal_zoom: true,
      };

      if (zoomState?.ts_start < 0) {
        const dateAgo = convertDurationToDate(data?.tvdSeries?.series || [])(
          zoomState?.ts_start,
        );
        const newStartTime = convertDateToDuration(
          data?.tvdSeries?.series || [],
        )(new Date(dateAgo));
        zoomState.ts_start = newStartTime;
        newZoomValues.type = IZoomType.DATE;
      }

      newZoomValues.date_start =
        zoomState.type === IZoomType.DEPTH ||
        !data?.tvdSeries?.series ||
        !newZoomValues.ts_start
          ? newZoomValues.date_start
          : new Date(
              convertDurationToDate(data?.tvdSeries?.series)(
                (zoomState?.ts_start ?? 0) + Math.trunc(newZoomValues.ts_start),
              ),
            );
      newZoomValues.date_end =
        zoomState.type === IZoomType.DEPTH || !data?.tvdSeries?.series
          ? newZoomValues.date_end
          : new Date(
              convertDurationToDate(data?.tvdSeries?.series)(
                (zoomState?.ts_start ?? 0) +
                  Math.trunc(Math.max(newZoomValues.ts_end || 0, 0)),
              ),
            );

      newZoomValues.ts_start =
        zoomState.type === IZoomType.DEPTH
          ? 0
          : (zoomState?.ts_start ?? 0) +
            Math.trunc(Math.max(newZoomValues.ts_start, 0));
      newZoomValues.ts_end =
        zoomState.type === IZoomType.DEPTH
          ? null
          : (zoomState?.ts_start ?? 0) +
            Math.trunc(Math.max(newZoomValues.ts_end || 0, 0));

      newZoomValues.depth_start =
        zoomState.type === IZoomType.DEPTH
          ? Math.trunc(Math.max(newZoomValues.depth_start, 0))
          : 0;
      newZoomValues.depth_end =
        zoomState.type === IZoomType.DEPTH
          ? Math.trunc(Math.max(newZoomValues.depth_end || 0, 0))
          : null;

      Track.interact("TVD - Zoom", {
        action: "Drag",
        date_end: newZoomValues.date_end,
        date_start: newZoomValues.date_start,
      });

      setHoveredEventTimeline(null);
      setHoveredEventTvD(null);

      resetSelectedEvent();
      setZoomState({
        ...newZoomValues,
        internal_zoom: true,
      });
    }
    brushActiveRef.current = false;
  };
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  const {
    width: chartWidthHook,
    height: chartHeightHook,
    ref: containerRef,
  } = useResizeDetector();
  const { chartWidth: containerWidth, chartHeight: containerHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const [bitDepthIndicator, setBitDepthIndicator] =
    useState<BitIndicatorProps | null>(null);
  const [crossHairPosition, setCrossHairPosition] =
    useState<IndicatorPosition | null>(null);
  const [endWellIndicator, setEndWellIndicator] =
    useState<IndicatorPosition | null>(null);
  const [hoveredEventTvD, setHoveredEventTvD] = useState<
    ICombinedEvents | null | undefined
  >(null);
  const [seriesAggregatedData, setSeriesAggregatedData] = useState<
    Array<ITvDCurveData>
  >([]);
  const bitDepthActive =
    displayOptions.curves === null ||
    displayOptions.curves.includes(CurvesEnum.BIT_DEPTH);
  const prevOffsetWells = useRef(offsetSelection);
  const prevSelectedWell = useRef(wellId);
  const getDynamicDurationInDays = (d: { dynamicDuration?: number }) =>
    (d?.dynamicDuration ?? 0) / secondsInDay;
  const getCumulativeDurationInDays = (d: { cumulativeDuration?: number }) =>
    Math.trunc(((d?.cumulativeDuration ?? 0) / secondsInDay) * 100) / 100;
  const getHoleDepth = (d: { holeDepth?: number | null }) => d?.holeDepth;
  const getBitDepth = (d: { bitDepth?: number | null }) => d?.bitDepth;

  const getDepth = useCallback(
    (d: { dynamicHoleDepth: number | null; holeDepth: number | null }) =>
      depthNormalized ? d?.dynamicHoleDepth : d?.holeDepth,
    [depthNormalized],
  );
  const getDate = (d: ActualTvdPointDto) =>
    d ? formatTime(d?.at, { formatStr: "MM/D/YY HH:mm:ss" }) : "";
  const getHoleDepthDisplay = useCallback(
    (d: { holeDepth?: number | null }) => depthUOM.display(d?.holeDepth ?? 0),
    [depthUOM],
  );
  const getBitDepthDisplay = useCallback(
    (d: ActualTvdPointDto) =>
      getBitDepth(d) !== undefined ? depthUOM.display(getBitDepth(d)) : null,
    [depthUOM],
  );
  const xMax = useMemo(
    () =>
      max(
        seriesAggregatedData?.flatMap((e) => e.data) ?? [],
        getDynamicDurationInDays,
      ),
    [seriesAggregatedData],
  );

  const yMax = useMemo(
    () => max(seriesAggregatedData?.flatMap((e) => e.data) ?? [], getDepth),
    [getDepth, seriesAggregatedData],
  );

  const xMinCumulative = useMemo(
    () =>
      min(
        seriesAggregatedData?.flatMap((e) => e.data) ?? [],
        (i) => i.cumulativeDuration,
      ) ?? 0,
    [seriesAggregatedData],
  );

  const yMinDepth = useMemo(
    () =>
      Math.min(
        min(seriesAggregatedData?.flatMap((e) => e.data) ?? [], getHoleDepth) ||
          0,
        (data?.tvdSeries?.series || data?.comparisonTvdSeries) && bitDepthActive
          ? min(
              [
                ...(data?.tvdSeries?.series ?? []),
                ...(data?.comparisonTvdSeries ?? []).flatMap(
                  (tvdSeries) => tvdSeries.series ?? [],
                ),
              ],
              getBitDepth,
            ) || 0
          : Infinity,
      ),
    [
      bitDepthActive,
      data?.comparisonTvdSeries,
      data?.tvdSeries?.series,
      seriesAggregatedData,
    ],
  );

  const xMaxCumulative = useMemo(
    () =>
      max(
        seriesAggregatedData?.flatMap((e) => e.data) ?? [],
        (i) => i.cumulativeDuration,
      ) ?? 0,
    [seriesAggregatedData],
  );

  const yMaxDepth = useMemo(
    () => max(seriesAggregatedData?.flatMap((e) => e.data) ?? [], getHoleDepth),
    [seriesAggregatedData],
  );
  const showEvents =
    data &&
    ((planActive && data?.planTvdSeries?.dataState === "Valid") ||
      (actualActive && data?.tvdSeries?.dataState === "Valid"));

  const showPlannedFutureEvents =
    data &&
    selectedSeries === wellId &&
    data?.planTvdSeries?.dataState === "Valid";

  const selectedSidebarTimelineTab = useAppSelector(
    (state) => state.state.timeline_state,
  );

  const { isWell } = useDashboardType();

  const showDigestTimelineEvents = useMemo(() => {
    return (
      isWell &&
      data &&
      (selectedSidebarTimelineTab === "ActualWeb" ||
        selectedSidebarTimelineTab === "ActualMemos" ||
        selectedSidebarTimelineTab === "Legend") &&
      selectedSeries === wellId &&
      data?.tvdSeries?.dataState === ResultDataState.Valid &&
      digestTimelineMemos.dataState === ResultDataState.Valid
    );
  }, [
    data,
    digestTimelineMemos.dataState,
    isWell,
    selectedSeries,
    selectedSidebarTimelineTab,
    wellId,
  ]);

  const getEventDynamicValues = useCallback(
    ({
      event,
      series,
    }: {
      event: Partial<ITimelineEventList>;
      series: ActualTvdSeriesDto | PlanTvdSeriesDto | null | undefined;
    }) => {
      if (!series?.series || !event?.cumulativeDuration)
        return {
          ...event,
          dynamicHoleDepth: 0 as number | null | undefined,
          dynamicDuration: 0,
        };
      const dynamicPosition = series.series.findIndex(
        (ev: ActualTvdPointDto | PlanTvdPointDto) =>
          ev.cumulativeDuration >= (event.cumulativeDuration || 0),
      );
      if (dynamicPosition > 0) {
        if (
          series.series[dynamicPosition - 1]?.cumulativeDuration &&
          series.series[dynamicPosition]?.cumulativeDuration
        ) {
          const percent =
            (event.cumulativeDuration -
              series.series[dynamicPosition - 1].cumulativeDuration) /
            (series.series[dynamicPosition].cumulativeDuration -
              series.series[dynamicPosition - 1].cumulativeDuration);
          const calculatedDepth =
            (series.series[dynamicPosition - 1][
              depthNormalized ? "dynamicHoleDepth" : "holeDepth"
            ] ?? 0) +
            percent *
              ((series.series[dynamicPosition][
                depthNormalized ? "dynamicHoleDepth" : "holeDepth"
              ] ?? 0) -
                (series.series[dynamicPosition - 1][
                  depthNormalized ? "dynamicHoleDepth" : "holeDepth"
                ] ?? 0));
          const dynamicHoleDepth =
            calculatedDepth ||
            series.series.find(
              (x) =>
                x.cumulativeDuration >= (event.cumulativeDuration || 0) &&
                x.holeDepth !== undefined,
            )?.dynamicHoleDepth;
          return {
            ...event,
            dynamicDuration:
              series.series[dynamicPosition - 1].dynamicDuration +
              percent *
                (series.series[dynamicPosition].dynamicDuration -
                  series.series[dynamicPosition - 1].dynamicDuration),
            dynamicHoleDepth,
          };
        }
      } else {
        if (
          event?.cumulativeDuration >=
          series.series.slice(-1)[0]?.cumulativeDuration
        )
          return {
            ...event,
            ...series.series.slice(-1)[0],
          };
        else
          return {
            ...event,
            ...series.series[0],
          };
      }
    },
    [depthNormalized],
  );

  useEffect(() => {
    if (isLoading || !data) return;
    const { planTvdSeries, tvdSeries, comparisonTvdSeries } = data;
    setSeriesAggregatedData(
      [
        ...(planActive
          ? [
              {
                data: planTvdSeries?.series || [],
                id: PLAN_SERIES_ID,
                color: themeColors.disabled_typography,
              },
            ]
          : []),

        ...(actualActive
          ? [
              {
                data: tvdSeries?.series ?? [],
                id: tvdSeries?.wellId,
                color: getColor({ key: (tvdSeries?.wellId ?? "").toString() }),
              },
            ]
          : []),
        ...(offsetWellsActive
          ? (comparisonTvdSeries ?? []).map((data) => ({
              data: data?.series ?? [],
              id: data.wellId,
              color: getColor({ key: (data.wellId ?? "").toString() }),
            }))
          : []),
      ].filter((e) => e),
    );
  }, [
    getColor,
    isLoading,
    data,
    planActive,
    actualActive,
    offsetWellsActive,
    themeColors,
  ]);

  const getEventsById = useCallback(
    (seriesId: number | null) => {
      if (!data) return [];
      const { planTvdSeries, tvdSeries } = data;
      const getEventsDynamicDuration = (event: ITimelineEventList) => {
        if (
          (seriesId === PLAN_SERIES_ID && !planTvdSeries?.series) ||
          (seriesId === tvdSeries?.wellId && !tvdSeries?.series) ||
          !event?.cumulativeDuration
        )
          return { cumulativeDuration: 0 };
        return getEventDynamicValues({
          event,
          series: seriesId === PLAN_SERIES_ID ? planTvdSeries : tvdSeries,
        });
      };
      const getEventsCombinations = (events: ITimelineEventList[]) => {
        let combinedEvents =
          events?.filter((e) => {
            if (e.deletedAtUtc) return false;
            if (
              e.cumulativeDuration < xMinCumulative ||
              e.cumulativeDuration > xMaxCumulative
            )
              return false;
            if (
              (getHoleDepth(e) ?? 0) < yMinDepth ||
              (getHoleDepth(e) ?? 0) > (yMaxDepth ?? 0)
            )
              return false;
            // e.type is not set for phases
            if (
              displayOptions.events === null ||
              !isArray(displayOptions?.events)
            )
              return e.compressible && e.type;

            return (
              e.compressible &&
              displayOptions.events.includes(
                EventsEnum[
                  `${(e.type ?? "").toString().toUpperCase()}S` as keyof typeof EventsEnum
                ],
              )
            );
          }) ?? [];

        combinedEvents = combinedEvents.reduce<ITimelineEventList[]>(
          (prev, current) => {
            const last: ITimelineEventList = prev[prev.length - 1];
            if (
              last &&
              Math.abs(
                (getEventsDynamicDuration(current)?.cumulativeDuration ?? 0) -
                  (getEventsDynamicDuration(last)?.cumulativeDuration ?? 0),
              ) /
                (xMax || 0) /
                secondsInDay <
                MAX_GAP
            ) {
              prev[prev.length - 1] = {
                ...last,
                ...(getEventsDynamicDuration(current) ?? 0),
                combinedEvents: [...last.combinedEvents, current],
              };
            } else {
              prev.push(current);
            }
            return prev;
          },
          [],
        );
        return combinedEvents.map((event) =>
          getEventDynamicValues({
            event,
            series: seriesId === PLAN_SERIES_ID ? planTvdSeries : tvdSeries,
          }),
        );
      };

      if (seriesId === PLAN_SERIES_ID && planActive)
        return getEventsCombinations(planTimelineEventsList);
      else if (seriesId === wellId && actualActive)
        return getEventsCombinations(actualTimelineEventsList);

      return [];
    },
    [
      data,
      planActive,
      planTimelineEventsList,
      wellId,
      actualActive,
      actualTimelineEventsList,
      getEventDynamicValues,
      xMinCumulative,
      xMaxCumulative,
      yMinDepth,
      yMaxDepth,
      displayOptions?.events,
      xMax,
    ],
  );

  const timelineEvents = useMemo(
    () => getEventsById(selectedSeries),
    [selectedSeries, getEventsById],
  );

  const plannedFutureEvents = useMemo(
    () =>
      getEventsById(PLAN_SERIES_ID).filter(
        (event) =>
          futurePlanEvents.includes(event?.id ?? 0) &&
          (event?.type === TimelineEventType.Warning ||
            event?.type === TimelineEventType.Instruction),
      ),
    [getEventsById, futurePlanEvents],
  );

  const digestTimelineEvents = useMemo(
    () =>
      data?.tvdSeries.series
        ? convertDigestTimelineToCombinedEvents({
            digestTimeline: digestTimelineMemos,
            series: data?.tvdSeries.series,
            depthNormalized,
            xMinCumulative: xMinCumulative,
            xMaxCumulative: xMaxCumulative,
            xMaxInDays: xMax,
            isZoomed:
              zoomState.internal_zoom || Number.isFinite(zoomState.depth_end),
          })
        : [],
    [
      data?.tvdSeries.series,
      depthNormalized,
      digestTimelineMemos,
      xMax,
      xMaxCumulative,
      xMinCumulative,
      zoomState.depth_end,
      zoomState.internal_zoom,
    ],
  );

  const currentPadding = selectedIndicators.has(
    IndicatorsState["Hole Sections"],
  )
    ? LEFT_PADDING_SECTIONS
    : LEFT_PADDING;
  const chartWidth = useMemo(() => {
    const width =
      (containerWidth ?? 0) - currentPadding - RIGHT_AXIS_PADDING * 2;
    if (width < 0) {
      return 0;
    }
    return width;
  }, [containerWidth, currentPadding]);
  const chartHeight = containerHeight ?? 0;

  const [heightOffset, bottomIndicatorsTotalHeight, bottomIndicatorsCount] = [
    IndicatorsState["Directional Intervals"],
    IndicatorsState["Well Phases"],
  ].reduce(
    (acc, indicator) => {
      if (selectedIndicators.has(indicator)) {
        acc[0] -= 38;
        acc[1] += 38;
        acc[2] += 1;
      }
      return acc;
    },
    [38, 0, 0],
  );

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, xMax || 0],
        range: [0, chartWidth],
        nice: true,
      }),
    [chartWidth, xMax],
  );
  const domain = useMemo(
    () => [
      // Added "?? 0" since we might have null values for gaps and it yields a NaN domain.
      +depthTransformer
        .display(yMinDepth ?? 0, { unit: "", fractionDigits: 0 })
        .replace(",", ""),
      +depthTransformer
        .display(yMax ?? 0, { unit: "", fractionDigits: 0 })
        .replace(",", ""),
    ],
    [depthTransformer, yMax, yMinDepth],
  );

  const yDisplayScale = useMemo(
    () =>
      scaleLinear<number>({
        domain,
        range: [0, isReport ? chartHeight - 36 - 38 - 10 : 380 + heightOffset],
        nice: true,
      }),
    [chartHeight, domain, heightOffset, isReport],
  );
  const yScale = useMemo(() => {
    const maxDepthScale = scaleLinear<number>({
      domain,
      nice: true,
    });
    return scaleLinear<number>({
      domain: maxDepthScale.domain().map(depthTransformer.toSI),
      range: [0, isReport ? chartHeight - 36 - 38 - 10 : 380 + heightOffset],
    });
  }, [chartHeight, depthTransformer.toSI, domain, heightOffset, isReport]);

  const paddingTop = isReport ? 96 : 34;
  const { showTooltip, hideTooltip, tooltipElement } =
    useChartTooltip<TvDPointWithGap>({
      containerRef,
      tvdTooltip: true,
      currentPadding,
      paddingTop: isReport ? 96 : 34,
      renderContent: ({ tooltipData }) => {
        if (!tooltipData) return null;
        return (
          <TooltipHighlightValue style={{ textAlign: "center" }}>
            MD{" "}
            {tooltipData.isInGap
              ? `- - ${depthUOM.abbr}`
              : getHoleDepthDisplay(tooltipData)}
            <br></br>
            {tooltipData.isInGap
              ? `Bit - - ${depthUOM.abbr}`
              : getBitDepthDisplay(tooltipData) !== null
                ? `Bit ${getBitDepthDisplay(tooltipData)}`
                : null}
            <br></br>
            Day {getDynamicDurationInDays(tooltipData).toFixed(2)}
            <br></br>
            {getDate(tooltipData)}
          </TooltipHighlightValue>
        );
      },
    });

  const seriesData = useMemo(() => {
    if (!data || isLoading) return [];
    if (selectedSeries === wellId) return data.tvdSeries?.series || [];
    if (selectedSeries === PLAN_SERIES_ID)
      return data.planTvdSeries?.series ?? [];
    return (
      (data.comparisonTvdSeries ?? []).find((e) => e.wellId === selectedSeries)
        ?.series ?? []
    );
  }, [data, isLoading, selectedSeries, wellId]);
  const [isAddAction, setIsAddAction] = useState(false);
  const frontFillFocalWellData = useMemo(() => {
    let currentDepth = 0;
    return (data?.tvdSeries.series ?? []).map((point) => {
      if (point.holeDepth === null || point.holeDepth === undefined)
        return {
          ...point,
          holeDepth: currentDepth,
        };
      currentDepth = point.holeDepth;
      return point;
    });
  }, [data?.tvdSeries.series]);

  const { xScaleDate, chunksCount } = useDiscontinuousTimeAxis({
    plotWidth: chartWidth,
    xScaleDomain: xScale.domain(),
    series: data?.tvdSeries?.series ?? [],
    overrideAxisLabelSize: OVERRIDE_TIME_AXIS_LABEL_SIZE,
  });

  const dispatch = useAppDispatch();

  const topbarZoomLabelInfo = useZoomModeLabel(zoomState);
  const getClosestPointDepth: (
    d: PlanTvdPointDto,
  ) => [number | null, number | null] = useCallback(
    (d) => {
      const x = getDepth(d);
      if (x === undefined || x === null) {
        const newPoint = seriesData.find((point) => {
          return (
            getDynamicDurationInDays(point) > getDynamicDurationInDays(d) &&
            getDepth(point) !== undefined &&
            getDepth(point) !== null
          );
        });
        if (!newPoint?.holeDepth) return [null, null];
        return [
          getDepth(newPoint)!,
          getBitDepth(newPoint as ActualTvdPointDto) ?? null,
        ];
      }
      return [x, getBitDepth(d as ActualTvdPointDto) ?? null];
    },
    [getDepth, seriesData],
  );
  // TODO: can this be shared with the timeline?
  // have the callback dep array as parameters inside the function
  // tooltip handler

  const handleTooltip = useCallback(
    (event: React.MouseEvent<SVGElement>, seriesId: number) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = xScale.invert(x - currentPadding);
      const index = bisector<PlanTvdPointDto, number>((d) =>
        getDynamicDurationInDays(d),
      ).left(seriesData, x0, 1);
      const d0 = seriesData[index - 1];
      const d1 = seriesData[index];

      let d = d0 as TvDPointWithGap;
      if (x0 > getDynamicDurationInDays(seriesData.slice(-1)[0])) {
        hideTooltip();
        setBitDepthIndicator(null);
        setCrossHairPosition(null);
        return;
      }
      if (d1 && getDynamicDurationInDays(d1) && d0) {
        d = (
          x0 - getDynamicDurationInDays(d0) > getDynamicDurationInDays(d1) - x0
            ? d1
            : d0
        ) as TvDPointWithGap;
      }

      const d0HoleDepth = getClosestPointDepth(d0)[0];
      const d1HoleDepth = getClosestPointDepth(d1)[0];

      if (
        !d1 ||
        !d0 ||
        d0HoleDepth === undefined ||
        d0HoleDepth === null ||
        d1HoleDepth === undefined ||
        d1HoleDepth === null
      ) {
        hideTooltip();
        setBitDepthIndicator(null);
        setCrossHairPosition(null);
        return;
      }
      const isGap = [d.bitDepth, d.holeDepth].every(
        (x) => x === undefined || x === null,
      );
      if (isGap) {
        d = {
          ...d,
          isInGap: isGap,
          holeDepth: getClosestPointDepth(d0)[0],
          bitDepth: getClosestPointDepth(d0)[1],
        };
      }
      if (seriesId === PLAN_SERIES_ID) {
        const percent = Math.abs(
          (x0 - getDynamicDurationInDays(d0)) /
            (getDynamicDurationInDays(d1) - getDynamicDurationInDays(d0)),
        );
        setCrossHairPosition({
          left: x,
          top:
            yScale(d0HoleDepth + percent * (d1HoleDepth - d0HoleDepth)) +
            paddingTop,
        });
        const currentCumulativeDuration =
          d0.cumulativeDuration +
          Math.abs(d1.cumulativeDuration - d0.cumulativeDuration) * percent;
        const currentDynamicDuration =
          d0.dynamicDuration +
          Math.abs(d1.dynamicDuration - d0.dynamicDuration) * percent;
        const currentHoleDepth =
          (d0.holeDepth ?? 0) +
          Math.abs((d1.holeDepth ?? 0) - (d0.holeDepth ?? 0)) * percent;
        const currentDynamicHoleDepth =
          (d0.dynamicHoleDepth ?? 0) +
          Math.abs((d1.dynamicHoleDepth ?? 0) - (d0.dynamicHoleDepth ?? 0)) *
            percent;
        showTooltip({
          tooltipData: {
            ...d,
            holeDepth: currentHoleDepth,
            dynamicHoleDepth: currentDynamicHoleDepth,
            cumulativeDuration: currentCumulativeDuration,
            dynamicDuration: currentDynamicDuration,
          },
          tooltipLeft: x,
          tooltipTop:
            yScale(
              getDepth(d0) ?? 0 + percent * Math.abs(d1HoleDepth - d0HoleDepth),
            ) + paddingTop,
        });
        return;
      }
      if (seriesId === selectedSeries) {
        setBitDepthIndicator({
          bitIndicatorLeft: x,
          bitIndicatorTop:
            yScale(
              (isGap
                ? getClosestPointDepth(d0)[1]
                : getBitDepth(d0 as ActualTvdPointDto)) ?? 0,
            ) +
            68 +
            paddingTop,
          color:
            seriesAggregatedData.find((e) => e.id === selectedSeries)?.color ||
            colors.well_color,
        });
      }
      setCrossHairPosition({
        left: x,
        top: Math.min(yScale(d0HoleDepth), chartHeight) + paddingTop,
      });
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: Math.min(yScale(d0HoleDepth), chartHeight) + paddingTop,
      });
    },
    [
      xScale,
      currentPadding,
      seriesData,
      getClosestPointDepth,
      selectedSeries,
      yScale,
      chartHeight,
      paddingTop,
      showTooltip,
      hideTooltip,
      getDepth,
      seriesAggregatedData,
    ],
  );

  const selectedSections = useMemo(() => {
    if (!data) return [];
    if (selectedSeries === wellId)
      return data.tvdSeries?.sectionIntervals ?? [];
    if (selectedSeries === PLAN_SERIES_ID)
      return data.planTvdSeries?.sectionIntervals ?? [];
    return (
      (data.comparisonTvdSeries ?? []).find((e) => e.wellId === selectedSeries)
        ?.sectionIntervals ?? []
    );
  }, [data, selectedSeries, wellId]);
  const shadowEvent = useRef(null);
  const dynamicToCumulative = useCallback(
    (dynamicPoint: number, type: "Duration" | "HoleDepth") => {
      if (
        (selectedSeries === wellId || selectedSeries === PLAN_SERIES_ID) &&
        seriesData
      ) {
        const currentPointIndex = seriesData.findIndex(
          (point) => (point[`dynamic${type}`] ?? 0) >= dynamicPoint,
        );
        const currentPoint = seriesData[currentPointIndex];
        const valueDifference =
          (currentPoint?.[
            type === "Duration" ? "dynamicDuration" : "dynamicHoleDepth"
          ] ?? 0) - dynamicPoint;

        return (
          (currentPoint?.[
            type === "Duration" ? "cumulativeDuration" : "holeDepth"
          ] ?? 0) - valueDifference
        );
      }
      return null;
    },
    [selectedSeries, seriesData, wellId],
  );
  // TODO: No performance improvement can be seen from throttling this one... wired
  const throttledDispatchTimelineOverride = useCallback(
    (x: number, y: number, passThrough?: boolean) => {
      if (
        !passThrough &&
        ((timeline_state === "ActualWeb" && selectedSeries !== wellId) ||
          (timeline_state === "Plan" && selectedSeries !== PLAN_SERIES_ID))
      )
        return null;
      if (actionEnabled) return null;
      if (x === undefined && setTimelineOverride) {
        setTimelineOverride(null);
        return;
      }
      // No need add the zoomState padding as we are converting this in cumulative duration already
      const dynamicDuration = xScale.invert(x - currentPadding) * secondsInDay;

      if (
        dynamicDuration < 0 ||
        dynamicDuration / secondsInDay >
          getDynamicDurationInDays(seriesData.slice(-1)[0])
      ) {
        setTimelineOverride?.(null);
        return null;
      }
      const dIndex = seriesData.findIndex(
        (point) => point.dynamicDuration >= dynamicDuration,
      );
      if (dIndex === -1 || dIndex === 0) {
        if (setTimelineOverride) {
          setTimelineOverride({
            cumulativeDuration:
              dynamicToCumulative(dynamicDuration, "Duration") ?? 0,
            depth: seriesData[0]?.holeDepth ?? 0,
            type: SetterEnum.TimeVsDepth,
          });
        }
        return;
      }
      const d1 = seriesData[dIndex] ?? {
        dynamicDuration: 0,
        holeDepth: 0,
      };
      const d0 = seriesData[dIndex - 1] ?? {
        dynamicDuration: 0,
        holeDepth: 0,
      };
      const denominator =
        getDynamicDurationInDays(d1) - getDynamicDurationInDays(d0);
      const percent =
        denominator === 0
          ? 0
          : Math.abs(
              (dynamicDuration / secondsInDay - getDynamicDurationInDays(d0)) /
                denominator,
            );
      const currentHoleDepth =
        (d0.holeDepth ?? 0) +
        Math.abs((d1.holeDepth ?? 0) - (d0.holeDepth ?? 0)) * percent;

      if (setTimelineOverride) {
        setTimelineOverride({
          cumulativeDuration:
            dynamicToCumulative(dynamicDuration, "Duration") ?? 0,
          depth: currentHoleDepth,
          type: SetterEnum.TimeVsDepth,
        });
      }
      return () => {
        if (setTimelineOverride) setTimelineOverride(null);
        shadowEvent.current = null;
      };
    },
    [
      timeline_state,
      selectedSeries,
      wellId,
      actionEnabled,
      setTimelineOverride,
      xScale,
      currentPadding,
      seriesData,
      dynamicToCumulative,
    ],
  );

  useEffect(() => {
    setCrossHairPosition(null);
  }, [timeline_state, wellId]);

  const resetSelectedEvent = useCallback(() => {
    dispatch({
      type: "SET_SELECTED_TIMELINE_EVENT",
      payload: null,
    });
  }, [dispatch]);

  const hideAddTooltip = useMemo(() => {
    if (
      selectedSeries === wellId &&
      displayOptions.curves &&
      !displayOptions.curves.includes(CurvesEnum.HOLE_DEPTH)
    )
      return true;
    if (brushActiveRef.current || !selectedSeries) return true;
    if (
      selectedSeries === PLAN_SERIES_ID &&
      displayOptions.curves &&
      !displayOptions.curves.includes(CurvesEnum.PLAN)
    )
      return true;
    if (
      ![wellId, PLAN_SERIES_ID].includes(selectedSeries) &&
      displayOptions.curves &&
      !displayOptions.curves.includes(CurvesEnum.OFFSET_WELLS)
    )
      return true;
    return false;
  }, [displayOptions.curves, selectedSeries, wellId]);

  const AddIconTimeline = useMemo(() => {
    if (hideAddTooltip) return null;
    // TODO: fix this; no longer need to differentiate timeline time and normal time
    if (
      (timelineOverride === null ||
        timelineOverride?.type !== SetterEnum.Timeline) &&
      !actionEnabled
    )
      return null;
    if (!data) return null;
    if (
      (timeline_state === "ActualWeb" && selectedSeries !== wellId) ||
      (timeline_state === "Plan" && selectedSeries !== PLAN_SERIES_ID)
    )
      return null;
    const addPosition = getEventDynamicValues({
      event: {
        combinedEvents: [],
        key: "",
        compressible: false,
        cumulativeDuration: timelineOverride?.cumulativeDuration,
        eventType: "EndOfTimeline",
      },
      series:
        selectedSeries === PLAN_SERIES_ID ? data.planTvdSeries : data.tvdSeries,
    });

    if (
      timelineOverride &&
      !isBetween({
        min: xMinCumulative,
        max: xMaxCumulative,
        value: timelineOverride.cumulativeDuration,
      })
    ) {
      shadowEvent.current = null;
      return null;
    }

    setCrossHairPosition(null);
    return addPosition && addPosition.dynamicHoleDepth ? (
      <div
        style={{
          position: "absolute",
          zIndex: zIndexLayer.sky,
          height: 20,
          width: 20,
          left:
            xScale(getDynamicDurationInDays(addPosition)) + currentPadding - 10,
          top: yScale(addPosition.dynamicHoleDepth ?? 0) + paddingTop + 64 - 10,
        }}
        onClick={() => {
          if (
            displayOptions.curves !== null &&
            !displayOptions.curves.includes(CurvesEnum.HOLE_DEPTH)
          )
            return null;
          if (actionEnabled) {
            shadowEvent.current = null;
            actionEnabledCB(false);
          }
        }}
      >
        <AddIconWrapper
          backgroundColor={themeColors.primary_button_bg}
          isAnimated={(actionEnabled ?? "").toString()}
          top={"0px"}
        >
          <PDComponent.SvgIcon name="add" style={{ fontSize: "20px" }} />
        </AddIconWrapper>
      </div>
    ) : null;
  }, [
    actionEnabled,
    actionEnabledCB,
    currentPadding,
    data,
    displayOptions.curves,
    getEventDynamicValues,
    paddingTop,
    selectedSeries,
    themeColors.primary_button_bg,
    timelineOverride,
    timeline_state,
    wellId,
    xMaxCumulative,
    xMinCumulative,
    xScale,
    yScale,
    hideAddTooltip,
  ]);

  const isSectionsVisible =
    !isLoading &&
    data &&
    selectedSections?.length > 0 &&
    selectedIndicators.has(IndicatorsState["Hole Sections"]) &&
    ((planActive && data.planTvdSeries?.dataState === ResultDataState.Valid) ||
      (actualActive && data.tvdSeries?.dataState === ResultDataState.Valid) ||
      (data?.comparisonTvdSeries || [])?.length > 0);

  const isDirectionalIntervalsVisible =
    !isLoading &&
    data &&
    selectedIndicators.has(IndicatorsState["Directional Intervals"]) &&
    ((planActive && data.planTvdSeries?.dataState === ResultDataState.Valid) ||
      (actualActive && data.tvdSeries?.dataState === ResultDataState.Valid) ||
      (data?.comparisonTvdSeries || [])?.length > 0);

  const isPhasesVisible =
    !isLoading &&
    data &&
    selectedIndicators.has(IndicatorsState["Well Phases"]) &&
    ((planActive && data.planTvdSeries?.dataState === ResultDataState.Valid) ||
      (actualActive && data.tvdSeries?.dataState === ResultDataState.Valid) ||
      (data?.comparisonTvdSeries || [])?.length > 0);

  const isNoData = useMemo(() => {
    if (isLoading) return false;
    const offsetState = data?.comparisonTvdSeries?.reduce<
      ResultDataState | undefined
    >((acc, crt) => {
      if (acc === ResultDataState.Valid) return acc;
      return crt.dataState;
    }, undefined);
    const planState = data?.planTvdSeries?.dataState;
    const actualState = data?.tvdSeries?.dataState;

    if (offsetWellsActive && offsetState === ResultDataState.Valid)
      return false;
    if (planActive && planState === ResultDataState.Valid) return false;
    if (actualActive && actualState === ResultDataState.Valid) return false;
    return true;
  }, [
    actualActive,
    data?.comparisonTvdSeries,
    data?.planTvdSeries?.dataState,
    data?.tvdSeries?.dataState,
    isLoading,
    offsetWellsActive,
    planActive,
  ]);

  const isActiveWell = useCallback(
    (crtWellId: number) => {
      if (!wellDetails?.[crtWellId]) return null;
      if (wellDetails[crtWellId]?.status !== WellStatusType.Active)
        return false;
      return true;
    },
    [wellDetails],
  );

  useEffect(() => {
    if (
      realTimeDataState === realTimeDataStateWidget ||
      JSON.stringify(offsetSelection) ===
        JSON.stringify(prevOffsetWells.current) ||
      (realTimeDataState !== RealTimeDataEnum.UNAVAILABLE &&
        prevSelectedWell.current === wellId)
    ) {
      return;
    }
    setRealtimeDataWidget(realTimeDataState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetSelection, wellId]);

  useEffect(() => {
    if (
      realTimeDataState !== undefined &&
      (JSON.stringify(offsetSelection) ===
        JSON.stringify(prevOffsetWells.current) ||
        (realTimeDataState !== RealTimeDataEnum.UNAVAILABLE &&
          prevSelectedWell.current === wellId))
    ) {
      return;
    }
    if (
      isActiveWell(wellId) === null ||
      offsetSelection.some((e) => isActiveWell(e) === null)
    )
      return;
    prevOffsetWells.current = offsetSelection;
    prevSelectedWell.current = wellId;
    if (isActiveWell(wellId) || offsetSelection.some((e) => isActiveWell(e))) {
      setRealtimeData(RealTimeDataEnum.ACTIVE);
    } else {
      setRealtimeData(RealTimeDataEnum.UNAVAILABLE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveWell, offsetSelection, wellId]);
  useEffect(() => {
    if (
      displayOptions.curves &&
      !displayOptions.curves?.includes(CurvesEnum.HOLE_DEPTH)
    ) {
      setEndWellIndicator(null);
      return;
    }
    if (!data?.tvdSeries?.series?.length) {
      setEndWellIndicator(null);
      return;
    }

    const shouldShow =
      !zoomState ||
      (isBetween({
        min: zoomState.depth_start,
        max: zoomState.depth_end || 0,
        value: selectedWellDetails?.drillingDetails?.lastHoleDepth || 0,
      }) &&
        isBetween({
          min: zoomState.ts_start,
          max: zoomState.ts_end || 0,
          value:
            selectedWellDetails?.drillingDetails
              ?.cumulativeDurationActiveOnly || 0,
        }));

    if (!shouldShow) {
      setEndWellIndicator(null);
      return;
    }
    if (isActiveWell(wellId)) {
      const lastPointWithValidHoleDepth =
        (data?.tvdSeries.series ?? [])
          .filter((d) => d.holeDepth !== null || d.holeDepth !== undefined)
          .slice(-1)[0] ?? 0;

      setEndWellIndicator({
        top:
          yScale(getHoleDepth(lastPointWithValidHoleDepth) ?? 0) +
          paddingTop +
          64,
        left:
          xScale(getDynamicDurationInDays(lastPointWithValidHoleDepth)) +
          currentPadding,
      });
    }
  }, [
    currentPadding,
    data?.tvdSeries,
    displayOptions.curves,
    isActiveWell,
    paddingTop,
    selectedWellDetails?.drillingDetails?.cumulativeDurationActiveOnly,
    selectedWellDetails?.drillingDetails?.lastHoleDepth,
    wellId,
    xScale,
    yScale,
    zoomState,
  ]);
  const shouldShowDateTimeAxis = useMemo(() => {
    return (
      (topbarZoomLabelInfo?.type === IZoomType.DATE ||
        topbarZoomLabelInfo?.type === IZoomType.DYNAMIC_WINDOW) &&
      (data?.tvdSeries?.series || [])?.length > 0
    );
  }, [data?.tvdSeries?.series, topbarZoomLabelInfo?.type]);

  const isFetchingWellOverview = useIsFetching({
    queryKey: [{ type: PDQueryType.WELL_OVERVIEW }],
    exact: false,
  });

  const resetTooltipState = useCallback(() => {
    hideTooltip();
    setCrossHairPosition(null);
    setBitDepthIndicator(null);
    shadowEvent.current = null;
    if (setTimelineOverride) setTimelineOverride(null);
  }, [hideTooltip, setTimelineOverride]);

  const selectedEvent = useAppSelector(
    (state) => state.timeline.selectedTimelineEvent,
  );
  const previousSelectedEvent = usePreviousNonNullValue(selectedEvent);

  const dispatchImperativeScrollToLegendSeries = useCallback(
    (seriesId: number) => {
      dispatch({
        type: "SET_SCROLL_TO_LEGEND_SERIES",
        payload: seriesId,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    // Prevents double tooltip display of events
    if (previousSelectedEvent && selectedEvent !== previousSelectedEvent) {
      setHoveredEventTvD(null);
    }
  }, [previousSelectedEvent, selectedEvent]);

  const handleOnLinePathClick = useCallback(
    (
      event: React.MouseEvent<SVGPathElement, MouseEvent>,
      series: ITvDCurveData,
    ) => {
      event.preventDefault();
      event.stopPropagation();

      if (brushActiveRef?.current) return;

      if (selectedSeries !== series.id) {
        setTimelineState("Legend");
        setSelectedSeries(series.id);
        dispatchImperativeScrollToLegendSeries(series.id);
        return;
      }

      const runAfterTimelineSelection = () => {
        if (shadowEvent.current) {
          if (setTimelineOverride) setTimelineOverride(shadowEvent.current);
          shadowEvent.current = null;
        }
        const { x, y } = localPoint(event) || { x: 0, y: 0 };
        if (!crossHairPosition) {
          resetTooltipState();
          return;
        }
        setHoveredEventTimeline(null);
        resetSelectedEvent();
        throttledDispatchTimelineOverride(x, y, true);
        actionEnabledCB(
          selectedSeries === PLAN_SERIES_ID || selectedSeries === wellId,
        );
        setCrossHairPosition(null);
      };

      if (timeline_state !== "Plan" && selectedSeries === PLAN_SERIES_ID) {
        // Delayed 500MS to account for animation and loading states inside new tab
        // It's hackish but can't be done without introducing orchestration
        setTimelineState("Plan");
        setTimeout(runAfterTimelineSelection, 500);
      } else if (timeline_state !== "ActualWeb" && selectedSeries === wellId) {
        setTimelineState("ActualWeb");
        setTimeout(runAfterTimelineSelection, 500);
      } else {
        runAfterTimelineSelection();
      }
    },
    [
      actionEnabledCB,
      crossHairPosition,
      dispatchImperativeScrollToLegendSeries,
      resetSelectedEvent,
      resetTooltipState,
      selectedSeries,
      setHoveredEventTimeline,
      setSelectedSeries,
      setTimelineOverride,
      setTimelineState,
      throttledDispatchTimelineOverride,
      timeline_state,
      wellId,
    ],
  );

  const selectionIsFocalWell = useMemo(
    () => selectedSeries === wellId || selectedSeries === PLAN_SERIES_ID,
    [selectedSeries, wellId],
  );

  const indicatorsSeparatorColorTvd = useMemo(() => {
    if (!selectionIsFocalWell) {
      return getColor({ key: `${selectedSeries}`, isMock: false });
    } else {
      return colors.well_color;
    }
  }, [getColor, selectedSeries, selectionIsFocalWell]);

  const {
    phaseSegments,
    intervalSegments,
    isLoading: isLoadingPhasesOrIntervals,
  } = usePhasesAndIntervalsToSegments(
    selectionIsFocalWell
      ? data?.tvdSeries?.phases ?? []
      : (data?.comparisonTvdSeries ?? []).find(
          (e) => e.wellId === selectedSeries,
        )?.phases ?? [],
    selectionIsFocalWell
      ? data?.tvdSeries?.directionIntervals ?? []
      : (data?.comparisonTvdSeries ?? []).find(
          (e) => e.wellId === selectedSeries,
        )?.directionIntervals ?? [],
  );

  return (
    <div
      ref={containerRef}
      style={
        isReport
          ? {
              height: "calc(100vh - 64px - 20px - 70px - 36px - 40px)",
            }
          : {}
      }
    >
      <svg
        onDoubleClick={() => {
          if (
            zoomState.ts_start === initialZoomData.ts_start &&
            zoomState.ts_end === initialZoomData.ts_end &&
            zoomState.depth_start === initialZoomData.depth_start &&
            zoomState.depth_end === initialZoomData.depth_end &&
            zoomState.date_start === initialZoomData.date_start &&
            zoomState.date_end === initialZoomData.date_end
          )
            return;
          resetTooltipState();

          setCrossHairPosition(null);
          setTimeout(() => actionEnabledCB(false), 500);
          brushActiveRef.current = false;
          Track.interact("TVD - Reset Zoom", {
            action: "Double Click",
          });
          setZoomState({
            ...initialZoomData,
            type: zoomState.type ?? IZoomType.TIME,
          });
        }}
        style={{
          backgroundColor: themeColors.primary_bg,
          userSelect: "none",
        }}
        height={isReport ? "calc(100vh - 64px - 20px - 70px - 36px)" : 500}
        width="100%"
      >
        <GridRows
          scale={yScale}
          width={(containerWidth ?? 0) - RIGHT_AXIS_PADDING * 2}
          height="calc(100% - 34px -36px)"
          stroke={themeColors.primary_chart_accent}
        />
        <Bar
          x="0"
          y={`calc(100% - 36px - ${bottomIndicatorsTotalHeight}px)`}
          width="100%"
          height={36}
          fill={themeColors.alt_quaterniary_bg}
        />
        {isSectionsVisible ? (
          <text
            x="24"
            y={isReport ? "94.2%" : `${97 - bottomIndicatorsCount * 8}%`}
            width="100%"
            fontSize={14}
            fill={themeColors.disabled_typography}
            style={{
              userSelect: "none",
            }}
          >
            {shouldShowDateTimeAxis ? "Date time" : "Days"}
          </text>
        ) : null}
        {isNoData ? (
          <text
            x="50%"
            y={isReport ? "94.2%" : `${97 - bottomIndicatorsCount * 8}%`}
            width="100%"
            fontSize={14}
            fill={themeColors.disabled_typography}
            style={{
              userSelect: "none",
            }}
          >
            Data not available
          </text>
        ) : null}

        {isSectionsVisible ? (
          <Group left={24} top={34 - 8}>
            <Sections
              sections={selectedSections}
              yScale={yScale}
              chartWidth={chartWidth}
            />
          </Group>
        ) : null}

        <svg
          y={chartHeight - 36 - bottomIndicatorsTotalHeight + 10}
          x={currentPadding}
          height={36}
          style={{
            position: "absolute",
            overflow: "visible",
          }}
        >
          {(() => {
            if (isNoData || isLoading) return null;
            if (shouldShowDateTimeAxis)
              return (
                <AxisBottom
                  hideTicks
                  tickStroke={themeColors.disabled_typography}
                  numTicks={chunksCount}
                  stroke={themeColors.disabled_typography}
                  tickComponent={(props) => {
                    if (!props.formattedValue) return null;
                    const timeAxisPosition = props.x;
                    const cumulativeTimeForPosition =
                      xScale.invert(timeAxisPosition);
                    const index = bisector<PlanTvdPointDto, number>((d) =>
                      getDynamicDurationInDays(d),
                    ).left(seriesData, cumulativeTimeForPosition, 1);
                    const d0 = seriesData[index - 1];
                    const d1 = seriesData[index];

                    let d = d0;
                    if (d1 && getDynamicDurationInDays(d1) && d0) {
                      d =
                        cumulativeTimeForPosition -
                          getDynamicDurationInDays(d0) >
                        getDynamicDurationInDays(d1) - cumulativeTimeForPosition
                          ? d1
                          : d0;
                    }

                    const crtDate = formatTime((d as ActualTvdPointDto).at, {
                      formatStr: DatepickDateFormat,
                    });
                    return (
                      <Group>
                        <Bar
                          x={props.x}
                          y={-10}
                          width={3}
                          height={2}
                          opacity={0.3}
                          fill={themeColors.primary_bg_faded}
                        />
                        <text
                          x={props.x}
                          y={props.y}
                          dx={-OVERRIDE_TIME_AXIS_LABEL_SIZE / 2 + 15}
                          dy={-10}
                          fontSize={14}
                          letterSpacing={-0.2}
                          textAnchor="right"
                          fill={themeColors.disabled_typography}
                          pointerEvents="none"
                          style={{
                            userSelect: "none",
                          }}
                        >
                          {crtDate}
                        </text>
                      </Group>
                    );
                  }}
                  hideAxisLine
                  top={0}
                  scale={xScaleDate}
                />
              );
            return (
              <AxisBottom
                hideTicks
                tickStroke={themeColors.disabled_typography}
                stroke={themeColors.disabled_typography}
                tickComponent={(props) => {
                  return (
                    <Group key={props.formattedValue}>
                      <Bar
                        x={props.x}
                        y={-10}
                        width={3}
                        height={2}
                        opacity={0.3}
                        fill={themeColors.primary_bg_faded}
                      />
                      <text
                        x={props.x}
                        y={props.y}
                        dx={-(5 * (props.formattedValue || "").length) / 2}
                        dy={-10}
                        fontSize={14}
                        letterSpacing={-0.2}
                        textAnchor="right"
                        fill={themeColors.disabled_typography}
                        pointerEvents="none"
                        style={{
                          userSelect: "none",
                        }}
                      >
                        {props.formattedValue}
                      </text>
                    </Group>
                  );
                }}
                hideAxisLine
                top={0}
                scale={xScale}
              />
            );
          })()}
        </svg>

        <Group top={34} left={currentPadding}>
          <AxisRight
            hideTicks
            tickStroke={themeColors.disabled_typography}
            stroke={themeColors.disabled_typography}
            axisClassName="VISX_AXIS" // TODO change this approach to the visx one when the lib evolves enough
            numTicks={MAX_DEPTH_LABELS_COUNT}
            tickComponent={(props) => (
              <text
                x={props.x}
                y={props.y}
                dy={props.dy}
                fontSize={14}
                letterSpacing={-0.2}
                textAnchor="right"
                fill={themeColors.disabled_typography}
                pointerEvents="none"
                style={{
                  userSelect: "none",
                }}
              >
                {props.formattedValue}
              </text>
            )}
            hideAxisLine
            left={(chartWidth ?? 0) + RIGHT_AXIS_PADDING}
            scale={yDisplayScale}
            hideZero={domain[1] - domain[0] === 0}
          />
          <Group
            left={0}
            top={0}
            onMouseMove={(event) => {
              if (hideAddTooltip) {
                resetTooltipState();
                return;
              }
              handleTooltip(event, selectedSeries!);
              if (
                selectedSeries === PLAN_SERIES_ID ||
                selectedSeries === wellId
              ) {
                const { x } = localPoint(event) || { x: 0, y: 0 };
                if (!x && setTimelineOverride) setTimelineOverride(null);
              }
            }}
            onClick={() => {
              if (actionEnabled) {
                actionEnabledCB(false);
              }
            }}
            onMouseLeave={() => {
              if (brushActiveRef?.current) return;
              if (timeline_state === "Legend") setHoveredSeries(undefined);
              if (actionEnabled) return;
              resetTooltipState();
            }}
          >
            <Brush
              brushRegion="chart"
              innerRef={brushRef}
              resetOnEnd
              xScale={xScale}
              yScale={yScale}
              margin={{
                left: zoomState.type === IZoomType.DEPTH ? 0 : currentPadding,
                top: zoomState.type === IZoomType.DEPTH ? paddingTop : 0,
                bottom:
                  zoomState.type === IZoomType.DEPTH
                    ? 40 + chartHeight * 0.01 + 36
                    : 0,
                right:
                  zoomState.type === IZoomType.DEPTH ? 0 : RIGHT_AXIS_PADDING,
              }}
              width={chartWidth}
              height={yScale.range()[1] + CHART_BOTTOM_MARGIN}
              handleSize={8}
              resizeTriggerAreas={
                zoomState.type === IZoomType.DEPTH
                  ? ["top", "bottom"]
                  : ["left", "right"]
              }
              brushDirection={
                zoomState.type === IZoomType.DEPTH ? "vertical" : "horizontal"
              }
              onBrushStart={() => {
                brushActiveRef.current = true;
              }}
              disableDraggingOverlay={!!isFetchingWellOverview}
              onBrushEnd={onBrushChange}
              selectedBoxStyle={selectedBrushStyle}
              useWindowMoveEvents
            />
          </Group>
          {seriesAggregatedData
            .sort((a) => (a.id === selectedSeries ? 1 : -1))
            ?.map((series) => {
              if (isLoading) return null;
              if (series.id === wellId && !actualActive) return null;
              if (series.id === PLAN_SERIES_ID && !planActive) return null;
              if (
                series.id !== wellId &&
                series.id !== PLAN_SERIES_ID &&
                !offsetWellsActive
              )
                return null;
              if (!series.data) return null;
              return (
                <React.Fragment key={`key-${series.id}-fragment`}>
                  <filter
                    id={`dropshadow-tvd-${series.id}`}
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feGaussianBlur stdDeviation="3" />
                    <feDropShadow
                      dx="0"
                      dy="0"
                      stdDeviation="3"
                      floodColor={series.color}
                      floodOpacity="1"
                    />
                  </filter>
                  <LinePath
                    key={`key-${series.id}-glowing-line`}
                    data={series.data ?? []}
                    shapeRendering="geometricPrecision"
                    filter={
                      selectedSeries === series.id
                        ? `url(#dropshadow-tvd-${series.id})`
                        : undefined
                    }
                    fill="none"
                    pointerEvents="none"
                    defined={(d) =>
                      d?.holeDepth !== null && d?.holeDepth !== undefined
                    }
                    x={(d) => xScale(getDynamicDurationInDays(d)) ?? 0}
                    y={(d) => yScale(getDepth(d) ?? 0) ?? 0}
                    stroke={series.color}
                    strokeOpacity={(() => {
                      if (isReport) return 1;
                      if (selectedSeries === series.id) return 1;
                      else if (series.id === hoveredSeriesTvd) return 0.7;
                      return 0.5;
                    })()}
                    style={{
                      pointerEvents: "none",
                    }}
                    strokeWidth={selectedSeries === series.id ? 1 : 2}
                  />
                  {selectedSeries === series.id ? (
                    <LinePath
                      key={`key-${series.id}-line`}
                      data={series.data ?? []}
                      shapeRendering="geometricPrecision"
                      fill="none"
                      pointerEvents="none"
                      defined={(d) =>
                        d?.holeDepth !== null && d?.holeDepth !== undefined
                      }
                      x={(d) => xScale(getDynamicDurationInDays(d)) ?? 0}
                      y={(d) => yScale(getDepth(d) ?? 0) ?? 0}
                      stroke={series.color}
                      strokeOpacity={1}
                      style={{
                        pointerEvents: "none",
                      }}
                      strokeWidth={3}
                    />
                  ) : null}
                  <LinePath
                    key={`key-${series.id}-overlay`}
                    data={
                      series.id === wellId
                        ? frontFillFocalWellData
                        : series.data
                    }
                    shapeRendering="geometricPrecision"
                    fill="none"
                    onClick={(ev) => handleOnLinePathClick(ev, series)}
                    onMouseMove={(event) => {
                      if (brushActiveRef?.current) return;
                      setHoveredSeries(series.id);
                      if (series.id === selectedSeries) {
                        handleTooltip(event, series.id);
                        if (
                          (selectedSeries === PLAN_SERIES_ID ||
                            selectedSeries === wellId) &&
                          !isAddAction
                        )
                          setIsAddAction(true);
                        const { x, y } = localPoint(event) || { x: 0, y: 0 };
                        throttledDispatchTimelineOverride(x, y);
                      }
                    }}
                    onMouseLeave={() => {
                      throttledDispatchTimelineOverride(0, 0, false);
                      if (isAddAction) setIsAddAction(false);
                      if (brushActiveRef?.current) return;
                      setHoveredSeries(undefined);
                    }}
                    defined={(d) => d !== null && d !== undefined}
                    x={(d) => xScale(getDynamicDurationInDays(d)) ?? 0}
                    y={(d) => yScale(getDepth(d) ?? 0) ?? 0}
                    stroke={"transparent"}
                    strokeWidth={10}
                    style={{
                      pointerEvents: "stroke",
                    }}
                  />
                  {bitDepthActive && selectedSeries === series.id ? (
                    <LinePath
                      key={`key-${series.id}-0-bitDepth`}
                      data={(series.data ?? []) as ActualTvdPointDto[]}
                      shapeRendering="geometricPrecision"
                      fill="none"
                      pointerEvents="none"
                      defined={(d) =>
                        d?.bitDepth !== null && d?.bitDepth !== undefined
                      }
                      x={(d: ActualTvdPointDto) =>
                        xScale(getDynamicDurationInDays(d)) ?? 0
                      }
                      y={(d: ActualTvdPointDto) =>
                        yScale(getBitDepth(d) ?? 0) ?? 0
                      }
                      stroke={series.color}
                      strokeOpacity={(() => {
                        if (isReport) return 1;
                        if (selectedSeries === series.id) return 1;
                        else if (series.id === hoveredSeriesTvd) return 0.7;
                        return 0.5;
                      })()}
                      strokeDasharray="1 2"
                      strokeWidth={1}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
        </Group>

        {isPhasesVisible && !isLoadingPhasesOrIntervals ? (
          <WellSegmentBand
            segments={phaseSegments}
            paddingLeft={currentPadding}
            isAbove={isDirectionalIntervalsVisible}
            isLoading={isLoading}
            separatorColor={indicatorsSeparatorColorTvd}
            xScale={xScale}
            isReport={isReport}
            deduplicationKey="phases"
          />
        ) : null}

        {isDirectionalIntervalsVisible && !isLoadingPhasesOrIntervals ? (
          <WellSegmentBand
            segments={intervalSegments}
            paddingLeft={currentPadding}
            isLoading={isLoading}
            separatorColor={indicatorsSeparatorColorTvd}
            xScale={xScale}
            isReport={isReport}
            deduplicationKey="directionalIntervals"
          />
        ) : null}
      </svg>
      {endWellIndicator ? (
        <EndOfWellIndicator
          top={endWellIndicator.top}
          left={endWellIndicator.left}
          isSelected={(selectedSeries === wellId).toString()}
          $realTime={(realTimeDataState === RealTimeDataEnum.ACTIVE).toString()}
        />
      ) : null}
      {showEvents
        ? timelineEvents.map((event) => {
            const getIcon = (event: ICombinedEvents) => {
              if ((event.combinedEvents ?? []).length === 0 && event.type)
                return eventIcon(event.type);
              return (
                <Typography style={{ color: themeColors.primary_typography }}>
                  {(event.combinedEvents || []).length + 1}
                </Typography>
              );
            };
            const tooltipMessage =
              event && (event.combinedEvents || []).length === 0
                ? `${event.type}\n${event.holeDepth === null || event.holeDepth === undefined ? `- - ${depthUOM.abbr}` : getHoleDepthDisplay(event)}\n${getCumulativeDurationInDays(event)} day${
                    getCumulativeDurationInDays(event) === 1 ? "" : "s"
                  }`
                : `${(event?.combinedEvents || []).length + 1} events`;

            const isHovered =
              ((event?.id === hoveredEvent?.id ||
                (event?.combinedEvents ?? []).some(
                  (cEv) => cEv.id === hoveredEvent?.id,
                )) &&
                hoveredEvent?.type === event?.type &&
                ((selectedSeries === wellId &&
                  timeline_state === "ActualWeb") ||
                  (selectedSeries === PLAN_SERIES_ID &&
                    timeline_state === "Plan"))) ||
              isEqual(event, hoveredEventTvD);
            const isEdited =
              event?.id === editedEvent?.id &&
              editedEvent?.type === event?.type &&
              ((selectedSeries === wellId && timeline_state === "ActualWeb") ||
                (selectedSeries === PLAN_SERIES_ID &&
                  timeline_state === "Plan"));
            return (
              <Tooltip
                key={event?.id}
                placement="top"
                overlayStyle={{ whiteSpace: "pre-line" }}
                title={tooltipMessage}
                arrowPointAtCenter
                destroyTooltipOnHide
                open={isHovered}
              >
                <TimelineEventIndicator
                  isHovered={isHovered.toString()}
                  eventId={event?.id}
                  isEdited={isEdited.toString()}
                  isFuturePlanEvent={futurePlanEvents
                    .includes(event?.id ?? 0)
                    .toString()}
                  onClick={() => {
                    if (brushActiveRef?.current || !event) return;
                    setHoveredEventTimeline({
                      id: event?.id,
                      type: event?.type,
                    });
                    setHoveredEventTvD(event);
                    setTimelineState(
                      Number.isFinite(event.planId) ? "Plan" : "ActualWeb",
                    );
                  }}
                  onClickOutside={() => {
                    setHoveredEventTimeline(null);
                    setHoveredEventTvD(null);
                  }}
                  onMouseEnter={() => {
                    if (!selectedEvent) {
                      if (brushActiveRef?.current) return;
                      setHoveredEventTimeline({
                        id: event?.id,
                        type: event?.type,
                      });
                      setHoveredEventTvD(event);
                    }
                  }}
                  onMouseLeave={() => {
                    if (!selectedEvent) {
                      if (brushActiveRef?.current) return;
                      setHoveredEventTimeline(null);
                      setHoveredEventTvD(null);
                    }
                  }}
                  top={yScale(event?.dynamicHoleDepth ?? 0) + paddingTop + 64}
                  left={
                    (event ? xScale(getDynamicDurationInDays(event)) : 0) +
                    currentPadding
                  }
                  translateX="-50%"
                  translateY="-50%"
                >
                  {event ? getIcon(event) : null}
                </TimelineEventIndicator>
              </Tooltip>
            );
          })
        : null}
      {showPlannedFutureEvents
        ? plannedFutureEvents.map((event) =>
            event ? (
              <PlannedFutureEvents
                key={event.id}
                event={event}
                depthUOM={depthUOM}
                hoveredEvent={hoveredEvent}
                selectedEvent={selectedEvent}
                hoveredEventTvD={hoveredEventTvD}
                brushActiveRef={brushActiveRef}
                setHoveredEventTimeline={setHoveredEventTimeline}
                setHoveredEventTvD={setHoveredEventTvD}
                paddingTop={paddingTop}
                xScale={xScale}
                yScale={yScale}
                currentPadding={currentPadding}
                dynamicHoleDepth={event.dynamicHoleDepth}
                setTimelineState={setTimelineState}
                setSelectedSeries={setSelectedSeries}
              />
            ) : null,
          )
        : null}
      {showDigestTimelineEvents && data?.tvdSeries.series
        ? digestTimelineEvents
            .filter((digestChapter) => {
              const memosDisplayOptions = displayOptions.events;
              if (!memosDisplayOptions) return true;
              if (digestChapter.type === "UnplannedEvent") {
                return memosDisplayOptions?.includes(
                  "UNPLANNED_EVENTS" as EventsEnum,
                );
              }
              if (digestChapter.type === "Note") {
                return memosDisplayOptions?.includes(
                  "LOGBOOK_ENTRIES" as EventsEnum,
                );
              }
              return false;
            })
            .map((event) => (
              <DigestTimelineEvents
                key={event.id}
                event={event}
                hoveredEvent={hoveredEvent}
                selectedEvent={selectedEvent}
                hoveredEventTvD={hoveredEventTvD}
                brushActiveRef={brushActiveRef}
                setHoveredEventTimeline={setHoveredEventTimeline}
                setHoveredEventTvD={setHoveredEventTvD}
                paddingTop={paddingTop}
                xScale={xScale}
                yScale={yScale}
                currentPadding={currentPadding}
                setTimelineState={setTimelineState}
              />
            ))
        : null}

      {!actionEnabled &&
      bitDepthActive &&
      bitDepthIndicator &&
      crossHairPosition ? (
        <BitDepthIndicator
          bitIndicatorTop={bitDepthIndicator.bitIndicatorTop}
          bitIndicatorLeft={bitDepthIndicator.bitIndicatorLeft}
          color={bitDepthIndicator.color || colors.well_color}
        />
      ) : null}
      {AddIconTimeline}
      {actionEnabled || !crossHairPosition ? null : tooltipElement}
      {crossHairPosition && !actionEnabled ? (
        isAddAction ? (
          <AddIconWrapper
            style={{
              position: "absolute",
              top: crossHairPosition.top + 68,
              zIndex: zIndexLayer.building,
              left: crossHairPosition.left,
              transform: "translate(-50%, -50%)",
            }}
            backgroundColor={
              seriesAggregatedData.find((e) => e.id === selectedSeries)
                ?.color || "transparent"
            }
          >
            <PDComponent.SvgIcon name="add" style={{ fontSize: "20px" }} />
          </AddIconWrapper>
        ) : (
          <AxisIndicator
            style={{
              position: "absolute",
              top: crossHairPosition.top + 68,
              zIndex: zIndexLayer.building,
              left: crossHairPosition.left,
              transform: "translate(-50%, -50%)",
            }}
            backgroundColor={
              seriesAggregatedData.find((e) => e.id === selectedSeries)
                ?.color || "transparent"
            }
          />
        )
      ) : null}
      {!actionEnabled && crossHairPosition
        ? [
            <div
              key={`key-crosshair-line-vertical`}
              style={{
                position: "absolute",
                top: 64 + paddingTop,
                left: crossHairPosition.left,
                transform: "translateX(-50%)",
                height: yScale.range()[1] + CHART_BOTTOM_MARGIN,
                width: "1px",
                borderLeft: `1px dashed ${themeColors.disabled_typography}`,
                pointerEvents: "none",
              }}
            />,
            <div
              key={`key-crosshair-line-horizontal`}
              style={{
                position: "absolute",
                top: crossHairPosition.top + 68,
                left: currentPadding,
                transform: "translateY(-50%)",
                width: chartWidth,
                height: "1px",
                borderTop: `1px dashed ${themeColors.disabled_typography}`,
                pointerEvents: "none",
              }}
            />,
          ]
        : null}
    </div>
  );
};
