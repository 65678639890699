import type { DateDto } from "apis/oag";
import {
  AttachmentReadiness,
  AttachmentType,
  DigestEntryAttachmentsApi,
  DigestEntryType,
} from "apis/oag";
import {
  ActionType,
  Carousel,
  initialState,
  reducer,
} from "components/Carousel/Carousel";
import { ImageCarouselItem } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/CarouselPage/ImageCarouselItem/ImageCarouselItem";
import { VideoCarouselItem } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/CarouselPage/VideoCarouselItem/VideoCarouselItem";
import type { EssentialMediaDescriptor } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/interfaces";
import dayjs from "dayjs";
import { useSelectedWell } from "hooks/wells/useSelectedWell";
import { useWellShortInfo } from "hooks/wells/useWellShortInfo";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { match } from "ts-pattern";
import { apiConfig } from "utils/apiConfig";
import { blobToDownload } from "utils/helper";

import * as Styled from "./style";

const api = new DigestEntryAttachmentsApi(apiConfig);

export const CarouselContainer = ({
  defaultIndex,
  mediaSources,
  onPreviewedIndexChange,
  downloadDate,
  digestEntryType,
}: {
  defaultIndex: number;
  mediaSources: EssentialMediaDescriptor[];
  onPreviewedIndexChange: (index: number) => void;
  downloadDate: DateDto;
  digestEntryType: DigestEntryType;
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    position: defaultIndex < 0 ? 0 : defaultIndex,
  });

  const [isVideoInteracting, setIsVideoInteracting] = useState(false);

  const handleVideoInteractionStart = () => setIsVideoInteracting(true);
  const handleVideoInteractionEnd = () => setIsVideoInteracting(false);

  useEffect(() => {
    onPreviewedIndexChange(state.position);
  }, [onPreviewedIndexChange, state.position]);

  const getLoadedMedia = useCallback(
    (mediaSource: (typeof mediaSources)[number]) => {
      switch (mediaSource.attachmentType) {
        case AttachmentType.Video:
          return mediaSource.readiness === AttachmentReadiness.Ready ? (
            <VideoCarouselItem
              key={mediaSource?.id}
              handleVideoInteractionStart={handleVideoInteractionStart}
              handleVideoInteractionEnd={handleVideoInteractionEnd}
              source={mediaSource.videoSrc}
            />
          ) : null;
        case AttachmentType.Image:
          return (
            <ImageCarouselItem
              src={mediaSource.imageSrc.remoteUid}
              key={mediaSource?.id}
            />
          );

        default:
          return null;
      }
    },
    [],
  );

  const mediaCarouselItems = useMemo(
    () => mediaSources.map((mediaSource) => getLoadedMedia(mediaSource)),
    [mediaSources, getLoadedMedia],
  );

  const { data: wellInfo } = useWellShortInfo();
  const selectedWell = useSelectedWell();
  const wellName = useMemo(() => {
    return (wellInfo?.byId[selectedWell]?.name ?? "Unknown_Well").replace(
      " ",
      "_",
    );
  }, [selectedWell, wellInfo?.byId]);

  const handleOnClickDownload = useCallback(
    (position: number) => {
      const mediaFile = mediaSources[position];
      const fileName = mediaFile.fileName;
      const pageName = match(digestEntryType)
        .with(DigestEntryType.Note, () => "Logbook")
        .with(DigestEntryType.UnplannedEvent, () => "Events")
        .otherwise(() => digestEntryType);

      const downloadDateString = dayjs(downloadDate.utc).format("YYYYMMDD");
      const fileNameWithExtension = `${wellName}_${pageName}_${downloadDateString}_${mediaFile.id}.${mediaFile.mimeType.split("/")[1]}`;

      dispatch({
        type: ActionType.SetIsDownloading,
        isDownloading: true,
      });

      const download = (file: Blob) => {
        blobToDownload(file, fileNameWithExtension);
        dispatch({
          type: ActionType.SetIsDownloading,
          isDownloading: false,
        });
      };

      match(mediaFile.attachmentType)
        .with(AttachmentType.Video, () => {
          return api
            .apiDigestEntryAttachmentsDownloadVideoGet({ fileName })
            .then(download);
        })
        .with(AttachmentType.Image, () => {
          return api
            .apiDigestEntryAttachmentsDownloadImageGet({ fileName })
            .then(download);
        })
        .otherwise(() => {
          console.error("Unknown media type");
          dispatch({
            type: ActionType.SetIsDownloading,
            isDownloading: false,
          });
        });
    },
    [digestEntryType, downloadDate.utc, mediaSources, wellName],
  );

  return (
    <Styled.Content>
      <Carousel
        state={state}
        disableSwipes={isVideoInteracting}
        dispatch={dispatch}
        onClickDownload={handleOnClickDownload}
      >
        {mediaCarouselItems}
      </Carousel>
    </Styled.Content>
  );
};
