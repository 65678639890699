import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import { DigestEntryAttachmentsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import { RequestUID } from "utils/queryNamespaces";

const api = new DigestEntryAttachmentsApi(apiConfig);

export const useDigestAttachmentsByFilename = (
  fileName: string,
  options?: UseSuspenseQueryOptions<Blob>,
) => {
  const AttachmentsQueryKey = {
    uid: RequestUID.digestEntry,
    fileName,
  };

  return useSuspenseQuery({
    queryKey: [AttachmentsQueryKey],
    queryFn: () => {
      return api.apiDigestEntryAttachmentsDownloadImageGet({
        fileName,
      });
    },
    ...options,
  });
};
