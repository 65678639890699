import { EllipsisTextCSS } from "commonStyles";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { PDConstants } from "utils/PDConstants/PDConstants";

export const LegendContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  padding-top: calc(
    ${PDConstants.SizesPx.IntelPage.LeftPaneSelectorHeight.asNumber() +
      PDConstants.SizesPx.IntelPage.LeftPaneGap.asNumber()}px - 16px
  );
  gap: 4px;
`;

export const LegendItem = styled.div<{ $isHighlighted: boolean }>`
  height: 45px; // To account for bottom gap

  &:after {
    content: "";
    display: block;
    height: 4px;
  }

  ${({ $isHighlighted, theme }) =>
    $isHighlighted
      ? `background: ${theme.themeStyle.intel.legend_selected_bg}`
      : css`
          background: ${theme.themeStyle.colors.tertiary_bg};
          &:hover {
            background: ${({ theme }) =>
              theme.themeStyle.intel.legend_hover_bg};
          }
        `};

  width: calc(100% - 4px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  border-radius: 4px;

  ${({ theme }) =>
    theme.isDark
      ? css`
          border: 1px solid ${colors.gluon_grey};
          box-shadow: 0px 0px 3px 0px ${colors.black};
        `
      : css`
          border: 1px solid rgba(200, 206, 208, 0.4);
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
        `}
`;

export const DotContainer = styled.div`
  width: 48px;
  display: grid;
  grid-template-areas: ". coloredDot .";
  margin: 16px 0;
`;

export const ColoredDot = styled.div<{ $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  grid-area: coloredDot;
  justify-self: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
`;

export const LegendItemTitle = styled.div`
  ${EllipsisTextCSS};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 16px;
  font-weight: 500;
  width: 160px;
  padding-left: 4px;
`;
