import styled from "styled-components";
import colors from "utils/colors";
import { PDConstants } from "utils/PDConstants/PDConstants";

export const Title = styled.div`
  width: 100%;
  color: ${colors.well_color};
  height: 19px;
  font-size: 16px;
  font-weight: 500;
`;

export const DateInterval = styled.div`
  margin-bottom: 8px;
  p {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0;
  }
  b {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    font-size: 14px;
    font-weight: 500;
  }
`;

export const SmallKnot = styled.div`
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${PDConstants.SizesPx.Timeline.Knot.SizeSmall.asPixels()};
  width: ${PDConstants.SizesPx.Timeline.Knot.SizeSmall.asPixels()};
  border-radius: 5px;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: ${PDConstants.SizesPx.Timeline.Knot.SizeSmall.asPixels()};
  background: ${({ theme }) => theme.themeStyle.digestTimeline.knotSmall.bg};
`;

export const KnotDrop = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background: ${({ theme }) => theme.themeStyle.digestTimeline.knotSmall.drop};
`;

export const AuthorRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  gap: 1ch;
`;

export const Author = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
  max-width: 10px;
  line-height: 120%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;
`;

export const CreatedAt = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

export const DateChangeLogRow = styled.div`
  vertical-align: middle;
  align-items: center;
  border-radius: 6px;
  display: grid;
  background-color: ${({ theme }) =>
    theme.themeStyle.digestTimeline.dateUpdates.bg};
  font-size: 12px;
  color: ${colors.necron_compound};
  grid-template-columns: minmax(75px, 0.25fr) 1fr;
  width: 100%;
  padding: 4px 8px;
  margin-top: 8px;
  &:first-child {
    margin-top: 8px;
  }
  gap: 2px;

  @media (max-width: 1450px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const DateTransitionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 2px;
`;

export const FromDate = styled.span`
  text-decoration: line-through;
`;
