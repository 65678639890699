import type { BinCoordinates, DecimalRange } from "apis/oag";
import { DimensionType, ResultDataState } from "apis/oag";
import {
  TooltipGroup,
  TooltipHighlightValue,
} from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { useParameterHeatmapFetcher } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/fetcher";
import {
  BIN_PADDING_X,
  HoneyCombChart,
  INFO_ICON_X,
  INFO_ICON_Y,
  TOOLTIP_TOP,
} from "components/Lenses/ContainerLens/ParameterHeatmapKpi/HoneyComb/HoneyCombChart";
import type { ChartBin } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/HoneyComb/HoneyCombD3";
import { HoveredBinType } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/HoneyComb/HoneyCombD3";
import {
  StyledInformationIcon,
  StyledLensContainer,
  StyledRow,
  StyledTooltipGrayText,
} from "components/Lenses/ContainerLens/ParameterHeatmapKpi/style";
import { getUnits } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/utils";
import type { ParameterHeatmapKpiProps } from "components/Lenses/interfaces";
import { MiniLoadingChart } from "components/Lenses/MiniLoadingChart";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { useTracksSuspense } from "hooks/drillingInvariants/useTracks";
import { useLensTemplates } from "hooks/lens/useLensTemplates";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { LensName, LensValue } from "pages/Lens/LensSummaryView";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { Col, Space } from "utils/componentLibrary";
import { useUOM } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

const UNINITIALIZED_MEASURE = 0;

export const ParameterHeatmapKpi: React.FC<ParameterHeatmapKpiProps> =
  React.memo(({ lens, detailed }) => {
    const [height, setHeight] = React.useState(UNINITIALIZED_MEASURE);
    const [width, setWidth] = React.useState(UNINITIALIZED_MEASURE);

    const chartContainerRef = useRef<HTMLDivElement | null>(null);

    const heatmapBinsRequest = useParameterHeatmapFetcher({
      height,
      width,
      lensId: lens?.id,
      isDetailed: detailed,
    });

    useLayoutEffect(() => {
      if (!chartContainerRef.current) {
        return;
      }

      const observer = new ResizeObserver(([lensContainer]) => {
        if (
          heatmapBinsRequest.data?.dataState !== ResultDataState.Valid &&
          (height !== UNINITIALIZED_MEASURE || width !== UNINITIALIZED_MEASURE)
        )
          return;
        setHeight(lensContainer.contentRect.height);
        setWidth(lensContainer.contentRect.width);
      });

      observer.observe(chartContainerRef.current);

      return () => {
        observer.disconnect();
      };
    }, [chartContainerRef, heatmapBinsRequest.data?.dataState, height, width]);

    const bins = heatmapBinsRequest.data?.bins;

    let xRange: DecimalRange = { min: 0, max: 0 },
      yRange: DecimalRange = { min: 0, max: 0 };

    if (
      !heatmapBinsRequest.isError &&
      heatmapBinsRequest.data?.dataState === ResultDataState.Valid
    ) {
      const { xRange: xRangeFromRemote, yRange: yRangeFromRemote } =
        heatmapBinsRequest.data || {};

      xRange = xRangeFromRemote;
      yRange = yRangeFromRemote;
    }

    // RENDERING PART
    const { data: tracks } = useTracksSuspense();
    const { data: templates } = useLensTemplates();
    const template = templates.byId[lens.lensTemplateId];

    const [realtimeDataState] = useStateQuery<RealTimeDataEnum>(
      URL_STATE_PARAM.REALTIME_DATA_WIDGET,
      RealTimeDataEnum.DISABLED,
    );

    const currentUOM = useAppSelector((state) => state.global.unit);
    const metricUnit = useUOM(DimensionType.Metres);
    const timeUnit = useUOM(DimensionType.Seconds);

    const { xUnits, yUnits, zUnits } = getUnits(lens, tracks, currentUOM);

    const axesUnitsTransformer = useMemo(
      () => ({ x: xUnits, y: yUnits, z: zUnits }),
      [xUnits, yUnits, zUnits],
    );

    const trackLabels = useMemo(
      () => ({
        x: tracks?.byId[lens.xTrack.trackId]?.name ?? "",
        y: tracks?.byId[lens.yTrack.trackId]?.name ?? "",
        z: tracks?.byId[lens.zTrack.trackId]?.name ?? "",
      }),
      [
        lens.xTrack?.trackId,
        lens.yTrack?.trackId,
        lens.zTrack?.trackId,
        tracks?.byId,
      ],
    );

    const {
      showTooltip: showChartTooltip,
      hideTooltip: hideChartTooltip,
      tooltipElement: chartTooltipElement,
    } = useChartTooltip({
      containerRef: chartContainerRef,
      renderContent: ({
        tooltipData,
      }: {
        tooltipData?: { binData?: ChartBin; hoveredBinType: HoveredBinType };
      }) => {
        if (
          tooltipData &&
          tooltipData.hoveredBinType === HoveredBinType.PRIMARY
        ) {
          return (
            <TooltipHighlightValue> Well recommendation</TooltipHighlightValue>
          );
        } else if (
          tooltipData &&
          tooltipData.hoveredBinType === HoveredBinType.SECONDARY
        ) {
          return (
            <TooltipHighlightValue>
              {" "}
              Offset recommendation{" "}
            </TooltipHighlightValue>
          );
        }
        // tooltipData[0] because of d3 event, but normally there should be only one item here. If there are more items,
        // there are multiple data-points crunched in the same bin which should not happen too often
        const bin = tooltipData?.binData?.length
          ? tooltipData?.binData[0]
          : tooltipData?.binData;
        if (tooltipData?.binData?.length > 1) {
          Track.event(
            "Hexbin This datapoint was crunched, should not have been",
            tooltipData,
          );
          console.warn(
            "Hexbin This datapoint was crunched, should not have been",
            tooltipData,
          );
        }

        return (
          <TooltipGroup>
            {tooltipData?.hoveredBinType === HoveredBinType.ACTIVE ? (
              <TooltipHighlightValue> Most Recent Value </TooltipHighlightValue>
            ) : null}
            <TooltipHighlightValue>
              {trackLabels.x}: {xUnits.toString(bin?.x)}
            </TooltipHighlightValue>
            <TooltipHighlightValue>
              {trackLabels.y}: {yUnits.toString(bin?.y)}
            </TooltipHighlightValue>
            <TooltipHighlightValue>
              {trackLabels.z}: {zUnits.toString(bin?.z)}
            </TooltipHighlightValue>
            <br />
            <TooltipHighlightValue>
              <StyledTooltipGrayText>
                Distance: {metricUnit.display(bin?.distance)}{" "}
              </StyledTooltipGrayText>
            </TooltipHighlightValue>
            <TooltipHighlightValue>
              <StyledTooltipGrayText>
                Duration: {timeUnit.display(bin?.duration)}
              </StyledTooltipGrayText>
            </TooltipHighlightValue>
          </TooltipGroup>
        );
      },
    });

    const {
      showTooltip: showInfoTooltip,
      hideTooltip: hideInfoTooltip,
      tooltipElement: infoTooltipElement,
    } = useChartTooltip({
      containerRef: chartContainerRef,
      renderContent: () => {
        return (
          <TooltipHighlightValue>
            Only rotary drilling points included
          </TooltipHighlightValue>
        );
      },
    });

    const handleOnBinHover = useCallback(
      (e: MouseEvent, hoveredBinType: HoveredBinType, binData?: ChartBin) => {
        showChartTooltip({
          tooltipLeft: e.offsetX + BIN_PADDING_X,
          tooltipTop: e.offsetY + TOOLTIP_TOP,
          tooltipData: { hoveredBinType, binData },
        });
      },
      [showChartTooltip],
    );

    const handleOnBinHoverOut = useCallback(() => {
      hideChartTooltip();
    }, [hideChartTooltip]);

    const handleOnIconOver = useCallback(() => {
      showInfoTooltip({
        //  adding 8 pixels to fix tooltip in the middle of the icon, since it's 16
        tooltipLeft: INFO_ICON_X + 8,
        tooltipTop: INFO_ICON_Y,
      });
    }, [showInfoTooltip]);

    const handleOnIconOut = useCallback(() => {
      hideInfoTooltip();
    }, [hideInfoTooltip]);

    const activeBinSettings = useMemo(
      () =>
        lens.showActiveBin && heatmapBinsRequest.data?.lastCapturedBin
          ? {
              bin: heatmapBinsRequest.data?.lastCapturedBin,
              isRealtime: realtimeDataState === RealTimeDataEnum.ACTIVE,
            }
          : null,
      [
        heatmapBinsRequest.data?.lastCapturedBin,
        lens.showActiveBin,
        realtimeDataState,
      ],
    );

    const overlayBinSettings = useMemo<{
      primary?: BinCoordinates | undefined;
      secondary?: BinCoordinates | undefined;
    }>(() => {
      const primary =
        lens.showFocalRecommendation &&
        heatmapBinsRequest.data?.focalRecommendationBins?.coordinates
          ? {
              x: heatmapBinsRequest.data.focalRecommendationBins.coordinates.x,
              y: heatmapBinsRequest.data.focalRecommendationBins.coordinates.y,
            }
          : undefined;

      const secondary =
        lens.showOffsetRecommendation &&
        heatmapBinsRequest.data?.offsetRecommendationBins?.coordinates
          ? {
              x: heatmapBinsRequest.data.offsetRecommendationBins.coordinates
                ?.x,
              y: heatmapBinsRequest.data.offsetRecommendationBins.coordinates
                ?.y,
            }
          : undefined;

      return {
        primary,
        secondary,
      };
    }, [
      heatmapBinsRequest.data?.focalRecommendationBins?.coordinates,
      heatmapBinsRequest.data?.offsetRecommendationBins?.coordinates,
      lens.showFocalRecommendation,
      lens.showOffsetRecommendation,
    ]);

    const { shouldDisplayHeatmap, title } = useMemo(
      () => ({
        shouldDisplayHeatmap: (bins ?? []).length > 0,
        title: lens.showOffsetData ? "Offset Well(s)" : "Selected Well",
      }),
      [bins, lens.showOffsetData],
    );

    const { atomThemeVariant, themeStyle } = useCustomTheme();
    return (
      <>
        {detailed ? (
          <StyledRow gutter={32}>
            <Col flex="0 auto">
              <Space direction="vertical" size={6}>
                <LensName>Parameter Heatmap Lens</LensName>
                {shouldDisplayHeatmap ? null : (
                  <Space direction="horizontal">
                    <LensValue variant={atomThemeVariant}>- -</LensValue>
                  </Space>
                )}
              </Space>
            </Col>
          </StyledRow>
        ) : null}

        <StyledLensContainer
          isDetailed={detailed}
          ref={chartContainerRef}
          isLoading={!shouldDisplayHeatmap}
        >
          {shouldDisplayHeatmap && bins ? (
            <>
              <StyledInformationIcon
                onMouseOver={handleOnIconOver}
                onMouseOut={() => {
                  handleOnIconOut();
                }}
                width="16"
                height="16"
                color={themeStyle.colors.faint_typography}
              />
              <HoneyCombChart
                bins={bins}
                xAxisValueRange={xRange}
                yAxisValueRange={yRange}
                parentContainerWidth={width}
                parentContainerHeight={height}
                onBinHover={handleOnBinHover}
                onBinHoverOut={handleOnBinHoverOut}
                isLoading={heatmapBinsRequest.isFetching}
                trackLabels={trackLabels}
                axesUnitsTransformer={axesUnitsTransformer}
                activeBinSettings={activeBinSettings || undefined}
                overlayBinSettings={overlayBinSettings || undefined}
                title={title}
              />
            </>
          ) : (
            <MiniLoadingChart
              id={lens.id}
              template={template?.type}
              label={"Parameter Heatmap Lens"}
              detailed={detailed}
              isLoading={
                heatmapBinsRequest.data?.dataState !== ResultDataState.NoData
              }
              description={"..."}
            />
          )}

          {chartTooltipElement}
          {infoTooltipElement}
        </StyledLensContainer>
      </>
    );
  });

export default ParameterHeatmapKpi;
