/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanTvdPointDto
 */
export interface PlanTvdPointDto {
    /**
     * 
     * @type {number}
     * @memberof PlanTvdPointDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof PlanTvdPointDto
     */
    dynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof PlanTvdPointDto
     */
    holeDepth: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanTvdPointDto
     */
    dynamicHoleDepth: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanTvdPointDto
     */
    phaseId: number;
}

/**
 * Check if a given object implements the PlanTvdPointDto interface.
 */
export function instanceOfPlanTvdPointDto(value: object): value is PlanTvdPointDto {
    if (!('cumulativeDuration' in value) || value['cumulativeDuration'] === undefined) return false;
    if (!('dynamicDuration' in value) || value['dynamicDuration'] === undefined) return false;
    if (!('holeDepth' in value) || value['holeDepth'] === undefined) return false;
    if (!('dynamicHoleDepth' in value) || value['dynamicHoleDepth'] === undefined) return false;
    if (!('phaseId' in value) || value['phaseId'] === undefined) return false;
    return true;
}

export function PlanTvdPointDtoFromJSON(json: any): PlanTvdPointDto {
    return PlanTvdPointDtoFromJSONTyped(json, false);
}

export function PlanTvdPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanTvdPointDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cumulativeDuration': json['CumulativeDuration'],
        'dynamicDuration': json['DynamicDuration'],
        'holeDepth': json['HoleDepth'],
        'dynamicHoleDepth': json['DynamicHoleDepth'],
        'phaseId': json['PhaseId'],
    };
}

export function PlanTvdPointDtoToJSON(value?: PlanTvdPointDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'CumulativeDuration': value['cumulativeDuration'],
        'DynamicDuration': value['dynamicDuration'],
        'HoleDepth': value['holeDepth'],
        'DynamicHoleDepth': value['dynamicHoleDepth'],
        'PhaseId': value['phaseId'],
    };
}

