export const _Collections = {
  Carousel: {
    mimeTypes: {
      video: {
        empiricallyLocalWorkingFormats: [
          "video/mp4",
          "video/quicktime",
          "video/webm",
        ],
      },
    },
  },
};
