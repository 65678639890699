/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DigestEntryType = {
    Unknown: 'Unknown',
    Note: 'Note',
    UnplannedEvent: 'UnplannedEvent',
    TimeSaver: 'TimeSaver',
    LessonLearned: 'LessonLearned'
} as const;
export type DigestEntryType = typeof DigestEntryType[keyof typeof DigestEntryType];


export function instanceOfDigestEntryType(value: any): boolean {
    for (const key in DigestEntryType) {
        if (Object.prototype.hasOwnProperty.call(DigestEntryType, key)) {
            if (DigestEntryType[key as keyof typeof DigestEntryType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DigestEntryTypeFromJSON(json: any): DigestEntryType {
    return DigestEntryTypeFromJSONTyped(json, false);
}

export function DigestEntryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigestEntryType {
    return json as DigestEntryType;
}

export function DigestEntryTypeToJSON(value?: DigestEntryType | null): any {
    return value as any;
}

