import type { DigestChapterDto } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { AttachmentsCarousel } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/AttachmentsCarousel";
import { KnotDescription } from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotDescription/KnotDescription";
import * as CommonStyle from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotTypes/style";
import { DateUpdates } from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotTypes/UnplannedEventKnotPanel/DateUpdates";
import { useMouseHover } from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotTypes/useMouseHover";
import { useImperativeTimelineEventScroll } from "components/TvDChart/components/TimelineEventIndicator/useImperativeTimelineEventScroll";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { durationToText } from "utils/helper";
import { PDConstants } from "utils/PDConstants/PDConstants";

import * as Styled from "./style";

export const UnplannedEventKnotPanel = ({
  chapter,
}: {
  chapter: DigestChapterDto;
}) => {
  const displayedDuration = useMemo(
    () => durationToText(chapter.unplannedTime),
    [chapter.unplannedTime],
  );

  const dispatch = useDispatch();

  const [displayedStartDate, displayedEndDate] = useMemo(
    () => [
      dayjs(chapter.referenceDate.utc).format("MMM DD, YYYY · HH:mm"),
      chapter.referenceEndDate
        ? dayjs(chapter.referenceEndDate.utc).format("MMM DD, YYYY · HH:mm")
        : PDConstants.Format.NotAvailable,
    ],
    [chapter.referenceDate.utc, chapter.referenceEndDate],
  );

  const leaderEntry = useMemo(() => {
    return chapter.unplannedEvents[0];
  }, [chapter.unplannedEvents]);

  const selectedEventId = useAppSelector(
    (state) => state.timeline.selectedTimelineEvent,
  );

  const isSelected = useMemo(
    () => selectedEventId === leaderEntry.id,
    [leaderEntry.id, selectedEventId],
  );

  const isHovered = useAppSelector((state) => {
    return leaderEntry.id === state.state.hoveredEvent?.id;
  });

  const handleOnSelect = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();
    if (isSelected) {
      dispatch({
        type: "SET_SELECTED_TIMELINE_EVENT",
        payload: null,
      });
    } else {
      dispatch({
        type: "SET_SELECTED_TIMELINE_EVENT",
        payload: leaderEntry.id,
      });
    }
  };

  const { trackMouseHoverState } = useMouseHover({
    onHover: () => {
      dispatch({
        type: "SET_EVENT_HOVERED",
        payload: {
          id: leaderEntry.id,
          type: chapter.type,
        },
      });
    },
    onLeave: () => {
      dispatch({
        type: "SET_EVENT_HOVERED",
        payload: null,
      });
    },
  });

  const { getDomEventId } = useImperativeTimelineEventScroll();

  return (
    <>
      <CommonStyle.MemoContainer $hasSmallPadding>
        <CommonStyle.Knot
          $bg={colors.tern_grey}
          onClick={handleOnSelect}
          $isSelected={isSelected || isHovered}
          $showHoverOutline={!!selectedEventId}
          ref={trackMouseHoverState}
        >
          <PDComponent.SvgIcon name="schedule" />
        </CommonStyle.Knot>

        <CommonStyle.KnotPanel>
          <Styled.Title>Unplanned Event</Styled.Title>
          <CommonStyle.EntrySubtitle>
            Duration: <b>{displayedDuration}</b>
          </CommonStyle.EntrySubtitle>
          <Styled.DateInterval>
            <p>Start</p>
            <b>{displayedStartDate}</b>
          </Styled.DateInterval>

          <Styled.DateInterval>
            <p>End</p>
            <b>{displayedEndDate}</b>
          </Styled.DateInterval>
        </CommonStyle.KnotPanel>
      </CommonStyle.MemoContainer>

      {chapter.unplannedEvents.map((entry) => (
        <CommonStyle.MemoContainer key={entry.id} id={getDomEventId(entry.id)}>
          <Styled.SmallKnot>
            <Styled.KnotDrop />
          </Styled.SmallKnot>

          <CommonStyle.KnotPanel>
            <Styled.AuthorRow>
              <Styled.Author>{entry.authorDisplayName}</Styled.Author> ·
              <Styled.CreatedAt>
                {" "}
                {dayjs(entry.createdAt.utc).format("MM/DD/YYYY, HH:mm")}
              </Styled.CreatedAt>
            </Styled.AuthorRow>
            <KnotDescription description={entry.description} />

            <DateUpdates entry={entry} />
          </CommonStyle.KnotPanel>

          <AttachmentsCarousel
            digestEntry={entry}
            downloadDate={chapter.referenceDate}
          />
        </CommonStyle.MemoContainer>
      ))}
    </>
  );
};
