import { PivotOrderType, PivotType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { assign } from "lodash";
import React from "react";

const pivotOrderTypeToIcons: Record<PivotOrderType, React.ReactNode> = {
  [PivotOrderType.Unknown]: null,
  [PivotOrderType.PivotKeyAscending]: (
    <PDComponent.SvgIcon name="kpiAscending" />
  ),
  [PivotOrderType.PivotKeyDescending]: (
    <PDComponent.SvgIcon name="kpiDescending" />
  ),
  [PivotOrderType.PivotValueAscending]: (
    <PDComponent.SvgIcon name="performanceAscending" />
  ),
  [PivotOrderType.PivotValueDescending]: (
    <PDComponent.SvgIcon name="performanceDescending" />
  ),
  [PivotOrderType.LastUpdateAscending]: (
    <PDComponent.SvgIcon name="timeAscending" />
  ),
  [PivotOrderType.LastUpdateDescending]: (
    <PDComponent.SvgIcon name="timeDescending" />
  ),
  [PivotOrderType.WellSpudDateAscending]: (
    <PDComponent.SvgIcon name="timeAscending" />
  ),
  [PivotOrderType.WellSpudDateDescending]: (
    <PDComponent.SvgIcon name="timeDescending" />
  ),
  [PivotOrderType.WellLastActivityDateAscending]: (
    <PDComponent.SvgIcon name="timeAscending" />
  ),
  [PivotOrderType.WellLastActivityDateDescending]: (
    <PDComponent.SvgIcon name="timeDescending" />
  ),
  [PivotOrderType.UserInputAscending]: <PDComponent.SvgIcon name="compare" />,
  [PivotOrderType.UserInputDescending]: (
    <PDComponent.SvgIcon name="kpiDescending" />
  ),
};

const pivotOrderTypeToTitle: Record<PivotOrderType, string> = {
  [PivotOrderType.Unknown]: "Unknown",
  [PivotOrderType.PivotKeyAscending]: "Ascending",
  [PivotOrderType.PivotKeyDescending]: "Descending",
  [PivotOrderType.PivotValueAscending]: "Increasing performance",
  [PivotOrderType.PivotValueDescending]: "Decreasing performance",
  [PivotOrderType.LastUpdateAscending]: "Earliest to Latest",
  [PivotOrderType.LastUpdateDescending]: "Latest to Earliest",
  [PivotOrderType.WellSpudDateAscending]: "Earliest to Latest (Start Date)",
  [PivotOrderType.WellSpudDateDescending]: "Latest to Earliest (Start Date)",
  [PivotOrderType.WellLastActivityDateAscending]:
    "Earliest to Latest (Last Date)",
  [PivotOrderType.WellLastActivityDateDescending]:
    "Latest to Earliest (Last Date)",
  [PivotOrderType.UserInputAscending]: "User sorting",
  [PivotOrderType.UserInputDescending]: "Descending",
};

export const isPerformanceSorting = (pivotOrder: PivotOrderType) =>
  pivotOrder === PivotOrderType.PivotValueAscending ||
  pivotOrder === PivotOrderType.PivotValueDescending;
export const defaultPointsOrder = (
  pivotsArr: {
    orderValue: PivotOrderType;
    value: PivotType;
    id: string;
  }[],
) => {
  const pivotsNullFiltered = pivotsArr.filter(
    (e) => e.value !== PivotType.None,
  );
  const pivotKpiValueIndexInsideFiltered = pivotsNullFiltered.findIndex((e) =>
    isPerformanceSorting(e.orderValue),
  );
  const pivotKpiValueIndex = pivotsArr.findIndex((e) =>
    isPerformanceSorting(e.orderValue),
  );
  if (pivotKpiValueIndexInsideFiltered !== pivotsNullFiltered.length - 1) {
    return pivotsArr.map((pivotIt, index) =>
      index === pivotKpiValueIndex
        ? {
            ...pivotIt,
            orderValue: getDefaultPivotOrderTypeByPivot(pivotIt.value),
          }
        : pivotIt,
    );
  }
  return pivotsArr;
};

export const getDefaultPivotOrderTypeByPivot: (
  pivot: PivotType,
) => PivotOrderType = (pivot) => {
  switch (pivot) {
    case PivotType.None:
      return PivotOrderType.Unknown;
    case PivotType.StandNumber:
      return PivotOrderType.PivotKeyAscending;
    case PivotType.Day:
    case PivotType.Week:
    case PivotType.Month:
    case PivotType.Quarter:
    case PivotType.Semester:
    case PivotType.Year:
      return PivotOrderType.LastUpdateAscending;
    case PivotType.Rig:
      return PivotOrderType.UserInputAscending;
    case PivotType.Well:
      return PivotOrderType.WellLastActivityDateAscending;
    case PivotType.HoleSection:
    case PivotType.HoleSize:
    case PivotType.DirectionalInterval:
    case PivotType.Driller:
    case PivotType.Shift:
      return PivotOrderType.PivotKeyAscending;
    default:
      return PivotOrderType.Unknown;
  }
};

export const getPivotOrderTypeTitle = ({
  pivot,
  pivotSort,
}: {
  pivot: PivotType;
  pivotSort: PivotOrderType;
}) => {
  if (
    (
      [
        PivotType.DirectionalInterval,
        PivotType.HoleSection,
        PivotType.Shift,
        PivotType.HoleSize,
      ] as PivotType[]
    ).includes(pivot)
  ) {
    if (pivotSort === PivotOrderType.PivotKeyAscending) {
      return "Default Order";
    } else if (pivotSort === PivotOrderType.PivotKeyDescending) {
      return "Reverse Order";
    } else {
      return pivotOrderTypeToTitle[pivotSort];
    }
  }
  return pivotOrderTypeToTitle[pivotSort];
};

export const getPivotOrderIcon = ({
  pivot,
  pivotSort,
}: {
  pivot: PivotType;
  pivotSort: PivotOrderType;
}) => {
  if (
    (
      [
        PivotType.DirectionalInterval,
        PivotType.HoleSection,
        PivotType.Shift,
        PivotType.HoleSize,
      ] as PivotType[]
    ).includes(pivot)
  ) {
    if (pivotSort === PivotOrderType.PivotKeyAscending) {
      return <PDComponent.SvgIcon name="seqAscending" />;
    } else if (pivotSort === PivotOrderType.PivotKeyDescending) {
      return <PDComponent.SvgIcon name="seqDescending" />;
    } else {
      return pivotOrderTypeToIcons[pivotSort];
    }
  }
  return pivotOrderTypeToIcons[pivotSort];
};

export function getDisplayOptions(isComparing?: boolean) {
  return (stateOptions: Array<PivotType>) => {
    // Remove any option that is not allowed
    let prevOptions = stateOptions.filter(
      (option) => option !== PivotType.None,
    );
    if (isComparing)
      prevOptions = prevOptions.filter(
        (option) => option !== PivotType.StandNumber,
      );
    else prevOptions = prevOptions.filter((option) => option !== PivotType.Rig);

    // Add the necessary option in
    if (isComparing) {
      if (!prevOptions.find((option) => option === PivotType.Rig)) {
        const newElement = PivotType.Rig;
        if (prevOptions.length > 1) {
          prevOptions.splice(1, 0, newElement);
          return prevOptions;
        }
        return assign(Array(3).fill(PivotType.None), [
          newElement,
          ...prevOptions,
        ]);
      } else {
        return assign(Array(3).fill(PivotType.None), prevOptions);
      }
    } else {
      if (prevOptions.length === 0) {
        const newElement = PivotType.Day;
        return assign(Array(2).fill(PivotType.None), [newElement]);
      }
      return assign(Array(2).fill(PivotType.None), prevOptions);
    }
  };
}
