/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigestEntryLessonLearnedDto } from './DigestEntryLessonLearnedDto';
import {
    DigestEntryLessonLearnedDtoFromJSON,
    DigestEntryLessonLearnedDtoFromJSONTyped,
    DigestEntryLessonLearnedDtoToJSON,
} from './DigestEntryLessonLearnedDto';
import type { DigestEntryUnplannedEventDto } from './DigestEntryUnplannedEventDto';
import {
    DigestEntryUnplannedEventDtoFromJSON,
    DigestEntryUnplannedEventDtoFromJSONTyped,
    DigestEntryUnplannedEventDtoToJSON,
} from './DigestEntryUnplannedEventDto';
import type { DigestEntryTimeSaverDto } from './DigestEntryTimeSaverDto';
import {
    DigestEntryTimeSaverDtoFromJSON,
    DigestEntryTimeSaverDtoFromJSONTyped,
    DigestEntryTimeSaverDtoToJSON,
} from './DigestEntryTimeSaverDto';
import type { DigestEntryType } from './DigestEntryType';
import {
    DigestEntryTypeFromJSON,
    DigestEntryTypeFromJSONTyped,
    DigestEntryTypeToJSON,
} from './DigestEntryType';
import type { DigestEntryNoteDto } from './DigestEntryNoteDto';
import {
    DigestEntryNoteDtoFromJSON,
    DigestEntryNoteDtoFromJSONTyped,
    DigestEntryNoteDtoToJSON,
} from './DigestEntryNoteDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface DigestChapterDto
 */
export interface DigestChapterDto {
    /**
     * 
     * @type {number}
     * @memberof DigestChapterDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DigestChapterDto
     */
    digestId: number;
    /**
     * 
     * @type {DigestEntryType}
     * @memberof DigestChapterDto
     */
    type: DigestEntryType;
    /**
     * 
     * @type {Array<DigestEntryNoteDto>}
     * @memberof DigestChapterDto
     */
    notes: Array<DigestEntryNoteDto>;
    /**
     * 
     * @type {Array<DigestEntryUnplannedEventDto>}
     * @memberof DigestChapterDto
     */
    unplannedEvents: Array<DigestEntryUnplannedEventDto>;
    /**
     * 
     * @type {Array<DigestEntryTimeSaverDto>}
     * @memberof DigestChapterDto
     */
    timeSavers: Array<DigestEntryTimeSaverDto>;
    /**
     * 
     * @type {Array<DigestEntryLessonLearnedDto>}
     * @memberof DigestChapterDto
     */
    lessonsLearned: Array<DigestEntryLessonLearnedDto>;
    /**
     * 
     * @type {DateDto}
     * @memberof DigestChapterDto
     */
    readonly referenceDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof DigestChapterDto
     */
    readonly referenceEndDate?: DateDto | null;
    /**
     * 
     * @type {number}
     * @memberof DigestChapterDto
     */
    readonly unplannedTime: number;
}



/**
 * Check if a given object implements the DigestChapterDto interface.
 */
export function instanceOfDigestChapterDto(value: object): value is DigestChapterDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('digestId' in value) || value['digestId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('notes' in value) || value['notes'] === undefined) return false;
    if (!('unplannedEvents' in value) || value['unplannedEvents'] === undefined) return false;
    if (!('timeSavers' in value) || value['timeSavers'] === undefined) return false;
    if (!('lessonsLearned' in value) || value['lessonsLearned'] === undefined) return false;
    if (!('referenceDate' in value) || value['referenceDate'] === undefined) return false;
    if (!('unplannedTime' in value) || value['unplannedTime'] === undefined) return false;
    return true;
}

export function DigestChapterDtoFromJSON(json: any): DigestChapterDto {
    return DigestChapterDtoFromJSONTyped(json, false);
}

export function DigestChapterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigestChapterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'digestId': json['DigestId'],
        'type': DigestEntryTypeFromJSON(json['Type']),
        'notes': ((json['Notes'] as Array<any>).map(DigestEntryNoteDtoFromJSON)),
        'unplannedEvents': ((json['UnplannedEvents'] as Array<any>).map(DigestEntryUnplannedEventDtoFromJSON)),
        'timeSavers': ((json['TimeSavers'] as Array<any>).map(DigestEntryTimeSaverDtoFromJSON)),
        'lessonsLearned': ((json['LessonsLearned'] as Array<any>).map(DigestEntryLessonLearnedDtoFromJSON)),
        'referenceDate': DateDtoFromJSON(json['ReferenceDate']),
        'referenceEndDate': json['ReferenceEndDate'] == null ? undefined : DateDtoFromJSON(json['ReferenceEndDate']),
        'unplannedTime': json['UnplannedTime'],
    };
}

export function DigestChapterDtoToJSON(value?: Omit<DigestChapterDto, 'ReferenceDate'|'ReferenceEndDate'|'UnplannedTime'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'DigestId': value['digestId'],
        'Type': DigestEntryTypeToJSON(value['type']),
        'Notes': ((value['notes'] as Array<any>).map(DigestEntryNoteDtoToJSON)),
        'UnplannedEvents': ((value['unplannedEvents'] as Array<any>).map(DigestEntryUnplannedEventDtoToJSON)),
        'TimeSavers': ((value['timeSavers'] as Array<any>).map(DigestEntryTimeSaverDtoToJSON)),
        'LessonsLearned': ((value['lessonsLearned'] as Array<any>).map(DigestEntryLessonLearnedDtoToJSON)),
    };
}

