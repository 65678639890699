import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { DigestTimelineDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDWellDigestTimelineKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const wellsApi = new WellsApi(apiConfig);

export function useWellDigestTimeline({
  wellId,
  options,
}: {
  wellId: number;
  options?: UseSuspenseQueryOptions<DigestTimelineDto>;
}) {
  const WellDigestTimelineKey: PDWellDigestTimelineKey = {
    wellId,
    uid: RequestUID.digestTimeline,
    type: PDQueryType.DIGEST_TIMELINE,
  };

  return useSuspenseQuery<DigestTimelineDto>({
    queryKey: [WellDigestTimelineKey],
    queryFn: () =>
      wellsApi.apiWellsIdDigestTimelineGet({
        id: wellId,
      }),

    staleTime: Infinity,
    refetchOnMount: true,
    ...options,
  });
}
