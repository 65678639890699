import type { DateDto, DigestEntryType } from "apis/oag";
import { CarouselPage } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/CarouselPage/CarouselPage";
import type { EssentialMediaDescriptor } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/interfaces";
import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { isValidId } from "utils/helper";

import * as Styled from "./style";

export const useCarouselModal = ({
  mediaSources,
  topbarDate,
  digestEntryType,
  downloadDate,
}: {
  mediaSources: EssentialMediaDescriptor[] | null;
  topbarDate: Date | undefined;
  digestEntryType: DigestEntryType;
  downloadDate: DateDto;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedMediaSourceId, setSelectedMediaSourceId] = useState<
    number | null
  >(null);

  const toggleCarouselMedia = useCallback(
    (selectedMediaSourceId: number) => {
      if (isOpen) {
        setIsOpen(false);
      } else {
        setSelectedMediaSourceId(selectedMediaSourceId);
        setIsOpen(true);
      }
    },
    [isOpen],
  );

  return {
    toggleCarouselMedia,
    isOpen,
    renderInsidePortal: () => {
      return isOpen
        ? createPortal(
            <Styled.Container>
              {mediaSources?.length && isValidId(selectedMediaSourceId) ? (
                <CarouselPage
                  selectedMediaSourceId={selectedMediaSourceId}
                  topbarDate={topbarDate}
                  downloadDate={downloadDate}
                  digestEntryType={digestEntryType}
                  mediaSources={mediaSources}
                  onClose={() => setIsOpen(false)}
                />
              ) : null}
            </Styled.Container>,
            document.body,
          )
        : null;
    },
  };
};
