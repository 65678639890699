import type {
  DateDto,
  DigestEntryAttachmentDto,
  DigestEntryNoteDto,
  DigestEntryUnplannedEventDto,
} from "apis/oag";
import { AttachmentType } from "apis/oag";
import { CarouselThumbnailByReadiness } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/CarouselThumbnailByReadiness";
import { useCarouselModal } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/useCarouselModal";
import { attachmentToEssentialMediaSource } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/utils";
import { useMemo } from "react";
import { PDConstants } from "utils/PDConstants/PDConstants";

import * as Styled from "./style";

const maxDeepLoadedAttachments =
  PDConstants.Amounts.Timeline.CarouselVisibleItems;

export const AttachmentsCarousel = ({
  digestEntry,
  downloadDate,
}: {
  digestEntry: DigestEntryNoteDto | DigestEntryUnplannedEventDto;
  downloadDate: DateDto;
}) => {
  const restOfImagesCount = useMemo(() => {
    const rest = digestEntry.attachmentCount - maxDeepLoadedAttachments + 1;
    return rest > 0 && digestEntry.attachmentCount !== maxDeepLoadedAttachments
      ? rest
      : 0;
  }, [digestEntry.attachmentCount]);

  const { renderInsidePortal, toggleCarouselMedia } = useCarouselModal({
    mediaSources: digestEntry.attachments.map(attachmentToEssentialMediaSource),
    topbarDate: digestEntry?.createdAt.utc,
    downloadDate,
    digestEntryType: digestEntry.digestEntryType,
  });

  const attachments = useMemo(
    () =>
      digestEntry.attachments
        .slice(0, maxDeepLoadedAttachments)
        .filter(
          (attachment): attachment is DigestEntryAttachmentDto =>
            attachment !== null,
        ),
    [digestEntry.attachments],
  );

  return (
    <Styled.ImagesContainer>
      {attachments.map((attachment, index) => {
        const isBlurred =
          index >= PDConstants.Amounts.Timeline.CarouselVisibleItems - 1 &&
          restOfImagesCount > 0;

        return (
          <Styled.CustomImageContainer
            onClick={() => {
              toggleCarouselMedia(attachment.id);
            }}
            key={`${attachment.id}-${index}-${attachment.digestEntryId}`}
            $isBlurred={isBlurred}
          >
            <img src={attachment.thumbnail ?? ""} alt="attachment" />

            <CarouselThumbnailByReadiness
              isVideo={attachment.attachmentType === AttachmentType.Video}
              attachmentReadiness={attachment.readiness}
              isBlurred={isBlurred}
            />

            {restOfImagesCount > 0 && isBlurred ? (
              <h1>+{restOfImagesCount}</h1>
            ) : null}
          </Styled.CustomImageContainer>
        );
      })}
      {renderInsidePortal()}
    </Styled.ImagesContainer>
  );
};
