import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import colors from "utils/colors";
import { PDConstants } from "utils/PDConstants/PDConstants";

export const ControlBar = styled.div`
  background: linear-gradient(
    180deg,
    ${colors.gluon_grey} 0%,
    rgba(27, 27, 31, 0.9) 100%
  );
  display: grid;
  grid-template-areas: ". date close";
  height: ${PDConstants.SizesPx.Carousel.Topbar.height.asPixels()};
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  color: ${colors.white};
`;

export const DateTitle = styled.div`
  align-items: center;
  grid-area: date;
  font-size: 20px;
  font-weight: 700;
`;

export const CloseContainer = styled.div`
  grid-area: close;
  padding-right: 32px;
`;
export const CloseButton = styled(PDComponent.SvgIcon).attrs({
  name: "close",
})`
  color: ${colors.lunar_rays};
  cursor: pointer;
  font-size: 28px;
`;
