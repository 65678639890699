import styled, { css } from "styled-components";
import colors from "utils/colors";
import { PDConstants } from "utils/PDConstants/PDConstants";

export const KnotPanel = styled.div`
  margin-top: -6px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  transform: translateY(-6px);
`;

export const Title = styled.div`
  width: 100%;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
`;

export const UnplannedTimeSubtitle = styled.div`
  line-height: normal;
  font-size: 14px;
  margin-top: 3px;

  b {
    color: ${colors.well_color};
    font-size: 16px;
    font-size: 500;
  }
`;

export const EntrySubtitle = styled.p`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;

  b {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};

    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
`;

export const UserName = styled.p`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;

export const Knot = styled.div<{
  $bg?: string;
  $isSelected?: boolean;
  $showHoverOutline?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${PDConstants.SizesPx.Timeline.Knot.Size.asPixels()};
  width: 28px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: ${(p) => p.$bg ?? colors.well_color};
  text-align: center;
  line-height: ${PDConstants.SizesPx.Timeline.Knot.Size.asPixels()};
  color: ${({ theme, $bg }) =>
    $bg ? colors.neutral : theme.themeStyle.colors.inverted_primary_typography};

  ${({ $showHoverOutline }) =>
    $showHoverOutline
      ? css`
          &:hover {
            outline: 2px solid ${colors.well_color};
          }
        `
      : null}

  ${({ $isSelected, theme }) =>
    $isSelected
      ? css`
          background: ${colors.helen_of_troy}4d;
          box-shadow: 0px 0px 1px 6px ${colors.perfect_pear}${theme.isDark ? "CC" : undefined};

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: ${colors.white};
            opacity: 0.9;
            z-index: -1;
          }
        `
      : null}
`;

export const MemoContainer = styled.div<{ $hasSmallPadding?: boolean }>`
  position: relative;
  width: 100%;
  padding-bottom: ${({ $hasSmallPadding }) =>
    $hasSmallPadding ? "14px" : "30px"};
  padding-left: 36px;
  padding-right: 48px;

  &:last-child {
    padding-bottom: 0;
  }
`;
