/* eslint-disable react/no-multi-comp */
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Button } from "atoms/Form";
import LeftContent from "components/Header/LeftContent";
import RightContent from "components/Header/RightContent";
import { Loader } from "components/Loader";
import { FullPageLoader } from "components/Loader/FullPage";
import { WellsMapViewProvider } from "components/MapView/useWellsMapView";
import { MobileModal } from "components/MobileModal/MobileModal";
import { PDComponent } from "components/PDComponents";
import { ReportsFetcher } from "components/ReportsFetcher";
import { CustomThemeProvider } from "CustomThemeProvider";
import { GlobalStyles } from "globalStyles";
import { useInitializeUser } from "hooks/user/useInitializeUser";
import mapboxgl from "mapbox-gl";
import { FilteredAllWellsProvider } from "pages/AllWells/useFilteredAllWells";
import { Suspense } from "react";
import CacheBuster from "react-cache-buster";
import { Provider as ReduxProvider } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { store } from "reducers/store";
import { PDRoutesMapping } from "routes";
import { MixpanelProvider } from "services/Mixpanel";
import { CenteredError, StyledTitle } from "style";
import { loginRequest } from "utils/auth/authConfig";
import { Col, Layout, Row, Space, Typography } from "utils/componentLibrary";
import { Content, Header } from "utils/componentLibrary/Layout";
import { getErrorHashCode } from "utils/getErrorHashCode";
import { PDConstants } from "utils/PDConstants/PDConstants";
import { GenericColorsContextProvider } from "utils/useColors";
import { zIndexLayer } from "utils/zIndex";

import { buildVersion } from "../package.json";

// Mapbox Access Key
mapboxgl.accessToken =
  "pk.eyJ1IjoicHJlY2lzaW9uZHJpbGxpbmciLCJhIjoiY2treDIwczh3MGF3aTJvbDZ6czc3NzM0eSJ9.kwi3BLpXvHxfV_CnZWvKXg";

// React Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",

    Component: () => {
      useInitializeUser();
      return (
        <Sentry.ErrorBoundary
          fallback={({ error }) => (
            <CenteredError>
              <Space direction="vertical">
                <Typography.Title level={1}>Oops!</Typography.Title>
                <Typography.Title level={2}>
                  Error reference id:{" "}
                  <b>{getErrorHashCode(error?.stack ?? "")}</b>
                </Typography.Title>
                <Typography.Title level={3}>
                  Something has caused the application to crash. <br /> This
                  error has been reported and we are working on the fix.
                </Typography.Title>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => window.location.replace("/")}
                  trackingName="Error - Go Back Main Page"
                >
                  Go to main page
                </Button>
              </Space>
            </CenteredError>
          )}
          beforeCapture={(scope, error) => {
            scope.setTag(
              "pd-error-tracking-id",
              getErrorHashCode(error?.stack ?? ""),
            );
          }}
        >
          <Suspense fallback={<Loader centered />}>
            <WellsMapViewProvider>
              <GenericColorsContextProvider>
                <FilteredAllWellsProvider>
                  <ReportsFetcher />
                  {/* TODO: Redo Spin at page level */}
                  <Outlet />
                </FilteredAllWellsProvider>
              </GenericColorsContextProvider>
            </WellsMapViewProvider>
          </Suspense>
        </Sentry.ErrorBoundary>
      );
    },
    children: [
      {
        Component: () => (
          <Layout>
            <Header
              style={{
                position: "fixed",
                zIndex: zIndexLayer.phobos,
                width: "100%",
                padding: "0 24px",
              }}
            >
              <Row
                justify="space-between"
                align="middle"
                style={{
                  height: PDConstants.SizesPx.Layout.TopBarHeight.asPixels(),
                }}
              >
                <Col flex="0 auto">
                  <StyledTitle level={2} variant="dark">
                    <PDComponent.SvgIcon name="clarityLogo" />
                  </StyledTitle>
                </Col>
              </Row>
            </Header>
            <Content
              style={{
                height: `calc(100vh - ${PDConstants.SizesPx.Layout.TopBarHeight.asPixels()})`,
                marginTop: PDConstants.SizesPx.Layout.TopBarHeight.asPixels(),
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        ),
        children: [PDRoutesMapping.public.reportPrepare],
      },
      {
        Component: () => {
          if (sessionStorage.getItem("isReport")) {
            return (
              <Layout>
                <Content style={{ height: "calc(100vh)", marginTop: "0" }}>
                  <Outlet />
                </Content>
              </Layout>
            );
          }

          return <Navigate to="/" />;
        },
        children: Object.values(PDRoutesMapping.report),
      },
      {
        Component: () => {
          const authRequest = {
            ...loginRequest,
          };
          return (
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={authRequest}
              errorComponent={() => <Navigate to="/auth/401/msalError" />}
              loadingComponent={() => <FullPageLoader />}
            >
              <Layout>
                <Header
                  style={{
                    position: "fixed",
                    zIndex: zIndexLayer.phobos,
                    width: "100%",
                    padding: "0 24px",
                  }}
                >
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{
                      height:
                        PDConstants.SizesPx.Layout.TopBarHeight.asPixels(),
                    }}
                  >
                    <Col flex="0 auto">
                      <LeftContent renderReady={true} />
                    </Col>
                    <Col flex="0 auto">{<RightContent />}</Col>
                  </Row>
                </Header>
                <Content
                  style={{
                    height: `calc(100vh - ${PDConstants.SizesPx.Layout.TopBarHeight.asPixels()})`,
                    marginTop:
                      PDConstants.SizesPx.Layout.TopBarHeight.asPixels(),
                  }}
                >
                  <Outlet />
                </Content>
              </Layout>
            </MsalAuthenticationTemplate>
          );
        },
        children: Object.values(PDRoutesMapping.private),
      },
      {
        Component: () => (
          <Layout>
            <Header
              style={{
                position: "fixed",
                zIndex: zIndexLayer.phobos,
                width: "100%",
                padding: "0 24px",
              }}
            >
              <Row
                justify="space-between"
                align="middle"
                style={{
                  height: PDConstants.SizesPx.Layout.TopBarHeight.asPixels(),
                }}
              >
                <Col flex="0 auto">
                  <StyledTitle level={2} variant="dark">
                    <PDComponent.SvgIcon name="clarityLogo" />
                  </StyledTitle>
                </Col>
              </Row>
            </Header>
            <Content
              style={{
                height: `calc(100vh - ${PDConstants.SizesPx.Layout.TopBarHeight.asPixels()})`,
                marginTop: PDConstants.SizesPx.Layout.TopBarHeight.asPixels(),
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        ),
        children: Object.values(PDRoutesMapping.public),
      },
      {
        path: "*",
        Component: () => <Navigate to="/auth/404" />,
      },
    ],
  },
]);

function App() {
  const isProduction = import.meta.env.PROD || import.meta.env.DEV; // check dev as well just for tests
  return (
    <CacheBuster
      currentVersion={buildVersion}
      isEnabled={isProduction} // If false, the library is disabled.
      isVerboseMode={false} // If true, the library writes verbose logs to console.
      loadingComponent={<Loader centered />} // If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} // If public assets are hosted somewhere other than root on your server.
    >
      <ReduxProvider store={store}>
        <CustomThemeProvider>
          <GlobalStyles />
          <MobileModal />

          <QueryClientProvider client={queryClient}>
            <MixpanelProvider>
              <RouterProvider
                router={router}
                fallbackElement={<Loader centered />}
              />
            </MixpanelProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </CustomThemeProvider>
      </ReduxProvider>
    </CacheBuster>
  );
}

export default App;
