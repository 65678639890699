// Documentation: https://github.com/bitmovin/bitmovin-player-react

import "bitmovin-player-ui/dist/css/bitmovinplayer-ui.css";

import type { VideoManifestDto } from "apis/oag";
import type { PlayerAPI, PlayerConfig, SourceConfig } from "bitmovin-player";
import { PlayerEvent } from "bitmovin-player";
import { BitmovinPlayer } from "bitmovin-player-react";
import type { VideoPlayerSource } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/interfaces";
import { useInView } from "framer-motion";
import React, { useCallback, useRef } from "react";

import { VideoPlayer } from "./styles";
import { PDConstants } from "utils/PDConstants/PDConstants";

const getAspectRatioFromSource = (manifests?: VideoManifestDto | null) => {
  if (manifests?.height && manifests?.width) {
    return manifests.width / manifests.height;
  }
  return 16 / 9;
};

export const BitmovinVideoPlayer = React.memo(
  ({
    source: { manifests, progressiveConfig },
    handleVideoInteractionStart,
    handleVideoInteractionEnd,
  }: {
    source: VideoPlayerSource;
    handleVideoInteractionStart: () => void;
    handleVideoInteractionEnd: () => void;
  }) => {
    const aspectRatio = getAspectRatioFromSource(manifests);
    const isLandscape = aspectRatio > 1;
    const playerConfig: PlayerConfig = {
      key: "dab3f2e5-69f6-4f10-a9ca-a99d609e27eb",
      playback: {
        muted: false,
        autoplay: true,
      },
      style: {
        width: isLandscape ? "100%" : undefined,
        height: `calc(100vh - ${PDConstants.SizesPx.Carousel.Topbar.height.asNumber() * 2}px)`,
        aspectratio: aspectRatio,
      },
    };
    const playerSource: SourceConfig = {
      // dash: manifests.dash ?? undefined, // DRM issue with this type of manifest
      hls: manifests?.hls ?? undefined,
      progressive: progressiveConfig ? [progressiveConfig] : undefined,
    };
    const containerRef = useRef<HTMLDivElement>(null);
    const isInView = useInView(containerRef, { amount: 0.1 });
    const handlePlayerRef = useCallback(
      (player: PlayerAPI) => {
        player.on(PlayerEvent.Seek, handleVideoInteractionStart);
        player.on(PlayerEvent.TimeChanged, handleVideoInteractionEnd);
      },
      [handleVideoInteractionStart, handleVideoInteractionEnd],
    );

    return (
      <VideoPlayer $isLandscape={isLandscape} ref={containerRef}>
        {isInView ? (
          <BitmovinPlayer
            playerRef={handlePlayerRef}
            config={playerConfig}
            source={playerSource}
          />
        ) : null}
      </VideoPlayer>
    );
  },
);
