import ButtonGroup from "antd/lib/button/button-group";
import styled from "styled-components";

export const Buttons = styled(ButtonGroup)`
  a:first-child {
    .ant-btn {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 0 !important;
    }
  }
  a:last-child {
    .ant-btn {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  button {
    @media screen and (max-width: 1450px) {
      padding: 0 8px !important;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
`;
