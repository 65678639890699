import type { PivotOrderType } from "apis/oag";
import { PivotType } from "apis/oag";
import { getDefaultPivotOrderTypeByPivot } from "components/RigComparisonSelectors/DisplayOptionsModal/utils";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useRigKpiPivotQueryParams } from "hooks/pivot/useStackedKpi";
import type { FC } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
  AddColorsForIdsAction,
  ResetLegendColorsAction,
} from "reducers/legendColorReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import type { ReactChildren } from "utils/types";

const aggregationColor = "#C8CED0";

export const colorsSequence = [
  "#0D9B83",
  "#AA9C7C",
  "#A492C3",
  "#C4877D",
  "#18A6B8",
  "#DCBDCB",
  "#8B90A6",
  "#FFB299",
  "#69BB90",
  "#8AC4D0",
  "#B9B591",
  "#9199C2",
  "#D2D6AE",
  "#D66E6B",
  "#CAACCF",
  "#F6B179",
  "#DEC299",
  "#B7D299",
  "#8E7E78",
  "#B0CBE1",
  "#82B584",
];

export const colorDecisionalPivotTypeArray: PivotType[] = [
  PivotType.DirectionalInterval,
  PivotType.HoleSize,
  PivotType.Rig,
  PivotType.HoleSection,
  PivotType.Driller,
];

// There's an alternative view for WedgeKpi, which is visible only on detailed lens -> Legend.
// Thus the additional "WedgeAlternative" pivot type.
type ColorPivot = PivotType | "WedgeAlternative";

type IColorsRecord = Record<string, Record<string, string>>;

type GetColorProps = {
  key: string;
  currentPivot?: ColorPivot;
  isMock?: boolean;
};

type IGetColorCallback = ({
  key,
  currentPivot,
  isMock,
}: GetColorProps) => string;

type IRegisterKeysCallback = (props: {
  pivot?: ColorPivot;
  keys: string[];
  isMock?: boolean;
  additive?: boolean;
}) => void;

type IColorsContext = {
  colors: IColorsRecord;
  getColor: IGetColorCallback;
  registerKeys: IRegisterKeysCallback;
  resetColors: (props?: unknown[]) => void;
};

const ColorsContext = createContext<IColorsContext | null>(null);

export const useColors = () => {
  const colorsContext = useContext(ColorsContext);
  if (!colorsContext) {
    throw new Error("useColors must be used within a ColorsContextProvider");
  }
  return colorsContext;
};

export const useResetRigColors = (
  selectedTab: number | null,
  isRigDashboard: boolean,
) => {
  const queryParams = useRigKpiPivotQueryParams(false);
  const { resetColors } = useColors();

  useEffect(() => {
    if (isRigDashboard) {
      resetColors();
    }
  }, [selectedTab, resetColors, queryParams, isRigDashboard]);
};

function getNextInSeq(currentValues: string[]) {
  for (const i of colorsSequence) {
    if (!currentValues.includes(i)) {
      return i;
    }
  }
  return aggregationColor;
}
export function isPivotColor(pivot: PivotType) {
  return colorDecisionalPivotTypeArray.includes(pivot);
}
export function isShading(pivot: PivotType) {
  return pivot === PivotType.Shift;
}

export function getCurrentPivot({
  group,
  subGroup,
  isComparing,
}: {
  group: PivotType;
  subGroup: PivotType;
  isComparing: boolean;
}) {
  let pivot: PivotType = PivotType.Rig;
  if (isComparing) return pivot;
  if (subGroup !== PivotType.None && isPivotColor(subGroup)) pivot = subGroup;
  else if (isPivotColor(group))
    pivot = group === PivotType.None ? PivotType.Rig : group;
  return pivot;
}

export const GenericColorsContextProvider: FC<ReactChildren> = ({
  children,
}) => {
  const [instantColors, setInstantColors] = useState<IColorsRecord>({});
  const location = useLocation();
  const colors = useDeferredValue(instantColors);

  const [displayOptions] = useStateQuery<{
    pivot: Array<PivotType>;
    order: Array<PivotOrderType>;
  }>(URL_STATE_PARAM.DATA_GROUPS_WIDGET, {
    pivot: [PivotType.Day, PivotType.None],
    order: [PivotType.Day, PivotType.None].map(getDefaultPivotOrderTypeByPivot),
  });

  const [offsetRigs] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET,
    [],
  );
  const [offsetWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELL,
    [],
  );
  const [offsetWellsRigsScoreCards] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.SELECTED_WELLS_RIG_DASHBOARD,
    [],
  );

  const [offsetWellsLenses] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );

  const offsetWellsReports = useAppSelector(
    (state) => state.report.offsetWells,
  );
  const dispatch = useAppDispatch();

  const isScoreCard = location.pathname.includes("scorecard");

  const addLegends = useCallback(
    (keys: string[], pivot: ColorPivot) => {
      dispatch(AddColorsForIdsAction({ keys, pivot }));
    },
    [dispatch],
  );

  const fallbackPivot = useMemo(
    () =>
      getCurrentPivot({
        group: displayOptions.pivot[0],
        subGroup: displayOptions.pivot[1],
        isComparing:
          offsetRigs.length > 0 ||
          offsetWells.length > 0 ||
          (offsetWellsRigsScoreCards?.length > 0 && isScoreCard) ||
          offsetWellsReports.length > 0 ||
          offsetWellsLenses.length > 0,
      }),
    [
      displayOptions,
      offsetRigs,
      offsetWells,
      offsetWellsRigsScoreCards,
      offsetWellsReports,
      offsetWellsLenses,
      isScoreCard,
    ],
  );

  const getColor: IGetColorCallback = useCallback(
    ({ key, isMock, currentPivot }) => {
      if (!key) return aggregationColor;
      const pivot = currentPivot ?? fallbackPivot;

      if (isMock) {
        return colors?.[`${pivot}-mock`]?.[key] ?? aggregationColor;
      }

      return colors?.[pivot]?.[key] ?? aggregationColor;
    },
    [colors, fallbackPivot],
  );

  const registerKeys: IRegisterKeysCallback = useCallback(
    ({ pivot, keys, isMock, additive = false }) => {
      const currentPivot = pivot ?? fallbackPivot;
      const currentPivotKey = isMock ? `${currentPivot}-mock` : currentPivot;
      setInstantColors((prevColors) => {
        const newColorsRecord = additive
          ? prevColors[currentPivotKey] ?? {}
          : {};
        let allKeysOnRecord = true;

        keys.forEach((key) => {
          if (!newColorsRecord?.[key]) {
            newColorsRecord[key] = getNextInSeq(Object.values(newColorsRecord));
            allKeysOnRecord = false;
          }
        });

        if (allKeysOnRecord) {
          return prevColors;
        }

        if (!isMock) {
          addLegends(keys, currentPivot);
        }

        return {
          ...prevColors,
          [currentPivotKey]: newColorsRecord,
        };
      });
    },
    [fallbackPivot, addLegends],
  );

  const resetColors = useCallback(() => {
    const currentPivot = fallbackPivot;

    dispatch(ResetLegendColorsAction());
    setInstantColors((prevColors) => {
      return {
        ...prevColors,
        [PivotType.None]: {},
        [currentPivot]: {},
      };
    });
  }, [dispatch, fallbackPivot]);

  const contextValues = useMemo(
    () => ({
      colors,
      getColor,
      registerKeys,
      resetColors,
    }),
    [colors, getColor, resetColors, registerKeys],
  );
  return (
    <ColorsContext.Provider value={contextValues}>
      {children}
    </ColorsContext.Provider>
  );
};
