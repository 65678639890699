import type { IEventProps } from "components/TvDChart/components/TimelineEventIndicator/style";
import { useImperativeTimelineEventScroll } from "components/TvDChart/components/TimelineEventIndicator/useImperativeTimelineEventScroll";
import type { CSSProperties } from "react";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";

import * as Styled from "./style";

type TimelineEventIndicatorProps = React.HTMLAttributes<HTMLDivElement> &
  IEventProps & {
    onClickOutside?: () => void;
    eventId?: number;
    style?: CSSProperties;
    isPlacedInTimeline?: boolean;
    preventResetState?: boolean;
    $isHovered?: boolean;
    $isCombined?: boolean;
  };

export const DigestTimelineEventIndicator = React.forwardRef(
  (props: TimelineEventIndicatorProps, ref: React.Ref<HTMLDivElement>) => {
    const { onClickOutside, eventId, style, onClick, ...restProps } = props;
    const eventIconRef = useRef<HTMLDivElement>(null);
    const { scrollToWithDelay } = useImperativeTimelineEventScroll();

    useImperativeHandle(ref, () => eventIconRef.current!, []);

    const selectedEvent = useAppSelector(
      (state) => state.timeline.selectedTimelineEvent,
    );

    const dispatch = useAppDispatch();

    const handleOnClick = useCallback(
      (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation(); // allowing bubbling would cause deselection of the event via the effect below
        onClick?.(ev);
        if (selectedEvent && selectedEvent === eventId) {
          dispatch({
            type: "SET_SELECTED_TIMELINE_EVENT",
            payload: null,
          });
        } else if (Number.isFinite(eventId) && eventId !== undefined) {
          dispatch({
            type: "SET_SELECTED_TIMELINE_EVENT",
            payload: eventId,
          });
          scrollToWithDelay(eventId);
        }
      },
      [dispatch, eventId, onClick, scrollToWithDelay, selectedEvent],
    );

    useEffect(() => {
      const listener = (e: MouseEvent) => {
        if (
          eventIconRef.current &&
          !eventIconRef.current.contains(e.target as Node)
        ) {
          onClickOutside?.();

          dispatch({
            type: "SET_SELECTED_TIMELINE_EVENT",
            payload: null,
          });
        }
      };

      if (!onClickOutside) {
        return;
      } else {
        document.addEventListener("click", listener);
        return () => {
          document.removeEventListener("click", listener);
        };
      }
    }, [dispatch, onClickOutside]);

    return (
      <Styled.EventIcon
        {...restProps}
        ref={eventIconRef}
        onClick={handleOnClick}
        style={style}
      />
    );
  },
);
