import {
  DisplayOption,
  OperationCategoryType,
  PivotType,
  ResultDataState,
} from "apis/oag";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { SectionLayout } from "components/Layout";
import type { LensTemplate } from "hooks/lens/useLensTemplates";
import {
  isParameterByDepthTemplate,
  isParameterRoadmapTemplate,
  isWedgeTemplate,
} from "hooks/lens/useLensTemplates";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useWellShortInfoSuspended } from "hooks/wells/useWellShortInfo";
import { SingleLegend } from "pages/Lens/LensLegendView/SingleLegend";
import { StyledHeaderContainer } from "pages/Lens/LensLegendView/style";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { Col, Row, Space } from "utils/componentLibrary";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";

// TODO comparison well generic type?
const LensLegendView: FC<{
  isReport?: boolean;
  template: LensTemplate;
}> = ({ isReport = false, template }) => {
  const wellData = useWellShortInfoSuspended();
  const dispatch = useAppDispatch();
  const { getColor } = useColors();

  const focalWellId = useAppSelector((state) => state.state.selectedWell);
  const legendAltStyle = useAppSelector((state) => state.state.legendAltStyle);
  const comparisonWellInfo = useAppSelector(
    (state) => state.widgetOptions.comparisonWellInfo,
  );
  const [comparisonWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );

  const displayOption = useAppSelector(
    (state) => state.widgetOptions.display_options,
  );

  const [legendView, setLegendView] = useState(legendAltStyle);

  const isComparing = useMemo(() => {
    // is aggregated by backend
    if (Object.keys(comparisonWellInfo).includes("0")) return true;
    return comparisonWells.filter((e) => comparisonWellInfo[e]).length > 0;
  }, [comparisonWellInfo, comparisonWells]);

  // TODO: NOT THE BEST IMPLEMENTATION, THIS IS JUST TEMPORARY --- 36 months ago ... :(
  const altNames = useMemo(() => {
    if (!isWedgeTemplate(template)) return;
    if (template.operationCategory === OperationCategoryType.Drilling) {
      return ["Rotating", "Sliding", "Weight to Weight", "Non Drilling"];
    }
    if (template.operationCategory === OperationCategoryType.Casing) {
      return ["Slip to Slip", "Pipe Moving"];
    }
    if (template.operationCategory === OperationCategoryType.Tripping) {
      return ["Slip to Slip", "Pipe Moving"];
    }
    return [];
  }, [template]);

  useEffect(() => {
    if (legendView !== legendAltStyle) {
      dispatch({
        type: "SET_LEGEND_ALT_STYLE",
        payload: legendView,
      });
    }
  }, [dispatch, legendAltStyle, legendView]);
  const isAggregatedLegend = useMemo(() => {
    if (isWedgeTemplate(template) || isParameterByDepthTemplate(template)) {
      // is aggregated by backend
      if (Object.keys(comparisonWellInfo).includes("0")) {
        return true;
      }
      if (
        isWedgeTemplate(template) &&
        comparisonWells.filter(
          (e) => comparisonWellInfo[e] === ResultDataState.Valid,
        ).length >= 7
      )
        return true;
      if (
        isParameterByDepthTemplate(template) &&
        comparisonWells.filter(
          (e) => comparisonWellInfo[e] === ResultDataState.Valid,
        ).length >= 5
      )
        return true;
    }
    return false;
  }, [comparisonWellInfo, comparisonWells, template]);

  const { atomThemeVariant, themeStyle } = useCustomTheme();

  return (
    <SectionLayout
      isReport={isReport}
      header={
        <StyledHeaderContainer>
          <Row align="middle" justify="space-between">
            <Col flex="0 auto">
              <Title level={3} variant={atomThemeVariant}>
                Legend
              </Title>
            </Col>
            <Col flex="0 auto" style={{ height: "36px" }}>
              {isWedgeTemplate(template) && !isReport && (
                <Space>
                  <Button
                    $engaged={legendView === false}
                    onClick={() => setLegendView(false)}
                  >
                    Well
                  </Button>
                  <Button
                    $engaged={legendView === true}
                    onClick={() => setLegendView(true)}
                  >
                    Activity
                  </Button>
                </Space>
              )}
            </Col>
          </Row>
        </StyledHeaderContainer>
      }
    >
      <div
        style={{
          overflowX: "hidden",
          height: "100%",
          background: themeStyle.colors.tertiary_bg,
          width: `100%`,
        }}
      >
        <Row justify="start">
          {!isComparing && displayOption === DisplayOption.DateShift && (
            <>
              <Col span={24}>
                <SingleLegend title="Day" color="#0d9b83" />
              </Col>
              <Col span={24}>
                <SingleLegend title="Night" color="#074e42" />
              </Col>
            </>
          )}
          {!legendAltStyle &&
          displayOption !== DisplayOption.DateShift &&
          focalWellId ? (
            <Col span={24}>
              <SingleLegend
                title={wellData.data.byId[focalWellId]?.name || ""}
                color={getColor({ key: focalWellId?.toString() })}
                wellId={focalWellId}
                dataState={
                  (!isComparing
                    ? ResultDataState.Valid
                    : comparisonWellInfo[focalWellId]) ?? ResultDataState.NoData
                }
              />
            </Col>
          ) : null}
          {!isParameterRoadmapTemplate(template) &&
            (legendAltStyle ? (
              (altNames || []).map((e, i) => (
                <Col span={24} key={e}>
                  <SingleLegend
                    title={e}
                    color={getColor({
                      key: (i + 1).toString(),
                      currentPivot: PivotType.None,
                    })}
                  />
                </Col>
              ))
            ) : (
              <Col span={24}>
                {isAggregatedLegend ? (
                  <SingleLegend
                    title={`${comparisonWells.length} Aggregated Wells`}
                    color={colors.off_state}
                  />
                ) : (
                  comparisonWells.map((e) => (
                    <SingleLegend
                      key={e}
                      title={wellData.data.byId[e]?.name || ""}
                      wellId={e}
                      color={getColor({ key: e.toString() })}
                      dataState={
                        (!isComparing
                          ? ResultDataState.Valid
                          : comparisonWellInfo[e]) ?? ResultDataState.NoData
                      }
                    />
                  ))
                )}
              </Col>
            ))}
        </Row>
      </div>
    </SectionLayout>
  );
};

export default LensLegendView;
