import { Button } from "atoms/Form";
import type { SizeType } from "utils/componentLibrary/Space";

import * as Styled from "./style";

type ButtonProps = (typeof Button.defaultProps & { label: string })[];

export const _ButtonSwitchGroup = ({
  buttons,
  selectedButtonIndex = 0,
  size = "large",
}: {
  buttons: ButtonProps;
  selectedButtonIndex?: number;
  size?: SizeType;
}) => {
  return (
    <Styled.Buttons>
      {buttons.map((buttonProps, idx) => {
        const {
          label,
          type = selectedButtonIndex === idx ? "primary" : "default",
          ...restButtonProps
        } = buttonProps;

        return (
          <Button
            shape="round"
            size={size}
            key={idx}
            type={type}
            {...restButtonProps}
            onClick={(ev) => {
              buttonProps.onClick?.(ev);
            }}
          >
            {label}
          </Button>
        );
      })}
    </Styled.Buttons>
  );
};
