import { DimensionType } from "apis/oag";
import { useUOM } from "utils/format";
import { PDConstants } from "utils/PDConstants/PDConstants";

import * as Styled from "./style";

export const DepthInput = ({
  error,
  value: numValue,
  placeholder,
  onChange,
}: {
  error?: string;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
}) => {
  const value = numValue?.toString() ?? "";
  const depthUOM = useUOM(DimensionType.Metres);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = PDConstants.RegExp.Number;
    if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
      onChange(inputValue);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    }
    const inputValue = valueTemp.replace(/0*(\d+)/, "$1");

    onChange(inputValue);
  };
  return (
    <Styled.DepthInput
      placeholder={placeholder}
      status={error ? "error" : undefined}
      error={error}
      suffix={depthUOM.abbr}
      value={value ?? ""}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
