import type { FiltersDto, PivotOrderType } from "apis/oag";
import { PivotType } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { Button } from "atoms/Form";
import { getTitleRealTimeIndicator } from "components/DetailsTopBar/utils";
import { checkActiveState } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import {
  RealTimeDataEnum,
  RealTimeIndicator,
} from "components/RealTimeIndicator";
import { DisplayOptionsModal } from "components/RigComparisonSelectors/DisplayOptionsModal";
import {
  getDefaultPivotOrderTypeByPivot,
  getDisplayOptions,
} from "components/RigComparisonSelectors/DisplayOptionsModal/utils";
import OffsetRigSelector from "components/RigComparisonSelectors/OffsetRigSelector";
import OperatorSelector from "components/RigComparisonSelectors/OperatorSelector";
import { WellSelector } from "components/RigComparisonSelectors/WellSelector";
import { RigSelector } from "components/RigSelector";
import Filters from "components/WellDashboard/ControlHeader/atoms/Filters";
import { useFiltersLoading } from "hooks/filters/useFiltersLoading";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { getCorrectOrder } from "hooks/pivot/useStackedKpi";
import { isEqual } from "lodash";
import type { FC } from "react";
import { Suspense, useEffect, useMemo, useState } from "react";
import type { IFiltersType } from "reducers/types";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { range30Days } from "utils/helper";
export const filtersDtoToFiltersType = (filters: FiltersDto): IFiltersType => ({
  directionalIntervals: filters?.directionalIntervalIds ?? null,
  holeSizes: filters?.holeSizeIds ?? null,
  sections: filters?.sectionIds ?? null,
  operationTime: ["Flat Time", "Sliding Time", "Rotating Time"].filter(
    (e) => e,
  ),
  phases: filters?.phaseIds ?? null,
});

export const HoverCol = styled(Col)`
  &:hover h3 {
    color: ${({ theme }) =>
      theme.themeStyle.colors.primary_typography} !important;
  }
  &:hover .pd-icon {
    color: ${({ theme }) =>
      theme.themeStyle.colors.primary_typography} !important;
  }
  .pd-icon {
    color: ${colors.gray} !important;
  }
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.soft_accent};
  margin-bottom: 0px;
  width: 100%;
  padding: 12px;
  min-height: 0px;
  cursor: pointer;
`;
export const initialFiltersState = {
  operationTime: null,
  sections: null,
  directionalIntervals: null,
  holeSizes: null,
  phases: null,
};
const ControlHeader: FC = () => {
  const [displayOptions, setDisplayOptions] = useStateQuery<{
    pivot: Array<PivotType>;
    order: Array<PivotOrderType>;
  }>(
    URL_STATE_PARAM.DATA_GROUPS_DASHBOARD,
    {
      pivot: [PivotType.Day, PivotType.None],
      order: [PivotType.Day, PivotType.None].map((e) =>
        getDefaultPivotOrderTypeByPivot(e),
      ),
    },
    [URL_STATE_PARAM.DATA_GROUPS_WIDGET],
  );

  const [realTimeDataState, setRealTimeDateState] = useStateQuery(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.UNAVAILABLE,
    [URL_STATE_PARAM.REALTIME_DATA_WIDGET],
  );
  const [offsetRigs] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET,
    [],
  );

  const [periodState, setPeriodState] = useStateQuery<RangeType>(
    URL_STATE_PARAM.PERIOD_RIG_DASHBOARD,
    range30Days,
    [URL_STATE_PARAM.PERIOD_RIG_WIDGET],
  );
  const {
    filtersLoading,
    availableFilters,
    directionalIntervals,
    holeSections,
    holeSizes,
  } = useFiltersLoading();

  const [filtersState, setFilterState] = useStateQuery<IFiltersType>(
    URL_STATE_PARAM.FILTERS_DASHBOARD,
    initialFiltersState,
    [URL_STATE_PARAM.FILTERS_WIDGET],
  );

  const [previousAvailableFilters, setPreviousAvailableFilters] =
    useState<FiltersDto>();

  useEffect(() => {
    if (!availableFilters) return;
    if (
      previousAvailableFilters === null ||
      isEqual(initialFiltersState, filtersState)
    ) {
      setPreviousAvailableFilters(availableFilters);
      return;
    }

    const isSame = isEqual(availableFilters, previousAvailableFilters);
    if (isSame) return;
    const newFilterValues = filtersState;
    type FilterValuesKeys = keyof IFiltersType;
    const optionsPreviousAvailableFilter = previousAvailableFilters
      ? filtersDtoToFiltersType(previousAvailableFilters)
      : initialFiltersState;
    const newFilterOptions = filtersDtoToFiltersType(availableFilters);
    for (const untypedKey of Object.keys(initialFiltersState)) {
      const key = untypedKey as FilterValuesKeys;
      const filterValues = filtersState[key];
      const optionsCurrentFilter = optionsPreviousAvailableFilter[key] ?? [];
      const optionsNewFilter = newFilterOptions[key] ?? [];

      const newOptions = optionsNewFilter.filter((e) => {
        return !(optionsCurrentFilter as Array<typeof e>).includes(e);
      });
      const currentFiltersByKey = newFilterValues[key] ?? [];
      const savedFiltersValues =
        filterValues === null ? null : [...filterValues, ...newOptions];
      (newFilterValues[key] as typeof currentFiltersByKey) =
        savedFiltersValues as typeof currentFiltersByKey;
    }

    setPreviousAvailableFilters(availableFilters);
    setFilterState(newFilterValues);
    // we want to run this effect just based on the changes in available filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFilters]);
  const isComparing = useMemo(
    () => offsetRigs?.length > 0,
    [offsetRigs?.length],
  );

  useEffect(() => {
    const pivot = getDisplayOptions(isComparing)(displayOptions.pivot);
    const order = getCorrectOrder(
      Object.values(pivot),
      { ...displayOptions },
      isComparing,
    );
    setTimeout(
      () =>
        setDisplayOptions({
          pivot,
          order,
        }),
      100,
    );
    // Don't need to run this effect on setDisplayOptions change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComparing]);

  const [displayOptionsModalVisible, setDisplayOptionsModalVisible] =
    useState(false);

  const filtersActiveState = checkActiveState(availableFilters, filtersState);

  const [filterVisible, setFilterVisible] = useState(false);

  const updatePeriod = (period: RangeType) => {
    setPeriodState(period);
    return true;
  };
  const applyFilters = (obj: IFiltersType) => {
    setFilterState(obj);
  };

  return (
    <Row justify="space-between" align="middle">
      <Col flex="1 auto">
        <RigSelector redirectLocation={(rigId) => `/rig/${rigId}`} />
      </Col>
      <Col flex="0 auto">
        <Row justify="end" align="middle" gutter={8}>
          <Col flex="0 auto">
            <Space>
              {/* Offset Rig Selector */}
              <OffsetRigSelector />
              {/* Offset Well Selector */}
              <Suspense fallback={null}>
                <WellSelector />
              </Suspense>
              {/* Operators Selector */}
              <Suspense fallback={null}>
                <OperatorSelector />
              </Suspense>

              <Tooltip title="Data Groups">
                <Button
                  icon={<PDComponent.SvgIcon name="layers" />}
                  $engaged={displayOptionsModalVisible}
                  onClick={() => {
                    Track.interact("Dashboard Rigs - Display Options", {
                      Action: "Open",
                    });
                    setDisplayOptionsModalVisible(true);
                  }}
                />
              </Tooltip>

              <PDComponent.VerticalDivider />

              <Tooltip title="Filter">
                <Popover
                  content={
                    !filtersLoading && (
                      <Suspense fallback={null}>
                        <Filters
                          appliedState={filtersState}
                          availableFilters={availableFilters}
                          directionalIntervals={directionalIntervals}
                          holeSections={holeSections}
                          holeSizes={holeSizes}
                          lens
                          onCancel={() => setFilterVisible(false)}
                          setFilterData={applyFilters}
                          visible={filterVisible}
                          hiddenFilters={["phases"]}
                        />
                      </Suspense>
                    )
                  }
                  trigger={["click", "scroll"]}
                  placement="bottomRight"
                  open={filterVisible}
                  onOpenChange={(e) => setFilterVisible(e)}
                  destroyTooltipOnHide
                >
                  <Button
                    size="large"
                    icon={<PDComponent.SvgIcon name="filter" />}
                    onClick={() => setFilterVisible((fl: boolean) => !fl)}
                    $engaged={filterVisible}
                    loading={filtersLoading}
                    type={filtersActiveState ? "primary" : "default"}
                    ghost={filtersActiveState}
                  />
                </Popover>
              </Tooltip>

              <DatePicker
                defaultDate={range30Days}
                selection={periodState}
                onApply={(period) => {
                  Track.interact("Rig Dashboard - Update Period", {
                    "Start Date": period.startDate?.toISOString?.() ?? "",
                    "End Date": period.endDate?.toISOString?.() ?? "",
                  });
                  updatePeriod(period);
                }}
              />
              <PDComponent.VerticalDivider />

              {/* Realtime Indicator */}
              <Tooltip title={getTitleRealTimeIndicator(realTimeDataState)}>
                <Button
                  size="large"
                  onClick={() => {
                    if (realTimeDataState === RealTimeDataEnum.UNAVAILABLE) {
                      Track.interact("Rig Dashboard - Real Time", {
                        State: "Unavailable",
                      });
                      return;
                    }
                    Track.interact("Rig Dashboard - Real Time", {
                      State:
                        realTimeDataState === RealTimeDataEnum.ACTIVE
                          ? "Disabled"
                          : "Active",
                    });
                    setRealTimeDateState(
                      realTimeDataState === RealTimeDataEnum.ACTIVE
                        ? RealTimeDataEnum.DISABLED
                        : RealTimeDataEnum.ACTIVE,
                    );
                  }}
                  icon={
                    <RealTimeIndicator realTimeDataState={realTimeDataState} />
                  }
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>
      </Col>
      <Suspense fallback={null}>
        {displayOptionsModalVisible ? (
          <DisplayOptionsModal
            isOpen={displayOptionsModalVisible}
            prevDisplayOptions={displayOptions.pivot}
            prevDisplayOrderOptions={displayOptions.order}
            onClose={() => setDisplayOptionsModalVisible(false)}
            saveDisplayOptions={(options, order) => {
              setDisplayOptions({
                order,
                pivot: options,
              });
            }}
            isComparing={isComparing}
          />
        ) : null}
      </Suspense>
    </Row>
  );
};

export default ControlHeader;
