import { ImageItem } from "components/Carousel/styles";
import { useDigestAttachmentsByFilename } from "hooks/comments/useDigestAttachmentsByFilename";
import { useEffect, useState } from "react";

interface ImageCarouselProps {
  src: string;
}

export const ImageCarouselItem = ({ src }: ImageCarouselProps) => {
  const { data: backendAttachments } = useDigestAttachmentsByFilename(src);

  const [base64, setBase64] = useState("");

  useEffect(() => {
    const data = backendAttachments;
    if (!data) return;
    const reader = new FileReader();
    reader.readAsDataURL(data);
    const onReload = function () {
      if (reader.result && typeof reader.result === "string") {
        setBase64(reader.result);
      }
    };
    reader.onloadend = onReload;
    return () => {
      reader.onloadend = null;
    };
  }, [backendAttachments]);

  return base64 ? (
    <ImageItem key={src}>
      <img src={base64} />
    </ImageItem>
  ) : null;
};
