import { useCallback, useEffect, useRef, useState } from "react";

export const useMouseHover = ({
  onHover,
  onLeave,
}: {
  onHover: () => void;
  onLeave: () => void;
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  const trackMouseHoverState = useCallback((ref: HTMLDivElement | null) => {
    elementRef.current = ref;
  }, []);

  useEffect(() => {
    const handleMouseEnter = () => {
      if (!isHovered) {
        setIsHovered(true);
        onHover();
      }
    };

    const handleMouseLeave = () => {
      if (isHovered) {
        setIsHovered(false);
        onLeave();
      }
    };

    if (elementRef.current) {
      elementRef.current.addEventListener("mouseenter", handleMouseEnter);
      elementRef.current.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener("mouseenter", handleMouseEnter);
        elementRef.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [isHovered, onHover, onLeave]);

  return { trackMouseHoverState };
};
