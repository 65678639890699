import type { DateDto, DigestEntryType } from "apis/oag";
import { CarouselContainer } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/CarouselPage/CarouselContainer/CarouselContainer";
import type { EssentialMediaDescriptor } from "components/Timeline/components/DigestTimeline/TimelineKnots/Carousel/interfaces";
import dayjs from "dayjs";
import { useState } from "react";
import { isValidId } from "utils/helper";

import * as Styled from "./style";

export const CarouselPage = ({
  selectedMediaSourceId,
  mediaSources,
  topbarDate,
  downloadDate,
  digestEntryType,
  onClose,
}: {
  selectedMediaSourceId?: number;
  mediaSources: EssentialMediaDescriptor[];
  topbarDate: Date | undefined;
  downloadDate: DateDto;
  digestEntryType: DigestEntryType;
  onClose: () => void;
}) => {
  const foundIndex = mediaSources.findIndex(
    (mediaSource) => mediaSource.id === selectedMediaSourceId,
  );

  const [openedIndex, setOpenedIndex] = useState<number>(foundIndex);

  return (
    <>
      <Styled.ControlBar>
        <Styled.DateTitle>
          {" "}
          {dayjs(topbarDate).format("MMM DD, YYYY · HH:mm")}{" "}
        </Styled.DateTitle>

        <Styled.CloseContainer>
          <Styled.CloseButton role="button" onClick={onClose} />
        </Styled.CloseContainer>
      </Styled.ControlBar>

      {isValidId(openedIndex) ? (
        <CarouselContainer
          defaultIndex={openedIndex}
          mediaSources={mediaSources}
          onPreviewedIndexChange={setOpenedIndex}
          downloadDate={downloadDate}
          digestEntryType={digestEntryType}
        />
      ) : null}
    </>
  );
};
