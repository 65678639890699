/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DigestEntryVoteType } from './DigestEntryVoteType';
import {
    DigestEntryVoteTypeFromJSON,
    DigestEntryVoteTypeFromJSONTyped,
    DigestEntryVoteTypeToJSON,
} from './DigestEntryVoteType';
import type { DigestEntryType } from './DigestEntryType';
import {
    DigestEntryTypeFromJSON,
    DigestEntryTypeFromJSONTyped,
    DigestEntryTypeToJSON,
} from './DigestEntryType';
import type { TimeUnit } from './TimeUnit';
import {
    TimeUnitFromJSON,
    TimeUnitFromJSONTyped,
    TimeUnitToJSON,
} from './TimeUnit';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import type { DigestEntryAttachmentDto } from './DigestEntryAttachmentDto';
import {
    DigestEntryAttachmentDtoFromJSON,
    DigestEntryAttachmentDtoFromJSONTyped,
    DigestEntryAttachmentDtoToJSON,
} from './DigestEntryAttachmentDto';

/**
 * 
 * @export
 * @interface DigestEntryTimeSaverDto
 */
export interface DigestEntryTimeSaverDto {
    /**
     * 
     * @type {number}
     * @memberof DigestEntryTimeSaverDto
     */
    id: number;
    /**
     * 
     * @type {DateDto}
     * @memberof DigestEntryTimeSaverDto
     */
    createdAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryTimeSaverDto
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof DigestEntryTimeSaverDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof DigestEntryTimeSaverDto
     */
    isApproved: boolean;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryTimeSaverDto
     */
    chapterId: number;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryTimeSaverDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof DigestEntryTimeSaverDto
     */
    authorDisplayName: string;
    /**
     * 
     * @type {Array<DigestEntryAttachmentDto>}
     * @memberof DigestEntryTimeSaverDto
     */
    attachments: Array<DigestEntryAttachmentDto>;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryTimeSaverDto
     */
    attachmentCount: number;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryTimeSaverDto
     */
    likeCount: number;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryTimeSaverDto
     */
    dislikeCount: number;
    /**
     * 
     * @type {DigestEntryVoteType}
     * @memberof DigestEntryTimeSaverDto
     */
    currentUserVoteType: DigestEntryVoteType;
    /**
     * 
     * @type {string}
     * @memberof DigestEntryTimeSaverDto
     */
    operationCategoryName: string;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryTimeSaverDto
     */
    rigId: number;
    /**
     * 
     * @type {string}
     * @memberof DigestEntryTimeSaverDto
     */
    rigDisplayName?: string | null;
    /**
     * 
     * @type {DigestEntryType}
     * @memberof DigestEntryTimeSaverDto
     */
    readonly digestEntryType: DigestEntryType;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryTimeSaverDto
     */
    estimatedTimeSavingInSeconds: number;
    /**
     * 
     * @type {TimeUnit}
     * @memberof DigestEntryTimeSaverDto
     */
    estimatedTimeSavingUnit: TimeUnit;
}



/**
 * Check if a given object implements the DigestEntryTimeSaverDto interface.
 */
export function instanceOfDigestEntryTimeSaverDto(value: object): value is DigestEntryTimeSaverDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('priority' in value) || value['priority'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('isApproved' in value) || value['isApproved'] === undefined) return false;
    if (!('chapterId' in value) || value['chapterId'] === undefined) return false;
    if (!('authorId' in value) || value['authorId'] === undefined) return false;
    if (!('authorDisplayName' in value) || value['authorDisplayName'] === undefined) return false;
    if (!('attachments' in value) || value['attachments'] === undefined) return false;
    if (!('attachmentCount' in value) || value['attachmentCount'] === undefined) return false;
    if (!('likeCount' in value) || value['likeCount'] === undefined) return false;
    if (!('dislikeCount' in value) || value['dislikeCount'] === undefined) return false;
    if (!('currentUserVoteType' in value) || value['currentUserVoteType'] === undefined) return false;
    if (!('operationCategoryName' in value) || value['operationCategoryName'] === undefined) return false;
    if (!('rigId' in value) || value['rigId'] === undefined) return false;
    if (!('digestEntryType' in value) || value['digestEntryType'] === undefined) return false;
    if (!('estimatedTimeSavingInSeconds' in value) || value['estimatedTimeSavingInSeconds'] === undefined) return false;
    if (!('estimatedTimeSavingUnit' in value) || value['estimatedTimeSavingUnit'] === undefined) return false;
    return true;
}

export function DigestEntryTimeSaverDtoFromJSON(json: any): DigestEntryTimeSaverDto {
    return DigestEntryTimeSaverDtoFromJSONTyped(json, false);
}

export function DigestEntryTimeSaverDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigestEntryTimeSaverDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'createdAt': DateDtoFromJSON(json['CreatedAt']),
        'priority': json['Priority'],
        'description': json['Description'],
        'isApproved': json['IsApproved'],
        'chapterId': json['ChapterId'],
        'authorId': json['AuthorId'],
        'authorDisplayName': json['AuthorDisplayName'],
        'attachments': ((json['Attachments'] as Array<any>).map(DigestEntryAttachmentDtoFromJSON)),
        'attachmentCount': json['AttachmentCount'],
        'likeCount': json['LikeCount'],
        'dislikeCount': json['DislikeCount'],
        'currentUserVoteType': DigestEntryVoteTypeFromJSON(json['CurrentUserVoteType']),
        'operationCategoryName': json['OperationCategoryName'],
        'rigId': json['RigId'],
        'rigDisplayName': json['RigDisplayName'] == null ? undefined : json['RigDisplayName'],
        'digestEntryType': DigestEntryTypeFromJSON(json['DigestEntryType']),
        'estimatedTimeSavingInSeconds': json['EstimatedTimeSavingInSeconds'],
        'estimatedTimeSavingUnit': TimeUnitFromJSON(json['EstimatedTimeSavingUnit']),
    };
}

export function DigestEntryTimeSaverDtoToJSON(value?: Omit<DigestEntryTimeSaverDto, 'DigestEntryType'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'CreatedAt': DateDtoToJSON(value['createdAt']),
        'Priority': value['priority'],
        'Description': value['description'],
        'IsApproved': value['isApproved'],
        'ChapterId': value['chapterId'],
        'AuthorId': value['authorId'],
        'AuthorDisplayName': value['authorDisplayName'],
        'Attachments': ((value['attachments'] as Array<any>).map(DigestEntryAttachmentDtoToJSON)),
        'AttachmentCount': value['attachmentCount'],
        'LikeCount': value['likeCount'],
        'DislikeCount': value['dislikeCount'],
        'CurrentUserVoteType': DigestEntryVoteTypeToJSON(value['currentUserVoteType']),
        'OperationCategoryName': value['operationCategoryName'],
        'RigId': value['rigId'],
        'RigDisplayName': value['rigDisplayName'],
        'EstimatedTimeSavingInSeconds': value['estimatedTimeSavingInSeconds'],
        'EstimatedTimeSavingUnit': TimeUnitToJSON(value['estimatedTimeSavingUnit']),
    };
}

