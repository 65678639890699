import type { DateDto, FiltersDto, UserLensDto } from "apis/oag";
import type { ICombinedEvents } from "components/TvDChart/types";
import type { IItem, IReportState } from "reducers/reportReducer";
import type { IAction, IEventType } from "reducers/types";
import colors from "utils/colors";
import type { ITimelineEventList } from "utils/eventUtils";
/*
 * This reducer holds and controls the state for TvD and the interaction with of it with the timeline
 */

export type TimelineStates =
  | "ActualWeb"
  | "ActualMemos"
  | "Plan"
  | "Information"
  | "Legend";
export type AddIconStates =
  | "Actual"
  | "Plan"
  | "OffsetWell"
  | "Timeline"
  | "None";
export enum IndicatorsState {
  "Hole Sections" = "Hole Sections",
  "Directional Intervals" = "Directional Intervals",
  "Well Phases" = "Well Phases",
}

export type ReportState = IReportState & Partial<IItem>;
export enum DataState {
  "Unavailable" = "Unavailable",
  "NotSelected" = "NotSelected",
  "Active" = "Active",
}

export enum TFetchingState {
  "Loading",
  "Settled",
  "Error",
}
export interface TimelineOverride {
  timeline_override: ITvDState["timeline_override"];
  timeline_ts?: ITvDState["timeline_ts"];
  timelineAt?: ITvDState["timelineAt"];
}
// TODO we might want to split this reducer; is getting cumbersome

export interface ITvDState {
  addIconPresent: AddIconStates;
  addingTarget: IEventType | null;
  aheadSchedule: number | null;
  availableFilters: FiltersDto;
  editedEvent: ITimelineEventList | null;
  endOfTimelineAdd: boolean;
  focalColor: string;
  holeSections: Array<{ id: number; name: string }>;
  hoveredEvent: ICombinedEvents | null;
  hoveredSeriesTvd: number | null;
  kpiTypesFactsLoading: boolean;
  lastInteractionFilter: string | null;
  legendAltStyle: boolean;
  // TODO: I am not especially proud of this tabs idea, but is the best one that I have now
  displayedLenses: UserLensDto[];
  loadingTvDChart: boolean;
  phases: Array<string>;
  plan: boolean;
  refetchOverview: boolean;
  selectedData: DataState;
  selectedIndicators: Set<IndicatorsState>;
  selectedSeries: number | null;
  selectedWell: number | null; // Plan series is considered to have id = -1
  timelineAt: DateDto | null;
  timeline_override: boolean;
  timeline_state: TimelineStates;
  timeline_ts: number;
  tokenPresent: boolean;
  tvdChartRanges: {
    x: {
      max: number | null;
      min: number | null;
    };
    y: {
      max: number | null;
      min: number | null;
    };
  };
  tvdFetchState: TFetchingState;
}

export const initialState: ITvDState = {
  addIconPresent: "None",
  addingTarget: null,
  aheadSchedule: null,
  availableFilters: {
    directionalIntervalIds: [],
    holeSizeIds: [],
    sectionIds: [],
    includeFlatTime: true,
    includeSlidingTime: true,
    includeRotatingTime: true,
    includeNullHoleDepth: true,
    includeAlphaRigs: true,
    includeNonAlphaRigs: true,
  },
  editedEvent: null,
  endOfTimelineAdd: false,
  focalColor: colors.well_color,
  holeSections: [],
  hoveredEvent: null,
  hoveredSeriesTvd: null,
  kpiTypesFactsLoading: true,
  lastInteractionFilter: null,
  legendAltStyle: false,
  displayedLenses: [],
  loadingTvDChart: true,
  phases: ["Vertical", "Curve", "Lateral"],
  plan: true,
  refetchOverview: false,
  selectedData: DataState.Active,
  selectedIndicators: new Set([
    IndicatorsState["Directional Intervals"],
    IndicatorsState["Hole Sections"],
  ]),
  selectedSeries: 1,
  selectedWell: null,
  timelineAt: null,
  timeline_override: false,
  timeline_state: "Legend",
  timeline_ts: 0,
  tokenPresent: false,
  tvdChartRanges: {
    x: {
      min: null,
      max: null,
    },
    y: {
      min: null,
      max: null,
    },
  },
  tvdFetchState: TFetchingState.Loading,
};

interface ISetDisplayedLense extends IAction {
  type: "SET_DISPLAYED_LENS";
  payload: UserLensDto[];
}

interface ISetTvdFetchingState extends IAction {
  type: "SET_TVD_FETCHING";
  payload: TFetchingState;
}

interface IReportSetState extends IAction {
  type: "SET_REPORT_STATE";
  payload: ReportState;
}
interface ISetDataState extends IAction {
  type: "SET_DATA_STATE";
  payload: ITvDState["selectedData"];
}

interface ISetRefetchOverview extends IAction {
  type: "SET_REFETCH_OVERVIEW";
  payload: boolean;
}

interface ISetTvDRanges extends IAction {
  type: "SET_TVD_RANGES";
  payload: ITvDState["tvdChartRanges"];
}

interface ISetTvDLoading extends IAction {
  type: "SET_LOADING_TVD";
  payload: ITvDState["loadingTvDChart"];
}
interface ISetKpiTypeFactsLoading extends IAction {
  type: "SET_LOADING_KPI_TYPE_FACTS";
  payload: ITvDState["loadingTvDChart"];
}

interface ISetSelectedIndicators extends IAction {
  type: "SET_SELECTED_INDICATORS";
  payload: Set<IndicatorsState>;
}

interface ISetTokenPresent extends IAction {
  type: "SET_TOKEN_PRESENT";
  payload: boolean;
}

interface IClickEndTimeline extends IAction {
  type: "SET_END_TIMELINE_CLICK";
  payload: boolean;
}

interface ISetAddingTarget extends IAction {
  type: "SET_ADDING_TARGET";
  payload: IEventType;
}
interface ISetEditEvent extends IAction {
  type: "SET_EDIT_EVENT";
  payload: ITvDState["editedEvent"];
}

interface ISetAddIcon extends IAction {
  type: "SET_ADDICON_STATE";
  payload: AddIconStates;
}
interface ISetHoleSections extends IAction {
  type: "SET_HOLE_SECTIONS";
  payload: ITvDState["holeSections"];
}

interface ISetPhases extends IAction {
  type: "SET_PHASES";
  payload: ITvDState["phases"];
}

interface ISetSelectedSeries extends IAction {
  type: "SET_SELECTED_SERIES";
  payload: number;
}

interface ISetAvailableFilters extends IAction {
  type: "SET_AVAILABLE_FILTERS";
  payload: ITvDState["availableFilters"];
}

interface ISetRigAvailableFilters extends IAction {
  type: "SET_RIG_AVAILABLE_FILTERS";
  payload: ITvDState["availableFilters"];
}

interface ITimlineOverrideAction extends IAction {
  type: "SET_TIMELINE_OVERRIDE";
  payload: TimelineOverride;
}

interface ISetSelectedWellAction extends IAction {
  type: "SET_SELECTED_WELL";
  payload: {
    well: ITvDState["selectedWell"];
  };
}

interface ISetTimelineStateAction extends IAction {
  type: "SET_TIMELINE_STATE";
  payload?: TimelineStates;
}

interface IAheadSchedule extends IAction {
  type: "SET_AHEAD_SCHEDULE";
  payload: ITvDState["aheadSchedule"];
}

interface IFocalWellColor extends IAction {
  type: "SET_FOCALWELL_COLOR";
  payload: {
    color: ITvDState["focalColor"];
  };
}

interface IHoveredSeriesTvD extends IAction {
  type: "SET_HOVERED_SERIES_TVD";
  payload: number | null;
}
interface IHoveredEventTvD extends IAction {
  type: "SET_EVENT_HOVERED";
  payload: ICombinedEvents | null;
}

interface ISetLegendAltStyle extends IAction {
  type: "SET_LEGEND_ALT_STYLE";
  payload: ITvDState["legendAltStyle"];
}
interface ISetLastInteractionFilter extends IAction {
  type: "SET_LAST_INTERACTION";
  payload: string;
}
interface IResetAvailableFilters extends IAction {
  type: "RESET_AVAILABLE_FILTERS";
}
type AvailableActions =
  | IAheadSchedule
  | IClickEndTimeline
  | IFocalWellColor
  | IHoveredEventTvD
  | IHoveredSeriesTvD
  | IReportSetState
  | IResetAvailableFilters
  | ISetAddIcon
  | ISetAddingTarget
  | ISetAvailableFilters
  | ISetRigAvailableFilters
  | ISetDataState
  | ISetEditEvent
  | ISetHoleSections
  | ISetKpiTypeFactsLoading
  | ISetLastInteractionFilter
  | ISetLegendAltStyle
  | ISetDisplayedLense
  | ISetPhases
  | ISetRefetchOverview
  | ISetSelectedIndicators
  | ISetSelectedSeries
  | ISetSelectedWellAction
  | ISetTimelineStateAction
  | ISetTokenPresent
  | ISetTvDLoading
  | ISetTvDRanges
  | ISetTvdFetchingState
  | ITimlineOverrideAction;

function stateReducer(
  state: ITvDState = initialState,
  action: AvailableActions,
): ITvDState {
  switch (action.type) {
    case "SET_DISPLAYED_LENS":
      return {
        ...state,
        displayedLenses: action.payload,
      };
    case "SET_REPORT_STATE":
      return {
        ...state,
        ...action.payload,
        selectedIndicators: new Set(
          (action.payload.indicators ?? []) as Array<IndicatorsState>,
        ),
      };
    case "RESET_AVAILABLE_FILTERS":
      return {
        ...state,
        availableFilters: initialState.availableFilters,
      };
    case "SET_AVAILABLE_FILTERS":
      return {
        ...state,
        availableFilters: action.payload,
      };
    case "SET_RIG_AVAILABLE_FILTERS":
      return {
        ...state,
        availableFilters: mergeObjects(state.availableFilters, action.payload),
      };
    case "SET_TVD_FETCHING":
      return {
        ...state,
        tvdFetchState: action.payload,
      };
    case "SET_DATA_STATE":
      return {
        ...state,
        selectedData: action.payload,
      };
    case "SET_REFETCH_OVERVIEW":
      return {
        ...state,
        refetchOverview: action.payload,
      };
    case "SET_TVD_RANGES":
      return {
        ...state,
        tvdChartRanges: action.payload,
      };
    case "SET_LAST_INTERACTION":
      return {
        ...state,
        lastInteractionFilter: action.payload,
      };
    case "SET_LOADING_TVD":
      return {
        ...state,
        loadingTvDChart: action.payload,
      };
    case "SET_LOADING_KPI_TYPE_FACTS":
      return {
        ...state,
        kpiTypesFactsLoading: action.payload,
      };
    case "SET_SELECTED_INDICATORS":
      return {
        ...state,
        selectedIndicators: action.payload,
      };
    case "SET_TOKEN_PRESENT":
      return {
        ...state,
        tokenPresent: action.payload,
      };
    case "SET_END_TIMELINE_CLICK":
      return {
        ...state,
        endOfTimelineAdd: action.payload,
      };
    case "SET_ADDING_TARGET":
      return {
        ...state,
        addingTarget: action.payload,
      };
    case "SET_EDIT_EVENT":
      return {
        ...state,
        editedEvent: action.payload,
      };
    case "SET_ADDICON_STATE":
      return {
        ...state,
        addIconPresent: action.payload,
      };
    case "SET_EVENT_HOVERED":
      return {
        ...state,
        hoveredEvent: action.payload,
      };
    case "SET_HOVERED_SERIES_TVD":
      return {
        ...state,
        hoveredSeriesTvd: action.payload,
      };
    case "SET_HOLE_SECTIONS":
      return {
        ...state,
        holeSections: action.payload,
      };
    case "SET_SELECTED_SERIES":
      return {
        ...state,
        selectedSeries: action.payload,
      };
    case "SET_SELECTED_WELL":
      return {
        ...state,
        tvdFetchState: TFetchingState.Loading,
        selectedWell: action.payload.well,
        selectedSeries: action.payload.well,
      };
    case "SET_TIMELINE_STATE":
      return action.payload
        ? {
            ...state,
            timeline_state: action.payload,
            endOfTimelineAdd: false,
          }
        : { ...state, endOfTimelineAdd: false };
    case "SET_TIMELINE_OVERRIDE":
      return {
        ...state,
        timeline_override: action.payload.timeline_override,
        timeline_ts: action.payload.timeline_ts || state.timeline_ts,
        timelineAt: action.payload.timelineAt || state.timelineAt,
      };
    case "SET_AHEAD_SCHEDULE":
      return {
        ...state,
        aheadSchedule: action.payload,
      };
    case "SET_FOCALWELL_COLOR":
      return {
        ...state,
        focalColor: action.payload.color,
      };
    case "SET_LEGEND_ALT_STYLE":
      return {
        ...state,
        legendAltStyle: action.payload,
      };
    default:
      return state;
  }
}

const mergeObjects = <T>(a: T, b: T) => {
  if (!a || !b) {
    return a || b;
  }
  const keys = Object.keys(a) ?? [];
  const mergedObject: T = { ...a, ...b };
  keys.forEach((key) => {
    const typedKey = key as keyof T;
    const unique = (v: unknown, i: number, a: Array<unknown>) =>
      a.indexOf(v) === i;
    if (Array.isArray(a[typedKey])) {
      mergedObject[typedKey] = [
        ...(a[typedKey] as unknown as Array<unknown>),
        ...(b[typedKey] as unknown as Array<unknown>),
      ].filter(unique) as unknown as T[keyof T];
    } else if (typeof a[typedKey] === "boolean") {
      mergedObject[typedKey] = a[typedKey] || b[typedKey];
    } else mergedObject[typedKey] = mergeObjects(a[typedKey], b[typedKey]);
  });
  return mergedObject;
};

export default stateReducer;
