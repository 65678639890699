import type { DigestChapterDto } from "apis/oag";
import { DigestEntryType } from "apis/oag";
import { EndKnot } from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotTypes/EndKnot";
import { LogbookKnotPanel } from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotTypes/LogbookKnotPanel";
import { StartKnot } from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotTypes/StartKnot";
import { UnplannedEventKnotPanel } from "components/Timeline/components/DigestTimeline/TimelineKnots/KnotTypes/UnplannedEventKnotPanel/UnplannedEventKnotPanel";
import React, { useLayoutEffect, useMemo, useRef } from "react";
import { match } from "ts-pattern";

import * as Styled from "./style";

export const TimelineKnotsContainer = React.memo(
  ({
    noteChapters = [],
    unplannedEventChapters = [],
    onResizeHeight,
    totalUnplannedDuration,
  }: {
    noteChapters: DigestChapterDto[];
    unplannedEventChapters: DigestChapterDto[];
    onResizeHeight: (height: number) => void;
    totalUnplannedDuration: number;
  }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      if (!containerRef.current) return;
      const observer = new ResizeObserver(([entry]) => {
        onResizeHeight(entry.contentRect.height);
      });

      observer.observe(containerRef.current);

      return () => {
        observer.disconnect();
      };
    }, [onResizeHeight]);

    const sortedChapters = useMemo(() => {
      return [...noteChapters, ...unplannedEventChapters].toSorted(
        (a, b) => b.referenceDate.utc.getTime() - a.referenceDate.utc.getTime(),
      );
    }, [noteChapters, unplannedEventChapters]);

    return (
      <Styled.Container ref={containerRef}>
        <StartKnot durationSeconds={totalUnplannedDuration} />

        {sortedChapters.map((chapter) =>
          match(chapter.type)
            .with(DigestEntryType.Note, () =>
              chapter.notes[0] ? (
                <LogbookKnotPanel chapter={chapter} key={chapter.id} />
              ) : null,
            )
            .with(DigestEntryType.UnplannedEvent, () => (
              <UnplannedEventKnotPanel chapter={chapter} key={chapter.id} />
            ))
            .otherwise(() => null),
        )}

        <EndKnot />
      </Styled.Container>
    );
  },
);
