/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AttachmentReadiness = {
    Empty: 'Empty',
    Processing: 'Processing',
    Ready: 'Ready',
    Error: 'Error'
} as const;
export type AttachmentReadiness = typeof AttachmentReadiness[keyof typeof AttachmentReadiness];


export function instanceOfAttachmentReadiness(value: any): boolean {
    for (const key in AttachmentReadiness) {
        if (Object.prototype.hasOwnProperty.call(AttachmentReadiness, key)) {
            if (AttachmentReadiness[key as keyof typeof AttachmentReadiness] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AttachmentReadinessFromJSON(json: any): AttachmentReadiness {
    return AttachmentReadinessFromJSONTyped(json, false);
}

export function AttachmentReadinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentReadiness {
    return json as AttachmentReadiness;
}

export function AttachmentReadinessToJSON(value?: AttachmentReadiness | null): any {
    return value as any;
}

