import type {
  ParameterByDepthUserLensDto,
  ParameterByDepthUserLensTrackItemDto,
  UserLensDto,
} from "apis/oag";
import { DimensionType } from "apis/oag";
import { getContainerLens } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { MiniLoadingChart } from "components/Lenses/MiniLoadingChart";
import { useKpiOptions } from "hooks/drillingInvariants/useKpiOptions";
import { useKpiTypes } from "hooks/drillingInvariants/useKpiTypes";
import {
  isParameterByDepthTemplate,
  isParameterRoadmapTemplate,
  isTorqueAndDrag,
  useLensTemplates,
} from "hooks/lens/useLensTemplates";
import { useUserLenses } from "hooks/lens/useUserLenses";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { noop } from "lodash";
import LensLegendView from "pages/Lens/LensLegendView";
import type { IKPIValue } from "pages/Lens/LensSummaryView";
import LensSummaryView from "pages/Lens/LensSummaryView";
import NameBar from "pages/Report/components/NameBar";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import styled, { css } from "styled-components";
import { Col, Row } from "utils/componentLibrary";
import { SIDEBAR_DEFAULT_WIDTH } from "utils/constants";
import { useColors } from "utils/useColors";

export const LegendWidth = `${SIDEBAR_DEFAULT_WIDTH}px`;
export const LABELS_PADDING = 24;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledColTransition = styled(Col)<{ width?: string }>`
  display: block !important;
  transition: all 0.4s ease;
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  border-left: 1px solid
    ${({ theme }) => theme.themeStyle.colors.primary_accent};
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

const Lens = () => {
  const dispatch = useAppDispatch();
  const { wellId: ws, lensId } = useParams<{
    wellId: string;
    lensId: string;
  }>();
  const lensIdNumber = parseInt(lensId || "", 10);
  const wellId = Number(ws);
  const [kpiValues] = useState<{
    mainKPIValues: IKPIValue | null;
    secondaryKPIValues: IKPIValue[];
  }>({
    mainKPIValues: null,
    secondaryKPIValues: [],
  });

  useEffect(() => {
    dispatch({
      type: "SET_SELECTED_WELL",
      payload: {
        well: wellId,
      },
    });
  }, [dispatch, wellId]);

  const { registerKeys } = useColors();

  const [offsetWells] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );

  useEffect(() => {
    const offsetKeys = offsetWells.map((key) => key.toString());
    registerKeys({
      keys: [wellId.toString(), ...offsetKeys],
    });
  }, [offsetWells, registerKeys, wellId]);

  const color = useAppSelector((state) => state.state.focalColor);
  const displayOption = useAppSelector(
    (state) => state.widgetOptions.display_options,
  );
  const outliers = useAppSelector((state) => state.widgetOptions.outliers);
  const visualAids = useAppSelector((state) => state.widgetOptions.visualAids);

  const legendVisibilityState = useAppSelector(
    (state) => state.global.reportIncludeLegend,
  );

  const { data: lenses } = useUserLenses();
  const { data: templates } = useLensTemplates();
  const { data: kpiTypes } = useKpiTypes();

  const userLens = useMemo(() => {
    return {
      ...lenses.byId[lensIdNumber],
      squeezesDisplay: true,
      showsOutliers: outliers,
      selectedVisualAids: visualAids,
    } as UserLensDto;
  }, [lensIdNumber, lenses.byId, outliers, visualAids]);
  const templateId = userLens.lensTemplateId;
  const template = templates.byId[templateId];

  const { kpiType, kpiGroup } = useKpiOptions(userLens);

  const [selectedTrackItems, setSelectedTrackItems] = useState<
    ParameterByDepthUserLensTrackItemDto[]
  >([]);

  useEffect(() => {
    if (
      selectedTrackItems.length === 0 &&
      (userLens as ParameterByDepthUserLensDto)?.userLensTrackItems
    ) {
      setSelectedTrackItems(
        (userLens as ParameterByDepthUserLensDto).userLensTrackItems.sort(
          (a, b) => a.position - b.position,
        ),
      );
    }
  }, [selectedTrackItems.length, userLens]);

  const dimension: DimensionType = useMemo(() => {
    if (kpiType) {
      return kpiType.dimension;
    }

    if (kpiGroup && kpiTypes?.byId[kpiGroup.kpiTypeIds[0]].dimension) {
      return kpiTypes?.byId[kpiGroup.kpiTypeIds[0]].dimension;
    }

    return DimensionType.Seconds;
  }, [kpiType, kpiGroup, kpiTypes?.byId]);

  if (!Number.isFinite(lensIdNumber)) {
    return <Navigate to={`/well/${wellId}`} />;
  }
  const isParameterLens =
    isParameterByDepthTemplate(template) ||
    isParameterRoadmapTemplate(template);
  if (!template) return null;

  const ContainerComponentInfo = getContainerLens(template.type);
  const hasHeader =
    isParameterLens || isTorqueAndDrag(template) || ContainerComponentInfo;
  const paddingLeft =
    !isParameterLens || ContainerComponentInfo ? LABELS_PADDING : 0;

  return (
    <div style={{ overflow: "hidden", height: "100%" }}>
      <Row
        justify="space-between"
        wrap={false}
        style={{
          height: "100%",
        }}
      >
        <StyledColTransition
          flex={
            legendVisibilityState
              ? `1 0 calc(100% - ${LegendWidth})`
              : "1 0 100%"
          }
          width={legendVisibilityState ? `calc(100% - ${LegendWidth})` : "100%"}
        >
          <ChartContainer>
            <div>
              <NameBar wellId={wellId} />
            </div>
            {!hasHeader && kpiValues.mainKPIValues ? (
              <div style={{ height: "200px", display: "flex" }}>
                <LensSummaryView
                  wellId={Number(wellId)}
                  lensId={lensIdNumber}
                  mainKPIValues={kpiValues.mainKPIValues}
                  secondaryKPIValues={kpiValues.secondaryKPIValues}
                />
              </div>
            ) : null}
            <div style={{ flexGrow: 1, height: "70vh", paddingLeft }}>
              <Suspense
                fallback={
                  <MiniLoadingChart
                    id={userLens.id}
                    detailed
                    label={isParameterLens ? "..." : template?.name || ""}
                    description="..."
                    isLoading
                  />
                }
              >
                {ContainerComponentInfo ? (
                  <ContainerComponentInfo.Component
                    lens={userLens}
                    detailed
                    dimension={dimension}
                    setLastUpdatedDate={noop}
                    displayOption={displayOption}
                    focalWellColor={color}
                    graphKey={userLens.id.toString()}
                    selectedTrackItems={selectedTrackItems}
                    setSelectedTrackItems={setSelectedTrackItems}
                  />
                ) : null}
              </Suspense>
            </div>
          </ChartContainer>
        </StyledColTransition>
        <StyledColTransition flex={`0 0 ${LegendWidth}`}>
          <LensLegendView isReport template={template} />
        </StyledColTransition>
      </Row>
    </div>
  );
};

export default Lens;
