/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActualTvdPhaseDto } from './ActualTvdPhaseDto';
import {
    ActualTvdPhaseDtoFromJSON,
    ActualTvdPhaseDtoFromJSONTyped,
    ActualTvdPhaseDtoToJSON,
} from './ActualTvdPhaseDto';
import type { TvdHoleDirectionDto } from './TvdHoleDirectionDto';
import {
    TvdHoleDirectionDtoFromJSON,
    TvdHoleDirectionDtoFromJSONTyped,
    TvdHoleDirectionDtoToJSON,
} from './TvdHoleDirectionDto';
import type { TvdSectionDto } from './TvdSectionDto';
import {
    TvdSectionDtoFromJSON,
    TvdSectionDtoFromJSONTyped,
    TvdSectionDtoToJSON,
} from './TvdSectionDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { ActualTvdPointDto } from './ActualTvdPointDto';
import {
    ActualTvdPointDtoFromJSON,
    ActualTvdPointDtoFromJSONTyped,
    ActualTvdPointDtoToJSON,
} from './ActualTvdPointDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ActualTvdSeriesDto
 */
export interface ActualTvdSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof ActualTvdSeriesDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof ActualTvdSeriesDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof ActualTvdSeriesDto
     */
    readonly dataState: ResultDataState;
    /**
     * 
     * @type {Array<ActualTvdPointDto>}
     * @memberof ActualTvdSeriesDto
     */
    series?: Array<ActualTvdPointDto> | null;
    /**
     * 
     * @type {Array<ActualTvdPhaseDto>}
     * @memberof ActualTvdSeriesDto
     */
    phases: Array<ActualTvdPhaseDto>;
    /**
     * 
     * @type {Array<TvdSectionDto>}
     * @memberof ActualTvdSeriesDto
     */
    sectionIntervals: Array<TvdSectionDto>;
    /**
     * 
     * @type {Array<TvdHoleDirectionDto>}
     * @memberof ActualTvdSeriesDto
     */
    directionIntervals: Array<TvdHoleDirectionDto>;
}



/**
 * Check if a given object implements the ActualTvdSeriesDto interface.
 */
export function instanceOfActualTvdSeriesDto(value: object): value is ActualTvdSeriesDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('phases' in value) || value['phases'] === undefined) return false;
    if (!('sectionIntervals' in value) || value['sectionIntervals'] === undefined) return false;
    if (!('directionIntervals' in value) || value['directionIntervals'] === undefined) return false;
    return true;
}

export function ActualTvdSeriesDtoFromJSON(json: any): ActualTvdSeriesDto {
    return ActualTvdSeriesDtoFromJSONTyped(json, false);
}

export function ActualTvdSeriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActualTvdSeriesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'series': json['Series'] == null ? undefined : ((json['Series'] as Array<any>).map(ActualTvdPointDtoFromJSON)),
        'phases': ((json['Phases'] as Array<any>).map(ActualTvdPhaseDtoFromJSON)),
        'sectionIntervals': ((json['SectionIntervals'] as Array<any>).map(TvdSectionDtoFromJSON)),
        'directionIntervals': ((json['DirectionIntervals'] as Array<any>).map(TvdHoleDirectionDtoFromJSON)),
    };
}

export function ActualTvdSeriesDtoToJSON(value?: Omit<ActualTvdSeriesDto, 'DataState'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Series': value['series'] == null ? undefined : ((value['series'] as Array<any>).map(ActualTvdPointDtoToJSON)),
        'Phases': ((value['phases'] as Array<any>).map(ActualTvdPhaseDtoToJSON)),
        'SectionIntervals': ((value['sectionIntervals'] as Array<any>).map(TvdSectionDtoToJSON)),
        'DirectionIntervals': ((value['directionIntervals'] as Array<any>).map(TvdHoleDirectionDtoToJSON)),
    };
}

