function pixelFormattableNumber(num: number) {
  return {
    asPixels: () => `${num}px`,
    asNumber: () => num,
  };
}

export const _SizesPx = {
  Layout: {
    TopBarHeight: pixelFormattableNumber(64),
  },
  Timeline: {
    ChronoLine: {
      LeftMargin: pixelFormattableNumber(26),
      TopMargin: pixelFormattableNumber(30),
      BottomPadding: pixelFormattableNumber(148),
    },
    Knot: {
      Size: pixelFormattableNumber(28),
      SizeSmall: pixelFormattableNumber(15),
    },
  },
  IntelPage: {
    LeftPaneSelectorHeight: pixelFormattableNumber(42),
    LeftPaneGap: pixelFormattableNumber(12),
    LeftPanePaddingLeft: pixelFormattableNumber(20),
  },
  Carousel: {
    Topbar: {
      height: pixelFormattableNumber(71),
    },
  },
};
