/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActualTvdPhaseDto
 */
export interface ActualTvdPhaseDto {
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPhaseDto
     */
    phasesId: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPhaseDto
     */
    startDynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTvdPhaseDto
     */
    endDynamicDuration: number;
}

/**
 * Check if a given object implements the ActualTvdPhaseDto interface.
 */
export function instanceOfActualTvdPhaseDto(value: object): value is ActualTvdPhaseDto {
    if (!('phasesId' in value) || value['phasesId'] === undefined) return false;
    if (!('startDynamicDuration' in value) || value['startDynamicDuration'] === undefined) return false;
    if (!('endDynamicDuration' in value) || value['endDynamicDuration'] === undefined) return false;
    return true;
}

export function ActualTvdPhaseDtoFromJSON(json: any): ActualTvdPhaseDto {
    return ActualTvdPhaseDtoFromJSONTyped(json, false);
}

export function ActualTvdPhaseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActualTvdPhaseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'phasesId': json['PhasesId'],
        'startDynamicDuration': json['StartDynamicDuration'],
        'endDynamicDuration': json['EndDynamicDuration'],
    };
}

export function ActualTvdPhaseDtoToJSON(value?: ActualTvdPhaseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'PhasesId': value['phasesId'],
        'StartDynamicDuration': value['startDynamicDuration'],
        'EndDynamicDuration': value['endDynamicDuration'],
    };
}

