import { Bar, Line } from "@visx/shape";
import { PDComponent } from "components/PDComponents";
import { DigestTimelineEventIndicator } from "components/TvDChart/components/DigestTimelineEvents/DigestTimelineEventIndicator/DigestTimelineEventIndicator";
import type { ExtendedCombinedEvents } from "components/TvDChart/components/DigestTimelineEvents/utils";
import { EVENT_ICON_SIZE, TOP_PADDING } from "components/TvDChart/constants";
import type { ICombinedEvents } from "components/TvDChart/types";
import type { ScaleLinear } from "d3";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import type { TimelineStates } from "reducers/stateReducer";
import { match, P } from "ts-pattern";
import colors from "utils/colors";
import { secondsInDay } from "utils/common";
import { Tooltip, Typography } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

export const DigestTimelineEvents = ({
  event,
  hoveredEvent,
  hoveredEventTvD,
  brushActiveRef,
  setHoveredEventTimeline,
  setHoveredEventTvD,
  paddingTop,
  xScale,
  yScale,
  currentPadding,
  dynamicHoleDepth,
  selectedEvent,
  setTimelineState,
}: {
  event: ExtendedCombinedEvents;
  hoveredEvent: ICombinedEvents | null;
  hoveredEventTvD: ICombinedEvents | null | undefined;
  brushActiveRef: React.MutableRefObject<boolean | null>;
  setHoveredEventTimeline: (event: ICombinedEvents | null) => void;
  setHoveredEventTvD: (
    value: React.SetStateAction<ICombinedEvents | null | undefined>,
  ) => void;
  paddingTop: number;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  currentPadding: number;
  selectedEvent: number | null;
  dynamicHoleDepth?: number | null;
  setTimelineState: (state: TimelineStates) => void;
}) => {
  const {
    themeStyle: { colors: themeColors },
    isDark,
  } = useCustomTheme();

  const getDuration = (d: number) => (d ?? 0) / secondsInDay;

  const getIcon = (event: ICombinedEvents) => {
    return match({
      type: event.type,
      combinedEventsCount: event.combinedEvents?.length,
    })
      .with({ combinedEventsCount: P.number.gt(0) }, () => (
        <Typography style={{ color: colors.black }}>
          {(event.combinedEvents || []).length + 1}
        </Typography>
      ))
      .with({ type: "UnplannedEvent" }, () => (
        <PDComponent.SvgIcon name="schedule" />
      ))
      .with({ type: "Note" }, () => <PDComponent.SvgIcon name="logbook" />)
      .otherwise(() => null);
  };

  const tooltipMessage = useMemo(() => {
    if (event.combinedEvents?.length) {
      return `${(event?.combinedEvents || []).length + 1} memos`;
    }
    return match(event.type)
      .with("UnplannedEvent", () => {
        return `Unplanned Event
          Start: ${dayjs(event.startDate).format("MMM DD, YYYY · HH:mm")}
          ${`End: ${event.endDate ? dayjs(event.endDate).format("MMM DD, YYYY · HH:mm") : "- -"}`}
          `;
      })
      .with("Note", () => {
        return `Logbook Entry
         Date: ${dayjs(event.startDate).format("MMM DD, YYYY · HH:mm")}
      `;
      })
      .otherwise(() => "");
  }, [event.combinedEvents, event.endDate, event.startDate, event.type]);

  const isHovered = useMemo(
    () =>
      hoveredEvent?.type === event?.type &&
      (event?.id === hoveredEvent?.id || event?.id === hoveredEventTvD?.id),
    [event, hoveredEvent?.id, hoveredEvent?.type, hoveredEventTvD],
  );

  const isSelected = useMemo(
    () => event.id === selectedEvent,
    [event.id, selectedEvent],
  );

  const topIconY = paddingTop + TOP_PADDING - EVENT_ICON_SIZE;

  const left =
    (event.startDynamicDuration
      ? xScale(getDuration(event.startDynamicDuration))
      : 0) + currentPadding;

  return (
    <>
      <Tooltip
        key={event?.id}
        placement="top"
        overlayStyle={{ whiteSpace: "pre-line" }}
        title={tooltipMessage}
        arrowPointAtCenter
        destroyTooltipOnHide
        open={isHovered || isSelected}
      >
        <DigestTimelineEventIndicator
          $isHovered={isHovered || isSelected}
          $isCombined={!!event.combinedEvents?.length}
          eventId={event?.id ? +event?.id : undefined}
          onClick={() => {
            if (brushActiveRef?.current || !event) return;
            setHoveredEventTimeline({
              id: event?.id,
              type: event?.type,
            });
            setHoveredEventTvD(event);
            setTimelineState("ActualMemos");
          }}
          onClickOutside={() => {
            setHoveredEventTimeline(null);
            setHoveredEventTvD(null);
          }}
          onMouseEnter={() => {
            if (!selectedEvent) {
              if (brushActiveRef?.current) return;
              setHoveredEventTimeline({
                id: event?.id,
                type: event?.type,
              });
              setHoveredEventTvD(event);
            }
          }}
          onMouseLeave={() => {
            if (!selectedEvent) {
              if (brushActiveRef?.current) return;
              setHoveredEventTimeline(null);
              setHoveredEventTvD(null);
            }
          }}
          top={topIconY}
          left={left}
          translateX="-50%"
          translateY="-50%"
        >
          {event ? getIcon(event) : null}
        </DigestTimelineEventIndicator>
      </Tooltip>

      <svg
        width={2}
        height={yScale.range()[1] + 10}
        style={{
          position: "absolute",
          top: topIconY + EVENT_ICON_SIZE / 2,
          left: left,
        }}
      >
        <Line
          x1={0}
          y1={0}
          x2={0}
          y2={393}
          stroke={themeColors.future_plan_line}
          strokeWidth={isDark ? 1 : 2}
          strokeDasharray={"2 4"}
        />
      </svg>

      {event.endDynamicDuration && event.startDynamicDuration && isSelected ? (
        <svg
          height={yScale.range()[1]}
          width={xScale(
            getDuration(event.endDynamicDuration - event.startDynamicDuration),
          )}
          style={{
            position: "absolute",
            top: paddingTop + TOP_PADDING,
            left: left,
          }}
        >
          <Bar
            width={xScale(
              getDuration(
                event.endDynamicDuration - event.startDynamicDuration,
              ),
            )}
            height={yScale.range()[1]}
            opacity={0.3}
            fill={colors.tern_grey}
          />
        </svg>
      ) : null}

      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        style={{
          position: "absolute",
          top: paddingTop + TOP_PADDING + yScale(dynamicHoleDepth ?? 0),
          left: left,
          transform: "translate(-50%, -100%)",
        }}
      >
        <defs>
          <filter
            id="plan_future_event"
            x="0"
            y="0"
            width="18"
            height="18"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="2"
              result="effect1_foregroundBlur_12559_143937"
            />
          </filter>
        </defs>
      </svg>

      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        style={{
          position: "absolute",
          top: paddingTop + TOP_PADDING + yScale(event.dynamicDepth ?? 0),
          left: left,
          transform: "translate(-50%, -100%)",
        }}
      >
        <path
          d="M12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12C10.6569 12 12 10.6569 12 9Z"
          fill={themeColors.future_plan_line}
        />
        <g opacity="0.5" filter="url(#plan_future_event)">
          <path
            d="M14 9C14 6.23858 11.7614 4 9 4C6.23858 4 4 6.23858 4 9C4 11.7614 6.23858 14 9 14C11.7614 14 14 11.7614 14 9Z"
            fill={themeColors.future_plan_line}
          />
        </g>
      </svg>
    </>
  );
};
