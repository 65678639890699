import { Title } from "atoms/Typography";
import { useIntelFilterActions } from "hooks/filters/useIntelFilterActions";
import {
  selectSimpleFilters,
  useIntelLinkedFiltersByGroupAndQuarter,
} from "hooks/intel/useIntelLinkedFilters";
import { DetailedFilterButton } from "pages/IntelDashboard/components/IntelHeader/DetailedFilterPopup/DetailedFilterButton";
import { IntelFilterTags } from "pages/IntelDashboard/components/IntelHeader/ItelFilterTags/IntelFilterTags";
import { MapModalButton } from "pages/IntelDashboard/components/IntelHeader/MapModalButton/MapModalButton";
import { SimpleFilterSelector } from "pages/IntelDashboard/components/IntelHeader/SimpleFilterSelector/SimpleFilterSelector";
import { useEffect } from "react";
import { useAppSelector } from "reducers/store";
import { Col } from "utils/componentLibrary";
import { PDConstants } from "utils/PDConstants/PDConstants";
import { useCustomTheme } from "utils/useTheme";

import { DepthFilter } from "./DepthFilter/DepthFilter";
import { QuarterSelector } from "./QuarterSelector/QuarterSelector";
import * as Styled from "./style";

export const IntelHeader = ({
  legendStatusText,
}: {
  legendStatusText: string;
}) => {
  const { atomThemeVariant } = useCustomTheme();

  const cosmeticFilterOptions = useAppSelector(
    (state) => state.intelFiltersCosmeticOptions,
  );

  const committedFilters = useAppSelector(
    (state) => state.intelFiltersCommitted,
  );

  const { commitPreselectedFilters } = useIntelFilterActions();

  const {
    data: simpleFilters,
    isLoading: isFilterOptionsLoading,
    isPlaceholderData,
  } = useIntelLinkedFiltersByGroupAndQuarter({
    select: (filtersFromNetwork) =>
      selectSimpleFilters(
        filtersFromNetwork,
        cosmeticFilterOptions,
        committedFilters,
      ),
  });

  const {
    data: allFilters,
    refetch,
    isFetching,
  } = useIntelLinkedFiltersByGroupAndQuarter();

  const hasAutoSelectedFilters = useAppSelector(
    (state) => state.intelDashboard.hasAutoSelectedFilters,
  );

  useEffect(() => {
    if (!isFetching && !hasAutoSelectedFilters) refetch();

    if (!hasAutoSelectedFilters && allFilters && !isFetching) {
      commitPreselectedFilters(allFilters);
    }
  }, [
    allFilters,
    commitPreselectedFilters,
    hasAutoSelectedFilters,
    isFetching,
    refetch,
  ]);

  return (
    <Styled.HeaderContainerRow
      justify="space-between"
      align="middle"
      wrap={false}
      id={PDConstants.uniqueDomIds.Intel.header}
    >
      <Styled.LegendStatus>
        <Title level={3} variant={atomThemeVariant} weight={500}>
          {legendStatusText}
        </Title>
      </Styled.LegendStatus>

      <Col>
        <Styled.RightContainer wrap>
          <Styled.FilterTagsContainer>
            <IntelFilterTags />
          </Styled.FilterTagsContainer>

          <Styled.PaddedSpace>
            <Title level={3} variant={"faded"} weight={400}>
              Filter by
            </Title>

            <DetailedFilterButton />
          </Styled.PaddedSpace>
          <Styled.PaddedSpace>
            <DepthFilter />
          </Styled.PaddedSpace>

          {simpleFilters?.map((singleFilter) => (
            <Styled.PaddedSpace key={singleFilter.storeKey}>
              <SimpleFilterSelector
                disabled={isFilterOptionsLoading || isPlaceholderData}
                displayName={singleFilter.displayName}
                storeKey={singleFilter.storeKey}
                listOptions={singleFilter.options}
                iconName={singleFilter.iconName}
              />
            </Styled.PaddedSpace>
          ))}

          <MapModalButton />
          <Styled.PaddedSpace>
            <QuarterSelector />
          </Styled.PaddedSpace>
        </Styled.RightContainer>
      </Col>
    </Styled.HeaderContainerRow>
  );
};
