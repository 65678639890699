import type { ParameterHeatmapResultSetDto } from "apis/oag";
import {
  type ApiParameterHeatmapUserLensesIdFactsPutRequest,
  ResultDataState,
} from "apis/oag";
import { getHeatmapDimensionsForParent } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/utils";
import { useParameterHeatmapFacts } from "hooks/facts/useParameterHeatmapFacts";
import { useFilterParams } from "hooks/filters/useFilterParams";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useEffect, useMemo, useRef, useState } from "react";
import { PARAMETER_LENS_SAMPLE_COUNT_NULL } from "utils/common";

interface ParameterHeatmapFetcherProps {
  height: number;
  width: number;
  lensId: number;
  isDetailed?: boolean;
}

// To prevent network requests while the user is resizing the window. Too high and it will take too much to load
// since the call will be done later, too soon and we might get too many requests. Depends on how fast the user resizes

const DEBOUNCE_DOM_RESIZE_EVENTS_MS = 250;

export function useParameterHeatmapFetcher({
  height,
  width,
  lensId,
  isDetailed,
}: ParameterHeatmapFetcherProps) {
  const previousData = useRef<ParameterHeatmapResultSetDto | undefined>();

  const [offsetWellsFromDetailed] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WIDGET,
    [],
  );
  const [offsetWellsGlobal] = useStateQuery<Array<number>>(
    URL_STATE_PARAM.OFFSET_WELL,
    [],
  );

  const offsetWellIds = useMemo(
    () => (isDetailed ? offsetWellsFromDetailed : offsetWellsGlobal),
    [isDetailed, offsetWellsFromDetailed, offsetWellsGlobal],
  );

  const { xBinCount, yBinCount } = getHeatmapDimensionsForParent({
    parentContainerHeight: height,
    parentContainerWidth: width,
  });

  const [xBinCountDebounced, setXBinCountDebounced] = useState(xBinCount);
  const [yBinCountDebounced, setYBinCountDebounced] = useState(yBinCount);
  const [shouldStart, setShouldStart] = useState(false);

  useEffect(() => {
    setShouldStart(false);

    const clearHandler = setTimeout(() => {
      setShouldStart(true);
      setXBinCountDebounced(xBinCount);
      setYBinCountDebounced(yBinCount);
    }, DEBOUNCE_DOM_RESIZE_EVENTS_MS);

    return () => {
      clearTimeout(clearHandler);
      setShouldStart(false);
    };
  }, [xBinCount, yBinCount]);

  const filterParams = useFilterParams();

  const requestParameters: ApiParameterHeatmapUserLensesIdFactsPutRequest =
    useMemo(
      () => ({
        id: lensId ?? -1,
        parameterHeatmapUserLensQueryDto: {
          ...filterParams,
          xBinCount: xBinCountDebounced,
          yBinCount: yBinCountDebounced,
          comparisonWellIds: [...offsetWellIds],
          sampleCount: PARAMETER_LENS_SAMPLE_COUNT_NULL,
          wellAggregationThreshold: 6,
        },
      }),
      [
        filterParams,
        lensId,
        offsetWellIds,
        xBinCountDebounced,
        yBinCountDebounced,
      ],
    );

  const heatmapBinsRequest = useParameterHeatmapFacts(
    lensId,
    requestParameters,
    {
      enabled: shouldStart && xBinCountDebounced > 1 && yBinCountDebounced > 1,
      placeholderData: () => previousData.current,
    },
  );

  useEffect(() => {
    if (heatmapBinsRequest?.data?.dataState === ResultDataState.Valid) {
      previousData.current = heatmapBinsRequest.data;
    } else {
      previousData.current = undefined;
    }
  }, [previousData, heatmapBinsRequest]);

  return heatmapBinsRequest;
}
