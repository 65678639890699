import { round } from "lodash";
import type { UOMHelper } from "utils/format";

export const inputToNumber = (value: string, depthUOM: UOMHelper) =>
  value === "" || isNaN(+value) ? null : depthUOM.toSI(+value);

export const numberToInput = (
  value: number | null | undefined,
  depthUOM: UOMHelper,
) => {
  if (value === null || value === undefined) {
    return "";
  }
  return round(depthUOM.fromSI(+value), 2).toString();
};
