/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttachmentType } from './AttachmentType';
import {
    AttachmentTypeFromJSON,
    AttachmentTypeFromJSONTyped,
    AttachmentTypeToJSON,
} from './AttachmentType';
import type { AttachmentReadiness } from './AttachmentReadiness';
import {
    AttachmentReadinessFromJSON,
    AttachmentReadinessFromJSONTyped,
    AttachmentReadinessToJSON,
} from './AttachmentReadiness';
import type { VideoManifestDto } from './VideoManifestDto';
import {
    VideoManifestDtoFromJSON,
    VideoManifestDtoFromJSONTyped,
    VideoManifestDtoToJSON,
} from './VideoManifestDto';

/**
 * 
 * @export
 * @interface DigestEntryAttachmentDto
 */
export interface DigestEntryAttachmentDto {
    /**
     * 
     * @type {number}
     * @memberof DigestEntryAttachmentDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DigestEntryAttachmentDto
     */
    thumbnail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigestEntryAttachmentDto
     */
    sourceFilename?: string | null;
    /**
     * 
     * @type {VideoManifestDto}
     * @memberof DigestEntryAttachmentDto
     */
    videoManifests?: VideoManifestDto | null;
    /**
     * 
     * @type {string}
     * @memberof DigestEntryAttachmentDto
     */
    mimeType: string;
    /**
     * 
     * @type {AttachmentReadiness}
     * @memberof DigestEntryAttachmentDto
     */
    readiness: AttachmentReadiness;
    /**
     * 
     * @type {number}
     * @memberof DigestEntryAttachmentDto
     */
    digestEntryId: number;
    /**
     * 
     * @type {AttachmentType}
     * @memberof DigestEntryAttachmentDto
     */
    readonly attachmentType: AttachmentType;
}



/**
 * Check if a given object implements the DigestEntryAttachmentDto interface.
 */
export function instanceOfDigestEntryAttachmentDto(value: object): value is DigestEntryAttachmentDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('mimeType' in value) || value['mimeType'] === undefined) return false;
    if (!('readiness' in value) || value['readiness'] === undefined) return false;
    if (!('digestEntryId' in value) || value['digestEntryId'] === undefined) return false;
    if (!('attachmentType' in value) || value['attachmentType'] === undefined) return false;
    return true;
}

export function DigestEntryAttachmentDtoFromJSON(json: any): DigestEntryAttachmentDto {
    return DigestEntryAttachmentDtoFromJSONTyped(json, false);
}

export function DigestEntryAttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigestEntryAttachmentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'thumbnail': json['Thumbnail'] == null ? undefined : json['Thumbnail'],
        'sourceFilename': json['SourceFilename'] == null ? undefined : json['SourceFilename'],
        'videoManifests': json['VideoManifests'] == null ? undefined : VideoManifestDtoFromJSON(json['VideoManifests']),
        'mimeType': json['MimeType'],
        'readiness': AttachmentReadinessFromJSON(json['Readiness']),
        'digestEntryId': json['DigestEntryId'],
        'attachmentType': AttachmentTypeFromJSON(json['AttachmentType']),
    };
}

export function DigestEntryAttachmentDtoToJSON(value?: Omit<DigestEntryAttachmentDto, 'AttachmentType'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Thumbnail': value['thumbnail'],
        'SourceFilename': value['sourceFilename'],
        'VideoManifests': VideoManifestDtoToJSON(value['videoManifests']),
        'MimeType': value['mimeType'],
        'Readiness': AttachmentReadinessToJSON(value['readiness']),
        'DigestEntryId': value['digestEntryId'],
    };
}

