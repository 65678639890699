import styled from "styled-components";
import { PDConstants } from "utils/PDConstants/PDConstants";
import { zIndexLayer } from "utils/zIndex";

export const LegendGroupSelectorWrapper = styled.div`
  height: 0;
  width: 253px;
  left: ${PDConstants.SizesPx.IntelPage.LeftPanePaddingLeft.asPixels()};
  position: sticky;
  z-index: ${zIndexLayer.above};
  align-self: start;
`;
